import React from "react";
import { Maintable } from "../css/Common";

const GRNDetails = () => {
  return (
    <div class="flex">
    <div className="flex flex-col w-full">
    <div className={Maintable.clientMasterMainAdd}>
      <div className="ml-12" class={Maintable.addScreenTitle}>{"GRN -> Add"}</div>
      <div className={Maintable.addShadowScreen}>
        <div className={Maintable.mainContentScreen}>
          <form className={Maintable.formForClientMaster}>
            <div className={Maintable.labelBold}>Make GRN</div>
            <hr className={Maintable.horizontalLine} />
            {/* Existing input boxes */}
            <div className="grid gap-4 mb-6 md:grid-cols-4 mt-6">
              <div>
                <label className={Maintable.label}>
                  GRN No
                  <span className="text-red-600">*</span>
                </label>
                <input type="text" className={Maintable.input} />
              </div>
              <div>
                <label className={Maintable.label}>
                  Date
                  <span className="text-red-600">*</span>
                </label>
                <input type="date" className={Maintable.input} value={"2023-06-27"} readOnly />
              </div>
              <div>
                <label className={Maintable.label}>
                  Received Date
                  <span className="text-red-600">*</span>
                </label>
                <input type="date" className={Maintable.input} value={"2023-06-27"} readOnly/>
              </div>
              <div>
                <label className={Maintable.label}>
                  Transport Ref.
                  <span className="text-red-600">*</span>
                </label>
                <input type="text" className={Maintable.input} />
              </div>
            </div>
            {/* End of existing input boxes */}
            {/* New table for displaying inputs */}
            <table className="w-full border border-gray-300 mt-6">
              <thead>
                <tr className="">                 
                  <th className="border text-white bg-black border-gray-300 p-2">
                  RawMeterial Name
                  </th>
                  <th className="border text-white bg-black border-gray-300 p-2">
                    RawMeterial Code
                  </th>
                  <th className="border text-white bg-black border-gray-300 p-2">
                    UOM
                  </th>
                  <th className="border text-white bg-black border-gray-300 p-2">
                    P.O. Quantity
                  </th>
                  <th className="border text-white bg-black border-gray-300 p-2">
                    Recd.Until Now
                  </th>
                  <th className="border text-white bg-black border-gray-300 p-2">
                    Receivable Qty
                  </th>
                  <th className="border text-white bg-black border-gray-300 p-2">
                    Received Now
                  </th>
                  <th className="border text-white bg-black border-gray-300 p-2">
                    Balance
                  </th>                       
                </tr>
              </thead>
              <tbody>
                <tr>
                <td className="border border-gray-300 p-2">
                    <input
                      type="text"
                      placeholder="Description"
                      value="Polymer"
                      className={Maintable.input}
                      readonly
                    />
                  </td>
                  <td className="border border-gray-300 p-1">
                    <input
                      type="text"
                      placeholder="Item Code"
                      value="RMLD00001"
                      className={Maintable.input}
                      readonly
                    />
                  </td>
                  <td className="border border-gray-300 p-2">
                    <input
                      type="text"
                      placeholder="UOM"
                      value="KG"
                      className={Maintable.input}
                      readonly
                    />
                  </td>
                  <td className="border border-gray-300 p-2">
                    <input
                      type="text"
                      placeholder="P.O. Qty"
                      value="1000"
                      className={Maintable.input}
                      readonly
                    />
                  </td>

                  <td className="border border-gray-300 p-1">
                    <input
                      type="text"
                      name="receivedQuantity"
                      value="0"
                      className={Maintable.input}
                      readonly
                    />
                  </td>
                  <td className="border border-gray-300 p-1">
                    <input
                      type="text"
                      value="1000"
                      placeholder="Receivable Qty"
                      className={Maintable.input}
                      readonly
                    />
                  </td>
                  <td className="border border-gray-300 p-2">
                    <input
                      type="text"
                      name="receivedNow"
                      value="0"
                      placeholder="Received Now"
                      className={Maintable.input}
                      readonly
                    />
                  </td>
                  <td className="border border-gray-300 p-1">
                    <input
                      type="text"
                      name="balance"
                      value="1000"
                      className={Maintable.input}
                      readonly
                    />
                  </td>                       
                </tr>
                <tr>
                <td className="border border-gray-300 p-2">
                    <input
                      type="text"
                      placeholder="Description"
                      className={Maintable.input}
                      value="Acrylic"
                      readonly
                    />
                  </td>
                  <td className="border border-gray-300 p-1">
                    <input
                      type="text"
                      placeholder="Item Code"
                      className={Maintable.input}
                      value="RMAD00001"
                      readonly
                    />
                  </td>
                  <td className="border border-gray-300 p-2">
                    <input
                      type="text"
                      placeholder="UOM"
                      className={Maintable.input}
                      value="KG"
                      readonly
                    />
                  </td>
                  <td className="border border-gray-300 p-2">
                    <input
                      type="text"
                      placeholder="P.O. Qty"
                      className={Maintable.input}
                      value="1000"
                      readonly
                    />
                  </td>

                  <td className="border border-gray-300 p-1">
                    <input
                      type="text"
                      placeholder="Recd.Until Now"
                      className={Maintable.input}
                      readonly
                      value="0"
                    />
                  </td>
                  <td className="border border-gray-300 p-1">
                    <input
                      type="text"
                      placeholder="Receivable Qty"
                      className={Maintable.input}
                      readonly
                      value="1000"
                    />
                  </td>
                  <td className="border border-gray-300 p-1">
                    <input
                      type="text"
                      placeholder="Received Now"
                      className={Maintable.input}
                      readonly
                      value="0"
                    />
                  </td>
                  <td className="border border-gray-300 p-2">
                    <input
                      type="text"
                      placeholder="Balance"
                      className={Maintable.input}
                      readonly
                      value="1000"
                    />
                  </td>                       
                </tr>
                <tr>
                <td className="border border-gray-300 p-2">
                    <input
                      type="text"
                      placeholder="Description"
                      className={Maintable.input}
                      value="Pigments"
                      readonly
                    />
                  </td>
                  <td className="border border-gray-300 p-1">
                    <input
                      type="text"
                      placeholder="Item Code"
                      className={Maintable.input}
                      value="RMIN00001"
                      readonly
                    />
                  </td>                
                  <td className="border border-gray-300 p-2">
                    <input
                      type="text"
                      placeholder="UOM"
                      className={Maintable.input}
                      value="KG"
                      readonly
                    />
                  </td>
                  <td className="border border-gray-300 p-2">
                    <input
                      type="text"
                      placeholder="P.O. Qty"
                      className={Maintable.input}
                      value="1000"
                      readonly
                      
                    />
                  </td>

                  <td className="border border-gray-300 p-1">
                    <input
                      type="text"
                      placeholder="Recd.Until Now"
                      className={Maintable.input}
                      value="0"
                      readonly
                    />
                  </td>
                  <td className="border border-gray-300 p-1">
                    <input
                      type="text"
                      placeholder="Receivable Qty"
                      className={Maintable.input}
                      readonly
                      value="1000"
                    />
                  </td>
                  <td className="border border-gray-300 p-1">
                    <input
                      type="text"
                      placeholder="Received Now"
                      className={Maintable.input}
                      readonly
                      value="0"
                    />
                  </td>
                  <td className="border border-gray-300 p-2">
                    <input
                      type="text"
                      placeholder="Balance"
                      className={Maintable.input}
                      readonly
                      value="1000"
                    />
                  </td>
                </tr>
                <tr>
                <td className="border border-gray-300 p-2">
                    <input
                      type="text"
                      placeholder="Description"
                      className={Maintable.input}
                      value="Solvents"
                      readonly
                    />
                  </td>
                  <td className="border border-gray-300 p-1">
                    <input
                      type="text"
                      placeholder="Item Code"
                      className={Maintable.input}
                      value="RMSL00001"
                      readonly
                    />
                  </td>
                  <td className="border border-gray-300 p-2">
                    <input
                      type="text"
                      placeholder="UOM"
                      className={Maintable.input}
                      value="KG"
                      readonly
                    />
                  </td>
                  <td className="border border-gray-300 p-2">
                    <input
                      type="text"
                      placeholder="P.O. Qty"
                      className={Maintable.input}
                      value="1000"
                      readonly
                    />
                  </td>

                  <td className="border border-gray-300 p-1">
                    <input
                      type="text"
                      placeholder="Recd.Until Now"
                      className={Maintable.input}
                      readonly
                      value="0"
                    />
                  </td>
                  <td className="border border-gray-300 p-1">
                    <input
                      type="text"
                      placeholder="Receivable Qty"
                      className={Maintable.input}
                      readonly
                      value="1000"
                    />
                  </td>
                  <td className="border border-gray-300 p-1">
                    <input
                      type="text"
                      placeholder="Received Now"
                      className={Maintable.input}
                      readonly
                      value="0"
                    />
                  </td>
                  <td className="border border-gray-300 p-2">
                    <input
                      type="text"
                      placeholder="Balance"
                      className={Maintable.input}
                      value="1000"
                      readonly
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            {/* End of new table */}
          </form>
        </div>
      </div>
    </div>
  </div>
  </div>
  );
};
export default GRNDetails;
