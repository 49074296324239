import React from "react";
import MainLayout from "../components/Mainlayout";
import { Common, Maintable } from "../css/Common";
import TableGrid from "../components/table";
import { useState } from "react";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";

const maincontent = {
  maincontent: "w-2/5 mx-auto",
};
const Container = styled.div`
height: 243px;
`;
const modules = {
  toolbar: [
    //[{ 'font': [] }],
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    [{ align: [] }, { color: [] }, { background: [] }],
    ["clean"],
  ],
};

const formats = [
  //'font',
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "align",
  "color",
  "background",
];

const ShrinkFilm = () => {
  const [visibleTable, setVisibleTable] = useState(true);
  const [visibleAdd, setVisibleAdd] = useState(false);

  const toggleData = () => {
    setVisibleTable(false);
    setVisibleAdd(true);
  };

  const cancel = () => {
   setVisibleTable(true);
   setVisibleAdd(false);
  };
  const [bindUOMDD, setBindUOMDD] = useState([
    { value: "MTRS", label: "MTRS" },
    { value: "km", label: "km" },
    { value: "meters", label: "meters" },
    { value: "cm", label: "cm" },
    { value: "ft", label: "ft" },
    { value: "lb", label: "lb" },
    { value: "KG", label: "KG" },
    { value: "oz", label: "oz" },
    { value: "s", label: "s" },
    { value: "min", label: "min" },
    { value: "hr", label: "hr" },
    { value: "°C", label: "°C" },
    { value: "°F", label: "°F" },
    { value: "K", label: "K" },
    { value: "L", label: "L" },
    { value: "mL", label: "mL" },
    { value: "gal", label: "gal" },
    { value: "lbf", label: "lbf" },
    { value: "N", label: "N" },
    { value: "Pa", label: "Pa" },
    { value: "bar", label: "bar" },
    { value: "MT", label: "MT" },
    { value: "MTRS", label: "MTRS" },
    { value: "N/A", label: "N/A" },
    { value: "BDLS", label: "BDLS" },
    { value: "SACKS", label: "SACKS" },
    { value: "pcs", label: "pcs" },

  ]);
  const [bindMachinesDD, setBindMachinesDD] = useState([
    { value: "s", label: "Excelam LBD-750mm Cold Roll to Roll Semi  LM00008 " },
    { value: "oz", label: "LC Rubber Roll To Roll Lamination Machine 360  LM00007 " },
    { value: "km", label: "LD SHRINK Film Plant  BF00001 " },
    { value: "min", label: "NPC280H TWC00009 " },
    { value: "hr", label: "NPC450 TWC00010 " },
    { value: "lb", label: "Pallet shrink wrap-Labelong WP00005 " },
    { value: "meters", label: "PVC Soft film plant BF-00002 " },
    { value: "cm", label: "SterFlex PF00003 " },
    { value: "ft", label: "SterFlex 2000 PF00004 " },
    { value: "KG", label: "ST6040A + BSE6040A Automatic Sleeve Sealer WP00006 " },
  ]);
  const [Operator, setOperator] = useState([

    { value: "Ababio", label: "Ababio" },
    { value: "Aboagye", label: "Aboagye" },
    { value: "Bubune", label: "Bubune" },
    { value: "Coblah", label: "Coblah" },
    { value: "Commie", label: "Commie" },
    { value: "Dodzi", label: "Dodzi" },
    { value: "Enam", label: "Enam" },
    { value: "Kaatachi", label: "Kaatachi" },
    { value: "Kwesi", label: "Kwesi" },
    { value: "Majid", label: "Majid" },
    { value: "Osei", label: "Osei" },
    { value: "Panyin", label: "Panyin" },
    { value: "Sabir", label: "Sabir" },
    { value: "Yooku", label: "Yooku" },
  ]);
  const [Supervisor, setSupervisor] = useState([
    { value: "Agymah", label: "Agymah" },
    { value: "Botwe", label: "Botwe" },
    { value: "Danquah", label: "Danquah" },
    { value: "Dzifa", label: "Dzifa" },
    { value: "Elinam", label: "Commie" },
    { value: "Dodzi", label: "Elinam" },
    { value: "Kojo", label: "Kojo" },
    { value: "Likem", label: "Likem" },
    { value: "Madonudenu", label: "Madonudenu" },
    { value: "Makafui", label: "Makafui" },
    { value: "Nkrumah", label: "Nkrumah" },
    { value: "Panyinsoj", label: "Panyinsoj" },
    { value: " Xoese", label: " Xoese" },
    { value: "Youvanan", label: "Youvanan" },
  ]);
  const [bindMaterialDD, setBindMaterialDD] = useState([
    { value: "injectionmoulding", label: "Acrylic (RMAD00001)" },
    { value: "rmstore", label: "Lamination Film(LAM00001)" },
    { value: "slitting", label: "Pigments (RMIN00001)" },
    { value: "fgstore", label: "Plain Shrink Film(PSF00001)" },
    { value: "blowfilm", label: "Polymer (RMLD00001)" },
    { value: "printing", label: "Polyethylene (RMHD00001)" },
    { value: "reproduction", label: "Polymers (RMSD00001)" },
    { value: "rmstore", label: "Printed Shrink Film(PSF00002)" },
    { value: "fgstore", label: "Shrink Film for Water Pouch(PSF00003)" },
    { value: "lamination", label: "Solvents (RMSL00001)" },
    { value: "fgstore", label: "Thinwall Food Containers(TFC00001)" },
  ]);
  return (
    <MainLayout>
      <div class="flex">
        {visibleTable && (
          <div class="relative w-full mx-auto">
            <div class={Maintable.headerTitle}>
              <div class="my-2 ml-8">Blow Film</div>
            </div>
            <div className="mx-8">
              <div className="md:justify-between md:flex  md:items-center">
                <div className={Maintable.searchAdd}>
                  <div className="mt-1 gap-2 flex justify-between items-baseline">
                    <label htmlFor="pageSize" className="hidden md:block">
                      Rows Per Page:
                    </label>
                    <select
                      className={Maintable.dropDownRecords}
                      name="pageSize"
                      id="pageSize"
                    >
                      <option value="2">2</option>
                      <option value="10" selected>
                        10
                      </option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor="table-search" className="sr-only">
                      Search
                    </label>
                  </div>
                  <div className="relative mt-1">
                    <div className={Maintable.searchIconPosition}>
                      <svg
                        className={Maintable.searchIconSvg}
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <input
                      type="text"
                      id="table-search"
                      className={Maintable.searchInputBox}
                      placeholder="Search for items"
                    />
                  </div>
                </div>
                <div className="flex self-end">
                  <div className="text-red-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner w-fit h-9 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">
                    <span className="mb-12 text-red-600 text-7xl">.</span>
                    <span>Block</span>
                  </div>
                  <button
                    type="button"
                    className={Maintable.addButton}
                    onClick={toggleData}
                  >
                    <svg
                      width="25"
                      height="20"
                      viewBox="0 0 50 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22.5 37.5H27.5V27.5H37.5V22.5H27.5V12.5H22.5V22.5H12.5V27.5H22.5V37.5ZM25 50C21.5417 50 18.2917 49.3433 15.25 48.03C12.2083 46.7183 9.5625 44.9375 7.3125 42.6875C5.0625 40.4375 3.28167 37.7917 1.97 34.75C0.656667 31.7083 0 28.4583 0 25C0 21.5417 0.656667 18.2917 1.97 15.25C3.28167 12.2083 5.0625 9.5625 7.3125 7.3125C9.5625 5.0625 12.2083 3.28083 15.25 1.9675C18.2917 0.655833 21.5417 0 25 0C28.4583 0 31.7083 0.655833 34.75 1.9675C37.7917 3.28083 40.4375 5.0625 42.6875 7.3125C44.9375 9.5625 46.7183 12.2083 48.03 15.25C49.3433 18.2917 50 21.5417 50 25C50 28.4583 49.3433 31.7083 48.03 34.75C46.7183 37.7917 44.9375 40.4375 42.6875 42.6875C40.4375 44.9375 37.7917 46.7183 34.75 48.03C31.7083 49.3433 28.4583 50 25 50ZM25 45C30.5833 45 35.3125 43.0625 39.1875 39.1875C43.0625 35.3125 45 30.5833 45 25C45 19.4167 43.0625 14.6875 39.1875 10.8125C35.3125 6.9375 30.5833 5 25 5C19.4167 5 14.6875 6.9375 10.8125 10.8125C6.9375 14.6875 5 19.4167 5 25C5 30.5833 6.9375 35.3125 10.8125 39.1875C14.6875 43.0625 19.4167 45 25 45Z"
                        fill="white"
                      />
                    </svg>
                    ADD
                  </button>
                </div>
              </div>
              <TableGrid
                className={Maintable.Table}

              />
              <div className={Maintable.submitCancelMainDiv}>
                <button               
                  type="button"
                  className={Maintable.submitButton}
                >
                  Previous
                </button>
                <label>1</label>
                <button              
                  type="button"
                  className={Maintable.cancelButton}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        )}
        {visibleAdd && (
          <div className="flex flex-col w-full">
            <div className={Maintable.clientMasterMainAdd}>
              <div className={Maintable.addScreenTitle}>
                {"Blow Film Production -> Add"}
              </div>
              <div className={Maintable.addShadowScreen}>
                <div className={Maintable.mainContentScreen}>
                  <form className={Maintable.formForClientMaster}>
                    <div className={Maintable.labelBold}>Department</div>
                    <hr className={Maintable.horizontalLine} />
                    <div className={Maintable.grid2}>
                    <div>
                      <label for="first_name" className={Maintable.label}>
                        Production Slip No.
                      </label>
                      <input
                        type="text"
                        id="issueslip"
                        name="issueslip"
                        placeholder="Production Slip No."
                        class={Maintable.input}                      
                      />                     
                    </div>
                    <div>
                      <label for="designation" className={Maintable.label}>
                        Date
                      </label>
                      <input
                        type="date"
                        id="issuedate"
                        name="issuedate"
                        placeholder="HR"                      
                        class={Maintable.input}                       
                      />                   
                    </div>
                  </div>
                  <div className={Maintable.grid2}>
                    <div>
                      <label for="first_name" className={Maintable.label}>
                        Machine
                      </label>
                      <Select
                        options={bindMachinesDD}
                        className={Maintable.input1}
                      />                   
                    </div>
                    <div>
                      <label for="first_name" className={Maintable.label}>
                        Machine Capacity
                      </label>
                      <input
                        className={Maintable.input}
                        type="text"
                        placeholder="Machine Capacity" 
                      />                   
                    </div>
                    <div>
                      <label for="first_name" className={Maintable.label}>
                        Operator
                      </label>
                      <Select
                        options={Operator}
                        className={Maintable.input1}
                      />
                    </div>
                    <div>
                      <label for="first_name" className={Maintable.label}>
                        Supervisor
                      </label>
                      <Select
                        options={Supervisor}
                        className={Maintable.input1}
                      />                    
                    </div>
                    
                  </div>
                  <div class={Maintable.labelBold}>Output</div>
                      <hr class={Maintable.horizontalLine}></hr>
                      <div className={Maintable.grid2}>
                      <div>
                      <label for="first_name" className={Maintable.label}>
                        Product
                      </label>
                      <Select
                        options={bindMaterialDD}
                        className={Maintable.input1}
                      />                     
                    </div>
                    <div>
                      <div className={Maintable.grid1_2} >
                      <div>
                          <label for="phone" className={Maintable.label}>
                            Quantity
                          </label>
                          <input
                            type="text"
                            id="mobile"
                            name="user_Mobile"
                            placeholder="Opening Quantity"
                            class={Maintable.input}                       
                          />                       
                        </div>
                        <div >
                          <label for="passsword" className={Maintable.label}>
                            U.O.M.
                          </label>
                          <Select
                            options={bindUOMDD}
                            className={Maintable.input1}
                          />
                        </div>           
                      </div>
                    </div>
                    <div>
                      <label for="first_name" className={Maintable.label}>
                        Usable Waste
                      </label>
                      <Select
                        options={bindMaterialDD}
                        className={Maintable.input1}
                      />                         
                    </div>
                    <div>
                      <div className={Maintable.grid1_2} >
                      <div>
                          <label for="phone" className={Maintable.label}>
                            Quantity
                          </label>
                          <input
                            type="text"
                            id="mobile"
                            name="user_Mobile"
                            placeholder="Opening Quantity"                   
                            class={Maintable.input}
                          />                       
                        </div>
                        <div >
                          <label for="passsword" className={Maintable.label}>
                            U.O.M.
                          </label>
                          <Select
                            options={bindUOMDD}
                            className={Maintable.input1}
                          />                                                
                        </div>
                      </div>
                    </div>              
                    <div>
                      <label for="first_name" className={Maintable.label}>
                        Unusable Waste
                      </label>
                      <Select
                        options={bindMaterialDD}
                        className={Maintable.input1}
                      />                 
                    </div>
                    <div>
                      <div className={Maintable.grid1_2} >
                      <div>
                          <label for="phone" className={Maintable.label}>
                            Quantity
                          </label>
                          <input
                            type="text"
                            id="mobile"
                            name="user_Mobile"
                            placeholder="Opening Quantity"                          
                            class={Maintable.input}
                          />                        
                        </div>
                        <div >
                          <label for="passsword" className={Maintable.label}>
                            U.O.M.
                          </label>
                          <Select
                            options={bindUOMDD}
                            className={Maintable.input1}
                          />                                               
                        </div>
                      </div>
                    </div>

                    <div className="col-span-2">
                      <label for="email" className={Maintable.label}>
                        Remarks
                      </label>
                      <div class={Maintable.input_richtextbox}>
                        <Container>
                          <ReactQuill
                            style={{ height: "200px" }}
                            theme="snow"
                            modules={modules}
                            formats={formats}
                            id="message"
                            name="cli_Description"
                          />
                        </Container>
                      </div>
                    </div>
                    </div>

                    {/* End of existing input boxes */}
                    <div className={Maintable.submitCancelMainDiv}>
                      <button
                        data-modal-hide="bottom-right-modal"
                        type="button"
                        className={Maintable.submitButton}
                      >
                        SUBMIT
                      </button>
                      <button
                        data-modal-hide="bottom-right-modal"
                        type="button"
                        className={Maintable.cancelButton}
                        onClick={cancel}
                      >
                        CANCEL
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default ShrinkFilm;
