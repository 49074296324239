import React from "react";
import { Maintable } from "../css/Common";

const UserDetails = ({ data }) => {

  console.log("Details:", data);
  return (
    <div>
      <div class="w-full">
        <div>
          <div class="text-xl font-medium text-neutral-700 dark:text-white">
            {"User Master -> User Details View"}
          </div>
          <div>
            <div class={Maintable.mainContentScreen}>
              <form class={Maintable.formForClientMaster}>
                <div class={Maintable.grid2}>
                  <div>
                    <label for="first_name" class={Maintable.label}>
                      First Name
                    </label>

                    <input
                      type="text"
                      id="firstname"
                      name="user_Firstname"
                      value={data.User_FirstName}
                      placeholder="John"
                      readOnly
                      class={Maintable.input}
                    />
                  </div>
                  <div>
                    <label for="lastname" class={Maintable.label}>
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="last_name"
                      name="user_Lastname"
                      value={data.User_LastName}
                      placeholder="Doe"
                      readOnly
                      class={Maintable.input}
                    />
                  </div>
                  <div class="">
                    <label for="email" class={Maintable.label}>
                      Email address
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="user_Email"
                      value={data.User_Email}
                      placeholder="john.doe@company.com"
                      readOnly
                      class={Maintable.input}
                    />
                  </div>
                  <div>
                    <label for="phone" class={Maintable.label}>
                      Phone number
                    </label>
                    <input
                      type="tel"
                      id="mobile"
                      name="user_Mobile"
                      value={data.User_PhoneNumber}
                      placeholder="123-45-678"
                      readOnly
                      class={Maintable.input}
                    />
                  </div>

                  <div>
                    <label for="designation" class={Maintable.label}>
                      Designation
                    </label>
                    <input
                      type="text"
                      id="last_name"
                      name="user_Designation"
                      value={data.User_Designation}
                      placeholder="HR"
                      readOnly
                      class={Maintable.input}
                    />
                  </div>
                  <div>
                    <label for="Department" class={Maintable.label}>
                      Department
                    </label>
                    <input
                      type="text"
                      id="Department"
                      name="user_Department"
                      value={data.User_Department}
                      placeholder="Developer"
                      readOnly
                      class={Maintable.input}
                    />
                  </div>

                  <div>
                    <label class={Maintable.label} for="multiple_files">
                      Photo
                    </label>
                    <div className="flex items-center">
                    <input
                      class={Maintable.fileUpload}
                      className="h-16 "
                      name="user_Image"
                     // value={data.User_Image}
                      id="profile"
                      type="file"
                      multiple
                    />
                    <div className=""><input
                    type="image"
                    src={data.User_Image}
                    alt="Image"
                    className="rounded-full w-20 h-20"
                  /></div>
                    </div>
                    
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
