import React, { useEffect, useState } from "react";
import { Maintable, validationcolor } from "../css/Common";
import { useNavigate } from "react-router-dom";
// import {
//   empBindDataAPI,
//   employeeMasterInsertAPI,
//   emp_EditAPI,
//   emp_ImageAPI,
// } from "../services/EmployeeAdd";
import { EmployeeHeaders } from "../components/headerAttribute";
import TableGrid from "../components/table";
// import TableGrid from "../components/table1";
import "reactjs-popup/dist/index.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Popup from "reactjs-popup";
import SideMenu from "../components/SideMenu";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";
import EmployeeAddress from "../popup-containers/EmployeeAddress";
import EmployeeDetails from "../popup-containers/EmployeeDetails";
import MainPopup from "../popup-containers/MainPopup";
import moment from "moment/moment";
import Mainlayout from "../components/Mainlayout"
import download5 from "../image/download5.jfif";
import Ghana from "../image/Ghana.jfif"
import download6 from "../image/download6.jfif"
import download3 from "../image/download3.jfif"
function EmployeeMaster1() {


  const [bindEmployeeMasterDatas, setBindUserMasterDatas] = useState([
    {
      Emp_Image: (
        <img src={download5} alt="userimage" style={{ width: "70px", height: "60px" }} />
      ),
      Emp_EmployeeId: "UPPIL000071",
      Emp_EmployeeName: "Commie",
      Emp_Designation: "Operator",
      EmpDateofjoining: "25/03/21",
      Emp_Email: "commie@gmail.com",
      Emp_Mobile: "+233 509486355",
    },
    {
      Emp_Image: (
        <img src={Ghana} alt="userimage" style={{ width: "70px", height: "60px" }} />
      ),
      Emp_EmployeeId: "UPPIL000086",
      Emp_EmployeeName: "Kaatachi",
      Emp_Designation: "Supervisor",
      EmpDateofjoining: "07/09/20",
      Emp_Email: "kaatachi@gmail.com",
      Emp_Mobile: "+233 509486445",
    },
    {
      Emp_Image: (
        <img src={download6} alt="userimage" style={{ width: "70px", height: "60px" }} />
      ),
      Emp_EmployeeId: "UPPIL000052",
      Emp_EmployeeName: "Yooku",
      Emp_Designation: "Operator",
      EmpDateofjoining: "03/09/22",
      Emp_Email: "yooku@gmail.com",
      Emp_Mobile: "+233 509468449",
    },
    {
      Emp_Image: (
        <img src={download3} alt="userimage" style={{ width: "70px", height: "60px" }} />
      ),
      Emp_EmployeeId: "UPPIL000025",
      Emp_EmployeeName: "Enam",
      Emp_Designation: "Supervisor",
      EmpDateofjoining: "10/02/22",
      Emp_Email: "enam@gmail.com",
      Emp_Mobile: "+233 509468550",
    },
  ]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  var itemkey = sessionStorage.getItem("createdby");
  // // console.log("1234", itemkey);
  const [currentPage1, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [result, setResult] = useState(10);
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);
  const [viewPopUp, setViewPopUp] = useState(false);
  const [editMode, setEditMode] = useState([]);

  const [sortColumn, setsortColumn] = useState(0);
  let [sortOrder, setSortOrder] = useState(0);

  const [filterParam, setFilterParam] = useState(0);
  const [filterParam2, setFilterParam2] = useState(0);
  const [filterParam3, setFilterParam3] = useState(0);
  const [filterParam4, setFilterParam4] = useState(0);
  const [filterParam5, setFilterParam5] = useState(0);
  const [filterParam6, setFilterParam6] = useState(0);

  const [filterValue, setFilterValue] = useState("");
  const [filterValue2, setFilterValue2] = useState("");
  const [filterValue3, setFilterValue3] = useState("");
  const [filterValue4, setFilterValue4] = useState("");
  const [filterValue5, setFilterValue5] = useState("");
  const [filterValue6, setFilterValue6] = useState("");

  const [viewAddress, setViewAddress] = useState(false);
  const [searchValue, setSearchValue] = useState(0);
  const [currentData, setCurrentData] = useState({});
  const [rowIndex, setRowIndex] = useState("");

  const [emp_EmployeeId, setEmp_EmployeeId] = useState("");
  const [emp_EmployeeName, setEmp_EmployeeName] = useState("");
  const [emp_FatherName, setEmp_FatherName] = useState("");
  const [emp_DateofBirth, setEmp_DateofBirth] = useState("");
  const [emp_Gender, setEmp_Gender] = useState("");
  const [emp_PhysicallyChallanged, setEmp_PhysicallyChallanged] =   useState(0);
  const [emp_Phone, setEmp_Phone] = useState("");
  const [emp_Mobile, setEmp_Mobile] = useState("");
  const [emp_Email, setEmp_Email] = useState("");
  const [emp_Image, setEmp_Image] = useState("");
  const [emp_Image_File, setEmp_Image_File] = useState("");
  const [emp_BloodGroup, setEmp_BloodGroup] = useState("");
  const [emp_Permanent_Address1, setEmp_Permanent_Address1] = useState("");
  const [emp_Permanent_Address2, setEmp_Permanent_Address2] = useState("");
  const [emp_Permanent_Address3, setEmp_Permanent_Address3] = useState("");
  const [emp_Permanent_City, setEmp_Permanent_City] = useState("");
  const [emp_Permanent_State, setEmp_Permanent_State] = useState("");
  const [emp_Permanent_Country, setEmp_Permanent_Country] = useState("");
  const [emp_Permanent_ZipCode, setEmp_Permanent_ZipCode] = useState("");

  const [emp_EmemergencyContactName, setEmp_EmemergencyContactName] =
    useState("");
  const [
    emp_EmemergencyContactRelationship,
    setEmp_EmemergencyContactRelationship,
  ] = useState("");
  const [emp_EmemergencyContactAddress, setEmp_EmemergencyContactAddress] =
    useState("");
  const [emp_EmemergencyContactPhone, setEmp_EmemergencyContactPhone] =
    useState("");
  const [emp_EmemergencyContact_Mobile, setEmp_EmemergencyContact_Mobile] =
    useState("");
  const [emp_EmemergencyContactEmailId, setEmp_EmemergencyContactEmailId] =
    useState("");
  const [emp_Temp_Address1, setEmp_Temp_Address1] = useState("");
  const [emp_Temp_Address2, setEmp_Temp_Address2] = useState("");
  const [emp_Temp_Address3, setEmp_Temp_Address3] = useState("");
  const [emp_Temp_City, setEmp_Temp_City] = useState("");
  const [emp_Temp_State, setEmp_Temp_State] = useState("");
  const [emp_Temp_Country, setEmp_Temp_Country] = useState("");
  const [emp_Temp_ZipCode, setEmp_Temp_ZipCode] = useState("");
  const [emp_Dateofjoining, setEmp_Dateofjoining] = useState(" ");
  const [emp_Designation, setEmp_Designation] = useState("");
  const [emp_Experience, setEmp_Experience] = useState("");
  const [emp_AccountNumber, setEmp_AccountNumber] = useState("");
  const [emp_BankName, setEmp_BankName] = useState("");
  const [emp_IFSCcode, setEmp_IFSCcode] = useState("");
  const [emp_DateofLeaving, setEmp_DateofLeaving] = useState("");
  const [emp_Skills, setEmp_Skills] = useState("");

  //Error UseStates Mandatory Fileds
  const [employeeIdError, setEmployeeIdError] = useState("");
  const [employeeNameError, setEmployeeNameError] = useState("");
  const [employeeFatherNameError, setEmployeeFatherNameError] = useState("");
  const [emp_DateofBirthError, setEmp_DateofBirthError] = useState("");
  const [emp_AdharNoError, setEmp_AdharNoError] = useState("");
  const [emp_MobileError, setEmp_MobileError] = useState("");
  const [emp_EmailError, setEmp_EmailError] = useState("");
  const [emp_Permanent_Address1Error, setEmp_Permanent_Address1Error] =
    useState("");
  const [emp_Permanent_CityError, setEmp_Permanent_CityError] = useState("");
  const [emp_Permanent_StateError, setEmp_Permanent_StateError] = useState("");
  const [emp_Permanent_CountryError, setEmp_Permanent_CountryError] =
    useState("");
  const [emp_DateofJoiningError, setEmp_DateofJoiningError] = useState("");
  const [updateButton, setUpdateButton] = useState(false);
  const [submitButton, setSubmitButton] = useState(false);

  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  const [viewScreenTitle, setViewScreenTitle] = useState(false);

  const [viewDetails, setViewDetails] = useState(false);
  const [deleteButton, setDeleteButton] = useState(false);
  const [blockButton, setBlockButton] = useState(false);
  const [unBlockButton, setUnBlockButton] = useState(false);
  const [viewFilter, setViewFilter] = useState(false);

  //popup State Values set
  const [popUpOpen, setpopUpOpen] = useState(false);
  const [popUpViewAddress, setPopUpViewAddress] = useState(false);

  const handleOpen = (isOpen) => {
    setpopUpOpen(isOpen);
  };

  const handleClose = () => {
    setpopUpOpen(false);
    setPopUpViewAddress(false);
  };

  const handleAddressOpen = (isOpen) => {
    setPopUpViewAddress(isOpen);
  };

  const showadd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setSubmitButton(true);
    setAddScreenTitle(true);
    setUpdateButton(false);
    setUpdateScreenTitle(false);
  };

  const cancel = () => {
    setEmp_EmployeeId("");
    setEmp_EmployeeName("");
    setEmp_FatherName("");
    setEmp_DateofBirth("");
    setEmp_Gender("");
    setEmp_PhysicallyChallanged("");
    setEmp_Email("");
    setEmp_Mobile("");
    setEmp_Phone("");
    setEmp_Image("");
    setEmp_BloodGroup("");
    setEmp_Permanent_Address1("");
    setEmp_Permanent_Address2("");
    setEmp_Permanent_Address3("");
    setEmp_Permanent_City("");
    setEmp_Permanent_State("");
    setEmp_Permanent_Country("");
    setEmp_Permanent_ZipCode("");

    setEmp_EmemergencyContactAddress("");
    setEmp_EmemergencyContactName("");
    setEmp_EmemergencyContactPhone("");
    setEmp_EmemergencyContactRelationship("");
    setEmp_EmemergencyContact_Mobile("");
    setEmp_EmemergencyContactEmailId("");

    setEmp_Temp_Address1("");
    setEmp_Temp_Address2("");
    setEmp_Temp_Address3("");
    setEmp_Temp_City("");
    setEmp_Temp_Country("");
    setEmp_Temp_State("");
    setEmp_Temp_ZipCode("");
    setEmp_Dateofjoining("");
    setEmp_Experience("");
    setEmp_Designation("");
    setEmp_AccountNumber("");
    setEmp_BankName("");
    setEmp_IFSCcode("");
    setEmp_DateofLeaving("");
    setEmp_Skills("");

    // Error State Values Empty
    setEmployeeIdError("");
    setEmployeeNameError("");
    setEmployeeFatherNameError("");
    setEmp_DateofBirthError("");
    setEmp_EmailError("");
    setEmp_MobileError("");
    setEmp_AdharNoError("");
    setEmp_Permanent_Address1Error("");
    setEmp_Permanent_CityError("");
    setEmp_Permanent_StateError("");
    setEmp_Permanent_CountryError("");
    setEmp_DateofJoiningError("");

    setLoading(true);
    setTimeout(() => {
      setLoading(false);

      setCurrentPage(1);
      setResult(10);
      setFilterParam(0);
      setFilterParam2(0);
      setFilterParam3(0);
      setFilterParam4(0);
      setFilterParam5(0);
      setFilterParam6(0);
      setFilterValue(0);
      setFilterValue2(0);
      setFilterValue3(0);
      setFilterValue4(0);
      setFilterValue5(0);
      setFilterValue6(0);

      setVisibleAdd(false);
      setVisibleTable(true);
      setSubmitButton(false);
      setAddScreenTitle(false);
      setUpdateButton(false);
      setUpdateScreenTitle(false);
    }, 2000);
  };

  function handleDropDown(e) {
    setResult(e.target.value);
    setCurrentPage(1);
  }

  const HandleSubmit = async (items, flag) => {
    var createdby = sessionStorage.getItem("createdby");
    debugger;
    setEmployeeNameError(
      emp_EmployeeName.length > 0
        ? /[A-Za-z ]/.exec(emp_EmployeeName)
          ? ""
          : "Only Alphabet and space allowed"
        : "Enter Name"
    );
    setEmployeeIdError(emp_EmployeeId.length > 0 ? "" : "Enter Employee Id");
    setEmployeeFatherNameError(
      emp_FatherName.length > 0
        ? /[A-Za-z ]{1,50}/.test(emp_FatherName)
          ? ""
          : "Only Alphabet and space allowed"
        : "Enter Father Name"
    );
    setEmp_DateofBirthError(
      emp_DateofBirth.length > 0 ? "" : "Enter Date of Birth"
    );
    setEmp_MobileError(
      emp_Mobile.length > 0
        ? /\d/.test(emp_Mobile) && emp_Mobile.length == 10
          ? ""
          : "Invalid Mobile Number"
        : "Enter Mobile No"
    );
    setEmp_EmailError(
      emp_Email.length > 0
        ? /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emp_Email)
          ? ""
          : "Invalid Email"
        : "Enter Email"
    );
    setEmp_Permanent_Address1Error(
      emp_Permanent_Address1.length > 0
        ? /[A-Za-z\/\- ]{1,500}/.test(emp_Permanent_Address1)
          ? ""
          : "Only Alphabet, space,'/' and '-'allowed"
        : "Enter Address"
    );
    setEmp_Permanent_CityError(
      emp_Permanent_City.length > 0
        ? /[A-Za-z\/\- ]{1,50}/.test(emp_Permanent_Address1)
          ? ""
          : "Only Alphabet, space,'/' and '-'allowed"
        : "Enter city"
    );
    setEmp_Permanent_StateError(
      emp_Permanent_State.length > 0
        ? /[A-Za-z\/\- ]{1,50}/.test(emp_Permanent_Address1)
          ? ""
          : "Only Alphabet, space,'/' and '-'allowed"
        : "Enter state"
    );
    setEmp_Permanent_CountryError(
      emp_Permanent_Country.length > 0
        ? /[A-Za-z\/\- ]{1,50}/.test(emp_Permanent_Address1)
          ? ""
          : "Only Alphabet, space,'/' and '-'allowed"
        : "Enter Country"
    );
    setEmp_DateofJoiningError(
      emp_Dateofjoining.length > 1 ? "" : "Enter Date of Joining"
    );

    if (
      !employeeIdError &&
      !employeeNameError &&
      !employeeFatherNameError &&
      !emp_DateofBirthError &&
      !emp_MobileError &&
      !emp_EmailError &&
      !emp_Permanent_Address1Error &&
      !emp_Permanent_CityError &&
      !emp_Permanent_StateError &&
      !emp_Permanent_CountryError &&
      !emp_DateofJoiningError
    ) {
      const addpayload = [
        {
          emp_EmployeeId: emp_EmployeeId,
          emp_EmployeeName: emp_EmployeeName,
          emp_FatherName: emp_FatherName,
          emp_DateofBirth: emp_DateofBirth,
          emp_Gender: emp_Gender,
          emp_Phone: emp_Phone,
          emp_Mobile: emp_Mobile,
          emp_Email: emp_Email,
          emp_Image: emp_Image,
          emp_BloodGroup: emp_BloodGroup,
          emp_PhysicallyChallanged: emp_PhysicallyChallanged,
          emp_Permanent_Address1: emp_Permanent_Address1,
          emp_Permanent_Address2: emp_Permanent_Address2,
          emp_Permanent_Address3: emp_Permanent_Address3,
          emp_Permanent_City: emp_Permanent_City,
          emp_Permanent_State: emp_Permanent_State,
          emp_Permanent_Country: emp_Permanent_Country,
          emp_Permanent_ZipCode: emp_Permanent_ZipCode,
          emp_EmemergencyContactName: emp_EmemergencyContactName,
          emp_EmemergencyContactRelationship:
            emp_EmemergencyContactRelationship,
          emp_EmemergencyContactAddress: emp_EmemergencyContactAddress,
          emp_EmemergencyContactPhone: emp_EmemergencyContactPhone,
          emp_EmemergencyContact_Mobile: emp_EmemergencyContact_Mobile,
          emp_EmemergencyContactEmailId: emp_EmemergencyContactEmailId,
          emp_Temp_Address1: emp_Temp_Address1,
          emp_Temp_Address2: emp_Temp_Address2,
          emp_Temp_Address3: emp_Temp_Address3,
          emp_Temp_City: emp_Temp_City,
          emp_Temp_State: emp_Temp_State,
          emp_Temp_Country: emp_Temp_Country,
          emp_Temp_ZipCode: emp_Temp_ZipCode,
          emp_Dateofjoining: emp_Dateofjoining,
          emp_Experience: emp_Experience || 0,
          emp_Designation: emp_Designation,
          emp_AccountNumber: emp_AccountNumber,
          emp_BankName: emp_BankName,
          emp_IFSCcode: emp_IFSCcode,
          emp_Skills: emp_Skills,
          emp_Createdby: createdby,
          flag: flag,
        },
      ];
    }
  };

  const HandleEdit = (items) => {
    setEditMode(true);

    setSubmitButton(false);
    setUpdateButton(true);
    setVisibleAdd(true);
    setVisibleTable(false);
    setUpdateScreenTitle(true);

    setEmp_EmployeeId(bindEmployeeMasterDatas[items].Emp_EmployeeId);
    setEmp_EmployeeName(bindEmployeeMasterDatas[items].Emp_EmployeeName);
    setEmp_FatherName(bindEmployeeMasterDatas[items].Emp_FatherName); 
    
    setEmp_DateofBirth(moment(bindEmployeeMasterDatas[items].Emp_DateofBirth).format("YYYY-MM-DD"));
    setEmp_Gender(bindEmployeeMasterDatas[items].Emp_Gender);
    setEmp_PhysicallyChallanged(
      bindEmployeeMasterDatas[items].Emp_PhysicallyChallenged
    );
    setEmp_Email(bindEmployeeMasterDatas[items].Emp_Email);
    setEmp_Mobile(bindEmployeeMasterDatas[items].Emp_Mobile);
    setEmp_Phone(bindEmployeeMasterDatas[items].Emp_Phone);
    setEmp_Image(bindEmployeeMasterDatas[items].Emp_Image);
    setEmp_BloodGroup(bindEmployeeMasterDatas[items].Emp_BloodGroup);
    setEmp_Permanent_Address1(
      bindEmployeeMasterDatas[items].Emp_Permanent_Address1
    );
    setEmp_Permanent_Address2(
      bindEmployeeMasterDatas[items].Emp_Permanent_Address2
    );
    setEmp_Permanent_Address3(
      bindEmployeeMasterDatas[items].Emp_Permanent_Address3
    );
    setEmp_Permanent_City(bindEmployeeMasterDatas[items].Emp_Permanent_City);
    setEmp_Permanent_State(bindEmployeeMasterDatas[items].Emp_Permanent_State);
    setEmp_Permanent_Country(
      bindEmployeeMasterDatas[items].Emp_Permanent_Country
    );
    setEmp_Permanent_ZipCode(
      bindEmployeeMasterDatas[items].Emp_Permanent_ZipCode
    );

    setEmp_EmemergencyContactAddress(
      bindEmployeeMasterDatas[items].Emp_EmemergencyContactAddress
    );
    setEmp_EmemergencyContactName(
      bindEmployeeMasterDatas[items].Emp_EmemergencyContactName
    );
    setEmp_EmemergencyContactPhone(
      bindEmployeeMasterDatas[items].Emp_EmemergencyContactPhone
    );
    setEmp_EmemergencyContactRelationship(
      bindEmployeeMasterDatas[items].Emp_EmemergencyContactRelationship
    );
    setEmp_EmemergencyContact_Mobile(
      bindEmployeeMasterDatas[items].Emp_EmemergencyContact_Mobile
    );
    setEmp_EmemergencyContactEmailId(
      bindEmployeeMasterDatas[items].Emp_EmemergencyContactEmailId
    );

    setEmp_Temp_Address1(bindEmployeeMasterDatas[items].Emp_Temp_Address1);
    setEmp_Temp_Address2(bindEmployeeMasterDatas[items].Emp_Temp_Address2);
    setEmp_Temp_Address3(bindEmployeeMasterDatas[items].Emp_Temp_Address3);
    setEmp_Temp_City(bindEmployeeMasterDatas[items].Emp_Temp_City);
    setEmp_Temp_Country(bindEmployeeMasterDatas[items].Emp_Temp_Country);
    setEmp_Temp_State(bindEmployeeMasterDatas[items].Emp_Temp_State);
    setEmp_Temp_ZipCode(bindEmployeeMasterDatas[items].Emp_Temp_ZipCode);
// date format start
// var dateofjoing = bindEmployeeMasterDatas[items].Emp_Dateofjoining;
//     var JmomentObj = moment(dateofjoing);
//       console.log("rew",JmomentObj);
// var dateofjoin = JmomentObj.format('YYYY-MM-DD');
// date format end
    setEmp_Dateofjoining(moment(bindEmployeeMasterDatas[items].Emp_Dateofjoining).format("YYYY-MM-DD"));
    setEmp_Experience(bindEmployeeMasterDatas[items].Emp_Experience);
    setEmp_Designation(bindEmployeeMasterDatas[items].Emp_Designation);
    setEmp_AccountNumber(bindEmployeeMasterDatas[items].Emp_AccountNumber);
    setEmp_BankName(bindEmployeeMasterDatas[items].Emp_BankName);
    setEmp_IFSCcode(bindEmployeeMasterDatas[items].Emp_IFSCcode);
//-----------------------------//
// var DateofLeaving = bindEmployeeMasterDatas[items].Emp_;

// var momentObj = moment(DateofBirth);
//  console.log("rew",momentObj);
// var dateofbth = momentObj.format('YYYY-MM-DD');
    //-----------------------//
    setEmp_DateofLeaving(moment(bindEmployeeMasterDatas[items].Emp_DateofLeaving).format('YYYY-MM-DD'));
    setEmp_Skills(bindEmployeeMasterDatas[items].Emp_Skills);
  };

  const HandleAddress = (rowIndex) => {
    handleAddressOpen(true);
    setViewAddress(true);
    setCurrentData(bindEmployeeMasterDatas[rowIndex]);
  };

  const HandleFilter = (filterValuesPass, itemIndex) => {
    // console.log("filterValues", filterValuesPass);
    // console.log("filterIndex", itemIndex);
    if (filterValuesPass.filterValue1) {
      setFilterParam("Emp_EmployeeId");
      setFilterValue(filterValuesPass.filterValue1);
    } else {
      setFilterParam(0);
      setFilterValue(0);
    }

    if (filterValuesPass.filterValue2) {
      setFilterParam2("Emp_EmployeeName");
      setFilterValue2(filterValuesPass.filterValue2);
    } else {
      setFilterParam2(0);
      setFilterValue2(0);
    }
    if (filterValuesPass.filterValue3) {
      setFilterParam3("Emp_Email");
      setFilterValue3(filterValuesPass.filterValue3);
    } else {
      setFilterParam3(0);
      setFilterValue3(0);
    }
    if (filterValuesPass.filterValue4) {
      setFilterParam4("Emp_EmployeeId");
      setFilterValue4(filterValuesPass.filterValue4);
    } else {
      setFilterParam4(0);
      setFilterValue4(0);
    }

    if (filterValuesPass.filterValue5) {
      setFilterParam5("Emp_EmployeeName");
      setFilterValue5(filterValuesPass.filterValue5);
    } else {
      setFilterParam5(0);
      setFilterValue5(0);
    }
    if (filterValuesPass.filterValue6) {
      setFilterParam6("Emp_Email");
      setFilterValue6(filterValuesPass.filterValue6);
    } else {
      setFilterParam6(0);
      setFilterValue6(0);
    }
  };

  const emp_HandleView = async (items) => {
    setEditMode(false);
    const data = [
      {
        emp_EmployeeId: items,
      },
    ];

    // console.log("resultId", items);
    // console.log(data.toString());
    setVisibleAdd(true);
    setVisibleTable(false);
    setViewScreenTitle(true);
    // const response = await emp_EditAPI(data);
    // console.log("recover Details", response);
    setEmp_EmployeeId(bindEmployeeMasterDatas[items].Emp_EmployeeId);
    setEmp_EmployeeName(bindEmployeeMasterDatas[items].Emp_EmployeeName);
    setEmp_FatherName(bindEmployeeMasterDatas[items].Emp_FatherName);
    setEmp_DateofBirth(bindEmployeeMasterDatas[items].Emp_DateofBirth);
    setEmp_Mobile(bindEmployeeMasterDatas[items].Emp_Mobile);
    setEmp_Email(bindEmployeeMasterDatas[items].Emp_Email);
    setEmp_Permanent_Address1(
      bindEmployeeMasterDatas[items].Emp_Permanent_Address1
    );
    setEmp_Permanent_City(bindEmployeeMasterDatas[items].Emp_Permanent_City);
    setEmp_Permanent_State(bindEmployeeMasterDatas[items].Emp_Permanent_State);
    setEmp_Permanent_Country(
      bindEmployeeMasterDatas[items].Emp_Permanent_Country
    );
  };

  const HandleSort = async (columnName) => {
    sortOrder++;
    if (sortOrder > 2) {
      sortOrder = 1;
    }
    setsortColumn(columnName);
    setSortOrder(sortOrder);
    // console.log("sort", sortOrder);
  };

  const handleImageUpload = async () => {
    const data = new FormData();
    const imagedata = emp_Image_File;
    data.append("Emp_Image", imagedata);
    // const response = await emp_ImageAPI(data);
    // setEmp_Image(response.data);
  };

  const modules = {
    toolbar: [
      //[{ 'font': [] }],
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      [{ align: [] }, { color: [] }, { background: [] }],
      ["clean"],
    ],
  };

  const formats = [
    //'font',
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "color",
    "background",
  ];

  const Container = styled.div`
    height: 243px;
  `;

  const HandleEmployeeDetails = (rowIndex, flag) => {
    handleOpen(true);
    debugger;
    if (flag === 1) {
      setViewDetails(true);
      setDeleteButton(false);
      setBlockButton(false);
      setUnBlockButton(false);
    } else if (flag === 2) {
      setViewDetails(true);
      setDeleteButton(true);
      setBlockButton(false);
      setUnBlockButton(false);
      setRowIndex(rowIndex);
    } else if (flag === 3) {
      setViewDetails(true);
      setDeleteButton(false);
      setBlockButton(true);
      setRowIndex(rowIndex);
      setUnBlockButton(false);
    } else if (flag === 4) {
      setViewDetails(true);
      setDeleteButton(false);
      setBlockButton(false);
      setRowIndex(rowIndex);
      setUnBlockButton(true);
    }
    // console.log("Meassage", rowIndex);
    setCurrentData(bindEmployeeMasterDatas[rowIndex]);
  };

  return (
    <Mainlayout>
      <ToastContainer />
      {visibleTable && (
        <div class="max-md:absolute">
          <div class={Maintable.headerTitle}>
            <div class="my-2 mx-12 lg:mx-0">{`Admin -> Master ->Employee Master`}</div>
          </div>

          <div >
            <div className="justify-between flex items-center">
              <div class={Maintable.searchAdd}>
                <div className="mt-1 gap-2 flex justify-between items-baseline">
                  <label for="pageSize" class="hidden md:block">
                    Rows Per Page:
                  </label>
                  <select
                    onChange={handleDropDown}
                    class={Maintable.dropDownRecords}
                    name="pageSize"
                    id="pageSize"
                  >
                    <option value="2">2</option>
                    <option value="10" selected>
                      10
                    </option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div>
                  <label for="table-search" class="sr-only">
                    Search
                  </label>
                </div>
                <div class="relative mt-1">
                  <div class={Maintable.searchIconPosition}>
                    <svg
                      class={Maintable.searchIconSvg}
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="table-search"
                    class={Maintable.searchInputBox}
                    placeholder="Search for items"
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </div>
                {/* <button
                  onClick={() => setViewFilter(!viewFilter)}
                  class="relative px-5 py-0 overflow-hidden font-medium text-gray-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner group"
                >
                  <span class="absolute top-0 left-0 w-0 h-0 transition-all duration-200 border-t-2 border-gray-600 group-hover:w-full ease"></span>
                  <span class="absolute bottom-0 right-0 w-0 h-0 transition-all duration-200 border-b-2 border-gray-600 group-hover:w-full ease"></span>
                  <span class="absolute top-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-gray-600 group-hover:h-full ease"></span>
                  <span class="absolute bottom-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-gray-600 group-hover:h-full ease"></span>
                  <span class="absolute inset-0 w-full h-full duration-300 delay-300 bg-gray-900 opacity-0 group-hover:opacity-100"></span>
                  <span class="relative transition-colors duration-300 delay-200 group-hover:text-white ease">
                    <svg
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      height="2em"
                      width="2em"
                    >
                      <path d="M6 10.5a.5.5 0 01.5-.5h3a.5.5 0 010 1h-3a.5.5 0 01-.5-.5zm-2-3a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5zm-2-3a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5z" />
                    </svg>
                  </span>
                </button> */}
              </div>
              <div className="flex self-end">
                <div className="text-red-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner w-fit h-9 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">
                  <span className="mb-12 text-red-600 text-7xl">.</span>
                  <span>Block</span>
                </div>
                <button
                  type="button"
                  class={Maintable.addButton}
                  onClick={showadd}
                >
                  <svg
                    width="25"
                    height="20"
                    viewBox="0 0 50 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.5 37.5H27.5V27.5H37.5V22.5H27.5V12.5H22.5V22.5H12.5V27.5H22.5V37.5ZM25 50C21.5417 50 18.2917 49.3433 15.25 48.03C12.2083 46.7183 9.5625 44.9375 7.3125 42.6875C5.0625 40.4375 3.28167 37.7917 1.97 34.75C0.656667 31.7083 0 28.4583 0 25C0 21.5417 0.656667 18.2917 1.97 15.25C3.28167 12.2083 5.0625 9.5625 7.3125 7.3125C9.5625 5.0625 12.2083 3.28083 15.25 1.9675C18.2917 0.655833 21.5417 0 25 0C28.4583 0 31.7083 0.655833 34.75 1.9675C37.7917 3.28083 40.4375 5.0625 42.6875 7.3125C44.9375 9.5625 46.7183 12.2083 48.03 15.25C49.3433 18.2917 50 21.5417 50 25C50 28.4583 49.3433 31.7083 48.03 34.75C46.7183 37.7917 44.9375 40.4375 42.6875 42.6875C40.4375 44.9375 37.7917 46.7183 34.75 48.03C31.7083 49.3433 28.4583 50 25 50ZM25 45C30.5833 45 35.3125 43.0625 39.1875 39.1875C43.0625 35.3125 45 30.5833 45 25C45 19.4167 43.0625 14.6875 39.1875 10.8125C35.3125 6.9375 30.5833 5 25 5C19.4167 5 14.6875 6.9375 10.8125 10.8125C6.9375 14.6875 5 19.4167 5 25C5 30.5833 6.9375 35.3125 10.8125 39.1875C14.6875 43.0625 19.4167 45 25 45Z"
                      fill="white"
                    />
                  </svg>
                  ADD
                </button>
              </div>
            </div>
            <TableGrid
              className={Maintable.Table}
              headerData={EmployeeHeaders}
              tableData={bindEmployeeMasterDatas}
              onEditGrid={HandleEdit}
              onDeleteGrid={HandleEmployeeDetails}
              onSortGrid={HandleSort}
              onBlockGrid={HandleEmployeeDetails}
              onViewGrid={HandleEmployeeDetails}
              onAddressGrid={HandleAddress}
              onFilterGrid={HandleFilter}
              sortColumn={sortColumn}
              sortOrder={sortOrder}
              viewFilterGrid={viewFilter}
            />
          </div>

          <div class={Maintable.submitCancelMainDiv}>
            <button
              disabled={currentPage1 === 1}
              onClick={() => setCurrentPage(currentPage1 - 1)}
              type="button"
              class={Maintable.submitButton}
            >
              Previous
            </button>
            <label>{currentPage1}</label>
            <button
              disabled={currentPage1 === totalPages / result}
              onClick={() => setCurrentPage(currentPage1 + 1)}
              type="button"
              class={Maintable.cancelButton}
            >
              Next
            </button>
          </div>
        </div>
      )}

      {visibleAdd && (
        <div class="flex flex-col w-full">
          <div class={Maintable.clientMasterMainAdd}>
            {addScreenTitle && (
              <div class={Maintable.addScreenTitle}>
                {"Admin -> Master -> Employee Master -> Add"}
              </div>
            )}

            {updateScreenTitle && (
              <div class={Maintable.addScreenTitle}>
                {"Admin -> Master -> Employee Master -> Edit"}
              </div>
            )}

            {viewScreenTitle && (
              <div class={Maintable.addScreenTitle}>
                {"Admin -> Master -> Employee Master -> View"}
              </div>
            )}

            <div class={Maintable.addShadowScreen}>
              <div class={Maintable.mainContentScreen}>
                <form class={Maintable.formForClientMaster}>
                  <div class={Maintable.labelBold}>Employee Details</div>
                  <hr class={Maintable.horizontalLine}></hr>
                  <div class={Maintable.gird3}>
                    <div>
                      <label for="employee_no" class={Maintable.label}>
                        Employee ID<span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        name="emp_EmployeeId"
                        value={emp_EmployeeId}
                        readOnly={!editMode}
                        onBlur={(e) => {
                          setEmp_EmployeeId(e.target.value);
                          setEmployeeIdError(
                            emp_EmployeeId.length > 0 ? "" : "Enter Employee Id"
                          );
                        }}
                        onChange={(e) => {
                          setEmp_EmployeeId(e.target.value);
                          setEmployeeIdError(
                            emp_EmployeeId.length > 0 ? "" : "Enter Employee Id"
                          );

                          // if (e.target.value.length >= 1) {
                          //   setEmployeeIdError("");
                          // }
                        }}
                        id="first_name"
                        class={Maintable.input}
                        className={
                          employeeIdError
                            ? validationcolor.errorColor
                            : Maintable.input
                        }
                        placeholder="XXX0011"
                      />
                      {employeeIdError && (
                        <span class="text-red-500">{employeeIdError}</span>
                      )}
                    </div>
                    <div>
                      <label for="employee_name" class={Maintable.label}>
                        Employee Name
                        <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        name="emp_EmployeeName"
                        value={emp_EmployeeName}
                        maxLength={30}
                        readOnly={!editMode}
                        onBlur={(e) => {
                          setEmp_EmployeeName(e.target.value);
                          setEmployeeNameError(
                            emp_EmployeeName.length > 0
                              ? /[A-Za-z \D]/.test(emp_EmployeeName)
                                ? ""
                                : "Only Alphabet and space allowed"
                              : "Enter Name"
                          );
                        }}
                        onChange={(e) => {
                          setEmp_EmployeeName(e.target.value);
                          setEmployeeNameError(
                            emp_EmployeeName.length > 0
                              ? /[A-Za-z \D]/.test(emp_EmployeeName)
                                ? ""
                                : "Only Alphabet and space allowed"
                              : "Enter Name"
                          );
                        }}
                        id="first_name"
                        className={
                          employeeNameError
                            ? validationcolor.errorColor
                            : Maintable.input
                        }
                        placeholder="XXX0011"
                      />
                      {employeeNameError && (
                        <span class="text-red-500">{employeeNameError}</span>
                      )}
                    </div>
                    <div>
                      <label for="father_name" class={Maintable.label}>
                        Father Name<span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        name="emp_FatherName"
                        value={emp_FatherName}
                        onChange={(e) => {
                          setEmp_FatherName(e.target.value);
                          setEmployeeFatherNameError(
                            emp_FatherName.length > 0
                              ? /[A-Za-z ]{1,50}/.test(emp_FatherName)
                                ? ""
                                : "Only Alphabet and space allowed"
                              : "Enter Father Name"
                          );
                          // if (e.target.value.length >= 1) {
                          //   setEmployeeFatherNameError("");
                          // }
                        }}
                        id="last_name"
                        class={Maintable.input}
                        readOnly={!editMode}
                        className={
                          employeeFatherNameError
                            ? validationcolor.errorColor
                            : Maintable.input
                        }
                        placeholder="Mani"
                      />
                      {employeeFatherNameError && (
                        <span class="text-red-500">
                          {employeeFatherNameError}
                        </span>
                      )}
                    </div>

                    <div>
                      <label for="Pan" class={Maintable.label}>
                        Date Of Birth
                        <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="Date"
                        name="emp_DateofBirth"
                        readOnly={!editMode}
                        value={emp_DateofBirth}
                        placeholder={emp_DateofBirth}
                        onChange={(e) => {
                          setEmp_DateofBirth(e.target.value);
                          setEmp_DateofBirthError(
                            emp_DateofBirth.length > 1
                              ? ""
                              : "Enter Date of Birth"
                          );
                          // if (e.target.value.length > 1) {
                          //   setEmp_DateofBirthError("");
                          // }
                        }}
                        id="company"
                        class={Maintable.input}
                        className={
                          emp_DateofBirthError
                            ? validationcolor.errorColor
                            : Maintable.input
                        }
                      />

                      {emp_DateofBirthError && (
                        <span class="text-red-500">{emp_DateofBirthError}</span>
                      )}
                    </div>
                    <div>
                      <label for="Pan" class={Maintable.label}>
                        Gender
                      </label>
                      <div class="flex items-center bg-gray-50   pl-4 border border-gray-300 rounded-lg dark:border-gray-500">
                        <input
                          id="bordered-radio-1"
                          name="emp_Gender"
                          value="Male"
                          checked={emp_Gender === "Male"}
                          onChange={(e) => setEmp_Gender(e.target.value)}
                          type="radio"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          for="bordered-radio-1"
                          class="w-full py-2.5 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                          Male
                        </label>
                        <input
                          id="bordered-radio-2"
                          name="emp_Gender"
                          value="Female"
                          checked={emp_Gender === "Female"}
                          onChange={(e) => setEmp_Gender(e.target.value)}
                          type="radio"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          for="bordered-radio-2"
                          class="w-full py-2.5 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                          Female
                        </label>
                      </div>
                    </div>
                    <div>
                      <label for="blood_group" class={Maintable.label}>
                        Blood Group
                      </label>
                      <input
                        type="text"
                        name="emp_BloodGroup"
                        value={emp_BloodGroup}
                        onChange={(e) => setEmp_BloodGroup(e.target.value)}
                        id="company"
                        class={Maintable.input}
                        placeholder="B+"
                      />
                    </div>

                    <div>
                      <label for="Pan" class={Maintable.label}>
                        Phone
                      </label>
                      <input
                        type="number"
                        name="emp_Phone"
                        value={emp_Phone}
                        onChange={(e) => setEmp_Phone(e.target.value)}
                        id="company"
                        class={Maintable.input}
                        placeholder="+91 6385636425"
                      />
                    </div>
                    <div>
                      <label for="Pan" class={Maintable.label}>
                        Mobile<span className="text-red-600">*</span>
                      </label>
                      <input
                        type="number"
                        readOnly={!editMode}
                        name="emp_Mobile"
                        value={emp_Mobile}
                        maxLength={10}
                        onChange={(e) => {
                          setEmp_Mobile(e.target.value);
                          setEmp_MobileError(
                            emp_Mobile.length > 0
                              ? /\d/.test(emp_Mobile) && emp_Mobile.length === 9
                                ? ""
                                : "Invalid Mobile Number"
                              : "Enter Mobile No"
                          );
                        }}
                        onBlur={(e) => {
                          setEmp_Mobile(e.target.value);
                          setEmp_MobileError(
                            emp_Mobile.length > 0
                              ? /\d/.test(emp_Mobile) &&
                                emp_Mobile.length === 10
                                ? ""
                                : "Invalid Mobile Number"
                              : "Enter Mobile No"
                          );
                        }}
                        id="company"
                        class={Maintable.input}
                        className={
                          emp_MobileError
                            ? validationcolor.errorColor
                            : Maintable.input
                        }
                        placeholder="+91 6385636425"
                      />
                      {emp_MobileError && (
                        <span class="text-red-500">{emp_MobileError}</span>
                      )}
                    </div>
                    <div>
                      <label for="company" class={Maintable.label}>
                        Email<span className="text-red-600">*</span>
                      </label>
                      <input
                        type="email"
                        readOnly={!editMode}
                        name="emp_Email"
                        value={emp_Email}
                        onChange={(e) => {
                          setEmp_Email(e.target.value);
                          setEmp_EmailError(
                            emp_Email.length > 0
                              ? /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                                  emp_Email
                                )
                                ? ""
                                : "Invalid Email"
                              : "Enter Email"
                          );
                          // if (e.target.value.length > 1) {
                          //   setEmp_EmailError("");
                          // }
                        }}
                        id="company"
                        class={Maintable.input}
                        className={
                          emp_EmailError
                            ? validationcolor.errorColor
                            : Maintable.input
                        }
                        placeholder="karthick@xlicon.in"
                      />
                      {emp_EmailError && (
                        <span class="text-red-500">{emp_EmailError}</span>
                      )}
                    </div>            
                    <div>
                      <div class="flex items-center bg-gray-50 pl-4 border border-gray-300  mt-7 rounded-lg dark:border-gray-500">
                        <label for="Pan" class={Maintable.label_1}>
                          Physically Challenged
                        </label>
                        <input
                          id="bordered-checkbox-2"
                          name="emp_PhysicallyChallanged"
                          checked={emp_PhysicallyChallanged}
                        value= {emp_PhysicallyChallanged}
                          onChange={(e) =>{
                            setEmp_PhysicallyChallanged("1");
                            //if (emp_PhysicallyChallanged) {setEmp_PhysicallyChallanged(1);} else {setEmp_PhysicallyChallanged("");}
                          }
                          }
                          type="checkbox"
                          class="w-4 h-4 ml-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          for="bordered-checkbox-2"
                          class="py-2.5 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                          Yes
                        </label>
                      </div>
                    </div>
                    <div class="lg:col-span-2 sm:col-span-1">
                      <label class={Maintable.label} for="multiple_files">
                        Employee Photo
                      </label>
                      <input
                        name="emp_Image"
                        onChange={(e) => setEmp_Image_File(e.target.files[0])}
                        class={Maintable.fileUpload}
                        id="multiple_files"
                        type="file"
                        multiple
                      />

                      {/* // Upload button
                        // Uplaod button click function
                        // In inside function 
                        // file data : formdata in react
                        // Upload API : http://hrms.xlicon.com/HRMSAPI/api/XL_HRMS_API/Upload
                        // From the response, we will get Uplaoded ImageURL,  store it in setEmp_Image */}
                    </div>
                    <div className="flex">
                      <div className="mt-7 mr-7">
                        <button
                          type="button"
                          class={Maintable.submitButton}
                          onClick={() => handleImageUpload()}
                        >
                          UPLOAD
                        </button>
                      </div>
                      <div>
                        <input
                          type="image"
                          src={emp_Image}
                          alt="Image"
                          className="rounded-full w-20 h-20"
                        />
                      </div>
                    </div>
                  </div>

                  <div class={Maintable.labelBold}>Permanent Address</div>
                  <hr class={Maintable.horizontalLine}></hr>
                  <div class={Maintable.gird3}>
                    <div>
                      <label for="address1" class={Maintable.label}>
                        Address 1<span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        readOnly={!editMode}
                        name="emp_Permanent_Address1"
                        value={emp_Permanent_Address1}
                        onChange={(e) => {
                          setEmp_Permanent_Address1(e.target.value);
                          setEmp_Permanent_Address1Error(
                            emp_Permanent_Address1.length > 0
                              ? /[A-Za-z\/\- ]{1,500}/.test(
                                  emp_Permanent_Address1
                                )
                                ? ""
                                : "Only Alphabet, space,'/' and '-'allowed"
                              : "Enter Address"
                          );
                        }}
                        id="last_name"
                        class={Maintable.input}
                        className={
                          emp_Permanent_Address1Error
                            ? validationcolor.errorColor
                            : Maintable.input
                        }
                        placeholder="86,Ambedkar Nagar"
                      />
                      {emp_Permanent_Address1Error && (
                        <span class="text-red-500">
                          {emp_Permanent_Address1Error}
                        </span>
                      )}
                    </div>
                    <div>
                      <label for="address_2" class={Maintable.label}>
                        Address 2
                      </label>
                      <input
                        type="text"
                        name="emp_Permanent_Address2"
                        value={emp_Permanent_Address2}
                        onChange={(e) =>
                          setEmp_Permanent_Address2(e.target.value)
                        }
                        id="company"
                        class={Maintable.input}
                        placeholder="Mosur"
                      />
                    </div>
                    <div>
                      <label for="address_3" class={Maintable.label}>
                        Address 3
                      </label>
                      <input
                        type="text"
                        name="emp_Permanent_Address3"
                        value={emp_Permanent_Address3}
                        onChange={(e) =>
                          setEmp_Permanent_Address3(e.target.value)
                        }
                        id="company"
                        class={Maintable.input}
                        placeholder="Arakkonam"
                      />
                    </div>
                    <div>
                      <label for="city" class={Maintable.label}>
                        City<span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        readOnly={!editMode}
                        name="emp_Permanent_City"
                        value={emp_Permanent_City}
                        onChange={(e) => {
                          setEmp_Permanent_City(e.target.value);
                          setEmp_Permanent_CityError(
                            emp_Permanent_City.length > 0
                              ? /[A-Za-z\/\- ]{1,50}/.test(
                                  emp_Permanent_Address1
                                )
                                ? ""
                                : "Only Alphabet, space,'/' and '-'allowed"
                              : "Enter city"
                          );
                          // if (e.target.value.length > 1) {
                          //   setEmp_Permanent_CityError("");
                          // }
                        }}
                        id="company"
                        class={Maintable.input}
                        className={
                          emp_Permanent_CityError
                            ? validationcolor.errorColor
                            : Maintable.input
                        }
                        placeholder="Arakkonam"
                      />
                      {emp_Permanent_CityError && (
                        <span class="text-red-500">{emp_Permanent_City}</span>
                      )}
                    </div>
                    <div>
                      <label for="state" class={Maintable.label}>
                        State<span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        readOnly={!editMode}
                        name="emp_Permanent_State"
                        value={emp_Permanent_State}
                        onChange={(e) => {
                          setEmp_Permanent_State(e.target.value);
                          setEmp_Permanent_StateError(
                            emp_Permanent_State.length > 0
                              ? /[A-Za-z\/\- ]{1,50}/.test(
                                  emp_Permanent_Address1
                                )
                                ? ""
                                : "Only Alphabet, space,'/' and '-'allowed"
                              : "Enter state"
                          );
                          // if (e.target.value.length > 1) {
                          //   setEmp_Permanent_StateError("");
                          // }
                        }}
                        id="company"
                        class={Maintable.input}
                        className={
                          emp_Permanent_StateError
                            ? validationcolor.errorColor
                            : Maintable.input
                        }
                        placeholder="tamilnadu"
                      />
                      {emp_Permanent_StateError && (
                        <span class="text-red-500">
                          {emp_Permanent_StateError}
                        </span>
                      )}
                    </div>
                    <div>
                      <label for="country" class={Maintable.label}>
                        Country<span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        readOnly={!editMode}
                        name="emp_Permanent_Country"
                        value={emp_Permanent_Country}
                        onChange={(e) => {
                          setEmp_Permanent_Country(e.target.value);
                          setEmp_Permanent_CountryError(
                            emp_Permanent_Country.length > 0
                              ? /[A-Za-z\/\- ]{1,50}/.test(
                                  emp_Permanent_Address1
                                )
                                ? ""
                                : "Only Alphabet, space,'/' and '-'allowed"
                              : "Enter Country"
                          );
                          // if (e.target.value.length > 1) {
                          //   setEmp_Permanent_CountryError("");
                          // }
                        }}
                        id="company"
                        class={Maintable.input}
                        className={
                          emp_Permanent_CountryError
                            ? validationcolor.errorColor
                            : Maintable.input
                        }
                        placeholder="India"
                      />
                      {emp_Permanent_CountryError && (
                        <span class="text-red-500">
                          {emp_Permanent_CountryError}
                        </span>
                      )}
                    </div>
                    <div>
                      <label for="zipcode" class={Maintable.label}>
                        Zip Code
                      </label>
                      <input
                        type="text"
                        name="emp_Permanent_ZipCode"
                        value={emp_Permanent_ZipCode}
                        onChange={(e) =>
                          setEmp_Permanent_ZipCode(e.target.value)
                        }
                        id="company"
                        class={Maintable.input}
                        placeholder="6543002"
                      />
                    </div>
                  </div>

                  <div class={Maintable.labelBold}>Emergency Contact Info</div>
                  <hr class={Maintable.horizontalLine}></hr>
                  <div class={Maintable.gird3}>
                    <div>
                      <label for="company" class={Maintable.label}>
                        Contact Name
                      </label>
                      <input
                        type="text"
                        name="emp_EmemergencyContactName"
                        value={emp_EmemergencyContactName}
                        onChange={(e) =>
                          setEmp_EmemergencyContactName(e.target.value)
                        }
                        id="name"
                        class={Maintable.input}
                        placeholder="Gokul"
                      />
                    </div>
                    <div>
                      <label for="company" class={Maintable.label}>
                        Contact Relationship
                      </label>
                      <input
                        type="text"
                        name="emp_EmemergencyContactRelationship"
                        value={emp_EmemergencyContactRelationship}
                        onChange={(e) =>
                          setEmp_EmemergencyContactRelationship(e.target.value)
                        }
                        id="name"
                        class={Maintable.input}
                        placeholder="Father"
                      />
                    </div>
                    <div>
                      <label for="company" class={Maintable.label}>
                        Contact Address
                      </label>
                      <input
                        type="text"
                        name="emp_EmemergencyContactAddress"
                        value={emp_EmemergencyContactAddress}
                        onChange={(e) =>
                          setEmp_EmemergencyContactAddress(e.target.value)
                        }
                        id="name"
                        class={Maintable.input}
                        placeholder="20, Gandhi Street, Chennai-64"
                      />
                    </div>
                    <div>
                      <label for="company" class={Maintable.label}>
                        Contact Phone
                      </label>
                      <input
                        type="tel"
                        name="emp_EmemergencyContactPhone"
                        value={emp_EmemergencyContactPhone}
                        onChange={(e) =>
                          setEmp_EmemergencyContactPhone(e.target.value)
                        }
                        id="phone"
                        class={Maintable.input}
                        placeholder="123-45-678"
                        pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                      />
                    </div>
                    <div>
                      <label for="company" class={Maintable.label}>
                        Contact Mobile
                      </label>
                      <input
                        type="tel"
                        name="emp_EmemergencyContact_Mobile"
                        value={emp_EmemergencyContact_Mobile}
                        onChange={(e) =>
                          setEmp_EmemergencyContact_Mobile(e.target.value)
                        }
                        id="phone"
                        class={Maintable.input}
                        placeholder="+91 6385636425"
                        pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                      />
                    </div>
                    <div>
                      <label for="company" class={Maintable.label}>
                        Contact Email
                      </label>
                      <input
                        type="email"
                        name="emp_EmemergencyContactEmailId"
                        value={emp_EmemergencyContactEmailId}
                        onChange={(e) =>
                          setEmp_EmemergencyContactEmailId(e.target.value)
                        }
                        id="company"
                        class={Maintable.input}
                        placeholder="karthick@xlicon.in"
                      />
                    </div>
                  </div>
                  <div class={Maintable.labelBold}>Temporary Address</div>
                  <hr class={Maintable.horizontalLine}></hr>
                  <div class={Maintable.gird3}>
                    <div>
                      <label for="address1" class={Maintable.label}>
                        Address 1
                      </label>
                      <input
                        type="text"
                        name="emp_Temp_Address1"
                        value={emp_Temp_Address1}
                        onChange={(e) => setEmp_Temp_Address1(e.target.value)}
                        id="last_name"
                        class={Maintable.input}
                        placeholder="86,Ambedkar Nager"
                      />
                    </div>
                    <div>
                      <label for="address_2" class={Maintable.label}>
                        Address 2
                      </label>
                      <input
                        type="text"
                        name="emp_Temp_Address2"
                        value={emp_Temp_Address2}
                        onChange={(e) => setEmp_Temp_Address2(e.target.value)}
                        id="company"
                        class={Maintable.input}
                        placeholder="Mosur"
                      />
                    </div>
                    <div>
                      <label for="address_3" class={Maintable.label}>
                        Address 3
                      </label>
                      <input
                        type="text"
                        name="emp_Temp_Address3"
                        value={emp_Temp_Address3}
                        onChange={(e) => setEmp_Temp_Address3(e.target.value)}
                        id="company"
                        class={Maintable.input}
                        placeholder="Arakkonam"
                      />
                    </div>
                    <div>
                      <label for="city" class={Maintable.label}>
                        City
                      </label>
                      <input
                        type="text"
                        name="emp_Temp_City"
                        value={emp_Temp_City}
                        onChange={(e) => setEmp_Temp_City(e.target.value)}
                        id="company"
                        class={Maintable.input}
                        placeholder="Arakkonam"
                      />
                    </div>
                    <div>
                      <label for="state" class={Maintable.label}>
                        State
                      </label>
                      <input
                        type="text"
                        name="emp_Temp_State"
                        value={emp_Temp_State}
                        onChange={(e) => setEmp_Temp_State(e.target.value)}
                        id="company"
                        class={Maintable.input}
                        placeholder="tamilnadu"
                      />
                    </div>
                    <div>
                      <label for="country" class={Maintable.label}>
                        Country
                      </label>
                      <input
                        type="text"
                        name="emp_Temp_Country"
                        value={emp_Temp_Country}
                        onChange={(e) => setEmp_Temp_Country(e.target.value)}
                        id="company"
                        class={Maintable.input}
                        placeholder="India"
                      />
                    </div>
                    <div>
                      <label for="zipcode" class={Maintable.label}>
                        Zip Code
                      </label>
                      <input
                        type="text"
                        name="emp_Temp_ZipCode"
                        value={emp_Temp_ZipCode}
                        onChange={(e) => setEmp_Temp_ZipCode(e.target.value)}
                        id="company"
                        class={Maintable.input}
                        placeholder="8743783"
                      />
                    </div>
                  </div>
                  <div class={Maintable.labelBold}>Other Details</div>
                  <hr class={Maintable.horizontalLine}></hr>
                  <div class={Maintable.gird3}>
                    <div>
                      <label for="date_0f_joining" class={Maintable.label}>
                        Date of Joining
                      </label>
                      <input
                        type="date"
                        name="emp_Dateofjoining"
                        value={emp_Dateofjoining}
                        onChange={(e) => {
                          setEmp_Dateofjoining(e.target.value);
                          setEmp_DateofJoiningError(
                            emp_Dateofjoining.length > 1
                              ? ""
                              : "Enter Date of Joining"
                          );

                          // if (e.target.value.length > 1) {
                          //   setEmp_DateofJoiningError("");
                          // }
                        }}
                        id="last_name"
                        class={Maintable.input}
                        className={
                          emp_DateofJoiningError
                            ? validationcolor.errorColor
                            : Maintable.input
                        }
                        placeholder="12-07-2022"
                      />
                      {emp_DateofJoiningError && (
                        <span class="text-red-500">
                          {emp_DateofJoiningError}
                        </span>
                      )}
                    </div>
                    <div>
                      <label for="experience" class={Maintable.label}>
                        Experience
                      </label>
                      <input
                        type="text"
                        name="emp_Experience"
                        value={emp_Experience}
                        onChange={(e) => setEmp_Experience(e.target.value)}
                        id="exper"
                        class={Maintable.input}
                        placeholder="5 years"
                      />
                    </div>
                    <div>
                      <label for="designation" class={Maintable.label}>
                        Designation
                      </label>
                      <input
                        name="emp_Designation"
                        value={emp_Designation}
                        onChange={(e) => setEmp_Designation(e.target.value)}
                        type="text"
                        id="designation"
                        class={Maintable.input}
                        placeholder="Software Devloper"
                      />
                    </div>
                    <div>
                      <label for="account_number" class={Maintable.label}>
                        Account Number
                      </label>
                      <input
                        name="emp_AccountNumber"
                        value={emp_AccountNumber}
                        onChange={(e) => setEmp_AccountNumber(e.target.value)}
                        type="number"
                        id="company"
                        class={Maintable.input}
                        placeholder="129836637582652"
                      />
                    </div>
                    <div>
                      <label for="Pan" class={Maintable.label}>
                        Bank Name
                      </label>
                      <input
                        name="emp_BankName"
                        value={emp_BankName}
                        onChange={(e) => setEmp_BankName(e.target.value)}
                        type="text"
                        id="company"
                        class={Maintable.input}
                        placeholder="HDFC Bank"
                      />
                    </div>
                    {/* <div>
                      <label for="Pan" class={Maintable.label}>
                        IFSC Code
                      </label>
                      <input
                        name="emp_IFSCcode"
                        value={emp_IFSCcode}
                        onChange={(e) => setEmp_IFSCcode(e.target.value)}
                        type="number"
                        id="company"
                        class={Maintable.input}
                        placeholder="HDFC56266"
                      />
                    </div> */}
                    <div>
                      <label for="Pan" class={Maintable.label}>
                        Date Of leaving
                      </label>
                      <input
                        type="Date"
                        name="emp_DateofLeaving"
                        value={emp_DateofLeaving}
                        onChange={(e) => setEmp_DateofLeaving(e.target.value)}
                        id="company"
                        class={Maintable.input}
                      />
                    </div>
                    <div class="lg:col-span-3 sm:col-span-1">
                      <label for="Skills" class={Maintable.label}>
                        Skills
                      </label>
                      <div class={Maintable.input_richtextbox}>
                        <Container>
                          <ReactQuill
                            style={{ height: "200px" }}
                            theme="snow"
                            modules={modules}
                            formats={formats}
                            id="message"
                            name="emp_Skills"
                            value={emp_Skills}
                            onChange={(e) => setEmp_Skills(e.target.value)}
                          />
                        </Container>
                      </div>
                    </div>
                  </div>
                </form>
                <div class={Maintable.submitCancelMainDiv}>
                  {submitButton && (
                    <button
                      data-modal-hide="bottom-right-modal"
                      type="button"
                      class={Maintable.submitButton}
                      onClick={() => HandleSubmit(0, 1)}
                    >
                      SUBMIT
                    </button>
                  )}

                  {updateButton && (
                    <button
                      data-modal-hide="bottom-right-modal"
                      type="button"
                      class={Maintable.submitButton}
                      onClick={() => HandleSubmit(0, 2)}
                    >
                      Update
                    </button>
                  )}

                  <button
                    data-modal-hide="bottom-right-modal"
                    type="button"
                    onClick={cancel}
                    class={Maintable.cancelButton}
                  >
                    CANCEL
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <MainPopup
        popUpOpen={popUpViewAddress}
        setpopUpOpen={setPopUpViewAddress}
        Open={HandleAddress}
      >
        <div>
          <button class="w-full">
            <span
              className="text-white flex justify-end"
              onClick={handleClose}
              class="text-2xl p-0 m-o block float-right mr-3"
            >
              &times;
            </span>
          </button>
          <EmployeeAddress data={currentData} />
        </div>
      </MainPopup>

      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={HandleEmployeeDetails}
      >
        <div>
          <button class="w-full">
            <span
              className="text-white flex justify-end"
              onClick={handleClose}
              class="text-2xl p-0 m-o block float-right mr-3"
            >
              &times;
            </span>
          </button>
          <EmployeeDetails data={currentData} />
        </div>
        <div class={Maintable.submitCancelMainDiv}>
          {deleteButton && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              class={Maintable.submitButton}
              onClick={() => HandleSubmit(rowIndex, 3)}
            >
              Delete
            </button>
          )}

          {blockButton && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              class={Maintable.submitButton}
              onClick={() => HandleSubmit(rowIndex, 4)}
            >
              Block
              {/* {currentData.Emp_Blockedby == 1 ? "Unblock" : "Block"}*/}
            </button>
          )}
          {unBlockButton && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              class={Maintable.submitButton}
              onClick={() => HandleSubmit(rowIndex, 5)}
            >
              Unblock
              {/* {(currentData.cli_Blockedby == 1) ? "Unblock" : "Block"} */}
            </button>
          )}

          <button
            data-modal-hide="bottom-right-modal"
            type="button"
            class={Maintable.cancelButton}
            onClick={handleClose}
          >
            CANCEL
          </button>
        </div>
      </MainPopup>
    </Mainlayout>
  );
}

export default EmployeeMaster1;
