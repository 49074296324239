import React from "react";
import MainLayout from "../components/Mainlayout";

const Products = () => {
  return (
    <MainLayout>
      <div class="bg-white">Products</div>
    </MainLayout>
  );
};

export default Products;
