import React from "react";
import MainLayout from "./components/Mainlayout";
// //import LoginUP from './container/LoginUP';
// import SideMenu from './components/SideMenu';

import RootRouter from "./connector/RootRouter";

const App = () => {
  return (
    <div>
      <RootRouter />
    </div>
  );
};

export default App;
