import React, { useEffect, useState } from "react";
import ShivShanker from "../image/ShivShankar.jpg";
import UPPIL3 from "../image/uppil-2023-3.png";
import UPPIL4 from "../image/uppil-2023-4.png";
import UPPIL2 from "../image/uppil-2023-2.png";
import UPPILAI from "../image/AiDesigner-2.png";
import { NavLink, useLocation } from "react-router-dom";
import { FOCUSABLE_SELECTOR } from "@testing-library/user-event/dist/utils";
const SideMenu = ({ SideMenuOpentrue }) => {
  const [openSideMenu, setOpenSideMenu] = useState(false);
  const [profileDropOpen, setProfileDropOpen] = useState(false);
  const [inventrydropOpen, setinventrydropOpen] = useState(false);
  const [productionDropOpen, setProductionDropOpen] = useState(false);
  const [thirdDropOpen, setthirdDropOpen] = useState(false);
  const [fourthDropOpen, setfourthDropOpen] = useState(false);
  const [purchaseSubMenu, setPurchaseSubMenu] = useState(false);
  const [planningMenu, setPlaningMenu] = useState(false);
  const [reportSubMenu, setReportSubMenu] = useState(false);
  const [productionSubmenu, setProductionSubmenu] = useState(false);
  const [reportDropOpen, setReportDropOpen] = useState(false);
  const [administrationSubMenu, setAdministrationSubMenu] = useState(false);
  const [injectionMouldingDropDown, setInjectionMouldingDropDown] =
    useState(false);
  const [ReportInventrySubMenu, setReportInventrySubMenu] = useState(false);
  const [ProductionSubmenuDropdown, setProductionSubmenuDropdown] =
    useState(false);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState("");
  const { pathname } = useLocation();
  const [activeSubMenu, setActiveSubMenu] = useState("");
  const [inventryDropdownOpen, setInventryDropdownOpen] = useState(false);

  const [handleSidemenuClose, setHandleSidemenuClose] = useState(false);

  const [breadcrumbs, setBreadcrumbs] = useState([]);

  // const SideMenuOpentrue = () => {
  //   setOpenSideMenu(!openSideMenu);
  // };

  const PlaningOpen = () => {
    setPlaningMenu(!planningMenu);
    setinventrydropOpen(false);
  };

  const ProductionDropOpen = () => {
    setProductionDropOpen(!productionDropOpen);
    setPlaningMenu(false);
  };

  const ReportDropOpen = () => {
    setReportDropOpen(!reportDropOpen);
    setProductionDropOpen(false);
  };

  const AdministrationDropOpen = () => {
    setthirdDropOpen(!thirdDropOpen);
    setReportDropOpen(false);
  };

  const inventryDropOpen = () => {
    setinventrydropOpen(!inventrydropOpen);
    setProfileDropOpen(false);
  };

  useEffect(() => {
    const currentPath = pathname;
    console.log("currentPath:", currentPath);
    setActiveMenu(currentPath);
    if (
      currentPath == "/myprofile" ||
      currentPath == "/editprofile" ||
      currentPath == "/settings"
    ) {
      setProfileDropOpen(true);
    } else setProfileDropOpen(false);
    if (
      currentPath == "/domesticpurchase" ||
      currentPath == "/importpurchase" ||
      currentPath == "/grn" ||
      currentPath == "/rtnSupplier" ||
      currentPath == "/issuetoproduction" ||
      currentPath == "/transferotherdepartment"
    ) {
      setinventrydropOpen(true);
      setPurchaseSubMenu(true);
    } else {
      setinventrydropOpen(false);
      setPurchaseSubMenu(false);
    }
    if (currentPath == "/rmplanning") {
      setPlaningMenu(true);
    } else {
      setPlaningMenu(false);
    }
    if (
      currentPath == "/planshrinkfilm" ||
      currentPath == "/printedshrinkfilm" ||
      currentPath == "/printedshrinkfilmslitting" ||
      currentPath == "/printedshrinkfilmlamination" ||
      currentPath == "/injectionmoulding"
    ) {
      setProductionDropOpen(true);
      setProductionSubmenu(true);
    } else {
      setProductionDropOpen(false);
      setProductionSubmenu(false);
    }
    if (
      currentPath === "/purchaseorderreport" ||
      currentPath === "/pendingpo"
    ) {
      setReportSubMenu(true);
      setReportDropOpen(true);
    } else {
      setReportSubMenu(false);
    }

    if (
      currentPath === "/rmstack" ||
      currentPath === "/rmstackledger" ||
      currentPath === "/fgstack" ||
      currentPath === "/fgstackledger" ||
      currentPath === "/issuereport" ||
      currentPath === "/receiptreport"
    ) {
      setReportInventrySubMenu(true);
      setReportDropOpen(true);
    } else {
      setReportInventrySubMenu(false);
    }

    if (
      currentPath === "/planshrinkfilmreport" ||
      currentPath === "/printedshrinkfilmreport" ||
      currentPath === "/printedshrinkfilmslittingreport" ||
      currentPath === "/printedshrinkfilmlaminationReport"
    ) {
      setProductionSubmenuDropdown(true);
      setReportDropOpen(true);
    } else {
      setProductionSubmenuDropdown(false);
    }
    if (
      currentPath == "/usermaster" ||
      currentPath == "/role" ||
      currentPath == "/rolevscapability" ||
      currentPath == "/CustomerMaster" ||
      currentPath == "/supplierMaster" ||
      currentPath == "/employeeMaster" ||
      currentPath == "/raw_matrialmaster" ||
      currentPath == "/fgmanagement" ||
      currentPath == "/unitofmeasure" ||
      currentPath == "/machinemaster" ||
      currentPath == "/departmentMaster" ||
      currentPath == "/systemsettings"
    ) {
      setthirdDropOpen(true);
    } else {
      setthirdDropOpen(false);
    }
  }, [pathname]);

  const handleInventryDropdownOpen = () => {
    setinventrydropOpen(!inventrydropOpen);
    setInventryDropdownOpen(true);
  };

  const SideMenuClose = () => {
    let status = !handleSidemenuClose;
    console.log("Status", status);
  };

  return (
    <div className="">
      <div
        className={`${
          openSideMenu
            ? "block fixed top-0 left-0 z-40 w-fit md:hidden sm:hidden lg:block m-4 bg-gray-400 h-screen  overflow-y-auto transition-transform -translate-x-full  dark:bg-gray-800"
            : ""
        }`}
      >
        <button
          type="button"
          data-drawer-hide="drawer-navigation"
          aria-controls="drawer-navigation"
          className=" mb-4 bg-transparent hover:bg-gray-200 hover: text-white  rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
        >
          {/* <svg  className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" ></path></svg> */}
          <span className="sr-only">Close menu</span>
        </button>
        <div
          className={`${
            SideMenuOpentrue
              ? `py-4  left-0 sm:w-full absolute md:relative lg:relative ${
                  handleSidemenuClose ? "hidden" : "block"
                } bg-hero lg:hidden  bg-blend-luminosity z-10 bg-[#e0e7ff] w-full md:w-fit h-screen p-4 drop-shadow-md transform  transition-transform duration-150 ease-in md:translate-x-0 overflow-y-auto`
              : `py-4 relative left-0 sm:block bg-hero hidden lg:block bg-blend-luminosity  z-10 bg-[#e0e7ff] w-full md:w-fit h-screen p-4 drop-shadow-md transition-transform  -translate-x-full translate-x-0  overflow-y-auto`
          }`}
        >
          <div className="flex justify-end ml-11">
            <button
              class=" absolute font-extrabold text-white lg:hidden md:hidden block"
              onClick={() => SideMenuClose()}
            >
              X
            </button>
          </div>
          <div className="p-5 flex justify-center">
            <img src={UPPIL3} width="80%" height="50%" alt="Image"></img>
          </div>

          {/* <ul className="space-y-2  py-6 font-medium">
            <li>
              <button
                type="button"
                onClick={() => setProfileDropOpen(!profileDropOpen)}
                className="flex items-center w-full p-2  text-white  transition duration-75 rounded-lg group  dark:text-white "
                aria-controls="dropdown-example"
                data-collapse-toggle="dropdown-example"
              >
                <img
                  src={thavasi}
                  alt="Image"
                  className="w-12 h-10 rounded-lg text-gray-500 transition duration-75 dark:text-gray-400 group-hover: text-white  dark:group-hover:text-white"
                />

                <span className="flex-1 ml-3 text-left text-white whitespace-nowrap">
                  Thavasi
                </span>
                <svg
                  className="w-6 h-6  text-white "
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"></path>
                </svg>
              </button>
              {profileDropOpen && (
                <ul id="dropdown-example" className=" py-2 space-y-2">
                  <li>
                    <NavLink
                      href="#"
                      className="flex  text-white  items-center w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white "
                    >
                      Products
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      href="#"
                      className="flex   text-white items-center w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white "
                    >
                      Billing
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      href="#"
                      className="flex   text-white  items-center w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white "
                    >
                      Invoice
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
          </ul> */}

          <ul className="space-y-2 py-4 font-medium">
            <li>
              <button
                type="button"
                onClick={() => setProfileDropOpen(!profileDropOpen)}
                className="flex items-center w-full p-2  text-white  transition duration-75 rounded-lg group hover:pointer-events-auto hover:bg-[#00000080] dark:text-white "
                aria-controls="dropdown-example"
                data-collapse-toggle="dropdown-example"
              >
                <img
                  src={ShivShanker}
                  alt="Image"
                  className="w-14 h-12  text-gray-500 transition duration-75"
                />

                <span className="flex-1 ml-3 text-left text-white whitespace-nowrap">
                  Shankar
                </span>
                <svg
                  className="w-6 h-6  text-white "
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"></path>
                </svg>
              </button>
              {profileDropOpen && (
                <ul id="dropdown-example" className=" py-2 space-y-2">
                  <li>
                    <NavLink
                      to={"/myprofile"}
                      className="flex text-white hover:pointer-events-auto hover:bg-[#00000080] items-center w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white "
                      // onClick={()=> setActiveMenu()}
                    >
                      My Profile
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/editprofile"}
                      className="flex hover:pointer-events-auto hover:bg-[#00000080]  text-white items-center w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white "
                    >
                      Edit Profile
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/settings"}
                      className="flex hover:pointer-events-auto hover:bg-[#00000080]  text-white  items-center w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white "
                    >
                      Settings
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <NavLink
                href="#"
                to={"/dashboard"}
                onClick={() => setBreadcrumbs(["dashboard"])}
                className={`flex items-center  text-white p-2 hover:pointer-events-auto hover:bg-[#00000080]    rounded-lg dark:text-white   ${
                  activeMenu === "/dashboard"
                    ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                    : ""
                }`}
                //className="flex items-center hover:pointer-events-auto hover:bg-[#00000080]  text-white p-2 hover:pointer-events-auto hover:bg-[#00000080]    rounded-lg dark:text-white  "
              >
                <svg
                  className="flex-shrink-0 w-6 h-6   text-white transition duration-75 group-hover: text-white  dark:text-gray-400 dark:group-hover:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                  <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                </svg>
                <span className="ml-3  text-white ">Dashboard</span>
              </NavLink>
            </li>

            <li>
              <NavLink
                type="button"
                //                 onClick={() => {
                //                   setinventrydropOpen(!inventrydropOpen);
                //                   setPurchaseSubMenu(!purchaseSubMenu);
                //                 }}
                //                 className={`flex items-center w-full p-2 group hover:pointer-events-auto hover:bg-[#00000080] text-white transition duration-75 rounded-lg dark:text-white
                // ${inventrydropOpen ? "submenu-open" : ""}`}
                onClick={() => {
                  setinventrydropOpen(!inventrydropOpen);
                  setBreadcrumbs(["inventry"]);
                }}
                className={`flex items-center w-full p-2 group hover:pointer-events-auto hover:bg-[#00000080] text-white transition duration-75 rounded-lg dark:text-white`}
              >
                <svg
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  // height="25px"
                  // width="50px"
                  className="flex-shrink-0 w-6 h-6    transition duration-75 group-hover: text-white  dark:text-gray-400 dark:group-hover:text-white"
                >
                  <path d="M19 6h-3V5c0-1.1-.9-2-2-2h-4c-1.1 0-2 .9-2 2v1H5C3.3 6 2 7.3 2 9v9c0 1.7 1.3 3 3 3h14c1.7 0 3-1.3 3-3V9c0-1.7-1.3-3-3-3zm-9-1h4v1h-4V5zm10 13c0 .6-.4 1-1 1H5c-.6 0-1-.4-1-1v-5.6L8.7 14H15c.1 0 .2 0 .3-.1l4.7-1.6V18z" />
                </svg>
                <span className="flex-1 ml-3 text-left whitespace-nowrap">
                  Inventory
                </span>
                <svg
                  className="w-6 h-6  text-white "
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"></path>
                </svg>
              </NavLink>
              {inventrydropOpen && (
                <ul id="dropdown-example" className=" py-2 space-y-2">
                  <li>
                    <NavLink
                      onClick={() => {
                        setPurchaseSubMenu(!purchaseSubMenu);
                        setBreadcrumbs([""]);
                      }}
                      className={`flex  hover:pointer-events-auto hover:bg-[#00000080] items-center w-full p-2   text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white`}
                    >
                      Purchase
                    </NavLink>
                    {purchaseSubMenu && (
                      <ul className="ml-4">
                        <li>
                          <NavLink
                            href="#"
                            to={"/domesticpurchase"}
                            onClick={() => {
                              // setinventrydropOpen(!inventrydropOpen);
                              // setPurchaseSubMenu(!purchaseSubMenu);
                            }}
                            className={`flex  hover:pointer-events-auto hover:bg-[#00000080] items-center w-full p-2   text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white  
                             ${
                               activeMenu === "/domesticpurchase"
                                 ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                                 : ""
                             }
                            `}
                          >
                            Domestic P.O.
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            href="#"
                            className={`flex  hover:pointer-events-auto hover:bg-[#00000080] items-center w-full p-2  mr-2 relative  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white   
                            ${
                              activeMenu === "/importpurchase"
                                ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                                : ""
                            }
                            `}
                          >
                            Import P.O.
                          </NavLink>
                        </li>
                      </ul>
                    )}
                  </li>

                  <li>
                    <NavLink
                      href="#"
                      to={"/grn"}
                      className={`flex  text-white  items-center w-full p-2  hover:pointer-events-auto hover:bg-[#00000080]  transition duration-75 rounded-lg pl-11 group  dark:text-white 
                      ${
                        activeMenu === "/grn"
                          ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                          : ""
                      }
                      `}
                    >
                      G.R.N.
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      href="#"
                      to={"/rtnSupplier"}
                      className={`flex  hover:pointer-events-auto hover:bg-[#00000080] items-center w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white 
                      ${
                        activeMenu === "/rtnSupplier"
                          ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                          : ""
                      }
                      `}
                    >
                      Return to Supplier
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      href="#"
                      to={"/issuetoproduction"}
                      className={`flex  hover:pointer-events-auto hover:bg-[#00000080] items-center w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white
                      ${
                        activeMenu === "/issuetoproduction"
                          ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                          : ""
                      }
                      `}
                    >
                      Issue to Production
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/transferotherdepartment"}
                      className={`flex  hover:pointer-events-auto hover:bg-[#00000080] items-center w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white 
                      ${
                        activeMenu === "/transferotherdepartment"
                          ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                          : ""
                      }
                      `}
                    >
                      Transfor to Other Department
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>

            <li>
              <button
                type="button"
                onClick={() => PlaningOpen()}
                className="flex  text-white items-center w-full p-2 hover:pointer-events-auto hover:bg-[#00000080] transition duration-75 rounded-lg group  dark:text-white "
                aria-controls="dropdown-example"
                data-collapse-toggle="dropdown-example"
              >
                <svg
                  viewBox="0 0 640 512"
                  fill="currentColor"
                  className="flex-shrink-0 w-6 h-6 transition duration-75 group-hover: text-white  dark:text-gray-400 dark:group-hover:text-white"
                >
                  <path d="M58.9 42.1c3-6.1 9.6-9.6 16.3-8.7L320 64l244.8-30.6c6.7-.8 13.3 2.7 16.3 8.7l41.7 83.4c9 17.9-.6 39.6-19.8 45.1l-163.4 46.7c-13.9 4-28.8-1.9-36.2-14.3L320 64l-83.4 139c-7.4 12.4-22.3 18.3-36.2 14.3L37.1 170.6c-19.3-5.5-28.8-27.2-19.8-45.1l41.6-83.4zM321.1 128l54.9 91.4c14.9 24.8 44.6 36.6 72.5 28.6L576 211.6v167c0 22-15 41.2-36.4 46.6l-204.1 51c-10.2 2.6-20.9 2.6-31 0l-204.1-51C79 419.7 64 400.5 64 378.5v-167L191.6 248c27.8 8 57.6-3.8 72.5-28.6l54.8-91.4h2.2z" />
                </svg>
                <span className="flex-1 ml-3 text-left  text-white  whitespace-nowrap">
                  Planning
                </span>
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"></path>
                </svg>
              </button>
              {planningMenu && (
                <ul id="dropdown-example" className=" py-2 space-y-2">
                  <li>
                    <NavLink
                      to={"/rmplanning"}
                      className={`flex items-center  hover:pointer-events-auto hover:bg-[#00000080] w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white
                      ${
                        activeMenu === "/rmplanning"
                          ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                          : ""
                      }
                      `}
                    >
                      R.M. Planning
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <button
                type="button"
                onClick={() => ProductionDropOpen()}
                className="flex  text-white items-center w-full p-2 hover:pointer-events-auto hover:bg-[#00000080] transition duration-75 rounded-lg group  dark:text-white "
                aria-controls="dropdown-example"
                data-collapse-toggle="dropdown-example"
              >
                <svg
                  viewBox="0 0 64 64"
                  fill="currentColor"
                  // height="1em"
                  // width="1em"
                  className="flex-shrink-0 w-6 hover:pointer-events-auto hover:bg-[#00000080]  h-6 transition duration-75 group-hover: text-white  dark:text-gray-400 dark:group-hover:text-white"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M36 34l5 5"
                  />
                  <path
                    fill="none"
                    stroke="currentColor"
                    strokeMiterlimit={10}
                    strokeWidth={1.99998}
                    d="M63 55l-5.999 6-19-19 6-6z"
                  />
                  <path
                    fill="none"
                    stroke="currentColor"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M12 16l6-6L8 4 6 6zM28 26L15 13M58 12.5l-8 3.75-4-4.125 3.5-8.062h0C39.5 4.062 37 9 37 14v4L3.5 52l-1.75 6 2.125 2 6.062-1.5L44 25h4c5 0 10-2.5 10-12.5h0z"
                  />
                </svg>
                <span className="flex-1 ml-3 text-left  text-white  whitespace-nowrap">
                  Production
                </span>
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"></path>
                </svg>
              </button>
              {productionDropOpen && (
                <ul id="dropdown-example" className=" py-2 space-y-2">
                  <li>
                    <NavLink
                      href="#"
                      // to={"/shrinkflim"}
                      onClick={() => setProductionSubmenu(!productionSubmenu)}
                      className="flex items-center  hover:pointer-events-auto hover:bg-[#00000080] w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white "
                    >
                      Blow Film
                    </NavLink>
                    {productionSubmenu && (
                      <ul class="ml-4">
                        <li>
                          <NavLink
                            href="#"
                            to={"/planshrinkfilm"}
                            className={`flex items-center  hover:pointer-events-auto hover:bg-[#00000080] w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white 
                            ${
                              activeMenu === "/planshrinkfilm"
                                ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                                : ""
                            }
                            `}
                          >
                            Plain Shrink Film
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            href="#"
                            to={"/printedshrinkfilm"}
                            className={`flex items-center  hover:pointer-events-auto hover:bg-[#00000080] w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white 
                            ${
                              activeMenu === "/printedshrinkfilm"
                                ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                                : ""
                            }
                            `}
                          >
                            Printed Shrink Film
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            href="#"
                            to={"/printedshrinkfilmslitting"}
                            className={`flex items-center  hover:pointer-events-auto hover:bg-[#00000080] w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white 
                            ${
                              activeMenu === "/printedshrinkfilmslitting"
                                ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                                : ""
                            }
                            `}
                          >
                            Printed Shrink Film Slitting
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            href="#"
                            to={"/printedshrinkfilmlamination"}
                            className={`flex items-center  hover:pointer-events-auto hover:bg-[#00000080] w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white
                            ${
                              activeMenu === "/printedshrinkfilmlamination"
                                ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                                : ""
                            }
                            `}
                          >
                            Printed Shrink Film Lamination
                          </NavLink>
                        </li>
                      </ul>
                    )}
                  </li>
                  <li>
                    <NavLink
                      href="#"
                      to={"/injectionmoulding"}
                      onClick={() =>
                        setInjectionMouldingDropDown(!injectionMouldingDropDown)
                      }
                      className={`flex items-center hover:pointer-events-auto hover:bg-[#00000080]    w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white 
                      ${
                        activeMenu === "/injectionmoulding"
                          ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                          : ""
                      }
                      `}
                    >
                      Injection Moulding
                    </NavLink>
                    {injectionMouldingDropDown && (
                      <ul class="ml-4">
                        <li>
                          <NavLink
                            href="#"
                            className="flex items-center hover:pointer-events-auto hover:bg-[#00000080]    w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white "
                          >
                            Thinwall Food Containers
                          </NavLink>
                        </li>
                      </ul>
                    )}
                  </li>
                </ul>
              )}
            </li>

            <li>
              <button
                type="button"
                onClick={() => ReportDropOpen()}
                className="flex  text-white items-center w-full p-2 hover:pointer-events-auto hover:bg-[#00000080] transition duration-75 rounded-lg group  dark:text-white "
                aria-controls="dropdown-example"
                data-collapse-toggle="dropdown-example"
              >
                <svg
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  // height="1em"
                  // width="1em"
                  className="flex-shrink-0 w-6 h-6 hover:pointer-events-auto hover:bg-[#00000080] transition duration-75 group-hover: text-white  dark:text-gray-400 dark:group-hover:text-white"
                >
                  <path
                    fill="currentColor"
                    d="M8 0C3.582 0 0 1.119 0 2.5v2C0 5.881 3.582 7 8 7s8-1.119 8-2.5v-2C16 1.119 12.418 0 8 0z"
                  />
                  <path
                    fill="currentColor"
                    d="M8 8.5C3.582 8.5 0 7.381 0 6v3c0 1.381 3.582 2.5 8 2.5s8-1.119 8-2.5V6c0 1.381-3.582 2.5-8 2.5z"
                  />
                  <path
                    fill="currentColor"
                    d="M8 13c-4.418 0-8-1.119-8-2.5v3C0 14.881 3.582 16 8 16s8-1.119 8-2.5v-3c0 1.381-3.582 2.5-8 2.5z"
                  />
                </svg>
                <span className="flex-1 ml-3 text-left  text-white  whitespace-nowrap">
                  Reports
                </span>
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"></path>
                </svg>
              </button>
              {reportDropOpen && (
                <ul id="dropdown-example" className=" py-2 space-y-2">
                  <li>
                    <NavLink
                      href="#"
                      onClick={() => setReportSubMenu(!reportSubMenu)}
                      className="flex items-center  hover:pointer-events-auto hover:bg-[#00000080] w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white "
                    >
                      M.I.S.
                    </NavLink>
                    {reportSubMenu && (
                      <ul class="ml-4">
                        <li>
                          <NavLink
                            href="#"
                            to={"/purchaseorderreport"}
                            className={`flex items-center  hover:pointer-events-auto hover:bg-[#00000080] w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white 
                            ${
                              activeMenu === "/purchaseorderreport"
                                ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                                : ""
                            }
                            `}
                          >
                            P.O. Register
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            href="#"
                            to={"/pendingpo"}
                            className={`flex items-center  hover:pointer-events-auto hover:bg-[#00000080] w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white 
                            ${
                              activeMenu === "/pendingpo"
                                ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                                : ""
                            }
                            `}
                          >
                            Pending P.O.
                          </NavLink>
                        </li>
                      </ul>
                    )}
                  </li>
                  <li>
                    <NavLink
                      href="#"
                      onClick={() =>
                        setReportInventrySubMenu(!ReportInventrySubMenu)
                      }
                      className="flex items-center hover:pointer-events-auto hover:bg-[#00000080]    w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white "
                    >
                      Inventory
                    </NavLink>
                    {ReportInventrySubMenu && (
                      <ul class="ml-4">
                        <li>
                          <NavLink
                            href="#"
                            to={"/rmstack"}
                            className={`flex items-center  hover:pointer-events-auto hover:bg-[#00000080] w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white
                            ${
                              activeMenu === "/rmstack"
                                ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                                : ""
                            }
                            `}
                          >
                            R.M. Stack
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            href="#"
                            to={"/rmstackledger"}
                            className={`flex items-center  hover:pointer-events-auto hover:bg-[#00000080] w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white 
                            ${
                              activeMenu === "/rmstackledger"
                                ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                                : ""
                            }
                            `}
                          >
                            R.M. Stack Ledger
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            href="#"
                            to={"/fgstack"}
                            className={`flex items-center  hover:pointer-events-auto hover:bg-[#00000080] w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white 
                            ${
                              activeMenu === "/fgstack"
                                ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                                : ""
                            }
                            `}
                          >
                            F.G. Stack
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            href="#"
                            to={"/fgstackledger"}
                            className={`flex items-center  hover:pointer-events-auto hover:bg-[#00000080] w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white 
                            ${
                              activeMenu === "/fgstackledger"
                                ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                                : ""
                            }
                            `}
                          >
                            F.G. Stack Ledger
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            href="#"
                            to={"/issuereport"}
                            className={`flex items-center  hover:pointer-events-auto hover:bg-[#00000080] w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white 
                            ${
                              activeMenu === "/issuereport"
                                ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                                : ""
                            }
                            `}
                          >
                            Issue Report
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            href="#"
                            className={`flex items-center  hover:pointer-events-auto hover:bg-[#00000080] w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white 
                            ${
                              activeMenu === "/injectionmoulding"
                                ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                                : ""
                            }
                            `}
                          >
                            Receipt Report
                          </NavLink>
                        </li>
                      </ul>
                    )}
                  </li>

                  <li>
                    <NavLink
                      href="#"
                      onClick={() =>
                        setProductionSubmenuDropdown(!ProductionSubmenuDropdown)
                      }
                      className="flex items-center hover:pointer-events-auto hover:bg-[#00000080]    w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white "
                    >
                      Production
                    </NavLink>
                    {ProductionSubmenuDropdown && (
                      <ul class="ml-4">
                        <li>
                          <NavLink
                            href="#"
                            to={"/planshrinkfilmreport"}
                            className={`flex items-center  hover:pointer-events-auto hover:bg-[#00000080] w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white 
                            ${
                              activeMenu === "/planshrinkfilmreport"
                                ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                                : ""
                            }
                            `}
                          >
                            Plain Shrink Film
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            href="#"
                            to={"/printedshrinkfilmreport"}
                            className={`flex items-center  hover:pointer-events-auto hover:bg-[#00000080] w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white 
                            ${
                              activeMenu === "/printedshrinkfilmreport"
                                ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                                : ""
                            }
                            `}
                          >
                            Printed Shrink Film
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            href="#"
                            to={"/printedshrinkfilmslittingreport"}
                            className={`flex items-center  hover:pointer-events-auto hover:bg-[#00000080] w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white 
                            ${
                              activeMenu === "/printedshrinkfilmslittingreport"
                                ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                                : ""
                            }
                            `}
                          >
                            Printed Shrink Film Slitting
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            href="#"
                            to={"/printedshrinkfilmlaminationReport"}
                            className={`flex items-center  hover:pointer-events-auto hover:bg-[#00000080] w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white 
                            ${
                              activeMenu ===
                              "/printedshrinkfilmlaminationReport"
                                ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                                : ""
                            }
                            `}
                          >
                            Printed Shrink Film Lamination
                          </NavLink>
                        </li>
                      </ul>
                    )}
                  </li>
                </ul>
              )}
            </li>

            <li>
              <button
                type="button"
                onClick={() => AdministrationDropOpen()}
                className="flex items-center w-full p-2 hover:pointer-events-auto hover:bg-[#00000080] text-white    transition duration-75 rounded-lg group  dark:text-white "
                aria-controls="dropdown-example"
                data-collapse-toggle="dropdown-example"
              >
                <svg
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  // height="1em"
                  // width="1em"
                  className="flex-shrink-0 w-6 h-6 hover:pointer-events-auto hover:bg-[#00000080]  text-white transition duration-75 group-hover:   dark:text-gray-400 dark:group-hover:text-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 14v2a6 6 0 00-6 6H4a8 8 0 018-8zm0-1c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm2.595 7.812a3.51 3.51 0 010-1.623l-.992-.573 1-1.732.992.573A3.496 3.496 0 0117 14.645V13.5h2v1.145c.532.158 1.012.44 1.405.812l.992-.573 1 1.732-.992.573a3.51 3.51 0 010 1.622l.992.573-1 1.732-.992-.573a3.496 3.496 0 01-1.405.812V22.5h-2v-1.145a3.496 3.496 0 01-1.405-.812l-.992.573-1-1.732.992-.572zM18 19.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                </svg>
                <span className="flex-1 ml-3  text-white  text-left whitespace-nowrap">
                  Admin
                </span>
                <svg
                  className="w-6 h-6  text-white "
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"></path>
                </svg>
              </button>
              {thirdDropOpen && (
                <ul id="dropdown-example" className=" py-2 space-y-2">
                  <li className="flex items-center hover:pointer-events-auto hover:bg-[#00000080] text-gray-200 font-extrabold decoration-gray-500 w-full p-2 transition duration-75 rounded-lg pl-7 group  dark:text-white ">
                    User
                  </li>

                  <li>
                    <NavLink
                      href="#"
                      to={"/usermaster"}
                      className={`flex items-center  hover:pointer-events-auto hover:bg-[#00000080] w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white 
                      ${
                        activeMenu === "/usermaster"
                          ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                          : ""
                      }
                      `}
                    >
                      User
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      href="#"
                      to={"/role"}
                      className={`flex items-center  hover:pointer-events-auto hover:bg-[#00000080] w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white 
                      ${
                        activeMenu === "/role"
                          ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                          : ""
                      }
                      `}
                    >
                      Role
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      href="#"
                      to={"/rolevscapability"}
                      className={`flex items-center  hover:pointer-events-auto hover:bg-[#00000080] w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white 
                      ${
                        activeMenu === "/rolevscapability"
                          ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                          : ""
                      }
                      `}
                    >
                      Role vs Capability
                    </NavLink>
                    <li className="flex items-center hover:pointer-events-auto hover:bg-[#00000080] text-white text-gray-200 font-extrabold  w-full p-2 transition duration-75 rounded-lg pl-7 group  dark:text-white ">
                      Master
                    </li>

                    <li>
                      <NavLink
                        href="#"
                        to={"/CustomerMaster"}
                        className={`flex items-center  hover:pointer-events-auto hover:bg-[#00000080] w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white 
                        ${
                          activeMenu === "/CustomerMaster"
                            ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                            : ""
                        }
                        `}
                      >
                        Customer
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        href="#"
                        to={"/supplierMaster"}
                        className={`flex items-center  hover:pointer-events-auto hover:bg-[#00000080] w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white 
                        ${
                          activeMenu === "/supplierMaster"
                            ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                            : ""
                        }
                        `}
                      >
                        Supplier
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        href="#"
                        to={"/employeeMaster"}
                        className={`flex items-center  hover:pointer-events-auto hover:bg-[#00000080] w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white 
                        ${
                          activeMenu === "/employeeMaster"
                            ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                            : ""
                        }
                        `}
                      >
                        Employee
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        href="#"
                        to={"/raw_matrialmaster"}
                        className={`flex items-center  hover:pointer-events-auto hover:bg-[#00000080] w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white 
                        ${
                          activeMenu === "/raw_matrialmaster"
                            ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                            : ""
                        }
                        `}
                      >
                        Raw Material
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        href="#"
                        to={"/fgmanagement"}
                        className={`flex items-center  hover:pointer-events-auto hover:bg-[#00000080] w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white 
                        ${
                          activeMenu === "/fgmanagement"
                            ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                            : ""
                        }
                        `}
                      >
                        Finished Goods
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        href="#"
                        to={"/unitofmeasure"}
                        className={`flex items-center  hover:pointer-events-auto hover:bg-[#00000080] w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white 
                        ${
                          activeMenu === "/unitofmeasure"
                            ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                            : ""
                        }
                        `}
                      >
                        Unit Of Measure
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        href="#"
                        to={"/machinemaster"}
                        className={`flex items-center  hover:pointer-events-auto hover:bg-[#00000080] w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white 
                        ${
                          activeMenu === "/machinemaster"
                            ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                            : ""
                        }
                        `}
                      >
                        Machine
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        href="#"
                        to={"/departmentMaster"}
                        className={`flex items-center  hover:pointer-events-auto hover:bg-[#00000080] w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white 
                        ${
                          activeMenu === "/departmentMaster"
                            ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                            : ""
                        }
                        `}
                      >
                        Department
                      </NavLink>
                    </li>
                    <li>
                      {/* <NavLink
                        href="#"
                        to={"/raw_matrialmaster"}
                        className="flex items-center hover:pointer-events-auto hover:bg-[#00000080] text-white  w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white "
                      >
                        Raw Material
                      </NavLink> */}
                    </li>
                    <li>
                      {/* <NavLink
                        href="#"
                        to={"/fgmanagement"}
                        className="flex items-center w-full p-2 hover:pointer-events-auto hover:bg-[#00000080] text-white transition duration-75 rounded-lg pl-11 group  dark:text-white "
                      >
                        Finished Goods
                      </NavLink> */}
                      <li>
                        <li className="flex items-center hover:pointer-events-auto text-gray-200 font-extraboldtext-gray-200 font-extrabold hover:bg-[#00000080] text-white  w-full p-2 transition duration-75 rounded-lg pl-7 group  dark:text-white ">
                          Settings
                        </li>
                        <NavLink
                          href="#"
                          to={"/systemsettings"}
                          className={`flex items-center  hover:pointer-events-auto hover:bg-[#00000080] w-full p-2  text-white  transition duration-75 rounded-lg pl-11 group  dark:text-white 
                          ${
                            activeMenu === "/systemsettings"
                              ? "hover:pointer-events-auto bg-[#00000080]  p-2"
                              : ""
                          }
                          `}
                        >
                          System Settings
                        </NavLink>
                      </li>
                    </li>
                  </li>
                </ul>
              )}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
