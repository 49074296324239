import React, { useState } from "react";
import MainLayout from "../components/Mainlayout";
import { Maintable } from "../css/Common";
import Select from "react-select";
import TableGrid from "../components/table";
import {
  IssueToProdHeader,
  PlainshrinkflimData,
  PlainshrinkflimHeader,
  PrintedshrinkflimData,
  PrintedshrinkflimHeader,
  PrintedshrinkflimslittingData,
  PrintedshrinkflimslittingHeader,
  RMStackHeader,
  domesticPurchaseHeader,
} from "../components/headerAttribute";
import MainPopup from "../popup-containers/MainPopup";
import PurchaseDetails from "../popup-containers/domesticPurchasePopUp";
import SupplierDetails from "../popup-containers/supplierPopUp";

const PrintedShrinkFilmSlittingReport = () => {
  const [showTable, setShowTable] = useState(false);
  const [viewPoDetails, setViewPoDetails] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [popUpOpen, setpopUpOpen] = useState(false);
  const [viewSupplierDetails, setViewSupplierDetails] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);

  const [bindMaterialDD, setBindMaterialDD] = useState([
    { value: "blowfilm", label: "Polymer (RMLD00001)" },
    { value: "printing", label: "Polyethylene (RMHD00001)" },
    { value: "slitting", label: "Pigments (RMIN00001)" },
    { value: "lamination", label: "Solvents (RMSL00001)" },
    { value: "reproduction", label: "Polymers (RMSD00001)" },
    { value: "injectionmoulding", label: "Acrylic (RMAD00001)" },
    { value: "fgstore", label: "Plain Shrink Film(PSF00001)" },
    { value: "rmstore", label: "Printed Shrink Film(PSF00002)" },
    { value: "fgstore", label: "Shrink Film for Water Pouch(PSF00003)" },
    { value: "rmstore", label: "Lamination Film(LAM00001)" },
    { value: "fgstore", label: "Thinwall Food Containers(TFC00001)" },
  ]);

 

  const handleClose = () => {
    setpopUpOpen(false);
    setViewPoDetails(false);
    setViewSupplierDetails(false);
  };
  const HandleSupplier = (rowIndex) => {
    handleSupplierOpen(true);
    setViewSupplierDetails(true);
    // setCurrentData(bindEmployeeMasterDatas[rowIndex]);
  };

  const HandleDetails = (rowIndex) => {
    handleAddressOpen(true);
    setViewDetails(true);
    // setCurrentData(bindEmployeeMasterDatas[rowIndex]);
  };

  const handleAddressOpen = (isOpen) => {
    setViewPoDetails(isOpen);
  };
  const handleSupplierOpen = (isOpen) => {
    setViewSupplierDetails(isOpen);
  };

  const GotableShow = () => {
    setShowTable(true);
  };

  return (
    <div>
      <MainLayout>
        <div class="relative mt-6 bg-white rounded-lg shadow-2xl shadow-gray-400  dark:bg-gray-700">
          <div class="px-16 mt-12 py-4">
            <div class="flex justify-center mb-4">
              <div class="text-2xl font-medium  items-center text-neutral-700 ">
              {`Reports - > Production ->Printed Shrink Film Slitting`}
              </div>
            </div>
            <div class={Maintable.gird9}>
              <div class="grid justify-end">
                <label class={Maintable.label}>Category</label>
              </div>
              <div class="grid col-span-2">
                <Select options={bindMaterialDD} class={Maintable.input} />
              </div>
              <div class="grid justify-end">
                <label class={Maintable.label}>From Date</label>
              </div>
              <div class="grid col-span-2">
                <input type="Date" name="from Date" class={Maintable.input} />
              </div>
              <div class="grid justify-end">
                <label class={Maintable.label}>To Date</label>
              </div>
              <div class="grid col-span-2">
                <input type="Date" name="from Date" class={Maintable.input} />
              </div>
              <div class="flex justify-start items-center mt-6">
                <button
                  type="button"
                  onClick={() => GotableShow()}
                  class="inline-block rounded bg-neutral-800 px-6 pb-2 pt-2.5  text-xs font-medium uppercase leading-normal text-neutral-50 shadow-[0_4px_9px_-4px_rgba(51,45,45,0.7)] transition duration-150 ease-in-out hover:bg-neutral-800 hover:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] focus:bg-neutral-800 focus:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] focus:outline-none focus:ring-0 active:bg-neutral-900 active:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] dark:bg-neutral-900 dark:shadow-[0_4px_9px_-4px_#030202] dark:hover:bg-neutral-900 dark:hover:shadow-[0_8px_9px_-4px_rgba(3,2,2,0.3),0_4px_18px_0_rgba(3,2,2,0.2)] dark:focus:bg-neutral-900 dark:focus:shadow-[0_8px_9px_-4px_rgba(3,2,2,0.3),0_4px_18px_0_rgba(3,2,2,0.2)] dark:active:bg-neutral-900 dark:active:shadow-[0_8px_9px_-4px_rgba(3,2,2,0.3),0_4px_18px_0_rgba(3,2,2,0.2)]"
                >
                  GO
                </button>
              </div>
            </div>
          </div>
          {showTable && (
            <div class="relative w-full mx-auto mt-4">
              {/* <div class={Maintable.headerTitle}>
                <div class="my-2 ml-7">Domestic P.O.</div>
              </div> */}
              <div class="mx-8">
                <div className="md:justify-between md:flex  md:items-center">
                  <div class={Maintable.searchAdd}>
                    <div className="mt-1 gap-2 flex justify-between items-baseline">
                      <label for="pageSize" class="hidden md:block">
                        Rows Per Page:
                      </label>
                      <select
                        class={Maintable.dropDownRecords}
                        name="pageSize"
                        id="pageSize"
                      >
                        <option value="2">2</option>
                        <option value="10" selected>
                          10
                        </option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                    <div>
                      <label for="table-search" class="sr-only">
                        Search
                      </label>
                    </div>
                    <div class="relative mt-1">
                      <div class={Maintable.searchIconPosition}>
                        <svg
                          class={Maintable.searchIconSvg}
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <input
                        type="text"
                        id="table-search"
                        class={Maintable.searchInputBox}
                        placeholder="Search for items"
                      />
                    </div>
                    {/* <button class="relative px-5 py-0 overflow-hidden font-medium text-gray-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner group">
                       <span class="absolute top-0 left-0 w-0 h-0 transition-all duration-200 border-t-2 border-gray-600 group-hover:w-full ease"></span>
                       <span class="absolute bottom-0 right-0 w-0 h-0 transition-all duration-200 border-b-2 border-gray-600 group-hover:w-full ease"></span>
                       <span class="absolute top-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-gray-600 group-hover:h-full ease"></span>
                       <span class="absolute bottom-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-gray-600 group-hover:h-full ease"></span>
                       <span class="absolute inset-0 w-full h-full duration-300 delay-300 bg-gray-900 opacity-0 group-hover:opacity-100"></span>
                       <span class="relative transition-colors duration-300 delay-200 group-hover:text-white ease">
                         <svg
                           fill="currentColor"
                           viewBox="0 0 16 16"
                           height="2em"
                           width="2em"
                           //class=" mt-1 bg-gray-800 inline-flex items-center sm:mx-3 justify-center  md:p-1 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                         >
                           <path d="M6 10.5a.5.5 0 01.5-.5h3a.5.5 0 010 1h-3a.5.5 0 01-.5-.5zm-2-3a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5zm-2-3a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5z" />
                         </svg>
                       </span>
                     </button> */}
                  </div>
                  {/* <div className="flex self-end">
                  <div className="text-red-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner w-fit h-9 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">
                    <span className="mb-12 text-red-600 text-7xl">.</span>
                    <span>Block</span>
                  </div>
                  <button
                       type="button"
                       onClick={showadd}
                       class={Maintable.addButton}
                     >
                       <svg
                         width="25"
                         height="20"
                         viewBox="0 0 50 50"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                       >
                         <path
                           d="M22.5 37.5H27.5V27.5H37.5V22.5H27.5V12.5H22.5V22.5H12.5V27.5H22.5V37.5ZM25 50C21.5417 50 18.2917 49.3433 15.25 48.03C12.2083 46.7183 9.5625 44.9375 7.3125 42.6875C5.0625 40.4375 3.28167 37.7917 1.97 34.75C0.656667 31.7083 0 28.4583 0 25C0 21.5417 0.656667 18.2917 1.97 15.25C3.28167 12.2083 5.0625 9.5625 7.3125 7.3125C9.5625 5.0625 12.2083 3.28083 15.25 1.9675C18.2917 0.655833 21.5417 0 25 0C28.4583 0 31.7083 0.655833 34.75 1.9675C37.7917 3.28083 40.4375 5.0625 42.6875 7.3125C44.9375 9.5625 46.7183 12.2083 48.03 15.25C49.3433 18.2917 50 21.5417 50 25C50 28.4583 49.3433 31.7083 48.03 34.75C46.7183 37.7917 44.9375 40.4375 42.6875 42.6875C40.4375 44.9375 37.7917 46.7183 34.75 48.03C31.7083 49.3433 28.4583 50 25 50ZM25 45C30.5833 45 35.3125 43.0625 39.1875 39.1875C43.0625 35.3125 45 30.5833 45 25C45 19.4167 43.0625 14.6875 39.1875 10.8125C35.3125 6.9375 30.5833 5 25 5C19.4167 5 14.6875 6.9375 10.8125 10.8125C6.9375 14.6875 5 19.4167 5 25C5 30.5833 6.9375 35.3125 10.8125 39.1875C14.6875 43.0625 19.4167 45 25 45Z"
                           fill="white"
                         />
                       </svg>
                       ADD
                     </button>
                </div> */}
                  <div class="flex justify-end items-center">
                    <button
                      type="button"
                      class="inline-block rounded bg-neutral-800 px-6 pb-2 pt-2.5  text-xs font-medium uppercase leading-normal text-neutral-50 shadow-[0_4px_9px_-4px_rgba(51,45,45,0.7)] transition duration-150 ease-in-out hover:bg-neutral-800 hover:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] focus:bg-neutral-800 focus:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] focus:outline-none focus:ring-0 active:bg-neutral-900 active:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] dark:bg-neutral-900 dark:shadow-[0_4px_9px_-4px_#030202] dark:hover:bg-neutral-900 dark:hover:shadow-[0_8px_9px_-4px_rgba(3,2,2,0.3),0_4px_18px_0_rgba(3,2,2,0.2)] dark:focus:bg-neutral-900 dark:focus:shadow-[0_8px_9px_-4px_rgba(3,2,2,0.3),0_4px_18px_0_rgba(3,2,2,0.2)] dark:active:bg-neutral-900 dark:active:shadow-[0_8px_9px_-4px_rgba(3,2,2,0.3),0_4px_18px_0_rgba(3,2,2,0.2)]"
                    >
                      Export
                    </button>
                  </div>
                </div>
                <div class={Maintable.tableShadowWidth}>
                  <TableGrid
                    className={Maintable.Table}
                    headerData={PrintedshrinkflimslittingHeader}
                    tableData={PrintedshrinkflimslittingData}
                    onAddressGrid={HandleDetails}
                  />
                </div>
                <div className={Maintable.submitCancelMainDiv}>
                  <button
                    // disabled={currentPage1 === 1}
                    // onClick={() => setCurrentPage(currentPage1 - 1)}
                    type="button"
                    className={Maintable.submitButton}
                  >
                    Previous
                  </button>
                  <label>1</label>
                  <button
                    // disabled={currentPage1 === totalPages / result}
                    // onClick={() => setCurrentPage(currentPage1 + 1)}
                    type="button"
                    className={Maintable.cancelButton}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <MainPopup
          popUpOpen={viewPoDetails}
          setpopUpOpen={setViewPoDetails}
          Open={HandleDetails}
        >
          <div>
            <button class="w-full">
              <span
                className="text-white flex justify-end"
                onClick={handleClose}
                class="text-2xl p-0 m-o block float-right mr-3"
              >
                &times;
              </span>
            </button>
            <PurchaseDetails data={currentData} />
          </div>
        </MainPopup>
        <MainPopup
          popUpOpen={viewSupplierDetails}
          setpopUpOpen={setViewSupplierDetails}
          Open={HandleSupplier}
          handlePopupClick={true}
        >
          <div>
            <button class="w-full">
              <span
                className="text-white flex justify-end"
                onClick={handleClose}
                class="text-2xl p-0 m-o block float-right mr-3"
              >
                &times;
              </span>
            </button>
            <SupplierDetails data={currentData} />
          </div>
        </MainPopup>
      </MainLayout>
    </div>
  );
};

export default PrintedShrinkFilmSlittingReport;
