import React, { useState } from "react";
import { Maintable } from "../css/Common";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";

const EmployeeDetails = ({ data }) => {
  debugger;
  // console.log("Details:", data);
  const [editMode, setEditMode] = useState([]);

  const modules = {
    toolbar: [
      //[{ 'font': [] }],
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      [{ align: [] }, { color: [] }, { background: [] }],
      ["clean"],
    ],
  };
  const formats = [
    //'font',
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "color",
    "background",
  ];
  const Container = styled.div`
    height: 243px;
  `;
  return (
    <div>
      <div class="w-full">
        <div>
          <div class="text-xl  mx-14 font-medium text-neutral-700 dark:text-white">
            {"Employee Master -> Employee Details View"}
          </div>
          <div>
            <div class={Maintable.mainContentScreen}>
              <form class={Maintable.formForClientMaster}>
                <div class={Maintable.labelBold}>Employee Details</div>
                <hr class={Maintable.horizontalLine}></hr>
                <div class={Maintable.gird3}>
                  <div>
                    <label for="employee_no" class={Maintable.label}>
                      Employee ID<span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      name="emp_EmployeeId"
                      value={data.Emp_EmployeeId}
                      readOnly
                      id="first_name"
                      class={Maintable.input}
                      className={Maintable.input}
                      placeholder="XXX0011"
                    />
                  </div>
                  <div>
                    <label for="employee_name" class={Maintable.label}>
                      Employee Name
                      <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      name="emp_EmployeeName"
                      value={data.Emp_EmployeeName}
                      maxLength={30}
                      readOnly={!editMode}
                      id="first_name"
                      className={Maintable.input}
                      placeholder="XXX0011"
                    />
                  </div>
                  <div>
                    <label for="father_name" class={Maintable.label}>
                      Father Name<span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      name="emp_FatherName"
                      value={data.Emp_FatherName}
                      id="last_name"
                      class={Maintable.input}
                      readOnly={!editMode}
                      className={Maintable.input}
                      placeholder="Mani"
                    />
                  </div>

                  <div>
                    <label for="Pan" class={Maintable.label}>
                      Date Of Birth
                      <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="Date"
                      name="emp_DateofBirth"
                      readOnly={!editMode}
                      value={data.Emp_DateofBirth}
                      id="company"
                      class={Maintable.input}
                      className={Maintable.input}
                      placeholder="129836637582652"
                    />
                  </div>
                  <div>
                    <label for="Pan" class={Maintable.label}>
                      Gender
                    </label>
                    <div class="flex items-center bg-gray-50   pl-4 border border-gray-300 rounded-lg dark:border-gray-500">
                      <input
                        id="bordered-radio-1"
                        name="emp_Gender"
                        value="Male"
                        type="radio"
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        for="bordered-radio-1"
                        class="w-full py-2.5 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Male
                      </label>
                      <input
                        checked
                        id="bordered-radio-2"
                        name="emp_Gender"
                        value="Female"
                        type="radio"
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        for="bordered-radio-2"
                        class="w-full py-2.5 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Female
                      </label>
                    </div>
                  </div>
                  <div>
                    <label for="blood_group" class={Maintable.label}>
                      Blood Group
                    </label>
                    <input
                      type="text"
                      name="emp_BloodGroup"
                      value={data.Emp_BloodGroup}
                      id="company"
                      class={Maintable.input}
                      placeholder="B+"
                    />
                  </div>

                  <div>
                    <label for="Pan" class={Maintable.label}>
                      Phone
                    </label>
                    <input
                      type="number"
                      name="emp_Phone"
                      value={data.Emp_Phone}
                      id="company"
                      class={Maintable.input}
                      placeholder="+91 6385636425"
                    />
                  </div>
                  <div>
                    <label for="Pan" class={Maintable.label}>
                      Mobile<span className="text-red-600">*</span>
                    </label>
                    <input
                      type="number"
                      readOnly={!editMode}
                      name="emp_Mobile"
                      value={data.Emp_Mobile}
                      maxLength={10}
                      id="company"
                      class={Maintable.input}
                      className={Maintable.input}
                      placeholder="+91 6385636425"
                    />
                  </div>
                  <div>
                    <label for="company" class={Maintable.label}>
                      Email<span className="text-red-600">*</span>
                    </label>
                    <input
                      type="email"
                      readOnly={!editMode}
                      name="emp_Email"
                      value={data.Emp_Email}
                      id="company"
                      class={Maintable.input}
                      className={Maintable.input}
                      placeholder="karthick@xlicon.in"
                    />
                  </div>

                  <div>
                    <label for="employee_name" class={Maintable.label}>
                      Aadhaar Number
                      <span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      readOnly={!editMode}
                      name="emp_AdharNo"
                      value={data.Emp_AadharNo}
                      id="first_name"
                      class={Maintable.input}
                      className={Maintable.input}
                      placeholder="XXX0011"
                    />
                  </div>
                  <div>
                    <label for="Pan" class={Maintable.label}>
                      PAN Number
                    </label>
                    <input
                      type="number"
                      name="emp_PANNumber"
                      value={data.Emp_PANNumber}
                      id="company"
                      class={Maintable.input}
                      placeholder="TCF525225"
                    />
                  </div>
                  <div>
                    <div class="flex items-center bg-gray-50 pl-4 border border-gray-300  mt-7 rounded-lg dark:border-gray-500">
                      <label for="Pan" class={Maintable.label_1}>
                        Physically Challenged
                      </label>
                      <input
                        id="bordered-checkbox-2"
                        name="emp_PhysicallyChallanged"
                        type="checkbox"
                        value={data.Emp_PhysicallyChallanged}
                        class="w-4 h-4 ml-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        for="bordered-checkbox-2"
                        class="py-2.5 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Yes
                      </label>
                    </div>
                  </div>
                  <div class="lg:col-span-2 sm:col-span-1">
                    <label class={Maintable.label} for="multiple_files">
                      Employee Photo
                    </label>
                    <input
                      name="emp_Image"
                      class={Maintable.fileUpload}
                      id="multiple_files"
                      type="file"
                      multiple
                    />

                    {/* // Upload button
                        // Uplaod button click function
                        // In inside function 
                        // file data : formdata in react
                        // Upload API : http://hrms.xlicon.com/HRMSAPI/api/XL_HRMS_API/Upload
                        // From the response, we will get Uplaoded ImageURL,  store it in setEmp_Image */}
                  </div>
                  <div className="flex">
                    {/* <div className="mt-7 mr-7">
                      <button
                        type="button"
                        class={Maintable.submitButton}
                      >
                        UPLOAD
                      </button>
                    </div> */}
                    <div>
                      <input
                        type="image"
                        src={data.Emp_Image}
                        alt="Image"
                        className="rounded-full w-20 h-20"
                      />
                    </div>
                  </div>
                </div>

                <div class={Maintable.labelBold}>Permanent Address</div>
                <hr class={Maintable.horizontalLine}></hr>
                <div class={Maintable.gird3}>
                  <div>
                    <label for="address1" class={Maintable.label}>
                      Address 1<span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      readOnly={!editMode}
                      name="emp_Permanent_Address1"
                      value={data.Emp_Permanent_Address1}
                      id="last_name"
                      class={Maintable.input}
                      className={Maintable.input}
                      placeholder="86,Ambedkar Nagar"
                    />
                  </div>
                  <div>
                    <label for="address_2" class={Maintable.label}>
                      Address 2
                    </label>
                    <input
                      type="text"
                      name="emp_Permanent_Address2"
                      value={data.Emp_Permanent_Address2}
                      id="company"
                      class={Maintable.input}
                      placeholder="Mosur"
                    />
                  </div>
                  <div>
                    <label for="address_3" class={Maintable.label}>
                      Address 3
                    </label>
                    <input
                      type="text"
                      name="emp_Permanent_Address3"
                      value={data.Emp_Permanent_Address3}
                      id="company"
                      class={Maintable.input}
                      placeholder="Arakkonam"
                    />
                  </div>
                  <div>
                    <label for="city" class={Maintable.label}>
                      City<span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      readOnly={!editMode}
                      name="emp_Permanent_City"
                      value={data.Emp_Permanent_City}
                      id="company"
                      class={Maintable.input}
                      className={Maintable.input}
                      placeholder="Arakkonam"
                    />
                  </div>
                  <div>
                    <label for="state" class={Maintable.label}>
                      State<span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      readOnly={!editMode}
                      name="emp_Permanent_State"
                      value={data.Emp_Permanent_State}
                      id="company"
                      class={Maintable.input}
                      className={Maintable.input}
                      placeholder="tamilnadu"
                    />
                  </div>
                  <div>
                    <label for="country" class={Maintable.label}>
                      Country<span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      readOnly={!editMode}
                      name="emp_Permanent_Country"
                      value={data.Emp_Permanent_Country}
                      id="company"
                      class={Maintable.input}
                      className={Maintable.input}
                      placeholder="India"
                    />
                  </div>
                  <div>
                    <label for="zipcode" class={Maintable.label}>
                      Zip Code
                    </label>
                    <input
                      type="text"
                      name="emp_Permanent_ZipCode"
                      value={data.Emp_Permanent_ZipCode}
                      id="company"
                      class={Maintable.input}
                      placeholder="6543002"
                    />
                  </div>
                </div>

                <div class={Maintable.labelBold}>Emergency Contact Info</div>
                <hr class={Maintable.horizontalLine}></hr>
                <div class={Maintable.gird3}>
                  <div>
                    <label for="company" class={Maintable.label}>
                      Contact Name
                    </label>
                    <input
                      type="text"
                      name="emp_EmemergencyContactName"
                      value={data.Emp_EmemergencyContactName}
                      id="name"
                      class={Maintable.input}
                      placeholder="Gokul"
                    />
                  </div>
                  <div>
                    <label for="company" class={Maintable.label}>
                      Contact Relationship
                    </label>
                    <input
                      type="text"
                      name="emp_EmemergencyContactRelationship"
                      value={data.Emp_EmemergencyContactRelationship}
                      id="name"
                      class={Maintable.input}
                      placeholder="Father"
                    />
                  </div>
                  <div>
                    <label for="company" class={Maintable.label}>
                      Contact Address
                    </label>
                    <input
                      type="text"
                      name="emp_EmemergencyContactAddress"
                      value={data.Emp_EmemergencyContactAddress}
                      id="name"
                      class={Maintable.input}
                      placeholder="20, Gandhi Street, Chennai-64"
                    />
                  </div>
                  <div>
                    <label for="company" class={Maintable.label}>
                      Contact Phone
                    </label>
                    <input
                      type="tel"
                      name="emp_EmemergencyContactPhone"
                      value={data.Emp_EmemergencyContactPhone}
                      id="phone"
                      class={Maintable.input}
                      placeholder="123-45-678"
                      pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                    />
                  </div>
                  <div>
                    <label for="company" class={Maintable.label}>
                      Contact Mobile
                    </label>
                    <input
                      type="tel"
                      name="emp_EmemergencyContact_Mobile"
                      value={data.Emp_EmemergencyContact_Mobile}
                      id="phone"
                      class={Maintable.input}
                      placeholder="+91 6385636425"
                      pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                    />
                  </div>
                  <div>
                    <label for="company" class={Maintable.label}>
                      Contact Email
                    </label>
                    <input
                      type="email"
                      name="emp_EmemergencyContactEmailId"
                      value={data.Emp_EmemergencyContactEmailId}
                      id="company"
                      class={Maintable.input}
                      placeholder="karthick@xlicon.in"
                    />
                  </div>
                </div>
                <div class={Maintable.labelBold}>Temporary Address</div>
                <hr class={Maintable.horizontalLine}></hr>
                <div class={Maintable.gird3}>
                  <div>
                    <label for="address1" class={Maintable.label}>
                      Address 1
                    </label>
                    <input
                      type="text"
                      name="emp_Temp_Address1"
                      value={data.Emp_Temp_Address1}
                      id="last_name"
                      class={Maintable.input}
                      placeholder="86,Ambedkar Nager"
                    />
                  </div>
                  <div>
                    <label for="address_2" class={Maintable.label}>
                      Address 2
                    </label>
                    <input
                      type="text"
                      name="emp_Temp_Address2"
                      value={data.Emp_Temp_Address2}
                      id="company"
                      class={Maintable.input}
                      placeholder="Mosur"
                    />
                  </div>
                  <div>
                    <label for="address_3" class={Maintable.label}>
                      Address 3
                    </label>
                    <input
                      type="text"
                      name="emp_Temp_Address3"
                      value={data.Emp_Temp_Address3}
                      id="company"
                      class={Maintable.input}
                      placeholder="Arakkonam"
                    />
                  </div>
                  <div>
                    <label for="city" class={Maintable.label}>
                      City
                    </label>
                    <input
                      type="text"
                      name="emp_Temp_City"
                      value={data.Emp_Temp_City}
                      id="company"
                      class={Maintable.input}
                      placeholder="Arakkonam"
                    />
                  </div>
                  <div>
                    <label for="state" class={Maintable.label}>
                      State
                    </label>
                    <input
                      type="text"
                      name="emp_Temp_State"
                      value={data.Emp_Temp_State}
                      id="company"
                      class={Maintable.input}
                      placeholder="tamilnadu"
                    />
                  </div>
                  <div>
                    <label for="country" class={Maintable.label}>
                      Country
                    </label>
                    <input
                      type="text"
                      name="emp_Temp_Country"
                      value={data.Emp_Temp_Country}
                      id="company"
                      class={Maintable.input}
                      placeholder="India"
                    />
                  </div>
                  <div>
                    <label for="zipcode" class={Maintable.label}>
                      Zip Code
                    </label>
                    <input
                      type="text"
                      name="emp_Temp_ZipCode"
                      value={data.Emp_Temp_ZipCode}
                      id="company"
                      class={Maintable.input}
                      placeholder="8743783"
                    />
                  </div>
                </div>
                <div class={Maintable.labelBold}>Other Details</div>
                <hr class={Maintable.horizontalLine}></hr>
                <div class={Maintable.gird3}>
                  <div>
                    <label for="date_0f_joining" class={Maintable.label}>
                      Date of Joining
                    </label>
                    <input
                      type="date"
                      name="emp_Dateofjoining"
                      value={data.Emp_Dateofjoining}
                      id="last_name"
                      class={Maintable.input}
                      className={Maintable.input}
                      placeholder="12-07-2022"
                    />
                  </div>
                  <div>
                    <label for="experience" class={Maintable.label}>
                      Experience
                    </label>
                    <input
                      type="text"
                      name="emp_Experience"
                      value={data.Emp_Experience}
                      id="exper"
                      class={Maintable.input}
                      placeholder="5 years"
                    />
                  </div>
                  <div>
                    <label for="designation" class={Maintable.label}>
                      Designation
                    </label>
                    <input
                      name="emp_Designation"
                      value={data.Emp_Designation}
                      type="text"
                      id="designation"
                      class={Maintable.input}
                      placeholder="Software Devloper"
                    />
                  </div>
                  <div>
                    <label for="account_number" class={Maintable.label}>
                      Account Number
                    </label>
                    <input
                      name="emp_AccountNumber"
                      value={data.Emp_AccountNumber}
                      type="number"
                      id="company"
                      class={Maintable.input}
                      placeholder="129836637582652"
                    />
                  </div>
                  <div>
                    <label for="Pan" class={Maintable.label}>
                      Bank Name
                    </label>
                    <input
                      name="emp_BankName"
                      value={data.Emp_BankName}
                      type="text"
                      id="company"
                      class={Maintable.input}
                      placeholder="HDFC Bank"
                    />
                  </div>
                  <div>
                    <label for="Pan" class={Maintable.label}>
                      IFSC Code
                    </label>
                    <input
                      name="emp_IFSCcode"
                      value={data.Emp_IFSCcode}
                      type="number"
                      id="company"
                      class={Maintable.input}
                      placeholder="HDFC56266"
                    />
                  </div>
                  <div>
                    <label for="Pan" class={Maintable.label}>
                      Date Of leaving
                    </label>
                    <input
                      type="Date"
                      name="emp_DateofLeaving"
                      value={data.Emp_DateofLeaving}
                      id="company"
                      class={Maintable.input}
                      placeholder="129836637582652"
                    />
                  </div>
                  <div class="lg:col-span-3 sm:col-span-1">
                    <label for="Skills" class={Maintable.label}>
                      Skills
                    </label>
                    <div class={Maintable.input_richtextbox}>
                      <Container>
                        <ReactQuill
                          style={{ height: "200px" }}
                          theme="snow"
                          modules={modules}
                          formats={formats}
                          id="message"
                          name="emp_skills"
                          value={data.Emp_Skills}
                        />
                      </Container>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeDetails;
