import React from "react";
import MainLayout from "../components/Mainlayout";
import { Common, Maintable } from "../css/Common";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";
import TableGrid from "../components/table";
import { customerMasterHeader } from "../components/headerAttribute";
import { useState } from "react";
import customer1 from "../image/OIP.jfif"
import newProcess from "../image/newProcessPlastic.png"
import industrial from "../image/download.png"
import liberty from "../image/liberty.png"
import plasteMast from "../image/Plastemart.png"
import IPG from "../image/IPG.png"
import Volza from "../image/VOLZA.jpg"
import Victorex from "../image/victorex logo.jpg"


const maincontent = {
  maincontent: "w-2/5 mx-auto",
};

const CustomerMaster = () => {
  const [visible, setVisible] = React.useState(false);
  const [visible2, setVisible2] = React.useState(true);

  const [bindUserMasterDatas, setBindUserMasterDatas] = useState([
    {
      customer_SNo:1,
     
      customer_Logo:( <img src={customer1} class="rounded-lg w-20 h-16" alt="customer Logo" />),
      customer_Code:"CUMR00001",
      customer_Name:"Plastic industry",
      customer_Mobile:"+21 9398292822",
      customer_Email:"plasticindustry@gmail.com"
    },
    {
      customer_SNo:2,
      
      customer_Logo:( <img src={newProcess} class="rounded-lg w-20 h-16" alt="customer Logo" />),
      customer_Code:"CUMR00002",
      customer_Name:"NEW PROCESS FIBRE COMPANY, INC.",
      customer_Mobile:"855-661-9275",
      customer_Email:"WRust@newprocess.com "
    },
    {
      customer_SNo:3,
      
      customer_Logo:( <img src={industrial} class="rounded-lg w-20 h-16" alt="customer Logo" />),
      customer_Code:"CUMR00003",
      customer_Name:"Industrial Plastic Supply, Inc.",
      customer_Mobile:"92806-6104",
      customer_Email:"industrial@plastic.com "
    },
    
    {
      customer_SNo:4,
      
      customer_Logo:( <img src={liberty} class="rounded-lg w-20 h-16" alt="customer Logo" />),
      customer_Code:"CUMR00004",
      customer_Name:"Liberty Plastics",
      customer_Mobile:"844-235-6945",
      customer_Email:"Liberty@plastic.com "
    },
    {
      customer_SNo:5,
      
      customer_Logo:( <img src={plasteMast} class="rounded-lg w-20 h-16" alt="customer Logo" />),
      customer_Code:"CUMR00005",
      customer_Name:"PlasteMast.com",
      customer_Mobile:"+91 9930304565",
      customer_Email:"contact@plastemart.com"
    },
    {
      customer_SNo:6,
      
      customer_Logo:( <img src={IPG} class="rounded-lg w-20 h-16" alt="customer Logo" />),
      customer_Code:"CUMR00006",
      customer_Name:"IPG",
      customer_Mobile:" 1-888-898-7834",
      customer_Email:"4IPGTEC@itape.com"
    },
    {
      customer_SNo:7,
      
      customer_Logo:( <img src={Volza} class="rounded-lg w-20 h-16" alt="customer Logo" />),
      customer_Code:"CUMR00007",
      customer_Name:"Volza Grow Global",
      customer_Mobile:"+1-302 786 5213",
      customer_Email:"sales@volza.com"
    },
    {
      customer_SNo:8,
      
      customer_Logo:( <img src={Victorex} class="rounded-lg w-20 h-16" alt="customer Logo" />),
      customer_Code:"CUMR00007",
      customer_Name:"victorex",
      customer_Mobile:"94468 13736",
      customer_Email:"arushitradings@gmail.com"
    },
  ]);

  const showadd = () => {
    setVisible(true);
    setVisible2(false);
  };

  const cancel = () => {
   setVisible2(true);
   setVisible(false);
  };

  const modules = {
    toolbar: [
      //[{ 'font': [] }],
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      [{ align: [] }, { color: [] }, { background: [] }],
      ["clean"],
    ],
  };

  const formats = [
    //'font',
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "color",
    "background",
  ];

  const Container = styled.div`
    height: 243px;
  `;


  return (
    <MainLayout>
      <div>
        <div class="flex">
          {visible2 && (
            <div class="relative w-full  mx-auto">
              <div class={Maintable.headerTitle}>
                <div class="my-2 ml-8">{`Admin -> Master -> Customer Master`}</div>
              </div>
              <div class="mx-8">
                <div className="md:justify-between md:flex  md:items-center">
                  <div class={Maintable.searchAdd}>
                    <div className="mt-1 gap-2 flex justify-between items-baseline">
                      <label for="pageSize" class="hidden md:block">
                        Rows Per Page:
                      </label>
                      <select
                        class={Maintable.dropDownRecords}
                        name="pageSize"
                        id="pageSize"
                      >
                        <option value="2">2</option>
                        <option value="10" selected>
                          5
                        </option>
                        <option value="10" >
                          10
                        </option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                    <div>
                      <label for="table-search" class="sr-only">
                        Search
                      </label>
                    </div>
                    <div class="relative mt-1">
                      <div class={Maintable.searchIconPosition}>
                        <svg
                          class={Maintable.searchIconSvg}
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <input
                        type="text"
                        id="table-search"
                        class={Maintable.searchInputBox}
                        placeholder="Search for items"
                      />
                    </div>
                    {/* <button class="relative px-5 py-0 overflow-hidden font-medium text-gray-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner group">
                      <span class="absolute top-0 left-0 w-0 h-0 transition-all duration-200 border-t-2 border-gray-600 group-hover:w-full ease"></span>
                      <span class="absolute bottom-0 right-0 w-0 h-0 transition-all duration-200 border-b-2 border-gray-600 group-hover:w-full ease"></span>
                      <span class="absolute top-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-gray-600 group-hover:h-full ease"></span>
                      <span class="absolute bottom-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-gray-600 group-hover:h-full ease"></span>
                      <span class="absolute inset-0 w-full h-full duration-300 delay-300 bg-gray-900 opacity-0 group-hover:opacity-100"></span>
                      <span class="relative transition-colors duration-300 delay-200 group-hover:text-white ease">
                        <svg
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          height="2em"
                          width="2em"
                          //class=" mt-1 bg-gray-800 inline-flex items-center sm:mx-3 justify-center  md:p-1 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                        >
                          <path d="M6 10.5a.5.5 0 01.5-.5h3a.5.5 0 010 1h-3a.5.5 0 01-.5-.5zm-2-3a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5zm-2-3a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5z" />
                        </svg>
                      </span>
                    </button> */}
                  </div>
                  <div className="flex self-end">
                    <div className="text-red-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner w-fit h-9 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">
                      <span className="mb-12 text-red-600 text-7xl">.</span>
                      <span>Block</span>
                    </div>
                    <button
                      type="button"
                      onClick={showadd}
                      class={Maintable.addButton}
                    >
                      <svg
                        width="25"
                        height="20"
                        viewBox="0 0 50 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22.5 37.5H27.5V27.5H37.5V22.5H27.5V12.5H22.5V22.5H12.5V27.5H22.5V37.5ZM25 50C21.5417 50 18.2917 49.3433 15.25 48.03C12.2083 46.7183 9.5625 44.9375 7.3125 42.6875C5.0625 40.4375 3.28167 37.7917 1.97 34.75C0.656667 31.7083 0 28.4583 0 25C0 21.5417 0.656667 18.2917 1.97 15.25C3.28167 12.2083 5.0625 9.5625 7.3125 7.3125C9.5625 5.0625 12.2083 3.28083 15.25 1.9675C18.2917 0.655833 21.5417 0 25 0C28.4583 0 31.7083 0.655833 34.75 1.9675C37.7917 3.28083 40.4375 5.0625 42.6875 7.3125C44.9375 9.5625 46.7183 12.2083 48.03 15.25C49.3433 18.2917 50 21.5417 50 25C50 28.4583 49.3433 31.7083 48.03 34.75C46.7183 37.7917 44.9375 40.4375 42.6875 42.6875C40.4375 44.9375 37.7917 46.7183 34.75 48.03C31.7083 49.3433 28.4583 50 25 50ZM25 45C30.5833 45 35.3125 43.0625 39.1875 39.1875C43.0625 35.3125 45 30.5833 45 25C45 19.4167 43.0625 14.6875 39.1875 10.8125C35.3125 6.9375 30.5833 5 25 5C19.4167 5 14.6875 6.9375 10.8125 10.8125C6.9375 14.6875 5 19.4167 5 25C5 30.5833 6.9375 35.3125 10.8125 39.1875C14.6875 43.0625 19.4167 45 25 45Z"
                          fill="white"
                        />
                      </svg>
                      ADD
                    </button>
                  </div>
                </div>
                <div class={Maintable.tableShadowWidth}>
                <TableGrid
            className={Maintable.Table}
            headerData={customerMasterHeader}
             tableData={bindUserMasterDatas}
            // onEditGrid={HandleEdit}
            // onDeleteGrid={HandleUserDetails}
            // onSortGrid={HandleSort}
            // onBlockGrid={HandleUserDetails}
            // onViewGrid={HandleUserDetails}
            // onAddressGrid={HandleAddress}
            // onFilterGrid={HandleFilter}
            // sortColumn={sortColumn}
            // sortOrder={sortOrder}
            // viewFilterGrid={viewFilter}
          />
                </div>
              </div>
            </div>
          )}
          {visible && (
            <div class=" flex flex-col w-full">
              <div class={Maintable.clientMasterMainAdd}>
                  <div class={Maintable.addScreenTitle}>
                  {`Admin -> Master -> Customer Master -> Add`}
                  </div>
                <div class={Maintable.addShadowScreen}>
                  <div class={Maintable.mainContentScreen}>
                    <form class={Maintable.formForClientMaster}>
                      <div class={Maintable.labelBold}>Customer Details</div>
                      <hr class={Maintable.horizontalLine}></hr>
                      <div class="grid gap-4 mb-6 md:grid-cols-3">
                        <div>
                          <label for="client_id" class={Maintable.label}>
                          Customer Code
                            <span className="text-red-600">*</span>
                          </label>
                          <input
                            type="number"
                            id="first_name"
                            class={Maintable.input}
                          />
                        </div>
                        <div>
                          <label for="client_name" class={Maintable.label}>
                          Customer Name
                            <span className="text-red-600">*</span>
                          </label>
                          <input
                            type="text"
                            id="Address1"
                            class={Maintable.input}
                          />
                        </div>
                        <div>
                          <label for="Address1" class={Maintable.label}>
                            Address<span className="text-red-600">*</span>
                          </label>
                          <input
                            type="text"
                            id="address1"
                            class={Maintable.input}
                          />                       
                        </div>
                        <div>
                          <label for="Address2" class={Maintable.label}>
                            Address 2
                          </label>
                          <input
                            type="text"
                            id="address2"                          
                            class={Maintable.input}                           
                          />
                        </div>
                        <div>
                          <label for="Address3" class={Maintable.label}>
                            Address 3
                          </label>
                          <input
                            type="text"
                            id="address"                          
                            class={Maintable.input}
                          />
                        </div>
                        <div>
                          <label for="city" class={Maintable.label}>
                            City
                            <span className="text-red-600">*</span>
                          </label>
                          <input
                            type="text"
                            id="city"
                            class={Maintable.input}
                          />
                        </div>
                        <div>
                          <label for="state" class={Maintable.label}>
                            State
                            <span className="text-red-600">*</span>
                          </label>
                          <input
                            type="text"
                            id="state"
                            class={Maintable.input}
                          />
                        </div>
                        <div>
                          <label for="Country" class={Maintable.label}>
                            Country
                            <span className="text-red-600">*</span>
                          </label>
                          <input
                            type="text"
                            id="company"                       
                            class={Maintable.input}
                          />
                        </div>
                        <div>
                          <label for="zipcode" class={Maintable.label}>
                            Zip/Pin Code
                          </label>
                          <input
                            type="number"
                            id="zipcode"
                            class={Maintable.input}
                          />
                        </div>
                        <div class="lg:col-span-2 sm:col-span-1">
                          <label class={Maintable.label} for="multiple_files">
                            Logo
                            <span className="text-red-600">*</span>
                          </label>
                          <input
                            name="cli_Logo"
                            class={Maintable.fileUpload}
                            id="multiple_files"
                            type="file"
                            alt="Image"
                          />
                        </div>
                        <div className="flex">
                          <div className="mt-7 mr-7">
                            <button
                              type="button"
                              class={Maintable.submitButton}
                            >
                              UPLOAD
                            </button>
                          </div>                         
                        </div>
                      </div>
                      <div class={Maintable.labelBold}>Contact Info</div>
                      <hr class={Maintable.horizontalLine}></hr>
                      <div class="grid gap-4 mb-6 md:grid-cols-3">
                        <div>
                          <label for="contactperson" class={Maintable.label}>
                            Contact Person
                            <span className="text-red-600">*</span>
                          </label>
                          <input
                            type="text"
                            id="contactperson"
                            class={Maintable.input}                         
                          />                         
                        </div>
                        <div>
                          <label for="designation" class={Maintable.label}>
                            Designation
                            <span className="text-red-600">*</span>
                          </label>
                          <input
                            type="text"
                            id="designation"                           
                            class={Maintable.input}                          
                          />
                        </div>
                        <div>
                          <label for="contactnumber" class={Maintable.label}>
                            Contact Number
                            <span className="text-red-600">*</span>
                          </label>
                          <input
                            type="number"
                            id="contactnumber"                          
                            class={Maintable.input}                          
                          />                         
                        </div>
                        <div>
                          <label for="company" class={Maintable.label}>
                            Email
                            <span className="text-red-600">*</span>
                          </label>
                          <input
                            type="email"
                            id="company"                                                
                            class={Maintable.input}                          
                          />                        
                        </div>
                        <div class="lg:col-span-3 sm:col-span-1">
                          <label for="Skills" class={Maintable.label}>
                            Description
                          </label>
                          <div class={Maintable.input_richtextbox}>
                            <Container>
                              <ReactQuill
                                style={{ height: "200px" }}
                                theme="snow"
                                modules={modules}
                                formats={formats}
                                id="message"
                                name="cli_Description"
                              />
                            </Container>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div class={Maintable.submitCancelMainDiv}>
                        <button
                          data-modal-hide="bottom-right-modal"
                          type="button"
                          class={Maintable.submitButton}
                        >
                          SUBMIT
                        </button>
                      <button
                        data-modal-hide="bottom-right-modal"
                        type="button"
                        onClick={cancel}
                        class={Maintable.cancelButton}
                      >
                        CANCEL
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default CustomerMaster;
