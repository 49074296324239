import React from 'react'
import MainLayout from '../components/Mainlayout'
import { Maintable } from '../css/Common'

const SystemSettings = () => {
  return (
    <MainLayout>
        <div class="container flex flex-col">
    <div class="mb-4">
        <p class={Maintable.headerTitle}>System Settings</p>
    </div>
    <form action="" class="formCss">
        <div class="Mainwrapper shadow-lg p-3 mb-5 bg-white rounded h-96">
            <div class="grid grid-cols-2 mx-32 gap-x-10 mt-4 sm:col-span-1">
                <div class="vhms_trans">
                    <label class={Maintable.label} for="vhmstrl">Domestic P.O. Starting Number</label>
                    <input class={Maintable.input} type="text" id="vhms_transactionrecordlimt"/>
                </div>
                
         
            <div class="row">
                <div class="vhms_trans">
                    <label class={Maintable.label} for="vhmsrrl">Import P.O. Starting Number</label>
                    <input class={Maintable.input} type="text" id="vhms_reportrecordlimit"/>
                </div>
            </div>
            <div class="row">
                <div class="vhms_trans">
                    <label class={Maintable.label} for="plmstrl">GRN Starting Number</label>
                    <input class={Maintable.input} type="text" id="plms_transactionrecordlimit"/>
                </div>
            </div>
            <div class="row">
                <div class="vhms_trans">
                    <label class={Maintable.label} for="plmsrrl">Proction Entry Starting Number</label>
                    <input class={Maintable.input} type="text" id="plms_reportrecordlimit"/>
                </div>
            </div>
            <div class="row">
                <div class="vhms_trans">
                    <label class={Maintable.label} for="dtc">Admin Email Id</label>
                    <input class={Maintable.input} type="text" id="dtc_transactionrecordlimit"/>
                </div>
            </div>
            <div class="row">
                <div class="vhms_trans">
                    <label class={Maintable.label} for="dtc">Password</label>
                    <input class={Maintable.input} type="text" id="dtc_reportrecordlimit"/>
                </div>
            </div>
            </div>
            <div class={Maintable.submitCancelMainDiv}>

                <button class={Maintable.submitButton} type="button"  onclick="IUDSystem()" id="btnsubmit">Submit</button>
                <button class={Maintable.cancelButton} type="button"  onclick="btncancel()" id="btnCt">Cancel</button>
            </div>

        </div>

    </form>
    </div>
</MainLayout>
   
  )
}

export default SystemSettings
