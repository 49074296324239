const UserHeaders = [
  {
    title: "S.No.",
    key: "User_AutoUserId",
    width: "md:w-12 2xl:w-12 px-2 py-3  ",
  },
  {
    title: "Photo",
    key: "User_Image",
    width: "md:w-24 2xl:flex-1 px-2 py-3 ",
  },
  {
    title: "First Name",
    key: "User_FirstName",
    width: "md:w-24 xl:w-44 2xl:w-32 px-2 py-3 ",
  },
  {
    title: "Last Name",
    key: "User_LastName",
    width: "md:w-24 2xl:w-32 px-2 py-3 ",
  },
  {
    title: "Email",
    key: "User_Email",
    width:
      "md:w-44 whitespace-normal break-words xl:w-52 2xl:w-52 2xl:px-0 px-2 py-3  ",
  },
  {
    title: "Phone Number",
    key: "User_PhoneNumber",
    width: "md:w-32 2xl:flex-1 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Designation",
    key: "User_Designation",
    width: "md:w-32 2xl:flex-1 px-2 py-3",
  },
  {
    title: "Role",
    key: "User_Role",
    width: "md:w-32 2xl:flex-1 px-2 py-3",
  },

  {
    title: "Action",
    key: "action",
    width: " 2xl:flex-1  px-2 py-3 ",
  },
];
const domesticPurchaseHeader = [
  {
    title: "S.No.",
    key: "Domestic_SNO",
    width: "md:w-12 2xl:w-12 2xl:pr-12 px-2 py-3",
    width1: "md:w-12 2xl:w-12 text-gray-900 px-2 py-3",
  },
  {
    title: "P.O. No.",
    key: "po_no",
    width: "md:w-28 2xl:w-44 2xl:px-0 px-2 py-3",
    width1: "md:w-24 2xl:w-32 text-gray-900 px-2 py-3",
  },
  {
    title: "P.O. Date",
    key: "po_date",
    width: "md:w-24 2xl:w-36 2xl:px-0 px-2 py-3",
    width1: "md:w-24 2xl:w-36 text-gray-900 px-2 py-3",
  },
  {
    title: "Supplier",
    key: "supplier",
    width: "md:w-64 xl:w-96 2xl:w-64 2xl:text-left 2xl:px-0 px-2 py-3",
    width1: "md:w-80 xl:w-96 2xl:w-96 2xl:align-left text-gray-900 px-2 py-3",
  },
  {
    title: "Expected Date",
    key: "expected_date",
    width: "md:w-28 2xl:flex-1 px-2 py-3  ",
    width1: "md:w-28 2xl:flex-1 text-gray-900 px-2 py-3  ",
  },
  {
    title: "P.O. Amount",
    key: "po_amount",
    width: "md:w-24 2xl:flex-1 px-2 py-3  ",
    width1: "md:w-24 2xl:flex-1 text-gray-900 px-2 py-3  ",
  },
  {
    title: "Item Details",
    key: "address",
    width: "md:w-28 2xl:flex-1 2xl:px-0 2xl:pl-16 px-2 py-3  ",
    width1: "md:w-24 2xl:text-center text-gray-900 px-2 py-3  ",
  },
  {
    title: "Action",
    key: "action",
    width: "2xl:text-center 2xl:pl-4 px-2 py-3 ",
  },
];

const rmHeaders = [
  {
    title: "S.No",
    key: "User_AutoUserId",
    width: "md:w-12 2xl:flex-1 px-2 py-3  ",
    width1: "md:w-12 2xl:flex-1 text-gray-900 px-2 py-3  ",
  },
  {
    title: "Raw Material",
    key: "User_Image",
    width: "md:w-28 2xl:flex-1 px-2 py-3 ",
    width1: "md:w-28 2xl:flex-1 text-gray-900 px-2 py-3 ",
  },
  {
    title: "Category",
    key: "User_FirstName",
    width: "md:w-44 2xl:flex-1 px-2 py-3 ",
    width1: "md:w-44 text-red-600 2xl:flex-1 px-2 py-3  ",
  },
  {
    title: "Description",
    key: "User_LastName",
    width: "md:w-44 2xl:flex-1 px-2 py-3 ",
    width1: "md:w-44 text-red-600 2xl:flex-1 px-2 py-3  ",
  },
  {
    title: "Unit of Measure",
    key: "User_Email",
    width: "md:w-44 whitespace-normal break-words 2xl:flex-1 px-2 py-3  ",
    width1:
      "md:w-44 whitespace-normal break-words text-red-600 2xl:flex-1 px-2 py-3  ",
  },
  {
    title: "Opening Quantity",
    key: "User_PhoneNumber",
    width: "md:w-32 2xl:flex-1 px-2 py-3 ",
    width1: "md:w-32 text-red-600 2xl:flex-1 px-2 py-3  ",
  },
  {
    title: "Opening Date",
    key: "User_Designation",
    width: "md:w-40 2xl:flex-1 px-2 py-3",
    width1: "md:w-40 text-red-600 2xl:flex-1 px-2 py-3  ",
  },

  {
    title: "Action",
    key: "action",
    width: "md:w-5 2xl:flex-1  px-2 py-3 ",
  },
];

const fgHeaders = [
  {
    title: "S.No",
    key: "fg_Sno",
    width: "md:w-12 2xl:w-12 px-2 py-3  ",
    width1: "md:w-12 2xl:flex-1 text-gray-900 px-2 py-3  ",
  },
  {
    title: "Item code",
    key: "fg_Code",
    width: "md:w-24 2xl:flex-1 px-2 py-3  ",
  },
  {
    title: "Finished Goods",
    key: "fg_name",
    width: "md:w-40 2xl:w-52 px-2 py-3 ",
  },
  {
    title: "Category",
    key: "fg_category",
    width: "md:w-40 2xl:w-56 px-2 py-3 ",
  },
  {
    title: "Description",
    key: "fg_Description",
    width: "md:w-48 xl:w-64 truncate 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "U.O.M.",
    key: "fg_UOM",
    width: "md:w-12  px-2 py-3  ",
  },
  {
    title: "Opening Qty.",
    key: "fg_Quentity",
    width: "md:w-24 2xl:text-right 2xl:pr-4 px-2 py-3 ",
    width1: "md:w-32 text-red-600 2xl:flex-1 px-2 py-3  ",
  },
  {
    title: "Opening Date",
    key: "fg_OpenDate",
    width: "md:w-28 2xl:flex-1 px-2 py-3",
  },

  {
    title: "Action",
    key: "action",
    width: "2xl:flex-1  px-2 py-3 ",
  },
];

const customerMasterHeader = [
  {
    title: "S.No",
    key: "sno",
    width: "md:w-12 2xl:w-12 px-2 py-3 ",
  },
  {
    title: "Logo",
    key: "customer_Logo",
    width: "md:w-32 2xl:text-center px-2 py-3 ",
  },
  {
    title: "Customer Code",
    key: "customer_Code",
    width: "md:w-32 2xl:w-36 px-2 py-3 ",
  },
  {
    title: "CustomerName",
    key: "customer_Name",
    width: "md:w-32 xl:w-56 2xl:w-72 px-2 py-3 ",
  },
  {
    title: "Mobile",
    key: "customer_Mobile",
    width: "md:w-32 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Email",
    key: "customer_Email",
    width: "md:w-48  whitespace-normal break-words 2xl:w-56 px-2 py-3 ",
  },
  {
    title: "Address",
    key: "address",
    width: "md:w-32 2xl:flex-1 px-2 py-3 ",
  },
  // {
  //   title: "Designation",
  //   key: "User_Designation",
  //   width: "md:w-32 2xl:flex-1 px-2 py-3 ",
  // },
  {
    title: "Action",
    key: "action",
    width: "2xl:flex-1 px-2 py-3 ",
  },
];

const supplierMasterHeader = [
  {
    title: "S.No",
    key: "supplier_S_no",
    width: "md:w-12 2xl:w-12 px-2 py-3 ",
  },
  {
    title: "Logo",
    key: "supplier_Logo",
    width: "md:w-28 2xl:text-center px-2 py-3 ",
  },
  {
    title: "Supplier Code",
    key: "supplier_Code",
    width: "md:w-32 2xl:w-36 px-2 py-3 ",
  },
  {
    title: "Supplier Name",
    key: "supplier_Name",
    width: "md:w-32 2xl:w-52 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Mobile",
    key: "supplier_Mobile",
    width: "md:w-32 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Email",
    key: "supplier_Email",
    width: "md:w-40 whitespace-normal break-words 2xl:w-64 2xl:px-0 px-2 py-3 ",
  },

  {
    title: "Designation",
    key: "supplier_Designation",
    width: "md:w-32 2xl:w-32 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Address",
    key: "supplier_Address",
    width: "md:w-26 xl:w-32 2xl:flex-1 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Action",
    key: "action",
    width: "2xl:flex-1 px-2 py-3 ",
  },
];

const roleHeader = [
  {
    title: "S.No",
    key: "role_sno",
    width: "md:w-32 2xl:flex-1 px-2 py-3 ",
  },
  {
    title: "Role",
    key: "role_name",
    width: "md:w-44  2xl:flex-1 px-2 py-3 ",
  },
  {
    title: "Description",
    key: "role_Description",
    width: "md:flex-1 2xl:flex-1 px-2 py-3 ",
  },
  {
    title: "Action",
    key: "action",
    width: "md:w-32 2xl:flex-1 px-2 py-3 ",
  },
];

const purchaseAddHeader = [
  {
    title: "S. No",
    key: "Purchase_SNo",
    width: "md:w-32 2xl:flex-1 px-2 py-3 ",
    inputBox:
      "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white",
  },
  {
    title: "Item Code",
    key: "Purchase_ItemCode",
    width: "md:w-32 2xl:flex-1 px-2 py-3 ",
    inputBox:
      "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white",
  },
  {
    title: "Item Description",
    key: "Purchase_ItemDescription",
    width: "md:w-32 2xl:flex-1 px-2 py-3 ",
    inputBox:
      "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white",
  },
  {
    title: "Quantity",
    key: "Purchase_Quantity",
    width: "md:w-32 2xl:flex-1 px-2 py-3 ",
    inputBox:
      "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white",
  },
  {
    title: "Rate",
    key: "Purchase_Rate",
    width: "md:w-32 2xl:flex-1 px-2 py-3 ",
    inputBox:
      "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white",
  },
  {
    title: "Amount",
    key: "Purchase_Amount",
    width: "md:w-32 2xl:flex-1 px-2 py-3 ",
    inputBox:
      "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white",
  },
  {
    title: "Add Record",
    key: "Purchase_Action",
    width: "md:w-32 2xl:flex-1 px-2 py-3 ",
    inputBox:
      "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white",
  },
];
const POHeader = [
  {
    title: "S.No",
    key: "sno",
    width: "md:w-16 2xl:w-12 px-2 py-3 ",
  },
  {
    title: "GRN. No",
    key: "GRNNo",
    width: "md:w-28 xl:w-32 2xl:w-52 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "GRN. Date",
    key: "GRNDate",
    width: "md:w-32  2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Supplier",
    key: "Supplier",
    width: "md:w-56 2xl:w-56 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "P.O.Number",
    key: "PONumber",
    width: "md:w-32 2xl:w-52 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "P.O. Date",
    key: "PODate",
    width: "md:w-28 2xl:flex-1 2xl:px-0 px-2 py-3 ",
  },
  // {
  //   title: "P.O. Amount",
  //   key: "POAmount",
  //   width: "md:w-24 2xl:flex-1 px-2 py-3 ",
  // },
  {
    title: "GRN. Details",
    key: "grn",
    width: "md:w-24 xl:w-28 2xl:flex-1 px-2 py-3 ",
  },
  {
    title: "Action",
    key: "action",
    width: "2xl:flex-1 2xl:text-center px-2 py-3 ",
  },
];
const GRNHeader = [
  {
    title: "S.No",
    key: "sno",
    width: "md:w-20 2xl:w-12 px-2 py-3 ",
  },
  {
    title: "P.O. No",
    key: "PurcheseOrder",
    width: "md:w-32 2xl:w-52 px-2 py-3 ",
  },
  {
    title: "P.O. Date",
    key: "PurcheseOrderDate",
    width: "md:w-32 2xl:flex-1 px-2 py-3 ",
  },
  {
    title: "Supplier",
    key: "Supplier",
    width: "md:w-56 2xl:w-60 2xl:px-2 px-2 py-3 ",
  },
  {
    title: "Expected Date",
    key: "ExpectedDate",
    width: "md:w-36 2xl:flex-1 px-2 py-3 ",
  },
  // {
  //   title: "P.O. Amount",
  //   key: "PurcheseOrderAmt",
  //   width: "md:w-40 2xl:flex-1 px-2 py-3 ",
  // },
  {
    title: "P.O. Details",
    key: "address",
    width: "md:w-24 xl:w-32 2xl:flex-1 px-2 py-3 ",
  },
  {
    title: "Action",
    key: "makegrn",
    width: "2xl:flex-1 px-2 py-3 ",
  },
];
const POdata = [
  {
    sno: 1,
    GRNNo: "GRN232400001",
    GRNDate: "15-06-23",
    Supplier: "Plasco Engineering Inc.",
    PONumber: "DPO232400001",
    PODate: "25-06-23",
    POAmount: "1,00,000",
  },
  {
    sno: 2,
    GRNNo: "GRN232400002",
    GRNDate: "16-06-23",
    Supplier: "Ye I Machinery Factory Co. Ltd.",
    PONumber: "DPO232400002",
    PODate: "26-06-23",
    POAmount: "10,00,000",
  },
  {
    sno: 3,
    GRNNo: "GRN232400003",
    GRNDate: "17-06-23",
    Supplier: "Windsor Machines Ltd",
    PONumber: "DPO232400003",
    PODate: "27-06-23",
    POAmount: "5,00,000",
  },
  {
    sno: 4,
    GRNNo: "GRN232400004",
    GRNDate: "18-06-23 ",
    Supplier: "Polystar Machinery Co. Ltd.",
    PONumber: "IPO232400001",
    PODate: "28-06-23",
    POAmount: "2,00,000",
  },
];
const dummyData = [
  {
    sno: 1,
    PurcheseOrder: "DPO232400001",
    PurcheseOrderDate: "15-06-23",
    Supplier: "Plasco Engineering Inc.",
    ExpectedDate: "25-06-23",
    PurcheseOrderAmt: "1,00,000",
  },
  {
    sno: 2,
    PurcheseOrder: "DPO232400002",
    PurcheseOrderDate: "16-06-23",
    Supplier: "Ye I Machinery Factory Co. Ltd.",
    ExpectedDate: "26-06-23",
    PurcheseOrderAmt: "10,00,000",
  },
  {
    sno: 3,
    PurcheseOrder: "DPO232400003",
    PurcheseOrderDate: "17-06-23",
    Supplier: "Windsor Machines Ltd",
    ExpectedDate: "27-06-23",
    PurcheseOrderAmt: "5,00,000",
  },
  {
    sno: 4,
    PurcheseOrder: "IPO232400001",
    PurcheseOrderDate: "18-06-23 ",
    Supplier: "Polystar Machinery Co. Ltd.",
    ExpectedDate: "28-06-23",
    PurcheseOrderAmt: "2,00,000",
  },
];
//export { UserHeaders, rmHeaders,fgHeaders, customerMasterHeader, roleHeader,domesticPurchaseHeader, purchaseAddHeader,GRNHeader,dummyData,POHeader,POdata};

const unitOfMeasureHeader = [
  {
    title: "S.No",
    key: "unit_SNO",
    width: "md:w-12 2xl:w-16 px-2 py-3  ",
    width1: "md:w-12 2xl:flex-1 text-gray-900 px-2 py-3  ",
  },
  {
    title: "Unit Name",
    key: "unit_Name",
    width: "md:w-28 2xl:w-48 px-2 py-3 ",
    width1: "md:w-20 2xl:flex-1 text-gray-900 px-2 py-3 ",
  },
  {
    title: "Symbol",
    key: "unit_symbol",
    width: "md:w-28  px-2 py-3 ",
    width1: "md:w-44 text-red-600 2xl:flex-1 px-2 py-3  ",
  },
  {
    title: "Description",
    key: "unit_Description",
    width: "md:w-72 xl:w-96 2xl:w-56 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Category",
    key: "unit_Category",
    width: "md:w-44 2xl:flex-1 px-2 py-3  ",
    width1: "md:w-44 text-red-600 2xl:flex-1 px-2 py-3  ",
  },
  {
    title: "Conversion Factor",
    key: "unit_ConversionFactor",
    width: "md:w-44 2xl:flex-1 px-2 py-3 ",
  },
  {
    title: "Action",
    key: "action",
    width: " 2xl:flex-1  px-2 py-3 ",
  },
];

const RawMaterialMSTHeader = [
  {
    title: "S.No",
    key: "Raw_MaterialSNO",
    width: "md:w-12 2xl:w-12 px-2 py-3  ",
    width1: "md:w-12 2xl:w-12 text-gray-900 px-2 py-3  ",
  },
  {
    title: "R.M. Code",
    key: "Raw_Material_Code",
    width: "md:w-28 2xl:w-40 2xl:px-0 px-2 py-3 ",
    width1: "md:w-28 2xl:flex-1 text-gray-900 px-2 py-3 ",
  },
  {
    title: "Raw Material",
    key: "Raw_Material",
    width: "md:w-28 2xl:w-52 px-2 py-3 ",
    width1: "md:w-28 2xl:flex-1 text-gray-900 px-2 py-3 ",
  },
  {
    title: "Category",
    key: "Raw_Category",
    width: "md:w-24 2xl:flex-1 px-2 py-3 ",
    width1: "md:w-24 text-red-600 2xl:flex-1 px-2 py-3  ",
  },
  {
    title: "Description",
    key: "Raw_Description",
    width: "md:w-56 2xl:w-44 truncate  px-2 py-3 ",
    width1: "md:w-56 text-red-600 truncate group-hover:block group-hover:w-32 group-hover:bg-gray-400 2xl:w-12 px-2 py-3  ",
  },
  {
    title: "U.O.M.",
    key: "UOM_Raw",
    width: "md:w-20 xl:w-24  px-2 py-3  ",
  },
  {
    title: "Opening Quantity",
    key: "Raw_Material_Quentity",
    width: "md:w-32  px-2 py-3 ",
    width1: "md:w-32 text-red-600 2xl:flex-1 px-2 py-3  ",
  },
  {
    title: "Opening Date",
    key: "Raw_Meterial_Opendate",
    width: "md:w-32 xl:w-40 2xl:flex-1 px-2 py-3",
  },

  {
    title: "Action",
    key: "action",
    width: " 2xl:flex-1  px-2 py-3 ",
  },
];

const MachineHeader = [
  {
    title: "S.No",
    key: "Machine_Sno",
    width: "md:w-12 2xl:flex-1 px-2 py-3  ",
    width1: "md:w-12 2xl:flex-1 text-gray-900 px-2 py-3  ",
  },
  {
    title: "Machine code",
    key: "Machine_Code",
    width: "md:w-28 2xl:flex-1 px-2 py-3  ",
    width1: "md:w-20 2xl:flex-1 text-gray-900 px-2 py-3  ",
  },
  {
    title: "Machine",
    key: "Machine_name",
    width: "md:w-44 2xl:flex-1 px-2 py-3 ",
    width1: "md:w-44 2xl:flex-1 text-gray-900 px-2 py-3 ",
  },
  {
    title: "Category",
    key: "Machine_category",
    width: "md:w-36 xl:w-40 2xl:flex-1 px-2 py-3 ",
  },
  {
    title: "Capacity",
    key: "Machine_Capacity",
    width: "md:w-20 2xl:flex-1 px-2 py-3 ",
  },
  {
    title: "U.O.M.",
    key: "Machine_UOM",
    width: "md:w-14 2xl:flex-1 px-2 py-3 ",
    width1: "md:w-12 text-red-600 2xl:flex-1 px-2 py-3  ",
  },
  {
    title: "Purchase Date",
    key: "Machine_DataofPurchase",
    width: "md:w-26 2xl:flex-1 px-2 py-3  ",
    width1: "md:w-24 text-red-600 2xl:flex-1 px-2 py-3  ",
  },
  {
    title: "Description",
    key: "Machine_Description",
    width: "md:w-52 xl:w-72 2xl:flex-1 px-2 py-3 ",
    width1: "md:w-72 text-red-600 2xl:flex-1 px-2 py-3  ",
  },
  {
    title: "Action",
    key: "action",
    width: "md:w-5 2xl:flex-1  px-2 py-3 ",
  },
];
const TODHeader = [
  {
    title: "S.No",
    key: "sno",
    width: "md:w-12 2xl:w-12 px-2 py-3 ",
  },
  {
    title: "Transfer No.",
    key: "TransferNo",
    width: "md:w-28 2xl:w-44 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Date",
    key: "Date",
    width: "md:w-28 2xl:flex-1 px-2 py-3 ",
  },
  {
    title: "Item Type",
    key: "MeterialType",
    width: "md:w-32 2xl:w-36 px-2 py-3 ",
  },
  {
    title: "Item Code",
    key: "MeterialCode",
    width: "md:w-40 2xl:w-40 px-2 py-3 ",
  },
  {
    title: "Qty.",
    key: "Qty",
    width: "md:w-16  px-2 py-3 ",
  },
  {
    title: "U.O.M.",
    key: "UOM",
    width: "md:w-16  px-2 py-3 ",
  },
  {
    title: "From Department",
    key: "FromDepartment",
    width: "md:w-32 2xl:w-40 px-2 py-3 ",
  },
  {
    title: "To Department",
    key: "TODepartment",
    width: "md:w-28 xl:w-40 2xl:flex-1 px-2 py-3 ",
  },
  {
    title: "Action",
    key: "action",
    width: "2xl:flex-1 2xl:text-center px-2 py-3 ",
  },
];
const TODData = [
  {
    sno: 1,
    TransferNo: "TOD232400001",
    Date: "25-06-23",
    MeterialType: "Raw Material",
    MeterialCode: "RMLD00001",
    Qty: "2000",
    UOM: "SACKS",
    FromDepartment: "R.M. Store",
    TODepartment: "Lamination",
  },
  {
    TransferNo: "TOD232400002",
    Date: "26-06-23",
    MeterialType: "Finished Good",
    MeterialCode: "RMSL00001",
    Qty: "9000",
    UOM: "KG",
    FromDepartment: "F.G. Store",
    TODepartment: "ReProduce",
  },
  {
    TransferNo: "TOD232400003",
    Date: "27-06-23",
    MeterialType: "Raw Material",
    MeterialCode: "RMIN00001",
    Qty: "110",
    UOM: "MTRS",
    FromDepartment: "R.M. Store",
    TODepartment: "Injection Moulding",
  },
  {
    TransferNo: "TOD232400004",
    Date: "28-06-23",
    MeterialType: "Finished Good",
    MeterialCode: "RMAD00001",
    Qty: "8000",
    UOM: "pcs",
    FromDepartment: "F.G. Store",
    TODepartment: "Printing",
  },
  {
    TransferNo: "TOD232400005",
    Date: "29-06-23",
    MeterialType: "Raw Material",
    MeterialCode: "RMSD00001",
    Qty: "6000",
    UOM: "meters",
    FromDepartment: "R.M. Store",
    TODepartment: "Slitting",
  },
];
const IssueToProdHeader = [
  {
    title: "S.No",
    key: "sno",
    width: "md:w-12  2xl:w-12 px-2 py-3 ",
  },
  {
    title: "Issue Date",
    key: "issuedate",
    width: "md:w-24 2xl:w-20 px-2 py-3 ",
  },
  {
    title: "Issue Slip No.",
    key: "issueno",
    width: "md:w-32 2xl:w-40 px-2 py-3 ",
  },
  {
    title: "Item Name",
    key: "material",
    width: "md:w-48 xl:w-56 2xl:w-56 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "U.O.M.",
    key: "uom",
    width: "md:w-16  px-2 py-3 ",
  },
  {
    title: "Quantity",
    key: "quantity",
    width: "md:w-20  px-2 py-3 ",
  },
  {
    title: "Issued Department",
    key: "department",
    width: "md:w-40 2xl:w-44 px-2 py-3 ",
  },
  {
    title: "Machine",
    key: "machine",
    width: "md:w-48 2xl:w-72 px-2 py-3 ",
  },
  {
    title: "Action",
    key: "action",
    width: "2xl:flex-1 px-2 py-3 ",
  },
];

const DepartmentHeader = [
  {
    title: "S.No",
    key: "department_sno",
    width: "md:w-12 2xl:flex-1 px-2 py-3  ",
    width1: "md:w-12 2xl:flex-1 text-gray-900 px-2 py-3  ",
  },
  {
    title: "Department Code",
    key: "department_code",
    width: "md:w-36 2xl:flex-1 px-2 py-3  ",
  },
  {
    title: "Department",
    key: "department_Name",
    width: "md:w-40 xl:w-56 2xl:flex-1 px-2 py-3 ",
  },
  {
    title: "Opening Date",
    key: "department_OpeningDate",
    width: "md:w-44 2xl:flex-1 px-2 py-3 ",
    width1: "md:w-20 2xl:flex-1 text-gray-900 px-2 py-3 ",
  },
  {
    title: "Description",
    key: "Department_Description",
    width: "md:w-96 2xl:flex-1 px-2 py-3 ",
    width1: "md:w-32 text-red-600 2xl:flex-1 px-2 py-3  ",
  },
  {
    title: "Action",
    key: "action",
    width: "2xl:flex-1  px-2 py-3 ",
  },
];
const PlainshrinkflimHeader = [
  {
    title: "S.No",
    key: "sno",
    width: "md:w-12 2xl:w-12 px-2 py-3 ",
  },
  {
    title: "P.S. Number",
    key: "PSNumber",
    width: "md:w-36 2xl:w-40 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Date",
    key: "Date",
    width: "md:w-28 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Machine Name",
    key: "MachineName",
    width: "md:w-56 2xl:w-72 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Machine Capacity",
    key: "MachineCapacity",
    width: "md:w-40  px-2 py-3 ",
  },
  {
    title: "Operator",
    key: "Operator",
    width: "md:w-32 2xl:w-44 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Supervisor",
    key: "Supervisor",
    width: "md:w-28 xl:w-32 2xl:w-44 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Action",
    key: "action",
    width: "2xl:flex-1 px-2 py-3 ",
  },
];
const PlainshrinkflimData = [
  {
    PSNumber: "PSN232400001",
    Date: "25-06-23",
    MachineName: "LD SHRINK Film Plant",
    MachineCapacity: "600 - 700",
    Operator: "Ababio",
    Supervisor: "Agymah",
  },
  {
    PSNumber: "PSN232400002",
    Date: "26-06-23",
    MachineName: "PVC Soft film plant",
    MachineCapacity: "50 - 100",
    Operator: "Coblah",
    Supervisor: "Botwe",
  },
  {
    PSNumber: "PSN232400003",
    Date: "27-06-23",
    MachineName: "SterFlex 2000",
    MachineCapacity: "100 - 300",
    Operator: "Dodzi",
    Supervisor: "Danquah",
  },
  {
    PSNumber: "PSN232400004",
    Date: "28-06-23",
    MachineName: "Pallet shrink wrap-Labelong",
    MachineCapacity: "800 - 900",
    Operator: "Kaatachi",
    Supervisor: "Elinam",
  },
  {
    PSNumber: "PSN232400005",
    Date: "29-06-23",
    MachineName: "NPC280H",
    MachineCapacity: "200 - 400",
    Operator: "Majid",
    Supervisor: "Kojo",
  },
];
const PrintedshrinkflimHeader = [
  {
    title: "S.No",
    key: "sno",
    width: "md:w-12 2xl:w-12 px-2 py-3 ",
  },
  {
    title: "P.S. Number",
    key: "PSNumber",
    width: "md:w-36 2xl:w-40 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Date",
    key: "Date",
    width: "md:w-28 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Machine Name",
    key: "MachineName",
    width: "md:w-56 2xl:w-72 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Machine Capacity",
    key: "MachineCapacity",
    width: "md:w-40  px-2 py-3 ",
  },
  {
    title: "Operator",
    key: "Operator",
    width: "md:w-32 2xl:w-44 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Supervisor",
    key: "Supervisor",
    width: "md:w-28 xl:w-32 2xl:w-44 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Action",
    key: "action",
    width: "2xl:flex-1 px-2 py-3 ",
  },
];
const PrintedshrinkflimData = [
  {
    PSNumber: "PSN232400001",
    Date: "25-06-23",
    MachineName: "LD SHRINK Film Plant",
    MachineCapacity: "600 - 700",
    Operator: "Ababio",
    Supervisor: "Agymah",
  },
  {
    PSNumber: "PSN232400002",
    Date: "26-06-23",
    MachineName: "PVC Soft film plant",
    MachineCapacity: "50 - 100",
    Operator: "Coblah",
    Supervisor: "Botwe",
  },
  {
    PSNumber: "PSN232400003",
    Date: "27-06-23",
    MachineName: "SterFlex 2000",
    MachineCapacity: "100 - 300",
    Operator: "Dodzi",
    Supervisor: "Danquah",
  },
  {
    PSNumber: "PSN232400004",
    Date: "28-06-23",
    MachineName: "Pallet shrink wrap-Labelong",
    MachineCapacity: "800 - 900",
    Operator: "Kaatachi",
    Supervisor: "Elinam",
  },
  {
    PSNumber: "PSN232400005",
    Date: "29-06-23",
    MachineName: "NPC280H",
    MachineCapacity: "200 - 400",
    Operator: "Majid",
    Supervisor: "Kojo",
  },
];
const PrintedshrinkflimlaminationHeader = [
  {
    title: "S.No",
    key: "sno",
    width: "md:w-12 2xl:w-12 px-2 py-3 ",
  },
  {
    title: "P.S. Number",
    key: "PSNumber",
    width: "md:w-36 2xl:w-40 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Date",
    key: "Date",
    width: "md:w-28 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Machine Name",
    key: "MachineName",
    width: "md:w-56 2xl:w-72 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Machine Capacity",
    key: "MachineCapacity",
    width: "md:w-40  px-2 py-3 ",
  },
  {
    title: "Operator",
    key: "Operator",
    width: "md:w-32 2xl:w-44 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Supervisor",
    key: "Supervisor",
    width: "md:w-28 xl:w-32 2xl:w-44 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Action",
    key: "action",
    width: "2xl:flex-1 px-2 py-3 ",
  },
];
const PrintedshrinkflimlaminationData = [
  {
    PSNumber: "PSN232400001",
    Date: "25-06-23",
    MachineName: "LD SHRINK Film Plant",
    MachineCapacity: "600 - 700",
    Operator: "Ababio",
    Supervisor: "Agymah",
  },
  {
    PSNumber: "PSN232400002",
    Date: "26-06-23",
    MachineName: "PVC Soft film plant",
    MachineCapacity: "50 - 100",
    Operator: "Coblah",
    Supervisor: "Botwe",
  },
  {
    PSNumber: "PSN232400003",
    Date: "27-06-23",
    MachineName: "SterFlex 2000",
    MachineCapacity: "100 - 300",
    Operator: "Dodzi",
    Supervisor: "Danquah",
  },
  {
    PSNumber: "PSN232400004",
    Date: "28-06-23",
    MachineName: "Pallet shrink wrap-Labelong",
    MachineCapacity: "800 - 900",
    Operator: "Kaatachi",
    Supervisor: "Elinam",
  },
  {
    PSNumber: "PSN232400005",
    Date: "29-06-23",
    MachineName: "NPC280H",
    MachineCapacity: "200 - 400",
    Operator: "Majid",
    Supervisor: "Kojo",
  },
];
const PrintedshrinkflimslittingHeader = [
  {
    title: "S.No",
    key: "sno",
    width: "md:w-12 2xl:w-12 px-2 py-3 ",
  },
  {
    title: "P.S. Number",
    key: "PSNumber",
    width: "md:w-36 2xl:w-40 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Date",
    key: "Date",
    width: "md:w-28 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Machine Name",
    key: "MachineName",
    width: "md:w-56 2xl:w-72 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Machine Capacity",
    key: "MachineCapacity",
    width: "md:w-40  px-2 py-3 ",
  },
  {
    title: "Operator",
    key: "Operator",
    width: "md:w-32 2xl:w-44 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Supervisor",
    key: "Supervisor",
    width: "md:w-28 xl:w-32 2xl:w-44 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Action",
    key: "action",
    width: "2xl:flex-1 px-2 py-3 ",
  },
];
const PrintedshrinkflimslittingData = [
  {
    PSNumber: "PSN232400001",
    Date: "25-06-23",
    MachineName: "LD SHRINK Film Plant",
    MachineCapacity: "600 - 700",
    Operator: "Ababio",
    Supervisor: "Agymah",
  },
  {
    PSNumber: "PSN232400002",
    Date: "26-06-23",
    MachineName: "PVC Soft film plant",
    MachineCapacity: "50 - 100",
    Operator: "Coblah",
    Supervisor: "Botwe",
  },
  {
    PSNumber: "PSN232400003",
    Date: "27-06-23",
    MachineName: "SterFlex 2000",
    MachineCapacity: "100 - 300",
    Operator: "Dodzi",
    Supervisor: "Danquah",
  },
  {
    PSNumber: "PSN232400004",
    Date: "28-06-23",
    MachineName: "Pallet shrink wrap-Labelong",
    MachineCapacity: "800 - 900",
    Operator: "Kaatachi",
    Supervisor: "Elinam",
  },
  {
    PSNumber: "PSN232400005",
    Date: "29-06-23",
    MachineName: "NPC280H",
    MachineCapacity: "200 - 400",
    Operator: "Majid",
    Supervisor: "Kojo",
  },
];
const InjectionmouldingHeader = [
  {
    title: "S.No",
    key: "sno",
    width: "md:w-12 2xl:w-12 px-2 py-3 ",
  },
  {
    title: "P.S. Number",
    key: "PSNumber",
    width: "md:w-36 2xl:w-40 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Date",
    key: "Date",
    width: "md:w-28 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Machine Name",
    key: "MachineName",
    width: "md:w-56 2xl:w-72 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Machine Capacity",
    key: "MachineCapacity",
    width: "md:w-40  px-2 py-3 ",
  },
  {
    title: "Operator",
    key: "Operator",
    width: "md:w-32 2xl:w-44 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Supervisor",
    key: "Supervisor",
    width: "md:w-28 xl:w-32 2xl:w-44 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Action",
    key: "action",
    width: "2xl:flex-1 px-2 py-3 ",
  },
];
const InjectionmouldingflimData = [
  {
    PSNumber: "PSN232400001",
    Date: "25-06-23",
    MachineName: "LD SHRINK Film Plant",
    MachineCapacity: "600 - 700",
    Operator: "Ababio",
    Supervisor: "Agymah",
  },
  {
    PSNumber: "PSN232400002",
    Date: "26-06-23",
    MachineName: "PVC Soft film plant",
    MachineCapacity: "50 - 100",
    Operator: "Coblah",
    Supervisor: "Botwe",
  },
  {
    PSNumber: "PSN232400003",
    Date: "27-06-23",
    MachineName: "SterFlex 2000",
    MachineCapacity: "100 - 300",
    Operator: "Dodzi",
    Supervisor: "Danquah",
  },
  {
    PSNumber: "PSN232400004",
    Date: "28-06-23",
    MachineName: "Pallet shrink wrap-Labelong",
    MachineCapacity: "800 - 900",
    Operator: "Kaatachi",
    Supervisor: "Elinam",
  },
  {
    PSNumber: "PSN232400005",
    Date: "29-06-23",
    MachineName: "NPC280H",
    MachineCapacity: "200 - 400",
    Operator: "Majid",
    Supervisor: "Kojo",
  },
];
const ReturntoSupplierHeader = [
  {
    title: "S.No",
    key: "sno",
    width: "md:w-12  2xl:w-12 px-2 py-3 ",
  },
  {
    title: "Date",
    key: "issuedate",
    width: "md:w-24 2xl:w-18 2xl:text-center 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Return Slip No.",
    key: "issueno",
    width: "md:w-32 2xl:w-44 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Material",
    key: "material",
    width: "md:w-48 xl:w-56 2xl:w-56 2xl:px-0 px-2 py-3 ",
  },
  {
    title: "U.O.M.",
    key: "uom",
    width: "md:w-16 2xl:flex-1  2xl:px-0 px-2 py-3 ",
  },
  {
    title: "Quantity",
    key: "quantity",
    width: "md:w-20 2xl:flex-1 px-2 py-3 ",
  },
  {
    title: "Supplier",
    key: "supplier",
    width: "md:w-80 2xl: px-2 py-3 ",
  },

  {
    title: "Action",
    key: "action",
    width: "2xl:flex-1 px-2 py-3 ",
  },
];

const RMStackHeader = [
  {
    title: "S.No",
    key: "RMStack_S.No",
    width: "md:w-32 2xl:flex-1 px-2 py-3 ",
  },
  {
    title: "Date",
    key: "rmStack_receivedDate",
    width: "md:w-32 2xl:flex-1 px-2 py-3 ",
  },
  {
    title: "Material Code",
    key: "rmstack_itemCode",
    width: "md:w-32 2xl:flex-1 px-2 py-3 ",
  },
  {
    title: "Material Name",
    key: "rmStack_itemName",
    width: "md:w-32 2xl:flex-1 px-2 py-3 ",
  },
  {
    title: "Opening Stack Qty/U.O.M",
    key: "rmStack_OpeningStack",
    width: "md:w-32 2xl:flex-1 px-2 py-3 ",
  },
  {
    title: "Invoice No.",
    key: "rmStack_invoiceNO",
    width: "md:w-32 2xl:flex-1 px-2 py-3 ",
  },
  {
    title: "Received Qty/U.O.M",
    key: "rmStack_received",
    width: "md:w-32 2xl:flex-1 px-2 py-3 ",
  },
  {
    title: "Total Qty/U.O.M",
    key: "rmStack_total",
    width: "md:w-32 2xl:flex-1 px-2 py-3 ",
  },
  {
    title: "Issue Receipt No. & Date",
    key: "rmStack_issueReceipt",
    width: "md:w-32 2xl:flex-1 px-2 py-3 ",
  },
  {
    title: "From Department",
    key: "rmStack_fromDepartment",
    width: "md:w-32 2xl:flex-1 px-2 py-3 ",
  },
  {
    title: "Qty/U.O.M",
    key: "rmStack_quentity",
    width: "md:w-32 2xl:flex-1 px-2 py-3 ",
  },
  {
    title: "Closing Stack",
    key: "rmStack_fromDepartment",
    width: "md:w-32 2xl:flex-1 px-2 py-3 ",
  },
  {
    title: "Action",
    key: "action",
    width: "2xl:flex-1 px-2 py-3 ",
  },
];
const EmployeeHeaders = [
  {
    title: "S.No",
    key: "sno",
    width: "md:w-12 2xl:w-12 px-2 py-3",
  },
  {
    title: "Photo",
    key: "Emp_Image",
    width: "md:w-24 2xl:text-center  px-2 py-3",
  },
  {
    title: "Employee ID",
    key: "Emp_EmployeeId",
    width: "md:w-28 2xl:w-32 px-2 py-3 ",
  },
  {
    title: "Employee Name",
    key: "Emp_EmployeeName",
    width: "md:w-32 2xl:w-40 px-2 py-3",
  },
  {
    title: "Designation",
    key: "Emp_Designation",
    width: "md:w-32 2xl:w-40 px-2 py-3",
  },
  {
    title: "DOJ",
    key: "EmpDateofjoining",
    width: "md:w-28  px-2 py-3",
  },
  {
    title: "Email",
    key: "Emp_Email",
    width: "md:w-44 2xl:w-56 px-2 py-3",
  },
  {
    title: "Mobile",
    key: "Emp_Mobile",
    width: "md:w-32 2xl:px-0 px-2 py-3",
  },
  {
    title: "Address",
    key: "address",
    width: "md:w-24 2xl:flex-1 px-2 py-3",
  },
  {
    title: "Action",
    key: "action",
    width: "2xl:flex-1 px-2 py-3 ",
  },
];

export {
  UserHeaders,
  rmHeaders,
  fgHeaders,
  customerMasterHeader,
  POdata,
  POHeader,
  supplierMasterHeader,
  roleHeader,
  domesticPurchaseHeader,
  purchaseAddHeader,
  GRNHeader,
  dummyData,
  unitOfMeasureHeader,
  RawMaterialMSTHeader,
  MachineHeader,
  DepartmentHeader,
  TODHeader,
  TODData,
  IssueToProdHeader,
  PlainshrinkflimData,
  PlainshrinkflimHeader,
  PrintedshrinkflimHeader,
  PrintedshrinkflimData,
  PrintedshrinkflimlaminationHeader,
  PrintedshrinkflimlaminationData,
  PrintedshrinkflimslittingHeader,
  PrintedshrinkflimslittingData,
  InjectionmouldingHeader,
  InjectionmouldingflimData,
  ReturntoSupplierHeader,
  RMStackHeader,
  EmployeeHeaders
};
