import React, { useState } from 'react';

const PurchaseTable = () => {
  const [tableData, setTableData] = useState([]);
  const [inputData, setInputData] = useState({
    Purchase_ItemCode: '',
    Purchase_ItemDescription: '',
    Purchase_Quantity: '',
    Purchase_Rate: '',
    Purchase_Amount: ''
  });

  const purchaseAddHeader = [
    {
      title: "S. No",
      key: "Purchase_SNo",
      width: "md:w-32 2xl:flex-1 px-2 py-3 "
    },
    {
      title: "Item Code",
      key: "Purchase_ItemCode",
      width: "md:w-32 2xl:flex-1 px-2 py-3 "
    },
    {
      title: "Item Description",
      key: "Purchase_ItemDescription",
      width: "md:w-32 2xl:flex-1 px-2 py-3 "
    },
    {
      title: "Quantity",
      key: "Purchase_Quantity",
      width: "md:w-32 2xl:flex-1 px-2 py-3 "
    },
    {
      title: "Rate",
      key: "Purchase_Rate",
      width: "md:w-32 2xl:flex-1 px-2 py-3 "
    },
    {
      title: "Amount",
      key: "Purchase_Amount",
      width: "md:w-32 2xl:flex-1 px-2 py-3 "
    }
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
  };

  const handleAddRow = () => {
    const newRow = {
      Purchase_SNo: tableData.length + 1,
      ...inputData
    };

    setTableData([...tableData, newRow]);
    setInputData({
      Purchase_ItemCode: '',
      Purchase_ItemDescription: '',
      Purchase_Quantity: '',
      Purchase_Rate: '',
      Purchase_Amount: ''
    });
  };

  return (
    <div>
      <table>
        <thead>
          <tr>
            {purchaseAddHeader.map((header) => (
              <th key={header.key} className={header.width}>
                <input
                  type="text"
                  name={header.key}
                  value={inputData[header.key]}
                  onChange={handleInputChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  placeholder={header.title}
                />
              </th>
            ))}
            <th>
              <button onClick={handleAddRow}>Add Row</button>
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((data, rowIndex) => (
            <tr key={rowIndex}>
              {purchaseAddHeader.map((header) => (
                <td key={header.key}>{data[header.key]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PurchaseTable;
