import React, { useEffect, useState } from "react";
import { Maintable, validationcolor } from "../css/Common";
import "../css/Misceleneous.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableGrid from "../components/table";
import {
  UserHeaders,
  fgHeaders,
  rmHeaders,
} from "../components/headerAttribute";
// import {
//   userBindDataAPI,
//   userMasterInsertAPI,
//   user_ImageAPI,
// } from "../services/UserAdd";
import UserDetails from "../popup-containers/UserDetails";
import MainPopup from "../popup-containers/MainPopup";
import MainLayout from "../components/Mainlayout";
import Select from "react-select";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { BsFillCheckCircleFill } from 'react-icons/bs';
// import { BsExclamationCircleFill } from 'react-icons/bs';

function FgManagement() {
  const [loading, setLoading] = useState(false);

  const [visibleAdd, setVisibleAdd] = React.useState(false);
  const [visibleTable, setVisibleTable] = React.useState(true);

  const [updateButton, setUpdateButton] = React.useState(false);
  const [submitButton, setSubmitButton] = React.useState(true);
  const [editMode, setEditMode] = useState([]);

  const [filterParam, setFilterParam] = useState(0);
  const [filterParam2, setFilterParam2] = useState(0);
  const [filterParam3, setFilterParam3] = useState(0);
  const [filterParam4, setFilterParam4] = useState(0);
  const [filterParam5, setFilterParam5] = useState(0);
  const [filterParam6, setFilterParam6] = useState(0);

  let [sortOrder, setSortOrder] = useState(0);
  const [sortColumn, setsortColumn] = useState(0);

  const [filterValue, setFilterValue] = useState("");
  const [filterValue2, setFilterValue2] = useState("");
  const [filterValue3, setFilterValue3] = useState("");
  const [filterValue4, setFilterValue4] = useState("");
  const [filterValue5, setFilterValue5] = useState("");
  const [filterValue6, setFilterValue6] = useState("");

  const [currentData, setCurrentData] = useState({});
  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  const [viewScreenTitle, setViewScreenTitle] = useState(false);
  const [bindUserMasterDatas, setBindUserMasterDatas] = useState([
    {
      fg_Sno: 1,
      fg_Code: "PSF00001",
      fg_name: " Plain Shrink Film",
      fg_category: "Packaging Material",
      fg_Description:
        "Clear transparent shrink film used for packaging and wrapping applications. It provides a tight and secure seal around products when heat is applied.",
      fg_UOM: "MT",
      fg_Quentity: 300,
      fg_OpenDate: "09-01-23",
    },
    {
      fg_Sno: 2,
      fg_Code: "PSF00002",
      fg_name: " Printed Shrink Film",
      fg_category: "Packaging Material",
      fg_Description:
        " Printed shrink film with customized designs or branding elements. It provides a visually appealing packaging solution with added marketing value. The film shrinks tightly around products when heat is applied, ensuring a secure seal.",
      fg_UOM: "MT",
      fg_Quentity: 700,
      fg_OpenDate: "10-01-23",
    },
    {
      fg_Sno: 3,
      fg_Code: "PSF00003",
      fg_name: " Shrink Film for Water Pouch",
      fg_category: "Packaging Material",
      fg_Description:
        " Shrink film specifically designed for water pouch packaging. It is a high-quality, food-grade film that provides excellent barrier properties to preserve the freshness and quality of the water. The film shrinks tightly around the water pouches when heat is applied, ensuring a secure and tamper-evident seal..",
      fg_UOM: "MT",
      fg_Quentity: 800,
      fg_OpenDate: "21-04-23",
    },
    {
      fg_Sno: 4,
      fg_Code: "LAM00001",
      fg_name: " Lamination Film",
      fg_category: "Packaging Material",
      fg_Description:
        "  Lamination film used for protecting and enhancing printed materials. It is a transparent, glossy film that provides a smooth and durable finish. The film is applied to surfaces using heat and pressure, creating a protective barrier against moisture, UV rays, and wear-and-tear. It is commonly used for laminating documents, photos, posters, and other printed materials.",
      fg_UOM: "MT",
      fg_Quentity: 100,
      fg_OpenDate: "07-03-23",
    },
    {
      fg_Sno: 5,
      fg_Code: "TFC00001",
      fg_name: " Thinwall Food Containers",
      fg_category: " Injection Moulding",
      fg_Description:
        " Thinwall food containers produced through injection moulding process. These containers are specifically designed for food packaging, offering lightweight yet sturdy construction. They provide excellent insulation properties, helping to keep the food fresh and maintain temperature. The containers are suitable for various food items, such as salads, desserts, and take-out meals..",
      fg_UOM: "pcs",
      fg_Quentity: 70,
      fg_OpenDate: "06-05-23",
    },
  ]);

  const [viewDetails, setViewDetails] = useState(false);
  const [deleteButton, setDeleteButton] = useState(false);
  const [blockButton, setBlockButton] = useState(false);
  const [unBlockButton, setUnBlockButton] = useState(false);

  const [currentPage1, setCurrentPage] = useState(1);
  const [rowIndex, setRowIndex] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [result, setResult] = useState(10);
  const [searchValue, setSearchValue] = useState(0);
  const [viewFilter, setViewFilter] = useState(false);
  // const [iconvisiable, seticonvisiable] = React.useState(true);
  // const [iconitemsshow, seticonitemshow] = React.useState(false);

  const [user_Firstname, setUser_Firstname] = useState("");
  const [user_Lastname, setUser_Lastname] = useState("");
  const [user_Email, setUser_Email] = useState("");
  const [user_Password, setUser_Password] = useState("");
  const [user_Mobile, setUser_Mobile] = useState("");
  const [user_Designation, setUser_Designation] = useState("");
  const [user_Department, setUser_Department] = useState("");
  const [user_Image, setUser_Image] = useState("");
  const [user_Image_File, setUser_Image_File] = useState("");
  const [user_Role, setUser_Role] = useState("");

  const [popUpOpen, setpopUpOpen] = useState(false);

  const [user_FirstNameError, setUser_FirstNameError] = useState("");
  const [user_LastNameError, setUser_LastNameError] = useState("");
  const [user_EmailError, setUser_EmailError] = useState("");
  const [user_PasswordError, setUser_PasswordError] = useState("");
  const [user_PhoneNumberError, setUser_PhoneNumberError] = useState("");
  const [user_DesignationError, setUser_DesignationError] = useState("");
  const [user_DepartmentError, setUser_DepartmentError] = useState("");
  const [user_RoleError, setUser_RoleError] = useState("");

  const [bindMaterialDD, setBindMaterialDD] = useState([
    { value: "Blow Film", label: "Blow Film" },
    { value: "Injection Moulding", label: "Injection Moulding" },
  ]);

  const showadd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setSubmitButton(true);
    setAddScreenTitle(true);
    setUpdateButton(false);
    setUpdateScreenTitle(false);
  };

  const cancel = () => {
    debugger;
    setVisibleTable(true);
    setVisibleAdd(false);
    setUser_Firstname("");
    setUser_Lastname("");
    setUser_Email("");
    setUser_Password("");
    setUser_Mobile("");
    setUser_Designation("");
    setUser_Department("");
    setUser_Image("");
    setUser_Role("");

    setUser_FirstNameError("");
    setUser_LastNameError("");
    setUser_EmailError("");
    setUser_PasswordError("");
    setUser_PhoneNumberError("");
    setUser_DesignationError("");
    setUser_DepartmentError("");
    setUser_RoleError("");

    setLoading(true);
    setTimeout(() => {
      setLoading(false);

      setCurrentPage(1);
      setResult(10);

      setVisibleAdd(false);
      setVisibleTable(true);
      setSubmitButton(false);
      setAddScreenTitle(false);
      setUpdateButton(false);
      setUpdateScreenTitle(false);
    }, 6000);
  };
  const HandleAddress = (rowIndex) => {};
  const HandleSort = async (columnName) => {
    // sortOrder++;
    // if (sortOrder > 2) {
    //   sortOrder = 1;
    // }
    // setsortColumn(columnName);
    // setSortOrder(sortOrder);
    // console.log("sort", sortOrder);
  };
  const HandleEdit = async (items) => {
    // setEditMode(true);
    // const data = [
    //   {
    //     emp_EmployeeId: items,
    //   },
    // ];
    // setSubmitButton(false);
    // setUpdateButton(true);
    // setVisibleAdd(true);
    // setVisibleTable(false);
    // setUpdateScreenTitle(true);
    // console.log("resultId", items);
    // setUser_Firstname(bindUserMasterDatas[items].User_FirstName);
    // setUser_Lastname(bindUserMasterDatas[items].User_LastName);
    // setUser_Email(bindUserMasterDatas[items].User_Email);
    // setUser_Mobile(bindUserMasterDatas[items].User_PhoneNumber);
    // setUser_Designation(bindUserMasterDatas[items].User_Designation);
    // setUser_Department(bindUserMasterDatas[items].User_Department);
    // setUser_Image(bindUserMasterDatas[items].User_Image);
  };

  function handleDropDown(e) {
    // setResult(e.target.value);
    // setCurrentPage(1);
  }

  const HandleSubmit = async (items, flag) => {
    // debugger;
    // var createdby = sessionStorage.getItem("createdby");
    // setUser_FirstNameError(
    //   user_Firstname.length > 0
    //     ? /[A-Za-z ]/.exec(user_Firstname)
    //       ? ""
    //       : "Only Alphabet and space allowed"
    //     : "Enter First Name"
    // );
    // setUser_LastNameError(
    //   user_Lastname.length > 0
    //     ? /[A-Za-z ]/.exec(user_Lastname)
    //       ? ""
    //       : "Only Alphabet and space allowed"
    //     : "Enter Last Name"
    // );
    // setUser_EmailError(
    //   user_Email.length > 0
    //     ? /^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/.test(user_Email)
    //       ? ""
    //       : "Invalid Email"
    //     : "Enter Email"
    // );
    // setUser_PasswordError(
    //   user_Password.length > 0
    //     ? /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&-+=()])(?=\\S+$).{8, 20}$/.test(
    //         user_Password
    //       )
    //       ? ""
    //       : "Invalid Password"
    //     : "Enter Password"
    // );
    // setUser_PhoneNumberError(
    //   user_Mobile.length > 0
    //     ? /\d/.test(user_Mobile) && user_Mobile.length == 10
    //       ? ""
    //       : "Invalid Mobile Number"
    //     : "Enter Mobile No"
    // );
    // setUser_DesignationError(
    //   user_Department.length > 0
    //     ? /[A-Za-z ]/.exec(user_Department)
    //       ? ""
    //       : "Only Alphabet and space allowed"
    //     : "Enter First Name"
    // );
    // setUser_DepartmentError(
    //   user_Department.length > 0
    //     ? /[A-Za-z ]/.exec(user_Department)
    //       ? ""
    //       : "Only Alphabet and space allowed"
    //     : "Enter First Name"
    // );
    // setUser_RoleError(
    //   user_Role === "Select"
    //     ? "Select a Role" : ""
    // );
    // if (
    //   !user_FirstNameError ||
    //   !user_LastNameError ||
    //   !user_EmailError ||
    //   !user_PasswordError ||
    //   !user_PhoneNumberError ||
    //   !user_DesignationError ||
    //   !user_DepartmentError ||
    //   !user_RoleError
    // ) {
    //   debugger;
    //   const data = [
    //     {
    //       User_FirstName: user_Firstname,
    //       User_LastName: user_Lastname,
    //       User_Email: user_Email,
    //       User_Password: user_Password,
    //       User_PhoneNumber: user_Mobile,
    //       User_Designation: user_Designation,
    //       User_Department: user_Department,
    //       User_Image: user_Image,
    //       User_Role: user_Role,
    //       User_CreatedBy: createdby,
    //       flag: flag,
    //     },
    //   ];
    //   console.log("Submit Payload", data);
    //   if (flag == 1) {
    //     const response = await userMasterInsertAPI(data);
    //     if (response.data.Table[0].Result === "S") {
    //       toast.success("Inserted Successfully", {
    //         position: toast.POSITION.TOP_CENTER,
    //       });
    //       cancel();
    //     }
    //     else {
    //       toast.error("Not inserted", {
    //         position: toast.POSITION.TOP_CENTER,
    //       });
    //     }
    //   } else if (flag == 2) {
    //     const response = await userMasterInsertAPI(data);
    //     if (response.data.Table[0].Result === "U") {
    //       toast.success("Updated Successfully", {
    //         position: toast.POSITION.TOP_CENTER,
    //       });
    //       cancel();
    //     } else {
    //       toast.error("Not Updated", {
    //         position: toast.POSITION.TOP_CENTER,
    //       });
    //     }
    //   }
    // } else if (flag == 3) {
    //   console.log("Block", bindUserMasterDatas[items].User_PhoneNumber);
    //   const data = [
    //     {
    //       User_PhoneNumber: bindUserMasterDatas[items].User_PhoneNumber,
    //       // user_Mobile: bindUserMasterDatas[items].User_PhoneNumber,
    //       User_CreatedBy: createdby,
    //       flag: flag,
    //     },
    //   ];
    //   const response = await userMasterInsertAPI(data);
    //   if (response.data.Table[0].Result === "D") {
    //     toast.success("Deleted Successfully", {
    //       position: toast.POSITION.TOP_CENTER,
    //     });
    //     cancel();
    //   } else {
    //     toast.error("Not Deleted", {
    //       position: toast.POSITION.TOP_CENTER,
    //     });
    //   }
    // } else if (flag == 4) {
    //   const data = [
    //     {
    //       User_PhoneNumber: bindUserMasterDatas[items].User_PhoneNumber,
    //       User_CreatedBy: createdby,
    //       flag: flag,
    //     },
    //   ];
    //   const response = await userMasterInsertAPI(data);
    //   if (response.data.Table[0].Result === "B") {
    //     toast.success("Blocked Successfully", {
    //       position: toast.POSITION.TOP_CENTER,
    //     });
    //     cancel();
    //   } else {
    //     toast.error("Not Blocked", {
    //       position: toast.POSITION.TOP_CENTER,
    //     });
    //   }
    // } else if (flag == 5) {
    //   const data = [
    //     {
    //       User_PhoneNumber: bindUserMasterDatas[items].User_PhoneNumber,
    //       User_CreatedBy: createdby,
    //       flag: flag,
    //     },
    //   ];
    //   const response = await userMasterInsertAPI(data);
    //   if (response.data.Table[0].Result === "UB") {
    //     toast.success("Unblocked Successfully", {
    //       position: toast.POSITION.TOP_CENTER,
    //     });
    //     cancel();
    //   } else {
    //     toast.error("Still Blocked", {
    //       position: toast.POSITION.TOP_CENTER,
    //     });
    //   }
    // }
  };

  // useEffect(() => {
  //   var createdby = sessionStorage.getItem("createdby");
  //   console.log("1234", createdby);
  //   const userDetailsdata = async () => {
  //     const response = await userBindDataAPI(
  //       filterParam ? filterParam : undefined,
  //       filterValue ? filterValue : undefined,
  //       filterParam2 ? filterParam2 : undefined,
  //       filterValue2 ? filterValue2 : undefined,
  //       filterParam3 ? filterParam3 : undefined,
  //       filterValue3 ? filterValue3 : undefined,
  //       filterParam4 ? filterParam4 : undefined,
  //       filterValue4 ? filterValue4 : undefined,
  //       filterParam5 ? filterParam5 : undefined,
  //       filterValue5 ? filterValue5 : undefined,
  //       filterParam6 ? filterParam6 : undefined,
  //       filterValue6 ? filterValue6 : undefined,
  //       currentPage1,
  //       result,
  //       sortColumn ? sortColumn : undefined,
  //       sortOrder ? sortOrder : undefined,
  //       searchValue ? searchValue : undefined
  //     );
  //     setBindUserMasterDatas(response.data.Table);
  //   };
  //   userDetailsdata();
  // }, [
  //   filterParam,
  //   filterValue,
  //   filterParam2,
  //   filterValue2,
  //   filterParam3,
  //   filterValue3,
  //   filterParam4,
  //   filterValue4,
  //   filterParam5,
  //   filterValue5,
  //   filterParam6,
  //   filterValue6,
  //   currentPage1,
  //   result,
  //   sortColumn,
  //   sortOrder,
  //   searchValue,
  // ]);

  const HandleFilter = (filterValuesPass, itemIndex) => {
    // console.log("filterValues", filterValuesPass);
    // console.log("filterIndex", itemIndex);
    // if (filterValuesPass.filterValue1) {
    //   setFilterParam("User_FirstName");
    //   setFilterValue(filterValuesPass.filterValue1);
    // } else {
    //   setFilterParam(0);
    //   setFilterValue(0);
    // }
    // if (filterValuesPass.filterValue2) {
    //   setFilterParam2("User_LastName");
    //   setFilterValue2(filterValuesPass.filterValue2);
    // } else {
    //   setFilterParam2(0);
    //   setFilterValue2(0);
    // }
    // if (filterValuesPass.filterValue3) {
    //   setFilterParam3("User_Email");
    //   setFilterValue3(filterValuesPass.filterValue3);
    // } else {
    //   setFilterParam3(0);
    //   setFilterValue3(0);
    // }
    // if (filterValuesPass.filterValue4) {
    //   setFilterParam4("User_PhoneNumber");
    //   setFilterValue4(filterValuesPass.filterValue4);
    // } else {
    //   setFilterParam4(0);
    //   setFilterValue4(0);
    // }
    // if (filterValuesPass.filterValue5) {
    //   setFilterParam5("User_Designation");
    //   setFilterValue5(filterValuesPass.filterValue5);
    // } else {
    //   setFilterParam5(0);
    //   setFilterValue5(0);
    // }
    // if (filterValuesPass.filterValue6) {
    //   setFilterParam6("User_Department");
    //   setFilterValue6(filterValuesPass.filterValue6);
    // } else {
    //   setFilterParam6(0);
    //   setFilterValue6(0);
    // }
  };

  const handleImageUpload = async () => {
    debugger;

    // const data = new FormData();
    // const imagedata = user_Image_File;
    // data.append("User_Image", imagedata);
    // const response = await user_ImageAPI(data);
    // setUser_Image(response.data);
    // console.log("Selected Image", response);
    // console.log("Selected Image", user_Image);
  };
  const HandleUserDetails = (rowIndex, flag) => {
    // setpopUpOpen(true);
    // debugger;
    // if (flag === 1) {
    //   setViewDetails(true);
    //   setDeleteButton(false);
    //   setBlockButton(false);
    //   setUnBlockButton(false);
    // } else if (flag === 2) {
    //   setViewDetails(true);
    //   setDeleteButton(true);
    //   setBlockButton(false);
    //   setUnBlockButton(false);
    //   setRowIndex(rowIndex);
    // } else if (flag === 3) {
    //   setViewDetails(true);
    //   setDeleteButton(false);
    //   setBlockButton(true);
    //   setRowIndex(rowIndex);
    //   setUnBlockButton(false);
    // } else if (flag === 4) {
    //   setViewDetails(true);
    //   setDeleteButton(false);
    //   setBlockButton(false);
    //   setRowIndex(rowIndex);
    //   setUnBlockButton(true);
    // }
    // console.log("Message", rowIndex);
    // setCurrentData(bindUserMasterDatas[rowIndex]);
  };

  return (
    <MainLayout>
      <ToastContainer />
      {visibleTable && (
        <div className="max-md:absolute">
          <div className={Maintable.headerTitle}>
            <div className="my-2 mx-12 lg:mx-0">{`Admin -> Master -> Finished Goods`}</div>
          </div>

          <div className="justify-between flex items-center">
            <div className={Maintable.searchAdd}>
              <div className="mt-1 gap-2 flex justify-between items-baseline">
                <label for="pageSize" className="hidden md:block">
                  Rows Per Page:
                </label>
                <select
                  onChange={handleDropDown}
                  className={Maintable.dropDownRecords}
                  name="pageSize"
                  id="pageSize"
                >
                  <option value="2">2</option>
                  <option value="10" selected>
                    10
                  </option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div>
                <label for="table-search" className="sr-only">
                  Search
                </label>
              </div>
              <div className="relative mt-1">
                <div className={Maintable.searchIconPosition}>
                  <svg
                    className={Maintable.searchIconSvg}
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  type="text"
                  id="table-search"
                  className={Maintable.searchInputBox}
                  placeholder="Search for items"
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
              {/* <button
                onClick={() => setViewFilter(!viewFilter)}
                className="relative px-5 py-0 overflow-hidden font-medium text-gray-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner group"
              >
                <span className="absolute top-0 left-0 w-0 h-0 transition-all duration-200 border-t-2 border-gray-600 group-hover:w-full ease"></span>
                <span className="absolute bottom-0 right-0 w-0 h-0 transition-all duration-200 border-b-2 border-gray-600 group-hover:w-full ease"></span>
                <span className="absolute top-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-gray-600 group-hover:h-full ease"></span>
                <span className="absolute bottom-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-gray-600 group-hover:h-full ease"></span>
                <span className="absolute inset-0 w-full h-full duration-300 delay-300 bg-gray-900 opacity-0 group-hover:opacity-100"></span>
                <span className="relative transition-colors duration-300 delay-200 group-hover:text-white ease">
                  <svg
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    height="2em"
                    width="2em"
                    //className=" mt-1 bg-gray-800 inline-flex items-center sm:mx-3 justify-center  md:p-1 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                    //onClick={() => setViewFilter(!viewFilter)}
                  >
                    <path d="M6 10.5a.5.5 0 01.5-.5h3a.5.5 0 010 1h-3a.5.5 0 01-.5-.5zm-2-3a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5zm-2-3a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5z" />
                  </svg>
                </span>
              </button> */}
            </div>
            <div className="flex self-end">
              <div className="text-red-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner w-fit h-9 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">
                <span className="mb-12 text-red-600 text-7xl">.</span>
                <span>Block</span>
              </div>
              <button
                type="button"
                className={Maintable.addButton}
                onClick={showadd}
              >
                <svg
                  width="25"
                  height="20"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.5 37.5H27.5V27.5H37.5V22.5H27.5V12.5H22.5V22.5H12.5V27.5H22.5V37.5ZM25 50C21.5417 50 18.2917 49.3433 15.25 48.03C12.2083 46.7183 9.5625 44.9375 7.3125 42.6875C5.0625 40.4375 3.28167 37.7917 1.97 34.75C0.656667 31.7083 0 28.4583 0 25C0 21.5417 0.656667 18.2917 1.97 15.25C3.28167 12.2083 5.0625 9.5625 7.3125 7.3125C9.5625 5.0625 12.2083 3.28083 15.25 1.9675C18.2917 0.655833 21.5417 0 25 0C28.4583 0 31.7083 0.655833 34.75 1.9675C37.7917 3.28083 40.4375 5.0625 42.6875 7.3125C44.9375 9.5625 46.7183 12.2083 48.03 15.25C49.3433 18.2917 50 21.5417 50 25C50 28.4583 49.3433 31.7083 48.03 34.75C46.7183 37.7917 44.9375 40.4375 42.6875 42.6875C40.4375 44.9375 37.7917 46.7183 34.75 48.03C31.7083 49.3433 28.4583 50 25 50ZM25 45C30.5833 45 35.3125 43.0625 39.1875 39.1875C43.0625 35.3125 45 30.5833 45 25C45 19.4167 43.0625 14.6875 39.1875 10.8125C35.3125 6.9375 30.5833 5 25 5C19.4167 5 14.6875 6.9375 10.8125 10.8125C6.9375 14.6875 5 19.4167 5 25C5 30.5833 6.9375 35.3125 10.8125 39.1875C14.6875 43.0625 19.4167 45 25 45Z"
                    fill="white"
                  />
                </svg>
                ADD
              </button>
            </div>
          </div>
          <TableGrid
            className={Maintable.Table}
            headerData={fgHeaders}
            tableData={bindUserMasterDatas}
            onEditGrid={HandleEdit}
            onDeleteGrid={HandleUserDetails}
            onSortGrid={HandleSort}
            onBlockGrid={HandleUserDetails}
            onViewGrid={HandleUserDetails}
            onAddressGrid={HandleAddress}
            onFilterGrid={HandleFilter}
            sortColumn={sortColumn}
            sortOrder={sortOrder}
            viewFilterGrid={viewFilter}
          />

          <div className={Maintable.submitCancelMainDiv}>
            <button
              disabled={currentPage1 === 1}
              onClick={() => setCurrentPage(currentPage1 - 1)}
              type="button"
              className={Maintable.submitButton}
            >
              Previous
            </button>
            <label>{currentPage1}</label>
            <button
              disabled={currentPage1 === totalPages / result}
              onClick={() => setCurrentPage(currentPage1 + 1)}
              type="button"
              className={Maintable.cancelButton}
            >
              Next
            </button>
          </div>
        </div>
      )}
      {visibleAdd && (
        <div className="flex flex-col w-full">
          <div className={Maintable.clientMasterMainAdd}>
            {addScreenTitle && (
              <div className={Maintable.addScreenTitle}>
                {"Admin -> Master -> Finished Goods -> Add"}
              </div>
            )}

            {updateScreenTitle && (
              <div className={Maintable.addScreenTitle}>{"Admin -> Master -> Finished Goods -> Edit"}</div>
            )}

            {viewScreenTitle && (
              <div className={Maintable.addScreenTitle}>{"Admin -> Master -> Finished Goods -> View"}</div>
            )}

            <div className={Maintable.addShadowScreen}>
              <div className={Maintable.mainContentScreen}>
                <form className={Maintable.formForClientMaster}>
                  <div className={Maintable.grid2}>
                    <div>
                      <label for="email" className={Maintable.label}>
                        Item Code
                      </label>
                      <input
                        type="text"
                        id="email"
                        name="user_Email"
                        value={user_Email}
                        placeholder="Description"
                        onChange={(e) => {
                          setUser_Email(e.target.value);
                          setUser_EmailError(
                            user_Email.length > 0
                              ? /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                                  user_Email
                                )
                                ? ""
                                : "Invalid Email"
                              : "Enter Email"
                          );
                        }}
                        class={Maintable.input}
                        className={
                          user_EmailError
                            ? validationcolor.errorColor
                            : Maintable.input
                        }
                      />
                      {user_EmailError && (
                        <span className="text-red-500">{user_EmailError}</span>
                      )}
                    </div>
                    <div>
                      <label for="first_name" className={Maintable.label}>
                        Finished Goods
                      </label>

                      <input
                        type="text"
                        id="firstname"
                        name="user_Firstname"
                        value={user_Firstname}
                        placeholder="Raw Material"
                        onChange={(e) => {
                          setUser_Firstname(e.target.value);
                          setUser_FirstNameError(
                            user_Firstname.length > 0
                              ? /[A-Za-z ]/.exec(user_Firstname)
                                ? ""
                                : "Only Alphabet and space allowed"
                              : "Enter First Name"
                          );
                        }}
                        class={Maintable.input}
                        className={
                          user_FirstNameError
                            ? validationcolor.errorColor
                            : Maintable.input
                        }
                      />
                      {user_FirstNameError && (
                        <span className="text-red-500">
                          {user_FirstNameError}
                        </span>
                      )}
                    </div>
                    <div>
                      <label for="lastname" className={Maintable.label}>
                        Category
                      </label>
                      <Select
                        options={bindMaterialDD}
                        class={Maintable.input1}
                      />
                      {/* <select class={Maintable.input}>
                        <option>Select</option>
                        <option>Plain Shrink Flim</option>
                        <option>Printed Shrink Flim</option>
                        <option>Shrink Flim for water Pouch</option>
                        <option>Lamination</option>
                        <option>Thin Walled Food Conaiers</option>
                      </select> */}
                     
                      {/* <input
                        type="text"
                        id="last_name"
                        name="user_Lastname"
                        value={user_Lastname}
                        placeholder="Doe"
                        onChange={(e) => {
                          setUser_Lastname(e.target.value);
                          setUser_LastNameError(
                            user_Lastname.length > 0
                              ? ""
                                ? ""
                                : "Only Alphabet and space allowed"
                              : "Enter Last Name"
                          );
                        }}
                        class={Maintable.input}
                        className={
                          user_LastNameError
                            ? validationcolor.errorColor
                            : Maintable.input
                        }
                      />
                      {user_LastNameError && (
                        <span className="text-red-500">{user_LastNameError}</span>
                      )} */}
                    </div>
                    <div>
                      <label for="email" className={Maintable.label}>
                        Description
                      </label>
                      <input
                        type="text"
                        id="email"
                        name="user_Email"
                        value={user_Email}
                        placeholder="Description"
                        onChange={(e) => {
                          setUser_Email(e.target.value);
                          setUser_EmailError(
                            user_Email.length > 0
                              ? /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                                  user_Email
                                )
                                ? ""
                                : "Invalid Email"
                              : "Enter Email"
                          );
                        }}
                        class={Maintable.input}
                        className={
                          user_EmailError
                            ? validationcolor.errorColor
                            : Maintable.input
                        }
                      />
                      {user_EmailError && (
                        <span className="text-red-500">{user_EmailError}</span>
                      )}
                    </div>
                    <div>
                      <label for="passsword" className={Maintable.label}>
                        Unit of Measure
                      </label>
                      <select class={Maintable.input}>
                        <option>Select</option>
                        <option>KG</option>
                        <option>MT</option>
                        <option>LTMS</option>
                        <option>Nos</option>
                        <option>L</option>
                        <option>BDLS</option>
                        <option>SACKS</option>
                        <option>cm</option>
                        <option>°C</option>
                      </select>
                      {/* <input
                        type="text"
                        id="password"
                        name="user_Password"
                        value={user_Password}
                        placeholder="Unit of Measure"
                        onChange={(e) => {
                          setUser_Password(e.target.value);
                          setUser_PasswordError(
                            user_Password.length > 0
                              ? /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(
                                  user_Email
                                )
                                ? ""
                                : "Invalid Password"
                              : "Enter Password"
                          );
                        }}
                        class={Maintable.input}
                        className={
                          user_PasswordError
                            ? validationcolor.errorColor
                            : Maintable.input
                        }
                      /> */}
                      {user_PasswordError && (
                        <span className="text-red-500">
                          {user_PasswordError}
                        </span>
                      )}
                    </div>
                    <div>
                      <label for="phone" className={Maintable.label}>
                        Opening Quantity
                      </label>
                      <input
                        type="number"
                        id="mobile"
                        name="user_Mobile"
                        value={user_Mobile}
                        placeholder="Opening Quantity"
                        onChange={(e) => {
                          setUser_Mobile(e.target.value);
                          setUser_PhoneNumberError(
                            user_Mobile.length > 0
                              ? /\d/.test(user_Mobile) &&
                                user_Mobile.length == 10
                                ? ""
                                : "Invalid Mobile Number"
                              : "Enter Mobile No"
                          );
                        }}
                        class={Maintable.input}
                        className={
                          user_PhoneNumberError
                            ? validationcolor.errorColor
                            : Maintable.input
                        }
                      />
                      {user_PhoneNumberError && (
                        <span className="text-red-500">
                          {user_PhoneNumberError}
                        </span>
                      )}
                    </div>

                    <div>
                      <label for="designation" className={Maintable.label}>
                        Opening Date
                      </label>
                      <input
                        type="date"
                        id="last_name"
                        name="user_Designation"
                        value={user_Designation}
                        placeholder="HR"
                        onChange={(e) => {
                          setUser_Designation(e.target.value);
                          setUser_DesignationError(
                            user_Department.length > 0
                              ? /[A-Za-z ]/.exec(user_Department)
                                ? ""
                                : "Only Alphabet and space allowed"
                              : "Enter First Name"
                          );
                        }}
                        class={Maintable.input}
                        className={
                          user_DesignationError
                            ? validationcolor.errorColor
                            : Maintable.input
                        }
                      />
                      {user_DesignationError && (
                        <span className="text-red-500">
                          {user_DesignationError}
                        </span>
                      )}
                    </div>
                    {/* <div>
                      <label for="Department" className={Maintable.label}>
                        Department
                      </label>
                      <input
                        type="text"
                        id="Department"
                        name="user_Department"
                        value={user_Department}
                        placeholder="Developer"
                        onChange={(e) => {
                          setUser_Department(e.target.value);
                          setUser_DepartmentError(
                            user_Department.length > 0
                              ? /[A-Za-z ]/.exec(user_Department)
                                ? ""
                                : "Only Alphabet and space allowed"
                              : "Enter First Name"
                          );
                        }}
                        class={Maintable.input}
                        className={
                          user_DepartmentError
                            ? validationcolor.errorColor
                            : Maintable.input
                        }
                      />
                      {user_DepartmentError && (
                        <span className="text-red-500">{user_DepartmentError}</span>
                      )}
                    </div> */}
                    {/* <div>
                      <label for="Department" className={Maintable.label}>
                        Role
                      </label>
                      <select className={Maintable.input} onChange={(e) => {setUser_Role(e.target.value)}}>
                        <option value="select">Select</option>
                        <option value="user">User</option>
                        <option value="admin">Admin</option>
                        <option value="superadmin">Super Admin</option>
                      </select>

                      {user_RoleError && (
                        <span className="text-red-500">{user_RoleError}</span>
                      )}
                    </div> */}
                    {/* <div>
                      <label className={Maintable.label} for="multiple_files">
                        Photo
                      </label>
                      <input
                        className={Maintable.fileUpload}
                        onChange={(e) => setUser_Image_File(e.target.files[0])}
                        name="user_Image"
                        id="profile"
                        type="file"
                        multiple
                      /> */}
                    {/* <BsFillCheckCircleFill className="hi" />
                              <BsExclamationCircleFill className="hello" /> */}
                    {/* </div> */}
                    {/* <div className="flex">
                      <div className="mt-7 mr-7">
                        <button
                          type="button"
                          className={Maintable.submitButton}
                          onClick={() => handleImageUpload()}
                        >
                          UPLOAD
                        </button>
                      </div>
                      <div>
                        <input
                          type="image"
                          src={user_Image}
                          alt="Image"
                          className="rounded-full w-20 h-20"
                        />
                      </div>
                    </div> */}
                    {/*<div className="flex">
                    <div className="flex-col">
                    <label className={Maintable.label} for="multiple_files">
                        Photo
                      </label>
                      <input
                        className={Maintable.fileUpload}
                        onChange={(e) => setUser_Image_File(e.target.files[0])}
                        name="user_Image"
                        id="profile"
                        type="file"
                        multiple
                      />
                    </div>
                      
                      <div className="mt-7 mx-7">
                        <button
                          type="button"
                          className={Maintable.submitButton}
                          onClick={() => handleImageUpload()}
                        >
                          UPLOAD
                        </button>
                      </div>
                      <div>
                        <input
                          type="image"
                          src={user_Image}
                          alt="Image"
                          className="rounded-full w-20 h-20"
                        />
                      </div>
                    </div>*/}
                  </div>
                </form>
                <div className={Maintable.submitCancelMainDiv}>
                  {submitButton && (
                    <button
                      data-modal-hide="bottom-right-modal"
                      type="button"
                      className={Maintable.submitButton}
                      onClick={() => HandleSubmit(0, 1)}
                    >
                      SUBMIT
                    </button>
                  )}
                  {updateButton && (
                    <button
                      data-modal-hide="bottom-right-modal"
                      type="submitButton"
                      className={Maintable.submitButton}
                      onClick={() => HandleSubmit(0, 2)}
                    >
                      UPDATE
                    </button>
                  )}
                  {/* <ToastContainer /> */}
                  <button
                    data-modal-hide="bottom-right-modal"
                    type="button"
                    className={Maintable.cancelButton}
                    onClick={cancel}
                  >
                    CANCEL
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={() => setpopUpOpen(true)}
      >
        <div>
          <button className="w-full">
            <span
              className="text-white flex justify-end"
              onClick={() => setpopUpOpen(false)}
              class="text-2xl p-0 m-o block float-right mr-3"
            >
              &times;
            </span>
          </button>
          <UserDetails data={currentData} />
        </div>
        <div className={Maintable.submitCancelMainDiv}>
          {deleteButton && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={Maintable.submitButton}
              onClick={() => HandleSubmit(rowIndex, 3)}
            >
              Delete
            </button>
          )}

          {blockButton && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={Maintable.submitButton}
              onClick={() => HandleSubmit(rowIndex, 4)}
            >
              Block
              {/* {currentData.Emp_Blockedby == 1 ? "Unblock" : "Block"}*/}
            </button>
          )}
          {unBlockButton && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={Maintable.submitButton}
              onClick={() => HandleSubmit(rowIndex, 5)}
            >
              Unblock
              {/* {(currentData.Emp_Blockedby == 1) ? "Unblock" : "Block"} */}
            </button>
          )}

          <button
            data-modal-hide="bottom-right-modal"
            type="button"
            className={Maintable.cancelButton}
            onClick={() => setpopUpOpen(false)}
          >
            CANCEL
          </button>
        </div>
      </MainPopup>
      {/*<div className="modal-design">
        <Popup
          // open={viewDetails}
          closeOnDocumentClick={false}
          onClose={() => setViewDetails(false)}
        >
          <div className="modal-reset">
            <button className="close" onClick={() => setViewDetails(false)}>
              &times;
            </button>
            <div className="modal-content">
              <UserDetails data={currentData} />
            </div>
          </div>
          <div className={Maintable.submitCancelMainDiv}>
            {deleteButton && (
              <button
                data-modal-hide="bottom-right-modal"
                type="button"
                className={Maintable.submitButton}
                onClick={() => HandleSubmit(rowIndex, 3)}
              >
                Delete
              </button>
            )}

            {blockButton && (
              <button
                data-modal-hide="bottom-right-modal"
                type="button"
                className={Maintable.submitButton}
                onClick={() => HandleSubmit(rowIndex, 4)}
              >
                Block
              </button>
            )}
            {unBlockButton && (
              <button
                data-modal-hide="bottom-right-modal"
                type="button"
                className={Maintable.submitButton}
                onClick={() => HandleSubmit(rowIndex, 5)}
              >
                Unblock
              </button>
            )}

            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={Maintable.cancelButton}
              onClick={() => setViewDetails(false)}
            >
              CANCEL
            </button>
          </div>
        </Popup>
            </div>*/}
    </MainLayout>
  );
}

export default FgManagement;
