import React from "react";
import MainLayout from "../components/Mainlayout";
import { Common, Maintable } from "../css/Common";
import TableGrid from "../components/table";
import {
  GRNHeader,
  dummyData,
  POHeader,
  POdata,
} from "../components/headerAttribute";
import { useState } from "react";
import { AiFillRest } from "react-icons/ai";
import MainPopup from "../popup-containers/MainPopup";
import PurchaseDetails from "../popup-containers/domesticPurchasePopUp";
import GRNDetails from "../popup-containers/GRNPopup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const maincontent = {
  maincontent: "w-2/5 mx-auto",
};

const SupplierMaster = () => {
  const [visible, setVisible] = useState(true);
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);
  const [visibleTableGRN, setVisibleTableGRN] = useState(true);
  const [visibleTablePO, setVisibleTablePO] = useState(false);

  const [viewPoDetails, setViewPoDetails] = useState(false);
  const [viewGRNDetails, setViewGRNDetails] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [popUpOpen, setpopUpOpen] = useState(false);
  const [currentData, setCurrentData] = useState({});

  const [receivedQuantity, setReceivedQuantity] = useState("0");
  const [receivableQuantity, setReceivableQuantity] = useState(1000);
  const [receivedNow, setReceivedNow] = useState("0");
  const [balance, setBalance] = useState("1000");
  const [receivedQuantity1, setReceivedQuantity1] = useState("0");
  const [receivableQuantity1, setReceivableQuantity1] = useState(1000);
  const [receivedNow1, setReceivedNow1] = useState("0");
  const [balance1, setBalance1] = useState("1000");
  const [receivedQuantity2, setReceivedQuantity2] = useState("0");
  const [receivableQuantity2, setReceivableQuantity2] = useState(1000);
  const [receivedNow2, setReceivedNow2] = useState("0");
  const [balance2, setBalance2] = useState("1000");
  const [receivedQuantity3, setReceivedQuantity3] = useState("0");
  const [receivableQuantity3, setReceivableQuantity3] = useState(1000);
  const [receivedNow3, setReceivedNow3] = useState("0");
  const [balance3, setBalance3] = useState("1000");

  const toggleData = () => {
    setVisibleTableGRN(true);
    setVisibleTablePO(true);
  };

  const toggleAddSection = () => {
    setVisibleAdd(!visibleAdd);
    setVisibleTable(!visibleTable);
  };
  const handleClose = () => {
    setpopUpOpen(false);
    setViewPoDetails(false);
  };
  const HandleGRN = (rowIndex) => {
    handleGRNOpen(true);
    setViewGRNDetails(true);
    // setCurrentData(bindEmployeeMasterDatas[rowIndex]);
  };

  const HandleDetails = (rowIndex) => {
    handleAddressOpen(true);
    setViewDetails(true);
    // setCurrentData(bindEmployeeMasterDatas[rowIndex]);
  };

  const handleAddressOpen = (isOpen) => {
    setViewPoDetails(isOpen);
  };
  const handleGRNOpen = (isOpen) => {
    setViewGRNDetails(isOpen);
  };
  const cancel = () => {
    setVisibleTable(true);
    setVisibleAdd(false);
  };
  const HandleSubmit =  () => {
    debugger;
    if (receivableQuantity - receivedNow >= 0) {
      setReceivableQuantity(receivableQuantity - receivedNow);
      setReceivedQuantity(parseInt(receivedQuantity) + parseInt(receivedNow));
      setBalance(receivableQuantity - receivedNow);
      setReceivedNow("0");
    }
    else {
      toast.error("You have exceeded the P.O. Quantity", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    if (receivableQuantity1 - receivedNow1 >= 0) {
      setReceivableQuantity1(receivableQuantity1 - receivedNow1);
      setReceivedQuantity1(parseInt(receivedQuantity1) + parseInt(receivedNow1));
      setBalance1(receivableQuantity1 - receivedNow1);
      setReceivedNow1("0");
    }
    else {
      toast.error("You have exceeded the P.O. Quantity", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    if (receivableQuantity2 - receivedNow2 >= 0) {
      setReceivableQuantity2(receivableQuantity2 - receivedNow2);
      setReceivedQuantity2(parseInt(receivedQuantity2) + parseInt(receivedNow2));
      setBalance2(receivableQuantity2 - receivedNow2);
      setReceivedNow2("0");
    }
    else {
      toast.error("You have exceeded the P.O. Quantity", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    if (receivableQuantity3 - receivedNow3 >= 0) {
      setReceivableQuantity3(receivableQuantity3 - receivedNow3);
      setReceivedQuantity3(parseInt(receivedQuantity3) + parseInt(receivedNow3));
      setBalance3(receivableQuantity3 - receivedNow3);
      setReceivedNow3("0");
    }
    else {
      toast.error("You have exceeded the P.O. Quantity", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <MainLayout>
      <ToastContainer />
      <div class="flex">
        {visibleTable && (
          <div class="relative w-full mx-auto">
            <div class={Maintable.headerTitle}>
              <div class="my-2 ml-8">{`Inventory -> G.R.N.`}</div>
            </div>
            <div className="mx-8">
              <div className="md:justify-between md:flex  md:items-center">
                <div className={Maintable.searchAdd}>
                  <div className="mt-1 gap-2 flex justify-between items-baseline">
                    <label htmlFor="pageSize" className="hidden md:block">
                      Rows Per Page:
                    </label>
                    <select
                      className={Maintable.dropDownRecords}
                      name="pageSize"
                      id="pageSize"
                    >
                      <option value="2">2</option>
                      <option value="10" selected>
                        10
                      </option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor="table-search" className="sr-only">
                      Search
                    </label>
                  </div>
                  <div className="relative mt-1">
                    <div className={Maintable.searchIconPosition}>
                      <svg
                        className={Maintable.searchIconSvg}
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <input
                      type="text"
                      id="table-search"
                      className={Maintable.searchInputBox}
                      placeholder="Search for items"
                    />
                  </div>
                </div>
                <div className="flex self-end">
                  <div
                    className="text-red-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner w-fit h-9 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2"
                    // onClick={toggleAddSection}
                  >
                    <span className="mb-12 text-red-600 text-7xl">.</span>
                    <span>Block</span>
                  </div>
                  <button
                    type="button"
                    className={Maintable.addButton}
                    onClick={toggleData}
                  >
                    <svg
                      width="25"
                      height="20"
                      viewBox="0 0 50 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22.5 37.5H27.5V27.5H37.5V22.5H27.5V12.5H22.5V22.5H12.5V27.5H22.5V37.5ZM25 50C21.5417 50 18.2917 49.3433 15.25 48.03C12.2083 46.7183 9.5625 44.9375 7.3125 42.6875C5.0625 40.4375 3.28167 37.7917 1.97 34.75C0.656667 31.7083 0 28.4583 0 25C0 21.5417 0.656667 18.2917 1.97 15.25C3.28167 12.2083 5.0625 9.5625 7.3125 7.3125C9.5625 5.0625 12.2083 3.28083 15.25 1.9675C18.2917 0.655833 21.5417 0 25 0C28.4583 0 31.7083 0.655833 34.75 1.9675C37.7917 3.28083 40.4375 5.0625 42.6875 7.3125C44.9375 9.5625 46.7183 12.2083 48.03 15.25C49.3433 18.2917 50 21.5417 50 25C50 28.4583 49.3433 31.7083 48.03 34.75C46.7183 37.7917 44.9375 40.4375 42.6875 42.6875C40.4375 44.9375 37.7917 46.7183 34.75 48.03C31.7083 49.3433 28.4583 50 25 50ZM25 45C30.5833 45 35.3125 43.0625 39.1875 39.1875C43.0625 35.3125 45 30.5833 45 25C45 19.4167 43.0625 14.6875 39.1875 10.8125C35.3125 6.9375 30.5833 5 25 5C19.4167 5 14.6875 6.9375 10.8125 10.8125C6.9375 14.6875 5 19.4167 5 25C5 30.5833 6.9375 35.3125 10.8125 39.1875C14.6875 43.0625 19.4167 45 25 45Z"
                        fill="white"
                      />
                    </svg>
                    ADD
                  </button>
                </div>
              </div>
              <div
                class={Maintable.tableShadowWidth}
                className="overflow-auto h-96"
              >
                {visibleTableGRN && (
                  <div>
                    <TableGrid
                      className={Maintable.Table}
                      headerData={POHeader}
                      tableData={POdata}
                      onGRN={toggleAddSection}
                      onAddressGrid={HandleGRN}

                    />
                    <div className={Maintable.submitCancelMainDiv}>
                      <button
                        // disabled={currentPage1 === 1}
                        // onClick={() => setCurrentPage(currentPage1 - 1)}
                        type="button"
                        className={Maintable.submitButton}
                      >
                        Previous
                      </button>
                      <label>1</label>
                      <button
                        // disabled={currentPage1 === totalPages / result}
                        // onClick={() => setCurrentPage(currentPage1 + 1)}
                        type="button"
                        className={Maintable.cancelButton}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                )}

                <br />
                {visibleTablePO && (
                  <div>
                    <label className="my-2 mx-12 lg:mx-0 md:px-0 text-xl font-medium text-neutral-700 dark:text-white">
                      Pending P.O. 
                    </label>
                    <TableGrid
                      className={Maintable.Table}
                      headerData={GRNHeader}
                      tableData={dummyData}
                      onGRN={toggleAddSection}
                      onAddressGrid={HandleDetails}
                    />
                    <div className={Maintable.submitCancelMainDiv}>
                      <button
                        // disabled={currentPage1 === 1}
                        // onClick={() => setCurrentPage(currentPage1 - 1)}
                        type="button"
                        className={Maintable.submitButton}
                      >
                        Previous
                      </button>
                      <label>1</label>
                      <button
                        // disabled={currentPage1 === totalPages / result}
                        // onClick={() => setCurrentPage(currentPage1 + 1)}
                        type="button"
                        className={Maintable.cancelButton}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {visibleAdd && (
          <div className="flex flex-col w-full">
            <div className={Maintable.clientMasterMainAdd}>
              <div className={Maintable.addScreenTitle}>{"Inventory -> G.R.N. -> Add"}</div>
              <div className={Maintable.addShadowScreen}>
                <div className={Maintable.mainContentScreen}>
                  <form className={Maintable.formForClientMaster}>
                    <div className={Maintable.labelBold}>Make G.R.N.</div>
                    <hr className={Maintable.horizontalLine} />
                    {/* Existing input boxes */}
                    <div className="grid gap-4 mb-6 md:grid-cols-6 mt-6">
                      <div>
                        <label className={Maintable.label}>
                        {`G.R.N. No.`}
                          <span className="text-red-600">*</span>
                        </label>
                        <input type="text" className={Maintable.input} />
                      </div>
                      <div>
                        <label className={Maintable.label}>
                          Date
                          <span className="text-red-600">*</span>
                        </label>
                        <input type="date" className={Maintable.input} value={"2023-06-27"} />
                      </div>
                      <div>
                        <label className={Maintable.label}>
                          Received Date
                          <span className="text-red-600">*</span>
                        </label>
                        <input type="date" className={Maintable.input} value={"2023-06-27"} />
                      </div>
                      <div>
                        <label className={Maintable.label}>
                          Transport Reference
                          <span className="text-red-600">*</span>
                        </label>
                        <input type="text" className={Maintable.input} />
                      </div>
                    </div>
                    {/* End of existing input boxes */}
                    {/* New table for displaying inputs */}
                    <table className="w-full border border-gray-300 mt-6">
                      <thead>
                        <tr>
                          <th className="border text-white bg-black border-gray-300 p-2">
                            Item Name
                          </th>
                          <th className="border text-white bg-black border-gray-300 p-3">
                            Item Code
                          </th>
                          <th className="border text-white bg-black border-gray-300 p-2">
                            U.O.M.
                          </th>
                          <th className="border text-white bg-black border-gray-300 p-2">
                            P.O. Qty
                          </th>
                          <th className="border text-white bg-black border-gray-300 p-2">
                            Recd.Until Now
                          </th>
                          <th className="border text-white bg-black border-gray-300 p-2">
                            Receivable Qty
                          </th>
                          <th className="border text-white bg-black border-gray-300 p-2">
                            Received Now
                          </th>
                          <th className="border text-white bg-black border-gray-300 p-2">
                            Balance
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="border border-gray-300 p-2">
                            <input
                              type="text"
                              placeholder="Description"
                              value="Polymer"
                              className={Maintable.input}
                              readonly
                            />
                          </td>
                          <td className="border border-gray-300 p-1">
                            <input
                              type="text"
                              placeholder="Item Code"
                              value="RMLD00001"
                              className={Maintable.input}
                              readonly
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            <input
                              type="text"
                              placeholder="U.O.M."
                              value="KG"
                              className={Maintable.input}
                              readonly
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            <input
                              type="text"
                              placeholder="P.O. Qty"
                              value="1000"
                              className={Maintable.input}
                              readonly
                            />
                          </td>

                          <td className="border border-gray-300 p-1">
                            <input
                              type="text"
                              name="receivedQuantity"
                              value={receivedQuantity}
                              className={Maintable.input}
                              readonly
                            />
                          </td>
                          <td className="border border-gray-300 p-1">
                            <input
                              type="text"
                              value={receivableQuantity}
                              onload={(e) => {
                                setReceivableQuantity("1000");
                              }}
                              placeholder="Receivable Qty"
                              className={Maintable.input}
                            />
                          </td>
                          {/* */}
                          <td className="border border-gray-300 p-2">
                            <input
                              type="text"
                              name="receivedNow"
                              value={receivedNow}
                              placeholder="Received Now"
                              className={Maintable.input}
                              onChange={(e) => {
                                setReceivedNow(e.target.value);
                              }}
                              onBlur={(e) => {
                                HandleSubmit();
                              }}
                            />
                          </td>
                          <td className="border border-gray-300 p-1">
                            <input
                              type="text"
                              name="balance"
                              value={balance}
                              className={Maintable.input}
                              readonly
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-gray-300 p-2">
                            <input
                              type="text"
                              placeholder="Description"
                              className={Maintable.input}
                              value="Polyethylene"
                              readonly
                            />
                          </td>
                          <td className="border border-gray-300 p-1">
                            <input
                              type="text"
                              placeholder="Item Code"
                              className={Maintable.input}
                              value="RMHD00001"
                              readonly
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            <input
                              type="text"
                              placeholder="U.O.M."
                              className={Maintable.input}
                              value="KG"
                              readonly
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            <input
                              type="text"
                              placeholder="P.O. Qty"
                              className={Maintable.input}
                              value="1000"
                              readonly
                            />
                          </td>

                          <td className="border border-gray-300 p-1">
                            <input
                              type="text"
                              placeholder="Recd.Until Now"
                              className={Maintable.input}
                              value={receivedQuantity1}
                              readonly
                            />
                          </td>
                          <td className="border border-gray-300 p-1">
                            <input
                              type="text"
                              placeholder="Receivable Qty"
                              className={Maintable.input}
                              value={receivableQuantity1}
                              onload={(e) => {
                                setReceivableQuantity1("1000");
                              }}
                            />
                          </td>
                          <td className="border border-gray-300 p-1">
                            <input
                              type="text"
                              placeholder="Received Now"
                              className={Maintable.input}
                              value={receivedNow1}
                              onChange={(e) => {
                                setReceivedNow1(e.target.value);
                              }}
                              onBlur={(e) => {
                                HandleSubmit();
                              }}
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            <input
                              type="text"
                              placeholder="Balance"
                              className={Maintable.input}
                              value={balance1}
                              readonly
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-gray-300 p-2">
                            <input
                              type="text"
                              placeholder="Description"
                              className={Maintable.input}
                              value="Pigments"
                              readonly
                            />
                          </td>
                          <td className="border border-gray-300 p-1">
                            <input
                              type="text"
                              placeholder="Item Code"
                              className={Maintable.input}
                              value="RMIN00001"
                              readonly
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            <input
                              type="text"
                              placeholder="U.O.M."
                              className={Maintable.input}
                              value="KG"
                              readonly
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            <input
                              type="text"
                              placeholder="P.O. Qty"
                              className={Maintable.input}
                              value="1000"
                              readonly
                            />
                          </td>

                          <td className="border border-gray-300 p-1">
                            <input
                              type="text"
                              placeholder="Recd.Until Now"
                              className={Maintable.input}
                              value={receivedQuantity2}
                              readonly
                            />
                          </td>
                          <td className="border border-gray-300 p-1">
                            <input
                              type="text"
                              placeholder="Receivable Qty"
                              className={Maintable.input}
                              value={receivableQuantity2}
                              onload={(e) => {
                                setReceivableQuantity2("1000");
                              }}
                            />
                          </td>
                          <td className="border border-gray-300 p-1">
                            <input
                              type="text"
                              placeholder="Received Now"
                              className={Maintable.input}
                              value={receivedNow2}
                              onChange={(e) => {
                                setReceivedNow2(e.target.value);
                              }}
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            <input
                              type="text"
                              placeholder="Balance"
                              className={Maintable.input}
                              value={balance2}
                              readonly
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-gray-300 p-2">
                            <input
                              type="text"
                              placeholder="Description"
                              className={Maintable.input}
                              value="Solvents"
                              readonly
                            />
                          </td>
                          <td className="border border-gray-300 p-1">
                            <input
                              type="text"
                              placeholder="Item Code"
                              className={Maintable.input}
                              value="RMSL00001"
                              readonly
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            <input
                              type="text"
                              placeholder="U.O.M."
                              className={Maintable.input}
                              value="KG"
                              readonly
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            <input
                              type="text"
                              placeholder="P.O. Qty"
                              className={Maintable.input}
                              value="1000"
                              readonly
                            />
                          </td>

                          <td className="border border-gray-300 p-1">
                            <input
                              type="text"
                              placeholder="Recd.Until Now"
                              className={Maintable.input}
                              value={receivedQuantity3}
                              readonly
                            />
                          </td>
                          <td className="border border-gray-300 p-1">
                            <input
                              type="text"
                              placeholder="Receivable Qty"
                              className={Maintable.input}
                              value={receivableQuantity3}
                              onload={(e) => {
                                setReceivableQuantity3("1000");
                              }}
                            />
                          </td>
                          <td className="border border-gray-300 p-1">
                            <input
                              type="text"
                              placeholder="Received Now"
                              className={Maintable.input}
                              value={receivedNow3}
                              onChange={(e) => {
                                setReceivedNow3(e.target.value);
                              }}
                              onBlur={(e) => {
                                HandleSubmit();
                              }}
                            />







                          </td>
                          <td className="border border-gray-300 p-2">
                            <input
                              type="text"
                              placeholder="Balance"
                              className={Maintable.input}
                              value={balance3}
                              readonly
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* End of new table */}
                    
                  </form>
                </div>
                <div className={Maintable.submitCancelMainDiv}>
                      <button
                        data-modal-hide="bottom-right-modal"
                        type="button"
                        className={Maintable.submitButton}
                        onClick={() => HandleSubmit()}
                      >
                        SUBMIT
                      </button>
                      <button
                        data-modal-hide="bottom-right-modal"
                        type="button"
                        className={Maintable.cancelButton}
                        onClick={cancel}
                      >
                        CANCEL
                      </button>
                    </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <MainPopup
        popUpOpen={viewPoDetails}
        setpopUpOpen={setViewPoDetails}
        Open={HandleDetails}

      >
        <div>
          <button class="w-full">
            <span
              className="text-white flex justify-end"
              onClick={handleClose}
              class="text-2xl p-0 m-o block float-right mr-3"
            >
              &times;
            </span>
          </button>
          <PurchaseDetails data={currentData} />
        </div>
      </MainPopup>
      <MainPopup
        popUpOpen={viewGRNDetails}
        setpopUpOpen={setViewGRNDetails}
        Open={HandleGRN}
      >
        <div>
          <button class="w-full">
            <span
              className="text-white flex justify-end"
              onClick={handleClose}
              class="text-2xl p-0 m-o block float-right mr-3"
            >
              &times;
            </span>
          </button>
          <GRNDetails data={currentData} />
        </div>
      </MainPopup>
    </MainLayout>
  );
};

export default SupplierMaster;
