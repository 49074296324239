import React from "react";
import MainLayout from "../components/Mainlayout";

const MyProfile = () => {
  return (
    <MainLayout>
      <div>MyProfile </div>
    </MainLayout>
  );
};

export default MyProfile;