import React, { useState } from "react";
import MainLayout from "../components/Mainlayout";
import { Maintable } from "../css/Common";
import TableGrid from "../components/table";
import {
  domesticPurchaseHeader,
  roleHeader,
} from "../components/headerAttribute";
import { purchaseAddHeader } from "../components/headerAttribute";
import MainPopup from "../popup-containers/MainPopup";
import PurchaseDetails from "../popup-containers/domesticPurchasePopUp";
import SupplierDetails from "../popup-containers/supplierPopUp";
import { MdCancel } from "react-icons/md";
import Select from "react-select";
import PLASCO from "../image/PLASCO1.png";
import Logo from "../image/Dummy_logo.png";

const DomesticPurchase = () => {
  const [materialCode, setMaterialCode] = useState("");
  const [itemDes, setItemDes] = useState("");
  const [viewPoDetails, setViewPoDetails] = useState(false);
  const [viewSupplierDetails, setViewSupplierDetails] = useState(false);
  const [popUpOpen, setpopUpOpen] = useState(false);
  const [bindPurchareMasterData, setbindPurchareMasterData] = useState([
    {
      Purchase_SNo: 1,
      Purchase_ItemCode: "LDPE-098765G",
      Purchase_ItemDescription: "LDPE Film Grade",
      Purchase_Quantity: "1000 kg",
      Purchase_Rate: "$1.50",
      Purchase_Amount: "$1,500",
      Purchase_Action: (
        <svg
          viewBox="0 0 800 1000"
          fill="currentColor"
          height="1rem"
          width="4rem"
        >
          <path d="M700 100c28 0 51.667 9.667 71 29s29 43 29 71v600c0 26.667-9.667 50-29 70s-43 30-71 30H100c-26.667 0-50-10-70-30S0 826.667 0 800V200c0-28 10-51.667 30-71s43.333-29 70-29h600M554 738l86-86-154-152 154-154-86-86-154 152-152-152-88 86 154 154-154 152 88 86 152-152 154 152" />
        </svg>
      ),
    },
    {
      Purchase_SNo: 2,
      Purchase_ItemCode: "LDPE-0877668N",
      Purchase_ItemDescription: "LDPE Resin, Natural Color",
      Purchase_Quantity: "500 kg",
      Purchase_Rate: "$2.00",
      Purchase_Amount: "$1,000",
      Purchase_Action: (
        <svg
          viewBox="0 0 800 1000"
          fill="currentColor"
          height="1rem"
          width="4rem"
        >
          <path d="M700 100c28 0 51.667 9.667 71 29s29 43 29 71v600c0 26.667-9.667 50-29 70s-43 30-71 30H100c-26.667 0-50-10-70-30S0 826.667 0 800V200c0-28 10-51.667 30-71s43.333-29 70-29h600M554 738l86-86-154-152 154-154-86-86-154 152-152-152-88 86 154 154-154 152 88 86 152-152 154 152" />
        </svg>
      ),
    },
    {
      Purchase_SNo: 3,
      Purchase_ItemCode: "LDPE-0877668M",
      Purchase_ItemDescription: "LDPE Injection Molding Grade",
      Purchase_Quantity: "800 kg",
      Purchase_Rate: "$1.80",
      Purchase_Amount: "$1,440",
      Purchase_Action: (
        <svg
          viewBox="0 0 800 1000"
          fill="currentColor"
          height="1rem"
          width="4rem"
        >
          <path d="M700 100c28 0 51.667 9.667 71 29s29 43 29 71v600c0 26.667-9.667 50-29 70s-43 30-71 30H100c-26.667 0-50-10-70-30S0 826.667 0 800V200c0-28 10-51.667 30-71s43.333-29 70-29h600M554 738l86-86-154-152 154-154-86-86-154 152-152-152-88 86 154 154-154 152 88 86 152-152 154 152" />
        </svg>
      ),
    },
    {
      Purchase_SNo: 4,
      Purchase_ItemCode: "LDPE-0877668B",
      Purchase_ItemDescription: "LDPE Blown Film Grade",
      Purchase_Quantity: "1200 kg",
      Purchase_Rate: "$1.75",
      Purchase_Amount: "$2,100",
      Purchase_Action: (
        <svg
          viewBox="0 0 800 1000"
          fill="currentColor"
          height="1rem"
          width="4rem"
        >
          <path d="M700 100c28 0 51.667 9.667 71 29s29 43 29 71v600c0 26.667-9.667 50-29 70s-43 30-71 30H100c-26.667 0-50-10-70-30S0 826.667 0 800V200c0-28 10-51.667 30-71s43.333-29 70-29h600M554 738l86-86-154-152 154-154-86-86-154 152-152-152-88 86 154 154-154 152 88 86 152-152 154 152" />
        </svg>
      ),
    },
  ]);
  const [currentData, setCurrentData] = useState({});
  const [viewDetails, setViewDetails] = useState(false);

  const [ViewPurchaseInputFiled, setViewPurchaseInputFiled] = useState(true);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [supplierName, setSupplierName] = useState("");
  const [supplierId, setSupplierId] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [logo, setLogo] = useState(Logo);

  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);

  const [bindUserMasterDatas, setBindUserMasterDatas] = useState([
    {
      Domestic_SNO: 1,
      po_no: "DPO232400001 ",
      po_date: "15-06-23",
      supplier: (
        <a
          href="#"
          onClick={() => HandleSupplier()}
          className="text-cyan-600 underline"
        >
          Plasco Engineering Inc.
        </a>
      ),
      expected_date: "25-06-23 ",
      po_amount: <p className="text-right">25,00,000</p>,
    },
    {
      Domestic_SNO: 2,
      po_no: "DPO232400002 ",
      po_date: "16-06-23",
      supplier: (
        <a
          href="#"
          onClick={() => HandleSupplier()}
          className="text-cyan-600 underline"
        >
          Ye I Machinery Factory Co. Ltd.
        </a>
      ),
      expected_date: "26-06-23 ",
      po_amount: <p className="text-right">10,00,000</p>,
    },
    {
      Domestic_SNO: 3,
      po_no: "DPO232400003",
      po_date: "17-06-23",
      supplier: (
        <a
          href="#"
          onClick={() => HandleSupplier()}
          className="text-cyan-600 underline"
        >
          Windsor Machines Ltd
        </a>
      ),
      expected_date: "27-06-23",
      po_amount: <p className="text-right">5,00,000</p>,
    },
    {
      Domestic_SNO: 4,
      po_no: "DPO232400004",
      po_date: "18-06-23 ",
      supplier: (
        <a
          href="#"
          onClick={() => HandleSupplier()}
          className="text-cyan-600 underline"
        >
          Polystar Machinery Co. Ltd.
        </a>
      ),
      expected_date: "28-06-23",
      po_amount: <p className="text-right">2,00,000</p>,
    },
    {
      Domestic_SNO: 5,
      po_no: "DPO232400005",
      po_date: "25-06-23 ",
      supplier: (
        <a
          href="#"
          onClick={() => HandleSupplier()}
          className="text-cyan-600 underline"
        >
          Polystar Machinery Co. Ltd.
        </a>
      ),
      expected_date: "05-07-23",
      po_amount: <p className="text-right">8,00,000</p>,
    },
    {
      Domestic_SNO: 6,
      po_no: "DPO232400006 ",
      po_date: "22-06-23",
      supplier: (
        <a
          href="#"
          onClick={() => HandleSupplier()}
          className="text-cyan-600 underline"
        >
          Plasco Engineering Inc.
        </a>
      ),
      expected_date: "25-06-23 ",
      po_amount: <p className="text-right">13,00,000</p>,
    },
    {
      Domestic_SNO: 7,
      po_no: "DPO232400007 ",
      po_date: "23-06-23",
      supplier: (
        <a
          href="#"
          onClick={() => HandleSupplier()}
          className="text-cyan-600 underline"
        >
          Ye I Machinery Factory Co. Ltd.
        </a>
      ),
      expected_date: "26-07-23 ",
      po_amount: <p className="text-right">10,00,000</p>,
    },
    {
      Domestic_SNO: 8,
      po_no: "DPO232400008",
      po_date: "24-06-23",
      supplier: (
        <a
          href="#"
          onClick={() => HandleSupplier()}
          className="text-cyan-600 underline"
        >
          Windsor Machines Ltd
        </a>
      ),
      expected_date: "27-07-23",
      po_amount: <p className="text-right">15,00,000</p>,
    },
    {
      Domestic_SNO: 9,
      po_no: "DPO232400009",
      po_date: "25-06-23 ",
      supplier: (
        <a
          href="#"
          onClick={() => HandleSupplier()}
          className="text-cyan-600 underline"
        >
          Polystar Machinery Co. Ltd.
        </a>
      ),
      expected_date: "26-07-23",
      po_amount: <p className="text-right">12,00,000</p>,
    },
    {
      Domestic_SNO: 10,
      po_no: "DPO2324000010",
      po_date: "27-06-23 ",
      supplier: (
        <a
          href="#"
          onClick={() => HandleSupplier()}
          className="text-cyan-600 underline"
        >
          Polystar Machinery Co. Ltd.
        </a>
      ),
      expected_date: "27-07-23",
      po_amount: <p className="text-right">18,00,000</p>,
    },
  ]);
  const [bindMaterialDD, setBindMaterialDD] = useState([
    { value: "Polymer RMLD00001", label: "Polymer RMLD00001" },
    { value: "Polyethylene RMHD00001", label: "Polyethylene RMHD00001" },
    { value: "Pigments RMIN00001", label: "Pigments RMIN00001" },
    { value: "Solvents RMSL00001", label: "Solvents RMSL00001" },
    { value: "Polymers RMSD00001", label: "Polymers RMSD00001" },
    { value: "Acrylic RMAD00001", label: "Acrylic RMAD00001" },
    {
      value: "Plain Shrink Film PSF00001",
      label: "Plain Shrink Film PSF00001",
    },
    {
      value: "Printed Shrink Film PSF00002",
      label: "Printed Shrink Film PSF00002",
    },
    {
      value: "Shrink Film for Water Pouch PSF00003",
      label: "Shrink Film for Water Pouch PSF00003",
    },
    { value: "Lamination Film bLAM00001", label: "Lamination Film bLAM00001" },
    {
      value: "Thinwall Food Containers TFC00001",
      label: "Thinwall Food Containers TFC00001",
    },
  ]);
  const [bindSupplierDD, setSupplierDD] = useState([
    {
      value: "Plasco Engineering Inc SULD00001",
      label: "Plasco Engineering Inc SULD00001",
    },
    {
      value: "Ye I Machinery Factory Co. Ltd. SULD00002",
      label: "Ye I Machinery Factory Co. Ltd. SULD00002",
    },
    {
      value: "Windsor Machines Ltd SULD00003",
      label: "Windsor Machines Ltd SULD00003",
    },
    {
      value: "Polystar Machinery Co. Ltd. SULD00004",
      label: "Polystar Machinery Co. Ltd. SULD00004",
    },
    {
      value: "Plasco Engineering Inc. SULD00005",
      label: "Plasco Engineering Inc. SULD00005",
    },
  ]);

  // const [tableData, setTableData] = useState([
  //   { itemCode: '', description: '', poQuantity: '', uom: '', rate: '', amount: '' }
  // ]);
  // const handleAddRow = () => {
  //   const newRow = { ...tableData[tableData.length - 1] }; // Copy the last row
  //   newRow.itemCode = ''; // Clear the values of the new row
  //   newRow.description = '';
  //   newRow.poQuantity = '';
  //   newRow.uom = '';
  //   newRow.rate = '';
  //   newRow.amount = '';

  //   setTableData(prevData => [...prevData, newRow]); // Add the new row to the table data
  // };
  // const getTotalPurchaseOrderAmount = () => {
  //   let totalAmount = 0;
  //   tableData.forEach(row => {
  //     const amount = parseFloat(row.amount);
  //     if (!isNaN(amount)) {
  //       totalAmount += amount;
  //     }
  //   });
  //   return totalAmount.toFixed(2);
  // };

  // const handleInputChange = (event, rowIndex, columnName) => {
  //   debugger;
  //   const { value } = event;
  //   setTableData(prevData => {
  //     const newData = [...prevData];
  //     newData[rowIndex][columnName] = value;
  //     if (columnName === "itemCode") {
  //       const selectedItemCode = value;
  //              let selectedDescription = ""; // Replace this with your logic to get the description for the selected itemCode

  //              let selectedUoM = "";

  //       if (selectedItemCode === "Polymer RMLD00001") {
  //         selectedDescription = "RMLD00001";
  //         selectedUoM = "KG";
  //       } else if (selectedItemCode === "Polyethylene RMHD00001") {
  //         selectedDescription = "RMHD00001";
  //         selectedUoM = "KG";
  //       } else if (selectedItemCode === "Pigments RMIN00001") {
  //         selectedDescription = "RMIN00001";
  //         selectedUoM = "L";
  //       } else if (selectedItemCode === "Solvents RMSL00001") {
  //         selectedDescription = "RMSL00001";
  //         selectedUoM = "KPa";
  //       }
  //      // const selectedDescription = "ABS"; // Replace this with your logic to get the description for the selected itemCode
  //      // const selectedUoM = "KG";
  //       const selectedAmount = newData[rowIndex].poQuantity * newData[rowIndex].rate;
  //       newData[rowIndex]['description'] = selectedDescription || '';
  //       newData[rowIndex]['uom'] = selectedUoM || '';
  //       newData[rowIndex]['amount'] = selectedAmount || '';
  //     }
  //     else if (columnName === "poQuantity" || columnName === "rate") {
  //       const poQuantity = newData[rowIndex].poQuantity;
  //       const rate = newData[rowIndex].rate;
  //       const updatedAmount = poQuantity && rate ? poQuantity * rate : '';
  //       newData[rowIndex]['amount'] = updatedAmount;
  //     }
  //     return newData;
  //   });
  // };

  // const handleDeleteRow = (index) => {
  //   const updatedTableData = [...tableData];
  //   updatedTableData.splice(index, 1);
  //   setTableData(updatedTableData);
  // };

  // const [tableData, setTableData] = useState([
  //   { itemCode: '', description: '', poQuantity: '', uom: '', rate: '', amount: '' }
  // ]);
  // const handleAddRow = () => {
  //   const newRow = { ...tableData[tableData.length - 1] }; // Copy the last row
  //   newRow.itemCode = ''; // Clear the values of the new row
  //   newRow.description = '';
  //   newRow.poQuantity = '';
  //   newRow.uom = '';
  //   newRow.rate = '';
  //   newRow.amount = '';

  //   setTableData(prevData => [...prevData, newRow]); // Add the new row to the table data
  // };

  // const handleInputChange = (event, rowIndex, columnName) => {
  //   const { value } = event.target;
  //   setTableData(prevData => {
  //     const newData = [...prevData];
  //     newData[rowIndex][columnName] = value;

  //     if (columnName === "itemCode") {
  //       // Logic to get description and uom based on itemCode
  //       let selectedDescription = "";
  //       let selectedUoM = "";

  //       if (value === "Polymer RMLD00001") {
  //         selectedDescription = "RMLD00001";
  //         selectedUoM = "KG";
  //       } else if (value === "Polyethylene RMHD00001") {
  //         selectedDescription = "RMHD00001";
  //         selectedUoM = "KG";
  //       } else if (value === "Pigments RMIN00001") {
  //         selectedDescription = "RMIN00001";
  //         selectedUoM = "L";
  //       } else if (value === "Solvents RMSL00001") {
  //         selectedDescription = "RMSL00001";
  //         selectedUoM = "KPa";
  //       }

  //       newData[rowIndex]['description'] = selectedDescription;
  //       newData[rowIndex]['uom'] = selectedUoM;
  //     } else if (columnName === "poQuantity" || columnName === "rate") {
  //       const poQuantity = parseFloat(newData[rowIndex].poQuantity);
  //       const rate = parseFloat(newData[rowIndex].rate);
  //       const updatedAmount = !isNaN(poQuantity) && !isNaN(rate) ? poQuantity * rate : '';
  //       newData[rowIndex]['amount'] = updatedAmount;
  //     }

  //     return newData;
  //   });
  // };

  // const handleDeleteRow = (index) => {
  //   const updatedTableData = [...tableData];
  //   updatedTableData.splice(index, 1);
  //   setTableData(updatedTableData);
  // };

  // const getTotalPurchaseOrderAmount = () => {
  //   let totalAmount = 0;
  //   tableData.forEach(row => {
  //     const amount = parseFloat(row.amount);
  //     if (!isNaN(amount)) {
  //       totalAmount += amount;
  //     }
  //   });
  //   return totalAmount.toFixed(2);
  // };

  const [tableData, setTableData] = useState([
    {
      itemCode: "",
      description: "",
      poQuantity: "0",
      uom: "",
      rate: "0",
      amount: "0",
    },
  ]);
  const handleAddRow = () => {
    const newRow = { ...tableData[tableData.length - 1] }; // Copy the last row
    newRow.itemCode = ""; // Clear the values of the new row
    newRow.description = "";
    newRow.poQuantity = "0";
    newRow.uom = "";
    newRow.rate = "0";
    newRow.amount = "0";

    setTableData((prevData) => [...prevData, newRow]); // Add the new row to the table data
  };
  const handleInputChange = (selectedOption, rowIndex, columnName) => {
    const value = selectedOption ? selectedOption.value : "";
    setTableData((prevData) => {
      const newData = [...prevData];
      newData[rowIndex][columnName] = value;

      if (columnName === "itemCode") {
        // Logic to get description and uom based on itemCode
        let selectedDescription = "";
        let selectedUoM = "";

        if (value === "Polymer RMLD00001") {
          selectedDescription = "RMLD00001";
          selectedUoM = "KG";
        } else if (value === "Polyethylene RMHD00001") {
          selectedDescription = "RMHD00001";
          selectedUoM = "KG";
        } else if (value === "Pigments RMIN00001") {
          selectedDescription = "RMIN00001";
          selectedUoM = "L";
        } else if (value === "Solvents RMSL00001") {
          selectedDescription = "RMSL00001";
          selectedUoM = "KPa";
        }

        newData[rowIndex]["description"] = selectedDescription;
        newData[rowIndex]["uom"] = selectedUoM;
        newData[rowIndex].poQuantity = rowIndex.poQuantity;
        newData[rowIndex].rate = rowIndex.rate;
      } else if (columnName === "poQuantity" || columnName === "rate") {
        newData[rowIndex][columnName] = value; // Update the value directly
        const poQuantity = parseInt(newData[rowIndex].poQuantity);
        const rate = parseInt(newData[rowIndex].rate);
        const updatedAmount =
          !isNaN(poQuantity) && !isNaN(rate) ? poQuantity * rate : "";
        newData[rowIndex]["amount"] = updatedAmount;
      }

      return newData;
    });
  };

  const handleDeleteRow = (index) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };

  const getTotalPurchaseOrderAmount = () => {
    let totalAmount = 0;
    tableData.forEach((row) => {
      const amount = parseInt(row.amount);
      if (!isNaN(amount)) {
        totalAmount += amount;
      }
    });
    return totalAmount.toFixed(2);
  };

  const handleSupplierChange = (value) => {
    debugger;
    const name = value.value;
    setSelectedSupplier(value);

    if (name === "Plasco Engineering Inc SULD00001") {
      setSupplierName("SULD00001");
      setAddress1("No. 90");
      setAddress2("Nanpi Rd");
      setAddress3("Nanpi Rewan");
      setCity("Taibao");
      setState("Chiayioi");
      setCountry("Chiayi");
      setZipCode(61251);
      setLogo(PLASCO);
    } else if (value === "SULD00001") {
      setSupplierName("Supplier B");
      setAddress1("Address 1 for SULD00001");
      setAddress2("Address 2 for SUPLR1001");
      setAddress3("Address 3 for SUPLR1001");
      setCity("City for SUPLR1001");
      setState("State for SUPLR1001");
      setCountry("Country for SUPLR1001");
      setZipCode("54321");
    } else {
      setSupplierName("");
      setAddress1("");
      setAddress2("");
      setAddress3("");
      setCity("");
      setState("");
      setCountry("");
      setZipCode("");
    }
  };

  const showadd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
  };
  const handleClose = () => {
    setpopUpOpen(false);
    setViewPoDetails(false);
    setViewSupplierDetails(false);
  };
  const HandleSupplier = (rowIndex) => {
    handleSupplierOpen(true);
    setViewSupplierDetails(true);
    // setCurrentData(bindEmployeeMasterDatas[rowIndex]);
  };

  const HandleDetails = (rowIndex) => {
    handleAddressOpen(true);
    setViewDetails(true);
    // setCurrentData(bindEmployeeMasterDatas[rowIndex]);
  };

  const handleAddressOpen = (isOpen) => {
    setViewPoDetails(isOpen);
  };
  const handleSupplierOpen = (isOpen) => {
    setViewSupplierDetails(isOpen);
  };
  const Suppliers = [
    {
      supplier1: {
        Description: 4,
        "Supplier Name": "Bob",
        Email: "Doe",
        Address: "Active",
        "Contact Personemail": "bobdoe@abc.com",
        "Contact Number": "Male",
      },
      supplier2: {
        id: 4,
        first_name: "Bob",
        last_name: "Doe",
        email: "bobdoe@abc.com",
        gender: "Male",
        status: "Active",
      },
    },
  ];

  // const handleAddRow = (inputValue) => {
  //   const newData = {
  //     Purchase_SNo: bindPurchareMasterData.length + 1,
  //     Purchase_ItemCode: inputValue.Purchase_ItemCode,
  //     Purchase_ItemDescription: inputValue.Purchase_ItemDescription,
  //     Purchase_Quantity: inputValue.Purchase_Quantity,
  //     Purchase_Rate: inputValue.Purchase_Rate,
  //     Purchase_Amount: inputValue.Purchase_Amount,
  //   };

  //   const updatedData = [...bindPurchareMasterData, newData];
  //   setbindPurchareMasterData(updatedData);
  // };

  // const handleAddRow = (inputValue) => {
  //   console.log("value", inputValue);
  //   const newData = {
  //     Purchase_SNo: bindPurchareMasterData.length + 1,
  //     Purchase_ItemCode: inputValue.filterValue2,
  //     Purchase_ItemDescription: inputValue.filterValue3,
  //     Purchase_Quantity: inputValue.filterValue4,
  //     Purchase_Rate: inputValue.filterValue5,
  //     Purchase_Amount: inputValue.filterValue6,
  //   };

  //   const updatedData = [...bindPurchareMasterData, newData];
  //   setbindPurchareMasterData(updatedData);
  // };

  const cancel = () => {
    setVisibleTable(true);
    setVisibleAdd(false);
  };
  return (
    <MainLayout>
      <div class="flex">
        {visibleTable && (
          <div class="relative w-full mx-auto">
            <div class={Maintable.headerTitle}>
              <div class="my-2 ml-7">{`Inventory -> Purchase -> Domestic P.O.`}</div>
            </div>
            <div class="lg:mx-8 md:mx-8 mx-4">
              <div className="md:justify-between md:flex  md:items-center">
                <div class={Maintable.searchAdd}>
                  <div className="mt-1 gap-2 flex justify-between items-baseline">
                    <label for="pageSize" class="hidden md:block">
                      Rows Per Page:
                    </label>
                    <select
                      class={Maintable.dropDownRecords}
                      name="pageSize"
                      id="pageSize"
                    >
                      <option value="2">2</option>
                      <option value="10" selected>
                        10
                      </option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <div>
                    <label for="table-search" class="sr-only">
                      Search
                    </label>
                  </div>
                  <div class="relative mt-1">
                    <div class={Maintable.searchIconPosition}>
                      <svg
                        class={Maintable.searchIconSvg}
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <input
                      type="text"
                      id="table-search"
                      class={Maintable.searchInputBox}
                      placeholder="Search for items"
                    />
                  </div>
                  {/* <button class="relative px-5 py-0 overflow-hidden font-medium text-gray-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner group">
                    <span class="absolute top-0 left-0 w-0 h-0 transition-all duration-200 border-t-2 border-gray-600 group-hover:w-full ease"></span>
                    <span class="absolute bottom-0 right-0 w-0 h-0 transition-all duration-200 border-b-2 border-gray-600 group-hover:w-full ease"></span>
                    <span class="absolute top-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-gray-600 group-hover:h-full ease"></span>
                    <span class="absolute bottom-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-gray-600 group-hover:h-full ease"></span>
                    <span class="absolute inset-0 w-full h-full duration-300 delay-300 bg-gray-900 opacity-0 group-hover:opacity-100"></span>
                    <span class="relative transition-colors duration-300 delay-200 group-hover:text-white ease">
                      <svg
                        fill="currentColor"
                        viewBox="0 0 16 16"
                        height="2em"
                        width="2em"
                        //class=" mt-1 bg-gray-800 inline-flex items-center sm:mx-3 justify-center  md:p-1 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                      >
                        <path d="M6 10.5a.5.5 0 01.5-.5h3a.5.5 0 010 1h-3a.5.5 0 01-.5-.5zm-2-3a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5zm-2-3a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5z" />
                      </svg>
                    </span>
                  </button> */}
                </div>
                <div className="flex lg:self-end md:self-end justify-between">
                  <div className="text-red-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner w-fit h-9 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">
                    <span className="mb-12 text-red-600 text-7xl">.</span>
                    <span>Block</span>
                  </div>
                  <button
                    type="button"
                    onClick={showadd}
                    class={Maintable.addButton}
                  >
                    <svg
                      width="25"
                      height="20"
                      viewBox="0 0 50 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22.5 37.5H27.5V27.5H37.5V22.5H27.5V12.5H22.5V22.5H12.5V27.5H22.5V37.5ZM25 50C21.5417 50 18.2917 49.3433 15.25 48.03C12.2083 46.7183 9.5625 44.9375 7.3125 42.6875C5.0625 40.4375 3.28167 37.7917 1.97 34.75C0.656667 31.7083 0 28.4583 0 25C0 21.5417 0.656667 18.2917 1.97 15.25C3.28167 12.2083 5.0625 9.5625 7.3125 7.3125C9.5625 5.0625 12.2083 3.28083 15.25 1.9675C18.2917 0.655833 21.5417 0 25 0C28.4583 0 31.7083 0.655833 34.75 1.9675C37.7917 3.28083 40.4375 5.0625 42.6875 7.3125C44.9375 9.5625 46.7183 12.2083 48.03 15.25C49.3433 18.2917 50 21.5417 50 25C50 28.4583 49.3433 31.7083 48.03 34.75C46.7183 37.7917 44.9375 40.4375 42.6875 42.6875C40.4375 44.9375 37.7917 46.7183 34.75 48.03C31.7083 49.3433 28.4583 50 25 50ZM25 45C30.5833 45 35.3125 43.0625 39.1875 39.1875C43.0625 35.3125 45 30.5833 45 25C45 19.4167 43.0625 14.6875 39.1875 10.8125C35.3125 6.9375 30.5833 5 25 5C19.4167 5 14.6875 6.9375 10.8125 10.8125C6.9375 14.6875 5 19.4167 5 25C5 30.5833 6.9375 35.3125 10.8125 39.1875C14.6875 43.0625 19.4167 45 25 45Z"
                        fill="white"
                      />
                    </svg>
                    ADD
                  </button>
                </div>
              </div>
              <div class="">
                <TableGrid
                  className={Maintable.Table}
                  headerData={domesticPurchaseHeader}
                  tableData={bindUserMasterDatas}
                  // onEditGrid={HandleEdit}
                  // onDeleteGrid={HandleUserDetails}
                  // onSortGrid={HandleSort}
                  // onBlockGrid={HandleUserDetails}
                  //onViewGrid={}
                  // onViewGrid={HandleUserDetails}
                  onAddressGrid={HandleDetails}
                  // onFilterGrid={HandleFilter}
                  // sortColumn={sortColumn}
                  // sortOrder={sortOrder}
                  // viewFilterGrid={viewFilter}
                />
                <div className={Maintable.submitCancelMainDiv}>
                  <button
                    // disabled={currentPage1 === 1}
                    // onClick={() => setCurrentPage(currentPage1 - 1)}
                    type="button"
                    className={Maintable.submitButton}
                  >
                    Previous
                  </button>
                  <label>1</label>
                  <button
                    // disabled={currentPage1 === totalPages / result}
                    // onClick={() => setCurrentPage(currentPage1 + 1)}
                    type="button"
                    className={Maintable.cancelButton}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {visibleAdd && (
          <div class=" flex flex-col w-full">
            <div class={Maintable.clientMasterMainAdd}>
              <div class={Maintable.addScreenTitle}>
                {"Inventory -> Purchase -> Domestic P.O. -> Add"}
              </div>
              <div class={Maintable.addShadowScreen}>
                <div class={Maintable.mainContentScreen}>
                  <form class={Maintable.formForClientMaster}>
                    <div class={Maintable.labelBold}>
                      Purchase Order Details
                    </div>
                    <hr class={Maintable.horizontalLine}></hr>
                    <div class="grid gap-4 mb-6 md:grid-cols-3">
                      <div>
                        <label for="client_id" class={Maintable.label}>
                          P.O. Number
                          <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          id="first_name"
                          // value="PO1001"
                          class={Maintable.input}
                        />
                      </div>
                      <div>
                        <label for="client_name" class={Maintable.label}>
                          P.O. Date
                          <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="date"
                          id="Address1"
                          class={Maintable.input}
                        />
                      </div>
                      <div>
                        <label for="client_name" class={Maintable.label}>
                          Expected Arrival Date
                          <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="date"
                          id="Address1"
                          class={Maintable.input}
                        />
                      </div>
                    </div>

                    <div class={Maintable.labelBold}>Supplier Details</div>
                    <hr class={Maintable.horizontalLine}></hr>
                    <div class="grid gap-4 mb-6 md:grid-cols-3">
                      <div>
                        <label for="client_id" class={Maintable.label}>
                          Supplier Name
                          <span className="text-red-600">*</span>
                        </label>
                        <Select
                          //  Itcode={bindMaterialDD.value.split(-10)}
                          // Itcode2=split{()}
                          options={bindSupplierDD}
                          // options = {bindMaterialDD.value.split(-10)}
                          // onChange={(value) =>
                          // console.log(value.value, value.value.slice(-9))
                          // }
                          onChange={handleSupplierChange}
                          className={Maintable.input1}
                        />
                        {/* <input
                          type="number"
                          id="first_name"
                          class={Maintable.input}
                        /> */}
                        {/* <select
                          className={Maintable.input}
                          value={selectedSupplier}
                          onChange={handleSupplierChange}
                        >
                          <option value="">Select</option>
                          <option value="SULD00001">SULD00001</option>
                          <option value="SULD00002">SULD00002</option>
                          <option value="SULD00003">SULD00003</option>
                          <option value="SULD00004">SULD00004</option>
                          <option value="SULD00005">SULD00005</option>
                        </select> */}
                      </div>

                      <div>
                        <label
                          htmlFor="supplierNameInput"
                          className={Maintable.label}
                        >
                          Supplier ID.
                        </label>
                        {Suppliers.map((Data) => (
                          <input
                            type="text"
                            id="supplierNameInput"
                            name="supplierName"
                            className={Maintable.input}
                            value={supplierName}
                            readOnly
                          />
                        ))}
                      </div>
                      <div>
                        <label htmlFor="Address1" className={Maintable.label}>
                          Address<span className="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          id="address1"
                          className={Maintable.input}
                          value={address1}
                          readOnly
                        />
                      </div>
                      <div>
                        <label htmlFor="Address2" className={Maintable.label}>
                          Address 2
                        </label>
                        <input
                          type="text"
                          id="address2"
                          className={Maintable.input}
                          value={address2}
                          readOnly
                        />
                      </div>
                      <div>
                        <label htmlFor="Address3" className={Maintable.label}>
                          Address 3
                        </label>
                        <input
                          type="text"
                          id="address3"
                          className={Maintable.input}
                          value={address3}
                          readOnly
                        />
                      </div>
                      <div>
                        <label htmlFor="city" className={Maintable.label}>
                          City
                          <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          id="city"
                          className={Maintable.input}
                          value={city}
                          readOnly
                        />
                      </div>
                      <div>
                        <label htmlFor="state" className={Maintable.label}>
                          State
                          <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          id="state"
                          className={Maintable.input}
                          value={state}
                          readOnly
                        />
                      </div>
                      <div>
                        <label htmlFor="Country" className={Maintable.label}>
                          Country
                          <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          id="country"
                          className={Maintable.input}
                          value={country}
                          readOnly
                        />
                      </div>
                      <div>
                        <label htmlFor="zipcode" className={Maintable.label}>
                          Zip/Pin Code
                        </label>
                        <input
                          type="number"
                          id="zipcode"
                          className={Maintable.input}
                          value={zipCode}
                          readOnly
                        />
                      </div>
                      <div>
                        <label class={Maintable.label} for="multiple_files">
                          Logo
                          <span className="text-red-600">*</span>
                        </label>

                        {/* <input
                        name="cli_Logo"
                        onChange={(e) =>{console.log(/image/+e.target.files[0].name); setLogo(e.target.files[0]);const data = new FormData(); data.append("logo", logo);console.log("Image:",logo)}}
                        class={Maintable.fileUpload}
                        id="multiple_files"
                        type="file"
                        multiple
                      /> */}
                        <img
                          alt="PLASOIMG"
                          src={logo}
                          class="w-20 h-16 mt-4 flex"
                        />
                      </div>
                    </div>
                    <div className="flex self-end">
                      <button
                        type="button"
                        onClick={handleAddRow}
                        class={Maintable.addButton}
                      >
                        <svg
                          width="25"
                          height="20"
                          viewBox="0 0 50 50"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M22.5 37.5H27.5V27.5H37.5V22.5H27.5V12.5H22.5V22.5H12.5V27.5H22.5V37.5ZM25 50C21.5417 50 18.2917 49.3433 15.25 48.03C12.2083 46.7183 9.5625 44.9375 7.3125 42.6875C5.0625 40.4375 3.28167 37.7917 1.97 34.75C0.656667 31.7083 0 28.4583 0 25C0 21.5417 0.656667 18.2917 1.97 15.25C3.28167 12.2083 5.0625 9.5625 7.3125 7.3125C9.5625 5.0625 12.2083 3.28083 15.25 1.9675C18.2917 0.655833 21.5417 0 25 0C28.4583 0 31.7083 0.655833 34.75 1.9675C37.7917 3.28083 40.4375 5.0625 42.6875 7.3125C44.9375 9.5625 46.7183 12.2083 48.03 15.25C49.3433 18.2917 50 21.5417 50 25C50 28.4583 49.3433 31.7083 48.03 34.75C46.7183 37.7917 44.9375 40.4375 42.6875 42.6875C40.4375 44.9375 37.7917 46.7183 34.75 48.03C31.7083 49.3433 28.4583 50 25 50ZM25 45C30.5833 45 35.3125 43.0625 39.1875 39.1875C43.0625 35.3125 45 30.5833 45 25C45 19.4167 43.0625 14.6875 39.1875 10.8125C35.3125 6.9375 30.5833 5 25 5C19.4167 5 14.6875 6.9375 10.8125 10.8125C6.9375 14.6875 5 19.4167 5 25C5 30.5833 6.9375 35.3125 10.8125 39.1875C14.6875 43.0625 19.4167 45 25 45Z"
                            fill="white"
                          />
                        </svg>
                        ADD
                      </button>
                    </div>
                    {/* <TableGrid
                      className={Maintable.Table}
                      headerData={purchaseAddHeader}
                      tableData={bindPurchareMasterData}
                      viewFilterGrid={ViewPurchaseInputFiled}
                      onFilterGrid={handleAddRow}
                    />  */}
                    {/* <table className="w-full border border-gray-300 mt-6">
                      <thead>
                        <tr>
                          <th className="border text-white bg-black border-gray-300 p-2">
                            Item Code
                          </th>
                          <th className="border text-white bg-black border-gray-300 p-2">
                            Description
                          </th>
                          <th className="border text-white bg-black border-gray-300 p-2">
                            P.O. Quantity
                          </th>
                          <th className="border text-white bg-black border-gray-300 p-2">
                            U.O.M.
                          </th>
                          <th className="border text-white bg-black border-gray-300 p-2">
                            Rate
                          </th>
                          <th className="border text-white bg-black border-gray-300 p-2">
                            Amount
                          </th>
                          <th className="border text-white bg-black border-gray-300 p-2">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="border border-gray-300 p-2">
                            <select
                              className={Maintable.input}
                              onChange={handleSupplierChange}
                            >
                              <option value="">Select</option>
                              <option value="LDPE-098765G">LDPE-098765G</option>
                              <option value="LDPE-0877668N">LDPE-0877668N</option>
                              <option value="LDPE-0877668M">LDPE-0877668M</option>
                              <option value="LDPE-0877668B">LDPE-0877668B</option>
                            </select>

                          </td>
                          <td className="border border-gray-300 p-2">
                            <input
                              type="text"
                              placeholder="Description"
                              className={Maintable.input}
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            <input
                              type="text"
                              placeholder="P.O. Quantity"
                              className={Maintable.input}
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            <input
                              type="text"
                              placeholder="Received Quantity"
                              className={Maintable.input}
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            <input
                              type="text"
                              placeholder="Receivable Quantity"
                              className={Maintable.input}
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            <input
                              type="text"
                              placeholder="Received"
                              className={Maintable.input}
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                          <AiFillMinusCircle />
                          </td>
                        </tr>
                      </tbody>
                    </table> */}
                    <table className="w-full border border-gray-300 mt-6">
                      <thead>
                        <tr>
                          <th className="border text-white bg-black border-gray-300 p-2 w-32">
                            Item Name
                          </th>
                          <th className="border text-white bg-black border-gray-300 p-2">
                            Item Code
                          </th>
                          <th className="border text-white bg-black border-gray-300 p-2">
                            P.O. Qty.
                          </th>
                          <th className="border text-white bg-black border-gray-300 p-2">
                            U.O.M.
                          </th>
                          <th className="border text-white bg-black border-gray-300 p-2">
                            Rate
                          </th>
                          <th className="border text-white bg-black border-gray-300 p-2">
                            Amount
                          </th>
                          <th className="border text-white bg-black border-gray-300 p-2">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((row, index) => (
                          <tr key={index}>
                            <td className="border border-gray-300 p-2 w-56">
                              {/* <Select
                          
                          options={bindMaterialDD}
                          
                         
                          onChange={(event) => handleInputChange(event, index, 'itemCode')}

                          className={Maintable.input1}
                        /> */}
                              <Select
                                options={bindMaterialDD}
                                value={bindMaterialDD.find(
                                  (option) => option.value === row.itemCode
                                )}
                                onChange={(selectedOption) =>
                                  handleInputChange(
                                    selectedOption,
                                    index,
                                    "itemCode"
                                  )
                                }
                              />

                              {/* <select
                                className={Maintable.input}
                                onChange={e => handleInputChange(e, index, 'itemCode')}
                              >
                                <option value="">Select</option>
                                <option value="RMLD00001">RMLD00001</option>
                                <option value="RMHD00001">RMHD00001</option>
                                <option value="RMIN00001">RMIN00001</option>
                                <option value="RMSL00001">RMSL00001</option>
                              </seelct> */}
                              {/* <input
          type="text"
          placeholder="Item Code"
          className={Maintable.input}
          value={row.itemCode}
          onChange={e => handleInputChange(e, index, 'itemCode')}
        /> */}
                            </td>
                            <td className="border border-gray-300 p-2 w-32">
                              <input
                                type="text"
                                placeholder="Description"
                                className={Maintable.input}
                                value={row.description}
                                onChange={(e) =>
                                  handleInputChange(
                                    e.target.value,
                                    index,
                                    "description"
                                  )
                                }
                                readOnly
                              />
                            </td>
                            <td className="border border-gray-300 p-2 w-28">
                              <input
                                type="text"
                                placeholder="P.O. Qty."
                                className={Maintable.input}
                                value={row.poQuantity}
                                onChange={(e) => {
                                  console.log(
                                    e.target.value,
                                    index,
                                    "poQuantity"
                                  );
                                  handleInputChange(
                                    e.target,
                                    index,
                                    "poQuantity"
                                  );
                                }}
                              />
                            </td>
                            <td className="border border-gray-300 p-2 cursor-not-allowed w-16">
                              <input
                                type="text"
                                placeholder="U.O.M."
                                className={Maintable.input}
                                value={row.uom}
                                onChange={(e) =>
                                  handleInputChange(
                                    e.target.value,
                                    index,
                                    "uom"
                                  )
                                }
                                readOnly
                              />
                            </td>
                            <td className="border border-gray-300 p-2 w-24">
                              <input
                                type="text"
                                placeholder="Rate"
                                className={Maintable.input}
                                value={row.rate}
                                onChange={(e) =>
                                  handleInputChange(e.target, index, "rate")
                                }
                              />
                            </td>
                            <td className="border border-gray-300 p-2 w-40">
                              <input
                                type="text"
                                placeholder="Amount"
                                className={Maintable.input}
                                value={row.amount}
                              />
                            </td>
                            <td className="border border-gray-300 p-2 w-12">
                              <MdCancel
                                class="mx-auto"
                                onClick={() => handleDeleteRow(index)}
                              />
                            </td>

                            {/* Render other cells for each column */}
                            {/* ... */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="text-right">
                      <p class={Maintable.labelAmount}>
                        Total Purchase Order Amount:{" "}
                        {getTotalPurchaseOrderAmount()}
                      </p>
                    </div>
                    <div class="grid gap-4 mb-6 md:grid-cols-2 mt-8">
                      <div>
                        <label class={Maintable.label} for="multiple_files">
                          Payment Terms
                          <span className="text-red-600">*</span>
                        </label>
                        <div class="col-span-1">
                          <textarea rows={4} class={Maintable.input}></textarea>
                        </div>
                      </div>
                      <div>
                        <label class={Maintable.label} for="multiple_files">
                          Delivery Terms
                          <span className="text-red-600">*</span>
                        </label>
                        <div class="col-span-1">
                          <textarea rows={4} class={Maintable.input}></textarea>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div class={Maintable.submitCancelMainDiv}>
                    <button
                      data-modal-hide="bottom-right-modal"
                      type="button"
                      class={Maintable.submitButton}
                    >
                      SUBMIT
                    </button>
                    <button
                      data-modal-hide="bottom-right-modal"
                      type="button"
                      onClick={cancel}
                      class={Maintable.cancelButton}
                    >
                      CANCEL
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <MainPopup
        popUpOpen={viewPoDetails}
        setpopUpOpen={setViewPoDetails}
        Open={HandleDetails}
      >
        <div>
          <button class="w-full">
            <span
              className="text-white flex justify-end"
              onClick={handleClose}
              class="text-2xl p-0 m-o block float-right mr-3"
            >
              &times;
            </span>
          </button>
          <PurchaseDetails data={currentData} />
        </div>
      </MainPopup>
      <MainPopup
        popUpOpen={viewSupplierDetails}
        setpopUpOpen={setViewSupplierDetails}
        Open={HandleSupplier}
        handlePopupClick={true}
      >
        <div>
          <button class="w-full">
            <span
              className="text-white flex justify-end"
              onClick={handleClose}
              class="text-2xl p-0 m-o block float-right mr-3"
            >
              &times;
            </span>
          </button>
          <SupplierDetails data={currentData} />
        </div>
      </MainPopup>
    </MainLayout>
  );
};
export default DomesticPurchase;
