import React, { useState } from "react";
import SideMenu from "./SideMenu";
import NavBar from "./NavBar";

const MainLayout = ({ children }) => {
  const [SidemenuFunction, setSidemenuFunction] = useState(false);
  const ShowSidemenuOpen = () => {
    console.log("karthick", SidemenuFunction);
    setSidemenuFunction(!SidemenuFunction);
  };

  return (
    <div className="flex ">
      <SideMenu SideMenuOpentrue={SidemenuFunction} />
      <div className="flex flex-col w-full">
        <NavBar showSideMenu={ShowSidemenuOpen} />

        <div class="mx-auto md:px-6 w-full">{children}</div>
      </div>
    </div>
  );
};

export default MainLayout;
