import React, { useState } from "react";
import { Maintable } from "../css/Common";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";

const TableGrid = ({
  headerData,
  tableData,
  onEditGrid,
  onViewGrid,
  onGRN,
  onDeleteGrid,
  onSortGrid,
  onBlockGrid,
  onAddressGrid,
  onAddressGrid1,
  onFilterGrid,
  sortOrder,
  sortColumn,
  viewFilterGrid,
  manuallyAddData,
}) => {
  const [count, setCount] = useState(0);
  const [viewsAll, setViewsAll] = useState(false);
  //const [viewsAllTable1, setviewsAllTable1] = useState(true);

  const [grnALL, setGrnALL] = useState(false);
  const [currentRowIndex1, setCurrentRowIndex1] = useState(null);

  const showadd = (views1, rowIndex) => {
    setGrnALL(views1);
    setCurrentRowIndex1(views1 ? rowIndex : null);
  };

  const [tableDatatest, setTableDatatest] = useState([]);
  const [inputData, setInputData] = useState({
    Purchase_ItemCode: "",
    Purchase_ItemDescription: "",
    Purchase_Quantity: "",
    Purchase_Rate: "",
    Purchase_Amount: "",
  });

  const handleAddRow = () => {
    const newRow = {
      Purchase_SNo: tableDatatest.length + 1,
      ...inputData,
    };

    setTableDatatest([...tableDatatest, newRow]);
    setInputData({
      Purchase_ItemCode: "",
      Purchase_ItemDescription: "",
      Purchase_Quantity: "",
      Purchase_Rate: "",
      Purchase_Amount: "",
    });
  };

  const [currentRowIndex, setCurrentRowIndex] = useState(null);

  //const [currentHeaderIndex, setCurrentHeaderIndex] = useState(null);
  const navigate = useNavigate();
  console.log("headerdata", headerData);

  console.log("table", tableData);
  const showPopUp = (views, rowIndex) => {
    console.log("viewall", rowIndex);
    setViewsAll(views);
    setCurrentRowIndex(views ? rowIndex : null);
  };
  console.log("currentIndex", viewsAll, currentRowIndex);

  const [targets, setTargets] = useState({
    filterValue1: "",
    filterValue2: "",
    filterValue3: "",
    filterValue4: "",
    filterValue5: "",
    filterValue6: "",
  });

  const handleChange = (event) => {
    setTargets({
      ...targets,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div className={Maintable.tableShadowWidth}>
      <div className={Maintable.Table} role="table">
        <div className={Maintable.tableHeader} role="rowgroup">
          {headerData.map((item, itemIndex) => (
            <div
              className={item.width}
              //class={Maintable.tableHeaderColumn}
              role="columnheader"
            >
              {/* {sortColumn === item.key && sortOrder === 1 && "▲ "}
              {sortColumn === item.key && sortOrder === 2 && "▼ "} */}
              <label
                // onClick={() => onSortGrid(item.key)}
                class="text-sm  font-medium"
              >
                {item.title}
              </label>

              {viewFilterGrid && item.title === "Action" && (
                <button
                  data-modal-hide="bottom-right-modal"
                  type="button"
                  class={Maintable.filterSubmitBtn}
                  onClick={(e) => onFilterGrid(targets)}
                >
                  Filter Apply
                </button>
              )}

              {viewFilterGrid &&
                item.title !== "S.No" &&
                item.title !== "Action" &&
                item.title !== "Address" &&
                item.title !== "Photo" &&
                item.title !== "Remarks" && (
                  <div>
                    {/* this is for User Master Filter input fileds  */}
                    {item.key === "User_FirstName" && (
                      <input
                        name="filterValue1"
                        type="text"
                        class={item.filterInputBox}
                        value={targets.filterValue1}
                        onChange={(event) => handleChange(event, item.key)}
                      />
                    )}
                    {item.key === "User_LastName" && (
                      <input
                        name="filterValue2"
                        type="text"
                        class={item.filterInputBox}
                        value={targets.filterValue2}
                        onChange={(event) => handleChange(event, item.key)}
                      />
                    )}
                    {item.key === "User_Email" && (
                      <input
                        name="filterValue3"
                        type="text"
                        class={item.filterInputBox}
                        value={targets.filterValue3}
                        onChange={(event) => handleChange(event, item.key)}
                      />
                    )}
                    {item.key === "User_PhoneNumber" && (
                      <input
                        name="filterValue4"
                        type="text"
                        class={item.filterInputBox}
                        value={targets.filterValue4}
                        onChange={(event) => handleChange(event, item.key)}
                      />
                    )}
                    {item.key === "User_Designation" && (
                      <input
                        name="filterValue5"
                        type="text"
                        class={item.filterInputBox}
                        value={targets.filterValue5}
                        onChange={(event) => handleChange(event, item.key)}
                      />
                    )}
                    {item.key === "User_Department" && (
                      <input
                        name="filterValue6"
                        type="text"
                        class={item.filterInputBox}
                        value={targets.filterValue6}
                        onChange={(event) => handleChange(event, item.key)}
                      />
                    )}
                    {/* this is for Project Master Filter input fileds  */}
                    {item.key === "Pro_ClientName" && (
                      <input
                        name="filterValue1"
                        type="text"
                        class={item.filterInputBox}
                        value={targets.filterValue1}
                        onChange={(event) => handleChange(event, item.key)}
                      />
                    )}
                    {item.key === "Pro_ProjectName" && (
                      <input
                        name="filterValue2"
                        type="text"
                        class={item.filterInputBox}
                        value={targets.filterValue2}
                        onChange={(event) => handleChange(event, item.key)}
                      />
                    )}
                    {item.key === "Pro_Version" && (
                      <input
                        name="filterValue3"
                        type="text"
                        class={item.filterInputBox}
                        value={targets.filterValue3}
                        onChange={(event) => handleChange(event, item.key)}
                      />
                    )}
                    {item.key === "Pro_ClientContactPerson" && (
                      <input
                        name="filterValue4"
                        type="text"
                        class={item.filterInputBox}
                        value={targets.filterValue4}
                        onChange={(event) => handleChange(event, item.key)}
                      />
                    )}
                    {item.key === "Pro_ClientEmail" && (
                      <input
                        name="filterValue5"
                        type="text"
                        class={item.filterInputBox}
                        value={targets.filterValue5}
                        onChange={(event) => handleChange(event, item.key)}
                      />
                    )}
                    {item.key === "Pro_ClientContactNumber" && (
                      <input
                        name="filterValue6"
                        type="text"
                        class={item.filterInputBox}
                        value={targets.filterValue6}
                        onChange={(event) => handleChange(event, item.key)}
                      />
                    )}

                    {/* this is for Client Master Filter input fileds  */}
                    {item.key === "Cli_ClientName" && (
                      <input
                        name="filterValue1"
                        type="text"
                        class={item.filterInputBox}
                        value={targets.filterValue1}
                        onChange={(event) => handleChange(event, item.key)}
                      />
                    )}
                    {item.key === "Cli_ContactPerson" && (
                      <input
                        name="filterValue2"
                        type="text"
                        class={item.filterInputBox}
                        value={targets.filterValue2}
                        onChange={(event) => handleChange(event, item.key)}
                      />
                    )}
                    {item.key === "Cli_ContactNumber" && (
                      <input
                        name="filterValue3"
                        type="text"
                        class={item.filterInputBox}
                        value={targets.filterValue3}
                        onChange={(event) => handleChange(event, item.key)}
                      />
                    )}
                    {item.key === "Cli_Email" && (
                      <input
                        name="filterValue4"
                        type="text"
                        class={item.filterInputBox}
                        value={targets.filterValue4}
                        onChange={(event) => handleChange(event, item.key)}
                      />
                    )}

                    {item.key === "Purchase_SNo" && (
                      <input
                        name="filterValue4"
                        type="text"
                        class={item.inputBox}
                        onChange={(event) => handleChange(event, item.key)}
                      />
                    )}
                    {item.key === "Purchase_ItemCode" && (
                      <select class={Maintable.input}>
                        <option>Select</option>
                        <option
                          value={targets.filterValue2}
                          name="filtLDPE-098765G erValue3"
                          onChange={(event) => handleChange(event, item.key)}
                        >
                          LDPE-098765G
                        </option>
                        <option
                          value={targets.filterValue2}
                          name="filterValue3"
                          onChange={(event) => handleChange(event, item.key)}
                        >
                          LDPE-0877668N
                        </option>
                        <option
                          value={targets.filterValue2}
                          name="filterValue3"
                          onChange={(event) => handleChange(event, item.key)}
                        >
                          LDPE-0877668M
                        </option>
                        <option
                          value={targets.filterValue2}
                          name="filterValue3"
                          onChange={(event) => handleChange(event, item.key)}
                        >
                          LDPE-0877668B
                        </option>
                        <option
                          value={targets.filterValue2}
                          name="filterValue3"
                          onChange={(event) => handleChange(event, item.key)}
                        >
                          LDPE-0877668P
                        </option>
                        <option
                          value={targets.filterValue2}
                          name="filterValue3"
                          onChange={(event) => handleChange(event, item.key)}
                        >
                          LDPE-0877668BO
                        </option>
                      </select>
                    )}

                    {item.key === "Purchase_ItemDescription" && (
                      <select class={Maintable.input}>
                        <option>Select</option>
                        <option
                          value={targets.filterValue2}
                          name="filterValue3"
                          onChange={(event) => handleChange(event, item.key)}
                        >
                          Transparent LDPE Granule
                        </option>
                        <option
                          value={targets.filterValue2}
                          name="filterValue3"
                          onChange={(event) => handleChange(event, item.key)}
                        >
                          Reprocessed ldpe granules
                        </option>
                        <option
                          value={targets.filterValue2}
                          name="filterValue3"
                          onChange={(event) => handleChange(event, item.key)}
                        >
                          Blue LDPE Granules
                        </option>
                        <option
                          value={targets.filterValue2}
                          name="filterValue3"
                          onChange={(event) => handleChange(event, item.key)}
                        >
                          Natrual HDPE Granule
                        </option>
                        <option
                          value={targets.filterValue2}
                          name="filterValue3"
                          onChange={(event) => handleChange(event, item.key)}
                        >
                          Reprocessed HDFE Granule
                        </option>
                        <option
                          value={targets.filterValue2}
                          name="filterValue3"
                          onChange={(event) => handleChange(event, item.key)}
                        >
                          Reprocessed HDFE White Granule
                        </option>
                      </select>
                    )}

                    {item.key === "Purchase_Quantity" && (
                      <input
                        name="filterValue4"
                        type="text"
                        class={item.inputBox}
                        value={targets.filterValue4}
                        onChange={(event) => handleChange(event, item.key)}
                      />
                    )}

                    {item.key === "Purchase_Rate" && (
                      <input
                        name="filterValue5"
                        type="text"
                        class={item.inputBox}
                        value={targets.filterValue5}
                        onChange={(event) => handleChange(event, item.key)}
                      />
                    )}

                    {item.key === "Purchase_Amount" && (
                      <input
                        name="filterValue6"
                        type="text"
                        class={item.inputBox}
                        value={targets.filterValue6}
                        onChange={(event) => handleChange(event, item.key)}
                      />
                    )}

                    {item.key === "Purchase_Action" && (
                      <svg
                        viewBox="0 0 1024 1024"
                        fill="currentColor"
                        height="2rem"
                        width="4rem"
                        onClick={() => onFilterGrid(targets)}
                      >
                        <path d="M480 580H372a8 8 0 00-8 8v48a8 8 0 008 8h108v108a8 8 0 008 8h48a8 8 0 008-8V644h108a8 8 0 008-8v-48a8 8 0 00-8-8H544V472a8 8 0 00-8-8h-48a8 8 0 00-8 8v108zm374.6-291.3c6 6 9.4 14.1 9.4 22.6V928c0 17.7-14.3 32-32 32H192c-17.7 0-32-14.3-32-32V96c0-17.7 14.3-32 32-32h424.7c8.5 0 16.7 3.4 22.7 9.4l215.2 215.3zM790.2 326L602 137.8V326h188.2z" />
                      </svg>
                    )}
                  </div>
                )}
            </div>
          ))}
        </div>

        <div className="overflow-y-auto h-96">
          {tableData.length > 0 &&
            tableData.map((data, rowIndex) => (
              <div class={Maintable.tableRow}>
                {headerData.map((keyData) => (
                  <div
                    scope="cell"
                    //class={Maintable.tableData}
                    role="cell"
                    className={keyData.width}
                  >
                    {keyData.key === "address" ||
                    keyData.key === "grn" ||
                    keyData.key === "details" ||
                    keyData.key === "supplier_Address" ||
                    keyData.key === "customer_Address" ? (
                      // <button
                      //   class="border border-black"
                      //   onClick={() => onAddressGrid(rowIndex)}
                      // >
                      //   View
                      // </button>
                      <button
                        onClick={() => onAddressGrid(rowIndex)}
                        class="relative px-5 py-2 overflow-hidden font-medium text-gray-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner group"
                      >
                        <span class="absolute top-0 left-0 w-0 h-0 transition-all duration-200 border-t-2 border-gray-600 group-hover:w-full ease"></span>
                        <span class="absolute bottom-0 right-0 w-0 h-0 transition-all duration-200 border-b-2 border-gray-600 group-hover:w-full ease"></span>
                        <span class="absolute top-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-gray-600 group-hover:h-full ease"></span>
                        <span class="absolute bottom-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-gray-600 group-hover:h-full ease"></span>
                        <span class="absolute inset-0 w-full h-full duration-300 delay-300 bg-gray-900 opacity-0 group-hover:opacity-100"></span>
                        <span class="relative transition-colors duration-300 delay-200 group-hover:text-white ease">
                          View
                        </span>
                      </button>
                    ) : // :keyData.key === "grnview"? (
                    //   // <button
                    //   //   class="border border-black"
                    //   //   onClick={() => onAddressGrid(rowIndex)}
                    //   // >
                    //   //   View
                    //   // </button>
                    //   <button
                    //     onClick={() => onAddressGrid1(rowIndex)}
                    //     class="relative px-5 py-2 overflow-hidden font-medium text-gray-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner group"
                    //   >
                    //     <span class="absolute top-0 left-0 w-0 h-0 transition-all duration-200 border-t-2 border-gray-600 group-hover:w-full ease"></span>
                    //     <span class="absolute bottom-0 right-0 w-0 h-0 transition-all duration-200 border-b-2 border-gray-600 group-hover:w-full ease"></span>
                    //     <span class="absolute top-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-gray-600 group-hover:h-full ease"></span>
                    //     <span class="absolute bottom-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-gray-600 group-hover:h-full ease"></span>
                    //     <span class="absolute inset-0 w-full h-full duration-300 delay-300 bg-gray-900 opacity-0 group-hover:opacity-100"></span>
                    //     <span class="relative transition-colors duration-300 delay-200 group-hover:text-white ease">
                    //       View
                    //     </span>
                    //   </button>

                    // )
                    keyData.key === "action" ? (
                      <div className={keyData.width}>
                        <button
                          id="dropdownMenuIconButton"
                          data-dropdown-toggle="dropdownDots"
                          class="inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                          type="button"
                          onClick={() => showPopUp(!viewsAll, rowIndex)}
                        >
                          <svg
                            class="w-6 h-6"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                          </svg>
                        </button>
                        {viewsAll && currentRowIndex == rowIndex && (
                          <div
                            id="dropdownDots"
                            class="z-10 bg-white absolute right-14 divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
                          >
                            <ul
                              class="py-2 text-sm text-gray-700 dark:text-gray-200"
                              aria-labelledby="dropdownMenuIconButton"
                            >
                              <li>
                                <a
                                  onClick={() => onViewGrid(rowIndex, 1)}
                                  class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                >
                                  View
                                </a>
                              </li>
                              <li>
                                <a
                                  onClick={() => onEditGrid(rowIndex)}
                                  class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                >
                                  Edit
                                </a>
                              </li>
                              <li>
                                <a
                                  onClick={() => onDeleteGrid(rowIndex, 2)}
                                  class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                >
                                  Delete
                                </a>
                              </li>
                              <li>
                                {data.User_Blockedby ||
                                data.Cli_Blockedby ||
                                data.Pro_Blockedby ? (
                                  <a
                                    onClick={() => onBlockGrid(rowIndex, 4)}
                                    class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                  >
                                    Unblock
                                  </a>
                                ) : (
                                  <a
                                    onClick={() => onBlockGrid(rowIndex, 3)}
                                    class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                  >
                                    Block
                                    {/* {(data.Emp_Blockedby === 1) ? "Unblock" : "Block"}*/}
                                  </a>
                                )}
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    ) : keyData.key === "makegrn" ? (
                      <div className={keyData.width}>
                        <button
                          id="dropdownMenuIconButton"
                          data-dropdown-toggle="dropdownDots"
                          class="inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                          type="button"
                          onClick={() => showadd(!grnALL, rowIndex)}
                        >
                          <svg
                            class="w-6 h-6"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                          </svg>
                        </button>
                        {grnALL && currentRowIndex1 == rowIndex && (
                          <div
                            id="dropdownDots"
                            class="z-10 bg-white absolute right-14 divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
                          >
                            <ul
                              class="py-2 text-sm text-gray-700 dark:text-gray-200"
                              aria-labelledby="dropdownMenuIconButton"
                            >
                              <li>
                                <a
                                  onClick={() => onGRN(rowIndex)}
                                  class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                >
                                  Make GRN
                                </a>
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    ) : keyData.key === "Emp_Dateofjoining" ? (
                      data.Emp_Blockedby || data.User_Blockedby ? (
                        <div className={keyData.width1}>
                          {moment(data[keyData.key]).format("DD-MM-YYYY")}
                        </div>
                      ) : (
                        <div className={keyData.width}>
                          {moment(data[keyData.key]).format("DD-MM-YYYY")}
                        </div>
                      )
                    ) : keyData.title === "S.No" ? (
                      data.Emp_Blockedby ||
                      data.User_Blockedby ||
                      data.Cli_Blockedby ||
                      data.Pro_Blockedby ||
                      data.Sly_Blockedby ? (
                        <div className={keyData.width1}>{rowIndex + 1}</div>
                      ) : (
                        <div className={keyData.width}>{rowIndex + 1}</div>
                      )
                    ) : keyData.key === "Cli_Logo" ? (
                      <input
                        type="image"
                        src={data[keyData.key]}
                        alt="Image"
                        className="rounded-full w-12 h-12"
                        width={keyData.width}
                      />
                    ) : data.User_Blockedby ||
                      data.Cli_Blockedby ||
                      data.Pro_Blockedby ? (
                      <div className={keyData.width1}>{data[keyData.key]}</div>
                    ) : (
                      (
                        <div className={keyData.width}>{data[keyData.key]}</div>
                      ) || (
                        <div className={keyData.width}>
                          {data.Purchase_Amount}
                        </div>
                      )
                    )}
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>

      <div className={Maintable.Table1} role="table">
        <div className=" w-full md:overflow-y-auto lg:h-96 h-full">
          {tableData.length > 0 &&
            tableData.map((data, rowIndex) => (
              <div scope="col" class={Maintable.mobileTable}>
                {headerData.map((keyData) => (
                  <div scope="cell" class={Maintable.tableData} role="cell">
                    {keyData.key === "address"  ? (
                      <div className="flex border p-1">
                        <div className="flex basis-1/2 font-bold">
                          {keyData.title}
                        </div>{" "}
                        <div className="flex basis-1/2">
                          <button
                            onClick={() => onAddressGrid(rowIndex)}
                            class="relative px-5 py-2 overflow-hidden font-medium text-gray-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner group"
                          >
                            <span class="absolute top-0 left-0 w-0 h-0 transition-all duration-200 border-t-2 border-gray-600 group-hover:w-full ease"></span>
                            <span class="absolute bottom-0 right-0 w-0 h-0 transition-all duration-200 border-b-2 border-gray-600 group-hover:w-full ease"></span>
                            <span class="absolute top-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-gray-600 group-hover:h-full ease"></span>
                            <span class="absolute bottom-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-gray-600 group-hover:h-full ease"></span>
                            <span class="absolute inset-0 w-full h-full duration-300 delay-300 bg-gray-900 opacity-0 group-hover:opacity-100"></span>
                            <span class="relative transition-colors duration-300 delay-200 group-hover:text-white ease">
                              View
                            </span>
                          </button>
                        </div>
                      </div>
                    ) : keyData.key === "action" ? (
                      <div class={Maintable.tableData}>
                        <div className="md:block hidden">
                          <button
                            id="dropdownMenuIconButton"
                            data-dropdown-toggle="dropdownDots"
                            class="inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                            type="button"
                            onClick={() => showPopUp(!viewsAll, rowIndex)}
                          >
                            <svg
                              class="w-6 h-6"
                              aria-hidden="true"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                            </svg>
                          </button>
                        </div>
                        {/* {viewsAllTable1 && currentRowIndex == rowIndex && ( */}
                          <div className="md:hidden  flex justify-center">
                            <button
                              className="bg-[#213F99] text-white mr-1 p-1 rounded-lg"
                              onClick={() => onViewGrid(rowIndex, 1)}
                            >
                              View
                            </button>
                            <button
                              className="bg-[#213F99] text-white mr-1 p-1 rounded-lg"
                              onClick={() => onEditGrid(rowIndex)}
                            >
                              Edit
                            </button>
                            <button
                              className="bg-[#213F99] text-white mr-1 p-1 rounded-lg"
                              onClick={() => onDeleteGrid(rowIndex, 2)}
                            >
                              Delete
                            </button>
                            <button
                              className="bg-[#213F99] text-white mr-1 p-1 rounded-lg"
                              onClick={() => onBlockGrid(rowIndex, 3)}
                            >
                              {data.User_Blockedby ? "Unblock" : "Block"}
                            </button>
                          </div>
                        {/* )} */}
                      </div>
                    ) : keyData.key === "User_Image" ||
                      keyData.key === "Cli_Logo" ? (
                      <div className="flex justify-center ">
                        <input
                          type="image"
                          src={data[keyData.key]}
                          alt="Image"
                          className="rounded-full  w-20 md:w-12 md:h-12"
                        />
                      </div>
                    ) : keyData.title === "S.No" ? (
                      <div class="hidden"></div>
                    ) : keyData.key === "Emp_Image" ? (
                      <div class="flex justify-center"></div>
                    ) : keyData.key === "Emp_Dateofjoining" ? (
                      <div className="flex border p-1">
                        <div className="flex basis-1/2 font-bold">
                          {keyData.title}
                        </div>{" "}
                        <div className="flex basis-1/2">
                          {moment(data[keyData.key]).format("L")}
                        </div>
                      </div>
                    ) : keyData.key === "Raw_Description" ? (
                      <div class="relative">
                        <div class="w-16 h-16 bg-gray-200 rounded-md flex items-center justify-center">
                          <div
                            class="opacity-0 relative inset-0 flex items-center justify-center"
                            title={data[keyData.key].Raw_Description}
                          >
                            {data.Raw_Description}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="flex border p-1">
                        <div className="flex basis-1/2 font-bold">
                          {keyData.title}
                        </div>{" "}
                        <div className="flex basis-1/2">
                          {data[keyData.key]}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default TableGrid;
