import React from "react";

const MainPopup = ({ children, popUpOpen, setpopUpOpen, forgetPopup }) => {

  const handleClose = () => {
    setpopUpOpen(false);

  };

  const handlePopupClick = (e) => {
    e.stopPropagation();
  };



  return (
    <div
      class={`fixed inset-0 flex z-[999] ${popUpOpen ? "block" : "hidden"} pointer-events-auto bg-[#00000080]`}
      onClick={handleClose}
    >
      <div class={`lg:w-10/12 m-auto lg:mx-80 lg:mr-52 md:mx-auto md:my-auto ${forgetPopup ? " lg:mr-96 md:mx-auto lg:mx-96" : ""} sm:ml-10 lg:mr-20  relative`} onClick={handlePopupClick}>
        <div class="relative bg-white rounded-lg shadow-2xl shadow-gray-400 dark:bg-gray-700">
          {children}
        </div>
      </div>
    </div>
  );
};

export default MainPopup;
