import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../container/login";
import SignUp from "../container/SignUp";
import Dashboard from "../container/Dashboard";
import Products from "../container/Products";
import GRN from "../container/GRN";
import ReturntoSupplier from "../container/ReturntoSupplier";
import TransferOtherDepartment from "../container/TransferOtherDepartment";
import RMplaning from "../container/RMplaning";
import ShrinkFlim from "../container/ShrinkFlim";
import PlanShrinkFlim from "../container/PlanShrinkFlim";
import UserMaster from "../container/UserMaster";
import MainLayout from "../components/Mainlayout";
import RoleVsCapability from "../container/RoleVsCapability";
import Role from "../container/Role";
import CustomerMaster from "../container/CustomerMaster";
import DomesticPurchase from "../container/DomesticPurchase";
import RmManagement from "../container/RmManagement";
import FgManagement from "../container/FgManagement";
import SystemSettings from "../container/SystemSettings";
import SupplierMaster from "../container/SupplierMaster";
import MyProfile from "../container/MyProfile";
import EditProfile from "../container/EditProfile";
import Settings from "../container/Settings";
import PurchaseTable from "../components/PurchaseTable";
import UnitOfMeasure from "../container/UnitOfMeasure";
import RawMaterialMST from "../container/RawMaterialMST";
import MachineMaster from "../container/MachineMaster";
import IssuetoProduction1 from "../container/IssuetoProduction1";
import IssuetoProduction from "../container/IssuetoProduction";
import InjectionMoulding from "../container/InjectionMoulding";
import DepartmentMST from "../container/DepartmentMST";
import PieChart from "../popup-containers/Piechart";
import PrintedShrinkFilm from "../container/PrintedShrinkFilm";
import PrintedShrinkFilmSlitting from "../container/PrintedShrinkFilmSlitting";
import PrintedShrinkFilmLamination from "../container/PrintedShrinkFilmLamination";
import POReportPending from "../container/POReportPending";
import POorderRegister from "../container/POorderRegister";
import EmployeeMaster1 from "../container/EmployeeMaster";
import RMStack from "../container/RMStack";
import RMStackLedger from "../container/RMStackLedger";
import FGStack from "../container/FGStack";
import FGStackLedger from "../container/FGStackLedger";
import IssueReport from "../container/IssueReport";
import PlainShrinkfilmReport from "../container/PlainShrinkfilmReport";
import PrintedShrinkFilmReport from "../container/PrintedShrinkFilmReport";
import PrintedShrinkFilmSlittingReport from "../container/PrintedShrinkFilmSlittingReport";
import PrintedShrinkFilmLaminationReport from "../container/PrintedShrinkFilmLaminationReport";

function RootRouter() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/mainlayout" element={<MainLayout />} />
        <Route path="/products" element={<Products />} />
        <Route path="/grn" element={<GRN />} />
        <Route path="/rtnSupplier" element={<ReturntoSupplier />} />
        <Route path="/issuetoproduction" element={<IssuetoProduction />} />
        <Route path="/issueofproduction" element={<IssuetoProduction1 />} />
        <Route path="/injectionmoulding" element={<InjectionMoulding />} />

        <Route
          path="/transferotherdepartment"
          element={<TransferOtherDepartment />}
        />
        <Route path="/rmplanning" element={<RMplaning />} />
        <Route path="/shrinkflim" element={<ShrinkFlim />} />
        <Route path="/planshrinkfilm" element={<PlanShrinkFlim />} />
        <Route path="/usermaster" element={<UserMaster />} />
        <Route path="/role" element={<Role />} />
        <Route path="/domesticpurchase" element={<DomesticPurchase />} />
        <Route path="/customermaster" element={<CustomerMaster />} />
        <Route path="/rmmanagement" element={<RmManagement />} />
        <Route path="/fgmanagement" element={<FgManagement />} />
        <Route path="/rolevscapability" element={<RoleVsCapability />} />
        <Route path="/systemsettings" element={<SystemSettings />} />
        <Route path="/suppliermaster" element={<SupplierMaster />} />
        <Route path="/myprofile" element={<MyProfile />} />
        <Route path="/editprofile" element={<EditProfile />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/purchasetable" element={<PurchaseTable />} />
        <Route path="/unitofmeasure" element={<UnitOfMeasure />} />
        <Route path="/raw_matrialmaster" element={<RawMaterialMST />} />
        <Route path="/machinemaster" element={<MachineMaster />} />
        <Route path="/departmentMaster" element={<DepartmentMST />} />
        <Route path="/piechart" element={<PieChart />} />
        <Route path="/employeeMaster" element={<EmployeeMaster1 />} />
        <Route path="/printedshrinkfilm" element={<PrintedShrinkFilm />} />
        <Route
          path="/printedshrinkfilmslitting"
          element={<PrintedShrinkFilmSlitting />}
        />
        <Route
          path="/printedshrinkfilmlamination"
          element={<PrintedShrinkFilmLamination />}
        />
        <Route path="/pendingpo" element={<POReportPending />} />
        <Route path="/purchaseorderreport" element={<POorderRegister />} />
        <Route path="/rmstack" element={<RMStack />} />
        <Route path="/rmstackledger" element={<RMStackLedger />} />
        <Route path="/fgstack" element={<FGStack />} />
        <Route path="/fgstackledger" element={<FGStackLedger />} />
        <Route path="/issuereport" element={<IssueReport />} />
        <Route path="/planshrinkfilmreport" element={<PlainShrinkfilmReport />} />
        <Route path="/printedshrinkfilmreport" element={<PrintedShrinkFilmReport />} />
        <Route path="/printedshrinkfilmslittingreport" element={<PrintedShrinkFilmSlittingReport />} />
        <Route path="/printedshrinkfilmlaminationReport" element={<PrintedShrinkFilmLaminationReport />} />
      </Routes>
    </div>
  );
}

export default RootRouter;
