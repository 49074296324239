import React from "react";
import { Maintable } from "../css/Common";

const EmployeeAddress = ({ data }) => {
  console.log("Address", data);

  return (
    <div>
      <div class="w-full">
        <div>
          <div class="text-xl  mx-14 font-medium text-neutral-700 dark:text-white">
            {"Employee Master -> Address View"}
          </div>
          <div>
            <div class={Maintable.mainContentScreen}>
              <form class={Maintable.formForClientMaster}>
                <div class={Maintable.labelBold}>Employee Details</div>
                <hr class={Maintable.horizontalLine}></hr>
                <div class={Maintable.gird3}>
                  <div>
                    <label for="employee_no" class={Maintable.label}>
                      Employee ID<span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      name="emp_EmployeeId"
                      value={data.Emp_EmployeeId}
                      id="first_name"
                      class={Maintable.input}
                      placeholder="XXX0011"
                    />
                  </div>
                  <div>
                    <label for="employee_name" class={Maintable.label}>
                      Employee Name<span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      name="emp_EmployeeName"
                      value={data.Emp_EmployeeName}
                      class={Maintable.input}
                      //onChange={(e) => {
                      //   setEmp_EmployeeName(e.target.value);
                      // }}
                      id="first_name"
                      //class={Maintable.input}

                      placeholder="XXX0011"
                    />
                  </div>
                  <div>
                    <label for="father_name" class={Maintable.label}>
                      Father Name<span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      name="emp_FatherName"
                      value={data.Emp_FatherName}
                      //onChange={(e) => setEmp_FatherName(e.target.value)}
                      id="last_name"
                      class={Maintable.input}
                      placeholder="Mani"
                    />
                  </div>
                </div>

                <div class={Maintable.labelBold}>Permanent Address</div>
                <hr class={Maintable.horizontalLine}></hr>
                <div class={Maintable.gird3}>
                  <div>
                    <label for="address1" class={Maintable.label}>
                      Address 1<span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      name="emp_Permanent_Address1"
                      value={data.Emp_Permanent_Address1}
                      //onChange={(e) =>
                      //   setEmp_Permanent_Address1(e.target.value)
                      // }
                      id="last_name"
                      class={Maintable.input}
                      placeholder="86,Ambedkar Nager"
                    />
                  </div>
                  <div>
                    <label for="address_2" class={Maintable.label}>
                      Address 2
                    </label>
                    <input
                      type="text"
                      name="emp_Permanent_Address2"
                      value={data.Emp_Permanent_Address2}
                      //onChange={(e) =>
                      //   setEmp_Permanent_Address2(e.target.value)
                      // }
                      id="company"
                      class={Maintable.input}
                      placeholder="Mosur"
                    />
                  </div>
                  <div>
                    <label for="address_3" class={Maintable.label}>
                      Address 3
                    </label>
                    <input
                      type="text"
                      name="emp_Permanent_Address3"
                      value={data.Emp_Permanent_Address3}
                      //onChange={(e) =>
                      //   setEmp_Permanent_Address3(e.target.value)
                      // }
                      id="company"
                      class={Maintable.input}
                      placeholder="Arakkonam"
                    />
                  </div>
                  <div>
                    <label for="city" class={Maintable.label}>
                      City<span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      name="emp_Permanent_City"
                      value={data.Emp_Permanent_City}
                      //onChange={(e) => setEmp_Permanent_City(e.target.value)}
                      id="company"
                      class={Maintable.input}
                      placeholder="Arakkonam"
                    />
                  </div>
                  <div>
                    <label for="state" class={Maintable.label}>
                      State<span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      name="emp_Permanent_State"
                      value={data.Emp_Permanent_State}
                      //onChange={(e) => setEmp_Permanent_State(e.target.value)}
                      id="company"
                      class={Maintable.input}
                      placeholder="tamilnadu"
                    />
                  </div>
                  <div>
                    <label for="country" class={Maintable.label}>
                      Country<span className="text-red-600">*</span>
                    </label>
                    <input
                      type="text"
                      name="emp_Permanent_Country"
                      value={data.Emp_Permanent_Country}
                      //onChange={(e) => setEmp_Permanent_Country(e.target.value)}
                      id="company"
                      class={Maintable.input}
                      placeholder="India"
                    />
                  </div>
                  <div>
                    <label for="zipcode" class={Maintable.label}>
                      Zip Code
                    </label>
                    <input
                      type="text"
                      name="emp_Permanent_ZipCode"
                      value={data.Emp_Permanent_ZipCode}
                      //onChange={(e) => setEmp_Permanent_ZipCode(e.target.value)}
                      id="company"
                      class={Maintable.input}
                      placeholder="6543002"
                    />
                  </div>
                </div>

                <div class={Maintable.labelBold}>Emergency Contact Info</div>
                <hr class={Maintable.horizontalLine}></hr>
                <div class={Maintable.gird3}>
                  <div>
                    <label for="company" class={Maintable.label}>
                      Contact Name
                    </label>
                    <input
                      type="text"
                      name="emp_EmemergencyContactName"
                      value={data.Emp_EmemergencyContactName}
                      //onChange={(e) =>
                      //   setEmp_EmemergencyContactName(e.target.value)
                      // }
                      id="name"
                      class={Maintable.input}
                      placeholder="Gokul"
                    />
                  </div>
                  <div>
                    <label for="company" class={Maintable.label}>
                      Contact Relationship
                    </label>
                    <input
                      type="text"
                      name="emp_EmemergencyContactRelationship"
                      value={data.Emp_EmemergencyContactRelationship}
                      //onChange={(e) =>
                      //   setEmp_EmemergencyContactRelationship(e.target.value)
                      // }
                      id="name"
                      class={Maintable.input}
                      placeholder="Father"
                    />
                  </div>
                  <div>
                    <label for="company" class={Maintable.label}>
                      Contact Address
                    </label>
                    <input
                      type="text"
                      name="emp_EmemergencyContactAddress"
                      value={data.Emp_EmemergencyContactAddress}
                      //onChange={(e) =>
                      //   setEmp_EmemergencyContactAddress(e.target.value)
                      // }
                      id="name"
                      class={Maintable.input}
                      placeholder="20, Gandhi Street, Chennai-64"
                    />
                  </div>
                  <div>
                    <label for="company" class={Maintable.label}>
                      Contact Phone
                    </label>
                    <input
                      type="tel"
                      name="emp_EmemergencyContactPhone"
                      value={data.Emp_EmemergencyContactPhone}
                      //onChange={(e) =>
                      //   setEmp_EmemergencyContactPhone(e.target.value)
                      // }
                      id="phone"
                      class={Maintable.input}
                      placeholder="123-45-678"
                      pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                    />
                  </div>
                  <div>
                    <label for="company" class={Maintable.label}>
                      Contact Mobile
                    </label>
                    <input
                      type="tel"
                      name="emp_EmemergencyContact_Mobile"
                      value={data.Emp_EmemergencyContact_Mobile}
                      //onChange={(e) =>
                      //   setEmp_EmemergencyContact_Mobile(e.target.value)
                      // }
                      id="phone"
                      class={Maintable.input}
                      placeholder="+91 6385636425"
                      pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                    />
                  </div>
                  <div>
                    <label for="company" class={Maintable.label}>
                      Contact Email
                    </label>
                    <input
                      type="email"
                      name="emp_EmemergencyContactEmailId"
                      value={data.Emp_EmemergencyContactEmailId}
                      //onChange={(e) =>
                      //   setEmp_EmemergencyContactEmailId(e.target.value)
                      // }
                      id="company"
                      class={Maintable.input}
                      placeholder="karthick@xlicon.in"
                    />
                  </div>
                </div>
                <div class={Maintable.labelBold}>Temporary Address</div>
                <hr class={Maintable.horizontalLine}></hr>
                <div class={Maintable.gird3}>
                  <div>
                    <label for="address1" class={Maintable.label}>
                      Address 1
                    </label>
                    <input
                      type="text"
                      name="emp_Temp_Address1"
                      value={data.Emp_Temp_Address1}
                      //onChange={(e) => setEmp_Temp_Address1(e.target.value)}
                      id="last_name"
                      class={Maintable.input}
                      placeholder="86,Ambedkar Nager"
                    />
                  </div>
                  <div>
                    <label for="address_2" class={Maintable.label}>
                      Address 2
                    </label>
                    <input
                      type="text"
                      name="emp_Temp_Address2"
                      value={data.Emp_Temp_Address2}
                      //onChange={(e) => setEmp_Temp_Address2(e.target.value)}
                      id="company"
                      class={Maintable.input}
                      placeholder="Mosur"
                    />
                  </div>
                  <div>
                    <label for="address_3" class={Maintable.label}>
                      Address 3
                    </label>
                    <input
                      type="text"
                      name="emp_Temp_Address3"
                      value={data.Emp_Temp_Address3}
                      //onChange={(e) => setEmp_Temp_Address3(e.target.value)}
                      id="company"
                      class={Maintable.input}
                      placeholder="Arakkonam"
                    />
                  </div>
                  <div>
                    <label for="city" class={Maintable.label}>
                      City
                    </label>
                    <input
                      type="text"
                      name="emp_Temp_City"
                      value={data.Emp_Temp_City}
                      //onChange={(e) => setEmp_Temp_City(e.target.value)}
                      id="company"
                      class={Maintable.input}
                      placeholder="Arakkonam"
                    />
                  </div>
                  <div>
                    <label for="state" class={Maintable.label}>
                      State
                    </label>
                    <input
                      type="text"
                      name="emp_Temp_State"
                      value={data.Emp_Temp_State}
                      //onChange={(e) => setEmp_Temp_State(e.target.value)}
                      id="company"
                      class={Maintable.input}
                      placeholder="tamilnadu"
                    />
                  </div>
                  <div>
                    <label for="country" class={Maintable.label}>
                      Country
                    </label>
                    <input
                      type="text"
                      name="emp_Temp_Country"
                      value={data.Emp_Temp_Country}
                      //onChange={(e) => setEmp_Temp_Country(e.target.value)}
                      id="company"
                      class={Maintable.input}
                      placeholder="India"
                    />
                  </div>
                  <div>
                    <label for="zipcode" class={Maintable.label}>
                      Zip Code
                    </label>
                    <input
                      type="text"
                      name="emp_Temp_ZipCode"
                      value={data.Emp_Temp_ZipCode}
                      //onChange={(e) => setEmp_Temp_ZipCode(e.target.value)}
                      id="company"
                      class={Maintable.input}
                      placeholder="8743783"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeAddress;
