import React from "react";
import MainLayout from "../components/Mainlayout";

const Settings = () => {
  return (
    <MainLayout>
      <div>Settings </div>
    </MainLayout>
  );
};

export default Settings;