import React from "react";
import { Maintable } from "../css/Common";
import MainLayout from "../components/Mainlayout";
// import { Common } from '../css/Common'
// import { maincontent } from '../css/Common'

// const Common = {
//     Main: 'w-full p-8 leading-8 flex flex-col',
//     Mainheading: ' text-2xl font-bold my-5',
//     Label: 'text-sm text-black mb-2',
//     Input: 'border border-black text-white rounded-lg mb-2',
//     Text: 'text-sm text-black h-9 mb-2',
//     Backbtn: 'w-40 h-8 border border-black rounded-lg text-black',
//     Nxtbtn: 'w-40 h-8 rounded-lg bg-[#3C5DC7] text-white'
// }

function RoleVsCapability() {
  return (
    <MainLayout>
      
        <div className="relative w-full">
          <div className="border-black">
            <div className="text-black text-2xl font-bold mx-8">
              <div className="mt-5">Role vs Capability</div>
            </div>
          </div>

          <div className="mx-8">
            <div className="text-black text-xl font-medium">
              <div className="mt-3 pb-2">Role </div>
            </div>
            <div className={Maintable.tableShadowWidth}>
              <div className={Maintable.searchAdd}>
                <label for="table-search" className="sr-only">
                  Enter Role
                </label>
                <div className="relative mt-1">
                  <select
                    id="table-search"
                    className={Maintable.searchInputBox}
                    placeholder="Search for items"
                  >
                    <option value="Admin">Admin</option>
                    <option value="User">User</option>
                  </select>
                </div>
              </div>
              <table className={Maintable.Table}>
                <thead className={Maintable.tableHeader}>
                  <tr>
                    <th scope="col" className="px-6 py-3"  width="50%">
                      <div className="flex items-center ">
                        <input
                          id="default-checkbox"
                          type="checkbox"
                          value=""
                          className={Maintable.largeCheckbox}
                        />
                        <label
                          for="default-checkbox"
                          className=" text-sm font-medium text-white dark:text-gray-300"
                        >
                          Select All Menus
                        </label>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3" width="100%">
                      <div className="flex items-center ">
                        <input
                          id="default-checkbox"
                          type="checkbox"
                          value=""
                          className={Maintable.largeCheckbox}
                        />
                        <label
                          for="default-checkbox"
                          className=" text-sm font-medium text-white dark:text-gray-300"
                        >
                          Rights
                        </label>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className={Maintable.tableRow}>
                    <td className="px-6 flex items-center " width="50%">
                      <div className="">
                        <input
                          id="default-checkbox"
                          type="checkbox"
                          value=""
                          className={Maintable.largeCheckbox}
                        />
                        <label
                          for="default-checkbox"
                          className={Maintable.rolecap_label}
                        >
                          Dashboard
                        </label>
                      </div>
                    </td>
                    <td className="px-3" width="100%">
                      <ul className="flex w-auto text-sm font-medium rounded-lg dark:text-white">
                        <li className="w-full rounded-t-lg">
                          <div className="flex items-center pl-3">
                            <input
                              id="vue-checkbox"
                              type="checkbox"
                              value=""
                              className={Maintable.smallCheckBox}
                            />
                            <label
                              for="vue-checkbox"
                              className={Maintable.smailCheckboxLabel}
                            >
                              View
                            </label>
                          </div>
                        </li>
                        <li className="w-full rounded-t-lg">
                          <div className="flex items-center pl-3">
                            <input
                              id="react-checkbox"
                              type="checkbox"
                              value=""
                              className={Maintable.smallCheckBox}
                            />
                            <label
                              for="react-checkbox"
                              className={Maintable.smailCheckboxLabel}
                            >
                              Add
                            </label>
                          </div>
                        </li>
                        <li className="w-full rounded-t-lg">
                          <div className="flex items-center pl-3">
                            <input
                              id="angular-checkbox"
                              type="checkbox"
                              value=""
                              className={Maintable.smallCheckBox}
                            />
                            <label
                              for="angular-checkbox"
                              className={Maintable.smailCheckboxLabel}
                            >
                              Edit
                            </label>
                          </div>
                        </li>
                        <li className="w-full rounded-t-lg">
                          <div className="flex items-center pl-3">
                            <input
                              id="laravel-checkbox"
                              type="checkbox"
                              value=""
                              className={Maintable.smallCheckBox}
                            />
                            <label
                              for="laravel-checkbox"
                              className={Maintable.smailCheckboxLabel}
                            >
                              Delete
                            </label>
                          </div>
                        </li>
                        <li className="w-full rounded-t-lg">
                          <div className="flex items-center pl-3">
                            <input
                              id="laravel-checkbox"
                              type="checkbox"
                              value=""
                              className={Maintable.smallCheckBox}
                            />
                            <label
                              for="laravel-checkbox"
                              className={Maintable.smailCheckboxLabel}
                            >
                              Block
                            </label>
                          </div>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr className={Maintable.tableRow}>
                    <td className="px-6 flex items-center " width="50%">
                      <div className="flex items-center ">
                        <input
                          id="default-checkbox"
                          type="checkbox"
                          value=""
                          className={Maintable.largeCheckbox}
                        />
                        <label
                          for="default-checkbox"
                          className={Maintable.rolecap_label}
                        >
                          Employee Master
                        </label>
                      </div>
                    </td>
                    <td className="px-3" width="100%">
                      <ul className="flex w-auto text-sm font-medium rounded-lg dark:text-white">
                        <li className="w-full rounded-t-lg">
                          <div className="flex items-center pl-3">
                            <input
                              id="vue-checkbox"
                              type="checkbox"
                              value=""
                              className={Maintable.smallCheckBox}
                            />
                            <label
                              for="vue-checkbox"
                              className={Maintable.smailCheckboxLabel}
                            >
                              View
                            </label>
                          </div>
                        </li>
                        <li className="w-full rounded-t-lg">
                          <div className="flex items-center pl-3">
                            <input
                              id="react-checkbox"
                              type="checkbox"
                              value=""
                              className={Maintable.smallCheckBox}
                            />
                            <label
                              for="react-checkbox"
                              className={Maintable.smailCheckboxLabel}
                            >
                              Add
                            </label>
                          </div>
                        </li>
                        <li className="w-full rounded-t-lg">
                          <div className="flex items-center pl-3">
                            <input
                              id="angular-checkbox"
                              type="checkbox"
                              value=""
                              className={Maintable.smallCheckBox}
                            />
                            <label
                              for="angular-checkbox"
                              className={Maintable.smailCheckboxLabel}
                            >
                              Edit
                            </label>
                          </div>
                        </li>
                        <li className="w-full rounded-t-lg">
                          <div className="flex items-center pl-3">
                            <input
                              id="laravel-checkbox"
                              type="checkbox"
                              value=""
                              className={Maintable.smallCheckBox}
                            />
                            <label
                              for="laravel-checkbox"
                              className={Maintable.smailCheckboxLabel}
                            >
                              Delete
                            </label>
                          </div>
                        </li>
                        <li className="w-full rounded-t-lg">
                          <div className="flex items-center pl-3">
                            <input
                              id="laravel-checkbox"
                              type="checkbox"
                              value=""
                              className={Maintable.smallCheckBox}
                            />
                            <label
                              for="laravel-checkbox"
                              className={Maintable.smailCheckboxLabel}
                            >
                              Block
                            </label>
                          </div>
                        </li>
                      </ul>
                    </td>
                  </tr>

                  <tr className={Maintable.tableRow}>
                    <td className="px-6 flex items-center " width="50%">
                      <div className="flex items-center ">
                        <input
                          id="default-checkbox"
                          type="checkbox"
                          value=""
                          className={Maintable.largeCheckbox}
                        />
                        <label
                          for="default-checkbox"
                          className={Maintable.rolecap_label}
                        >
                          Client Master
                        </label>
                      </div>
                    </td>
                    <td className="px-3" width="100%">
                      <ul className="flex w-auto text-sm font-medium rounded-lg dark:text-white">
                        <li className="w-full rounded-t-lg">
                          <div className="flex items-center pl-3">
                            <input
                              id="vue-checkbox"
                              type="checkbox"
                              value=""
                              className={Maintable.smallCheckBox}
                            />
                            <label
                              for="vue-checkbox"
                              className={Maintable.smailCheckboxLabel}
                            >
                              View
                            </label>
                          </div>
                        </li>
                        <li className="w-full rounded-t-lg">
                          <div className="flex items-center pl-3">
                            <input
                              id="react-checkbox"
                              type="checkbox"
                              value=""
                              className={Maintable.smallCheckBox}
                            />
                            <label
                              for="react-checkbox"
                              className={Maintable.smailCheckboxLabel}
                            >
                              Add
                            </label>
                          </div>
                        </li>
                        <li className="w-full rounded-t-lg">
                          <div className="flex items-center pl-3">
                            <input
                              id="angular-checkbox"
                              type="checkbox"
                              value=""
                              className={Maintable.smallCheckBox}
                            />
                            <label
                              for="angular-checkbox"
                              className={Maintable.smailCheckboxLabel}
                            >
                              Edit
                            </label>
                          </div>
                        </li>
                        <li className="w-full rounded-t-lg">
                          <div className="flex items-center pl-3">
                            <input
                              id="laravel-checkbox"
                              type="checkbox"
                              value=""
                              className={Maintable.smallCheckBox}
                            />
                            <label
                              for="laravel-checkbox"
                              className={Maintable.smailCheckboxLabel}
                            >
                              Delete
                            </label>
                          </div>
                        </li>
                        <li className="w-full rounded-t-lg">
                          <div className="flex items-center pl-3">
                            <input
                              id="laravel-checkbox"
                              type="checkbox"
                              value=""
                              className={Maintable.smallCheckBox}
                            />
                            <label
                              for="laravel-checkbox"
                              className={Maintable.smailCheckboxLabel}
                            >
                              Block
                            </label>
                          </div>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr className={Maintable.tableRow}>
                    <td className="px-6 flex items-center " width="50%">
                      <div className="flex items-center ">
                        <input
                          id="default-checkbox"
                          type="checkbox"
                          value=""
                          className={Maintable.largeCheckbox}
                        />
                        <label
                          for="default-checkbox"
                          className={Maintable.rolecap_label}
                        >
                          Role
                        </label>
                      </div>
                    </td>
                    <td className="px-3" width="100%">
                      <ul className="flex w-auto text-sm font-medium rounded-lg dark:text-white">
                        <li className="w-full rounded-t-lg">
                          <div className="flex items-center pl-3">
                            <input
                              id="vue-checkbox"
                              type="checkbox"
                              value=""
                              className={Maintable.smallCheckBox}
                            />
                            <label
                              for="vue-checkbox"
                              className={Maintable.smailCheckboxLabel}
                            >
                              View
                            </label>
                          </div>
                        </li>
                        <li className="w-full rounded-t-lg">
                          <div className="flex items-center pl-3">
                            <input
                              id="react-checkbox"
                              type="checkbox"
                              value=""
                              className={Maintable.smallCheckBox}
                            />
                            <label
                              for="react-checkbox"
                              className={Maintable.smailCheckboxLabel}
                            >
                              Add
                            </label>
                          </div>
                        </li>
                        <li className="w-full rounded-t-lg">
                          <div className="flex items-center pl-3">
                            <input
                              id="angular-checkbox"
                              type="checkbox"
                              value=""
                              className={Maintable.smallCheckBox}
                            />
                            <label
                              for="angular-checkbox"
                              className={Maintable.smailCheckboxLabel}
                            >
                              Edit
                            </label>
                          </div>
                        </li>
                        <li className="w-full rounded-t-lg">
                          <div className="flex items-center pl-3">
                            <input
                              id="laravel-checkbox"
                              type="checkbox"
                              value=""
                              className={Maintable.smallCheckBox}
                            />
                            <label
                              for="laravel-checkbox"
                              className={Maintable.smailCheckboxLabel}
                            >
                              Delete
                            </label>
                          </div>
                        </li>
                        <li className="w-full rounded-t-lg">
                          <div className="flex items-center pl-3">
                            <input
                              id="laravel-checkbox"
                              type="checkbox"
                              value=""
                              className={Maintable.smallCheckBox}
                            />
                            <label
                              for="laravel-checkbox"
                              className={Maintable.smailCheckboxLabel}
                            >
                              Block
                            </label>
                          </div>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr className={Maintable.tableRow}>
                    <td className="px-6 flex items-center " width="50%">
                      <div className="flex items-center ">
                        <input
                          id="default-checkbox"
                          type="checkbox"
                          value=""
                          className={Maintable.largeCheckbox}
                        />
                        <label
                          for="default-checkbox"
                          className={Maintable.rolecap_label}
                        >
                          Role vs Capability
                        </label>
                      </div>
                    </td>
                    <td className="px-3" width="100%">
                      <ul className="flex w-auto text-sm font-medium rounded-lg dark:text-white">
                        <li className="w-full rounded-t-lg">
                          <div className="flex items-center pl-3">
                            <input
                              id="vue-checkbox"
                              type="checkbox"
                              value=""
                              className={Maintable.smallCheckBox}
                            />
                            <label
                              for="vue-checkbox"
                              className={Maintable.smailCheckboxLabel}
                            >
                              View
                            </label>
                          </div>
                        </li>
                        <li className="w-full rounded-t-lg">
                          <div className="flex items-center pl-3">
                            <input
                              id="react-checkbox"
                              type="checkbox"
                              value=""
                              className={Maintable.smallCheckBox}
                            />
                            <label
                              for="react-checkbox"
                              className={Maintable.smailCheckboxLabel}
                            >
                              Add
                            </label>
                          </div>
                        </li>
                        <li className="w-full rounded-t-lg">
                          <div className="flex items-center pl-3">
                            <input
                              id="angular-checkbox"
                              type="checkbox"
                              value=""
                              className={Maintable.smallCheckBox}
                            />
                            <label
                              for="angular-checkbox"
                              className={Maintable.smailCheckboxLabel}
                            >
                              Edit
                            </label>
                          </div>
                        </li>
                        <li className="w-full rounded-t-lg">
                          <div className="flex items-center pl-3">
                            <input
                              id="laravel-checkbox"
                              type="checkbox"
                              value=""
                              className={Maintable.smallCheckBox}
                            />
                            <label
                              for="laravel-checkbox"
                              className={Maintable.smailCheckboxLabel}
                            >
                              Delete
                            </label>
                          </div>
                        </li>
                        <li className="w-full rounded-t-lg">
                          <div className="flex items-center pl-3">
                            <input
                              id="laravel-checkbox"
                              type="checkbox"
                              value=""
                              className={Maintable.smallCheckBox}
                            />
                            <label
                              for="laravel-checkbox"
                              className={Maintable.smailCheckboxLabel}
                            >
                              Block
                            </label>
                          </div>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr className={Maintable.tableRow}>
                    <td className="px-6 flex items-center " width="50%">
                      <div className="flex items-center ">
                        <input
                          id="default-checkbox"
                          type="checkbox"
                          value=""
                          className={Maintable.largeCheckbox}
                        />
                        <label
                          for="default-checkbox"
                          className={Maintable.rolecap_label}
                        >
                          User
                        </label>
                      </div>
                    </td>
                    <td className="px-3" width="100%">
                      <ul className="flex w-auto text-sm font-medium rounded-lg dark:text-white">
                        <li className="w-full rounded-t-lg">
                          <div className="flex items-center pl-3">
                            <input
                              id="vue-checkbox"
                              type="checkbox"
                              value=""
                              className={Maintable.smallCheckBox}
                            />
                            <label
                              for="vue-checkbox"
                              className={Maintable.smailCheckboxLabel}
                            >
                              View
                            </label>
                          </div>
                        </li>
                        <li className="w-full rounded-t-lg">
                          <div className="flex items-center pl-3">
                            <input
                              id="react-checkbox"
                              type="checkbox"
                              value=""
                              className={Maintable.smallCheckBox}
                            />
                            <label
                              for="react-checkbox"
                              className={Maintable.smailCheckboxLabel}
                            >
                              Add
                            </label>
                          </div>
                        </li>
                        <li className="w-full rounded-t-lg">
                          <div className="flex items-center pl-3">
                            <input
                              id="angular-checkbox"
                              type="checkbox"
                              value=""
                              className={Maintable.smallCheckBox}
                            />
                            <label
                              for="angular-checkbox"
                              className={Maintable.smailCheckboxLabel}
                            >
                              Edit
                            </label>
                          </div>
                        </li>
                        <li className="w-full rounded-t-lg">
                          <div className="flex items-center pl-3">
                            <input
                              id="laravel-checkbox"
                              type="checkbox"
                              value=""
                              className={Maintable.smallCheckBox}
                            />
                            <label
                              for="laravel-checkbox"
                              className={Maintable.smailCheckboxLabel}
                            >
                              Delete
                            </label>
                          </div>
                        </li>
                        <li className="w-full rounded-t-lg">
                          <div className="flex items-center pl-3">
                            <input
                              id="laravel-checkbox"
                              type="checkbox"
                              value=""
                              className={Maintable.smallCheckBox}
                            />
                            <label
                              for="laravel-checkbox"
                              className={Maintable.smailCheckboxLabel}
                            >
                              Block
                            </label>
                          </div>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      
    </MainLayout>
  );
}

export default RoleVsCapability;
