import React from "react";
import { Maintable } from "../css/Common";
import TableGrid from "../components/table";
import { purchaseAddHeader } from "../components/headerAttribute";
import PLASCO from "../image/PLASCO1.png";

const PurchaseDetails = ({ data }) => {
  const cancel = () => {
    window.location.reload();
  };

  console.log("Address", data);
  return (
    <div class="flex">
      {/* {visibleTable && (
          <div class="relative w-full mx-auto">
            <div class={Maintable.headerTitle}>
              <div class="my-2 ml-7">Domestic Purchase</div>
            </div>
            <div class="mx-8">
              <div className="md:justify-between md:flex  md:items-center">
                <div class={Maintable.searchAdd}>
                  <div className="mt-1 gap-2 flex justify-between items-baseline">
                    <label for="pageSize" class="hidden md:block">
                      Rows per page:
                    </label>
                    <select
                      class={Maintable.dropDownRecords}
                      name="pageSize"
                      id="pageSize"
                    >
                      <option value="2">2</option>
                      <option value="10" selected>
                        10
                      </option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <div>
                    <label for="table-search" class="sr-only">
                      Search
                    </label>
                  </div>
                  <div class="relative mt-1">
                    <div class={Maintable.searchIconPosition}>
                      <svg
                        class={Maintable.searchIconSvg}
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <input
                      type="text"
                      id="table-search"
                      class={Maintable.searchInputBox}
                      placeholder="Search for items"
                    />
                  </div>
                </div>
                <div className="flex self-end">
                  <div className="text-red-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner w-fit h-9 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">
                    <span className="mb-12 text-red-600 text-7xl">.</span>
                    <span>Block</span>
                  </div>
                  <button
                    type="button"
                    onClick={showadd}
                    class={Maintable.addButton}
                  >
                    <svg
                      width="25"
                      height="20"
                      viewBox="0 0 50 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22.5 37.5H27.5V27.5H37.5V22.5H27.5V12.5H22.5V22.5H12.5V27.5H22.5V37.5ZM25 50C21.5417 50 18.2917 49.3433 15.25 48.03C12.2083 46.7183 9.5625 44.9375 7.3125 42.6875C5.0625 40.4375 3.28167 37.7917 1.97 34.75C0.656667 31.7083 0 28.4583 0 25C0 21.5417 0.656667 18.2917 1.97 15.25C3.28167 12.2083 5.0625 9.5625 7.3125 7.3125C9.5625 5.0625 12.2083 3.28083 15.25 1.9675C18.2917 0.655833 21.5417 0 25 0C28.4583 0 31.7083 0.655833 34.75 1.9675C37.7917 3.28083 40.4375 5.0625 42.6875 7.3125C44.9375 9.5625 46.7183 12.2083 48.03 15.25C49.3433 18.2917 50 21.5417 50 25C50 28.4583 49.3433 31.7083 48.03 34.75C46.7183 37.7917 44.9375 40.4375 42.6875 42.6875C40.4375 44.9375 37.7917 46.7183 34.75 48.03C31.7083 49.3433 28.4583 50 25 50ZM25 45C30.5833 45 35.3125 43.0625 39.1875 39.1875C43.0625 35.3125 45 30.5833 45 25C45 19.4167 43.0625 14.6875 39.1875 10.8125C35.3125 6.9375 30.5833 5 25 5C19.4167 5 14.6875 6.9375 10.8125 10.8125C6.9375 14.6875 5 19.4167 5 25C5 30.5833 6.9375 35.3125 10.8125 39.1875C14.6875 43.0625 19.4167 45 25 45Z"
                        fill="white"
                      />
                    </svg>
                    ADD
                  </button>
                </div>
              </div>
              <div class={Maintable.tableShadowWidth}>
                <TableGrid
                  className={Maintable.Table}
                  headerData={domesticPurchaseHeader}
                  tableData={bindUserMasterDatas}
                />
              </div>
            </div>
          </div>
        )} */}
      {/* {visibleAdd && ( */}
      <div class=" flex flex-col w-full">
        <div class={Maintable.clientMasterMainAdd}>
          <div className="ml-12" class={Maintable.addScreenTitle}>
            {"P.O. Details"}
          </div>

          <div class="px-6 py-2 lg:px-8">
            <form class={Maintable.formForClientMaster}>
              <div class={Maintable.labelBold}>Purchase Order Details</div>
              <hr class={Maintable.horizontalLine}></hr>
              <div class="grid gap-4 mb-6 md:grid-cols-3">
                <div>
                  <label for="client_id" class={Maintable.label}>
                    P.O. Number
                    <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    id="first_name"
                    value="DPO232400001"
                    class={Maintable.input}
                  />
                </div>
                <div>
                  <label for="client_name" class={Maintable.label}>
                    P.O. Date
                    <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="date"
                    id="podate"
                    value="2023-06-25"
                    class={Maintable.input}
                  />
                </div>
                <div>
                  <label for="client_name" class={Maintable.label}>
                    Expected Arrival Date
                    <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="date"
                    id="expirydate"
                    class={Maintable.input}
                    value="2023-06-25"
                  />
                </div>
              </div>

              <div class={Maintable.labelBold}>Supplier Details</div>
              <hr class={Maintable.horizontalLine}></hr>
              <div class="grid gap-4 mb-6 md:grid-cols-3">
                <div>
                  <label for="client_id" class={Maintable.label}>
                    Supplier Id
                    <span className="text-red-600">*</span>
                  </label>
                  {/* <input
                          type="number"
                          id="first_name"
                          class={Maintable.input}
                        /> */}
                  <input
                    type="text"
                    id="supplierId"
                    name="supplierId"
                    className={Maintable.input}
                    value="SULD00001"
                    readOnly
                  />
                </div>

                <div>
                  <label
                    htmlFor="supplierNameInput"
                    className={Maintable.label}
                  >
                    Supplier Name:
                  </label>

                  <input
                    type="text"
                    id="supplierNameInput"
                    name="supplierName"
                    className={Maintable.input}
                    value="Plasco Engineering Inc."
                    readOnly
                  />
                </div>
                <div>
                  <label htmlFor="Address1" className={Maintable.label}>
                    Address<span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    id="address1"
                    className={Maintable.input}
                    value="No. 90 "
                    readOnly
                  />
                </div>
                <div>
                  <label htmlFor="Address2" className={Maintable.label}>
                    Address 2
                  </label>
                  <input
                    type="text"
                    id="address2"
                    className={Maintable.input}
                    value="Nanpi Rd"
                    readOnly
                  />
                </div>
                <div>
                  <label htmlFor="Address3" className={Maintable.label}>
                    Address 3
                  </label>
                  <input
                    type="text"
                    id="address3"
                    className={Maintable.input}
                    value=" Nanpi Rewan"
                    readOnly
                  />
                </div>
                <div>
                  <label htmlFor="city" className={Maintable.label}>
                    City
                    <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    id="city"
                    className={Maintable.input}
                    value=" Taibao"
                    readOnly
                  />
                </div>
                <div>
                  <label htmlFor="state" className={Maintable.label}>
                    State
                    <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    id="state"
                    className={Maintable.input}
                    value=" Taibao"
                    readOnly
                  />
                </div>
                <div>
                  <label htmlFor="Country" className={Maintable.label}>
                    Country
                    <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    id="country"
                    className={Maintable.input}
                    value=" Chiayi"
                    readOnly
                  />
                </div>
                <div>
                  <label htmlFor="zipcode" className={Maintable.label}>
                    Zip/Pin Code
                  </label>
                  <input
                    type="text"
                    id="zipcode"
                    className={Maintable.input}
                    value="61251 "
                    readOnly
                  />
                </div>
                <div>
                  <label class={Maintable.label} for="multiple_files">
                    Logo
                    <span className="text-red-600">*</span>
                  </label>
                  <input
                    name="cli_Logo"
                    class={Maintable.fileUpload}
                    id="multiple_files"
                    type="text"
                    alt="Image"
                    value="/https.plascologo.png"
                  />
                  <img
                    alt="PLASOIMG"
                    src={PLASCO}
                    class="w-20 h-16 mt-4 flex"
                  />
                </div>
              </div>
              {/* <TableGrid
                      className={Maintable.Table}
                      headerData={purchaseAddHeader}
                      tableData={bindPurchareMasterData}
                      viewFilterGrid={ViewPurchaseInputFiled}
                      onFilterGrid={handleAddRow}
                    />  */}
              <table className="w-full border border-gray-300 mt-6">
                <thead>
                  <tr>
                    <th className="border text-white bg-black border-gray-300 p-2">
                      Item Name
                    </th>
                    <th className="border text-white bg-black border-gray-300 p-2">
                      Item Code
                    </th>
                    <th className="border text-white bg-black border-gray-300 p-2">
                      P.O. Quantity
                    </th>
                    <th className="border text-white bg-black border-gray-300 p-2">
                      UOM
                    </th>
                    <th className="border text-white bg-black border-gray-300 p-2">
                      Rate
                    </th>
                    <th className="border text-white bg-black border-gray-300 p-2">
                      Amount
                    </th>
                    {/* <th className="border text-white bg-black border-gray-300 p-2">
                        Action
                      </th> */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border border-gray-300 p-2">Polymer</td>
                    <td className="border border-gray-300 p-2">RMLD00001</td>
                    <td className="border border-gray-300 p-2 text-right">
                      1000
                    </td>
                    <td className="border border-gray-300 p-2">KG</td>
                    <td className="border border-gray-300 p-2 text-right">
                      500
                    </td>
                    <td className="border border-gray-300 p-2 text-right">
                      500000
                    </td>

                    {/* <td className="border border-gray-300 p-2">
                          <AiFillMinusCircle onClick={() => handleDeleteRow(index)} />
                        </td> */}

                    {/* Render other cells for each column */}
                    {/* ... */}
                  </tr>
                  <tr>
                    <td className="border border-gray-300 p-2">Polyethylene</td>
                    <td className="border border-gray-300 p-2">RMHD00001</td>
                    <td className="border border-gray-300 p-2 text-right">
                      1000
                    </td>
                    <td className="border border-gray-300 p-2">KG</td>
                    <td className="border border-gray-300 p-2 text-right">
                      500
                    </td>
                    <td className="border border-gray-300 p-2 text-right">
                      500000
                    </td>

                    {/* <td className="border border-gray-300 p-2">
                          <AiFillMinusCircle onClick={() => handleDeleteRow(index)} />
                        </td> */}

                    {/* Render other cells for each column */}
                    {/* ... */}
                  </tr>
                  <tr>
                    <td className="border border-gray-300 p-2">Pigments</td>
                    <td className="border border-gray-300 p-2">RMIN00001</td>
                    <td className="border border-gray-300 p-2 text-right">
                      1000
                    </td>
                    <td className="border border-gray-300 p-2">L</td>
                    <td className="border border-gray-300 p-2 text-right">
                      500
                    </td>
                    <td className="border border-gray-300 p-2 text-right">
                      500000
                    </td>

                    {/* <td className="border border-gray-300 p-2">
                          <AiFillMinusCircle onClick={() => handleDeleteRow(index)} />
                        </td> */}

                    {/* Render other cells for each column */}
                    {/* ... */}
                  </tr>
                  <tr>
                    <td className="border border-gray-300 p-2">Solvents</td>
                    <td className="border border-gray-300 p-2">RMSL00001</td>
                    <td className="border border-gray-300 p-2 text-right">
                      1000
                    </td>
                    <td className="border border-gray-300 p-2">KPa</td>
                    <td className="border border-gray-300 p-2 text-right">
                      500
                    </td>
                    <td className="border border-gray-300 p-2 text-right">
                      500000
                    </td>

                    {/* <td className="border border-gray-300 p-2">
                          <AiFillMinusCircle onClick={() => handleDeleteRow(index)} />
                        </td> */}

                    {/* Render other cells for each column */}
                    {/* ... */}
                  </tr>
                  <tr>
                    <td className="border border-gray-300 p-2">Polymers</td>
                    <td className="border border-gray-300 p-2">RMSD00001</td>
                    <td className="border border-gray-300 p-2 text-right">
                      1000
                    </td>
                    <td className="border border-gray-300 p-2">MT</td>
                    <td className="border border-gray-300 p-2 text-right">
                      500
                    </td>
                    <td className="border border-gray-300 p-2 text-right">
                      500000
                    </td>
                    {/* <td className="border border-gray-300 p-2">
                          <AiFillMinusCircle onClick={() => handleDeleteRow(index)} />
                        </td> */}

                    {/* Render other cells for each column */}
                    {/* ... */}
                  </tr>
                </tbody>
              </table>
              <div className="text-right">
                <p class={Maintable.labelAmount}>
                  Total Purchase Order Amount: 2500000
                </p>
              </div>
              <div class="grid gap-4 mb-6 md:grid-cols-2 mt-8">
                <div>
                  <label class={Maintable.label} for="multiple_files">
                    Payment Terms
                    <span className="text-red-600">*</span>
                  </label>
                  <div class="col-span-1">
                    <textarea rows={4} class={Maintable.input}></textarea>
                  </div>
                </div>
                <div>
                  <label class={Maintable.label} for="multiple_files">
                    Delivery Terms
                    <span className="text-red-600">*</span>
                  </label>
                  <div class="col-span-1">
                    <textarea rows={4} class={Maintable.input}></textarea>
                  </div>
                </div>
              </div>
            </form>
            {/* <div class={Maintable.submitCancelMainDiv}>
              <button
                data-modal-hide="bottom-right-modal"
                type="button"
                class={Maintable.submitButton}
              >
                SUBMIT
              </button>
              <button
                data-modal-hide="bottom-right-modal"
                type="button"
                onClick={cancel}
                class={Maintable.cancelButton}
              >
                CANCEL
              </button>
            </div> */}
          </div>
        </div>
      </div>

      {/* )} */}
    </div>
  );
};
export default PurchaseDetails;
