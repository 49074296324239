import React, { useEffect, useState } from "react";
import { Maintable, validationcolor } from "../css/Common";
import "../css/Misceleneous.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableGrid from "../components/table";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";
import Select from "react-select";
import SupplierDetails from "../popup-containers/supplierPopUp";
import PLASCO from "../image/PLASCO1.png";
import Logo from "../image/Dummy_logo.png";

import {
  ReturntoSupplierHeader,
    RawMaterialMSTHeader,
  UserHeaders,
  rmHeaders,
} from "../components/headerAttribute";
import UserDetails from "../popup-containers/UserDetails";
import MainPopup from "../popup-containers/MainPopup";
import MainLayout from "../components/Mainlayout";

function ReturntoSupplier() {
  const [loading, setLoading] = useState(false);

  const [visibleAdd, setVisibleAdd] = React.useState(false);
  const [visibleTable, setVisibleTable] = React.useState(true);

  const [updateButton, setUpdateButton] = React.useState(false);
  const [submitButton, setSubmitButton] = React.useState(true);
  const [editMode, setEditMode] = useState([]);

  const [selectedSupplier, setSelectedSupplier] = useState(null);

  const [supplierName, setSupplierName] = useState("");
  const [supplierId, setSupplierId] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [logo, setLogo] = useState(Logo);

  const [bindSupplierDD, setSupplierDD] = useState([
    { value: "Plasco Engineering Inc SULD00001", label: "Plasco Engineering Inc SULD00001" },
    { value: "Ye I Machinery Factory Co. Ltd. SULD00002", label: "Ye I Machinery Factory Co. Ltd. SULD00002" },
    { value: "Windsor Machines Ltd SULD00003", label: "Windsor Machines Ltd SULD00003" },
    { value: "Polystar Machinery Co. Ltd. SULD00004", label: "Polystar Machinery Co. Ltd. SULD00004" },
    { value: "Plasco Engineering Inc. SULD00005", label: "Plasco Engineering Inc. SULD00005" },
  ]);

  const [filterParam, setFilterParam] = useState(0);
  const [filterParam2, setFilterParam2] = useState(0);
  const [filterParam3, setFilterParam3] = useState(0);
  const [filterParam4, setFilterParam4] = useState(0);
  const [filterParam5, setFilterParam5] = useState(0);
  const [filterParam6, setFilterParam6] = useState(0);

  let [sortOrder, setSortOrder] = useState(0);
  const [sortColumn, setsortColumn] = useState(0);

  const [filterValue, setFilterValue] = useState("");
  const [filterValue2, setFilterValue2] = useState("");
  const [filterValue3, setFilterValue3] = useState("");
  const [filterValue4, setFilterValue4] = useState("");
  const [filterValue5, setFilterValue5] = useState("");
  const [filterValue6, setFilterValue6] = useState("");

  const [currentData, setCurrentData] = useState({});
  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  const [viewScreenTitle, setViewScreenTitle] = useState(false);
  const [bindUserMasterDatas, setBindUserMasterDatas] = useState([
    {
      sno: 1,
      issuedate: "23-06-23",
      issueno: "RTSF232400001",
      material: "Polymer (RMLD00001)",
      uom: "KG",
      quantity: 55,
      supplier: (<a href="#" onClick={() => HandleSupplier()} className="text-cyan-600 underline">Plasco Engineering Inc.</a>),
    },
    {
      sno: 2,
      issuedate: "24-06-23",
      issueno: "RTSF232400002",
      material: "Polyethylene (RMHD00001)",
      uom: "KG",
      quantity: 55,
      supplier: (<a href="#" onClick={() => HandleSupplier()} className="text-cyan-600 underline">Ye I Machinery Factory Co. Ltd.</a>),
    },
    {
      sno: 3,
      issuedate: "25-06-23",
      issueno: "RTSF232400003",
      material: "Pigments (RMIN00001)",
      uom: "pcs",
        quantity: 50,
        supplier: (<a href="#" onClick={() => HandleSupplier()} className="text-cyan-600 underline">Windsor Machines Ltd</a>),
      },
    {
      sno: 4,
      issuedate: "26-06-23",
      issueno: "RTSF232400004",
      material: "Solvents (RMSL00001)",
      uom: "KPa",
      quantity: 40,
      supplier: (<a href="#" onClick={() => HandleSupplier()} className="text-cyan-600 underline">Polystar Machinery Co. Ltd.</a>),
    },
    {
      sno: 5,
      issuedate: "27-06-23",
      issueno: "RTSF232400005",
      material: "Polymers (RMSD00001)",
      uom: "MT",
      quantity: 45,
      supplier: (<a href="#" onClick={() => HandleSupplier()} className="text-cyan-600 underline">Polystar Machinery Co. Ltd.</a>),
    },
    {
      sno: 6,
      issuedate: "28-06-23",
      issueno: "RTSF232400006",
      material: "Acrylic (RMAD00001)",
      uom: "KG",
      quantity: 60,
      supplier: (<a href="#" onClick={() => HandleSupplier()} className="text-cyan-600 underline">Plasco Engineering Inc.</a>),
    },
  ]);
  const Suppliers = [
    {
      supplier1: {
        Description: 4,
        "Supplier Name": "Bob",
        Email: "Doe",
        Address: "Active",
        "Contact Personemail": "bobdoe@abc.com",
        "Contact Number": "Male",
      },
      supplier2: {
        id: 4,
        first_name: "Bob",
        last_name: "Doe",
        email: "bobdoe@abc.com",
        gender: "Male",
        status: "Active",
      },
    },
  ];

  const [bindUOMDD, setBindUOMDD] = useState([
    { value: "MTRS", label: "MTRS" },
    { value: "km", label: "km" },
    { value: "meters", label: "meters" },
    { value: "cm", label: "cm" },
    { value: "ft", label: "ft" },
    { value: "lb", label: "lb" },
    { value: "KG", label: "KG" },
    { value: "oz", label: "oz" },
    { value: "s", label: "s" },
    { value: "min", label: "min" },
    { value: "hr", label: "hr" },
    { value: "°C", label: "°C" },
    { value: "°F", label: "°F" },
    { value: "K", label: "K" },
    { value: "L", label: "L" },
    { value: "mL", label: "mL" },
    { value: "gal", label: "gal" },
    { value: "lbf", label: "lbf" },
    { value: "N", label: "N" },
    { value: "Pa", label: "Pa" },
    { value: "bar", label: "bar" },
    { value: "MT", label: "MT" },
    { value: "MTRS", label: "MTRS" },
    { value: "N/A", label: "N/A" },
    { value: "BDLS", label: "BDLS" },
    { value: "SACKS", label: "SACKS" },
    { value: "pcs", label: "pcs" },

  ]);
  const [bindDepartmentDD, setBindDepartmentDD] = useState([
    { value: "blowfilm", label: "Blow Film" },
    { value: "printing", label: "Printing" },
    { value: "slitting", label: "Slitting" },
    { value: "lamination", label: "Lamination" },
    { value: "reproduction", label: "Reproduction" },
    { value: "injectionmoulding", label: "Injection Moulding" },
    { value: "fgstore", label: "F.G. Store" },
    { value: "rmstore", label: "R.M. Store" },

  ]);
  const [bindMachinesDD, setBindMachinesDD] = useState([

    { value: "km", label: "LD SHRINK Film Plant BF00001" },
    { value: "meters", label: "PVC Soft film plant BF00002" },
    { value: "cm", label: "SterFlex PF00003" },
    { value: "ft", label: "SterFlex 2000 PF00004" },
    { value: "lb", label: "Pallet shrink wrap-Labelong WP00005" },
    { value: "KG", label: "ST6040A + BSE6040A Automatic Sleeve Sealer(WP00006)" },
    { value: "oz", label: "LC Rubber Roll To Roll Lamination Machine 360(LM00007)" },
    { value: "s", label: "Excelam LBD-750mm Cold Roll to Roll Semi(LM00008)" },
    { value: "min", label: "NPC280H(TWC00009)" },
    { value: "hr", label: "NPC450(TWC00010)" },
  ]);
  const [bindMaterialDD, setBindMaterialDD] = useState([
    { value: "blowfilm", label: "Polymer RMLD00001", uom: "KG"},
    { value: "printing", label: "Polyethylene RMHD00001", uom: "KG" },
    { value: "slitting", label: "Pigments RMIN00001", uom: "pcs" },
    { value: "lamination", label: "Solvents RMSL00001", uom: "KPa" },
    { value: "reproduction", label: "Polymers RMSD00001", uom: "MT" },
    { value: "injectionmoulding", label: "Acrylic RMAD00001", uom: "KG" },
    { value: "fgstore", label: "Plain Shrink Film PSF00001", uom: "MT" },
    { value: "rmstore", label: "Printed Shrink Film PSF00002", uom: "MT" },
    { value: "fgstore", label: "Shrink Film for Water Pouch PSF00003", uom: "MT" },
    { value: "rmstore", label: "Lamination Film LAM00001", uom: "MT" },
    { value: "fgstore", label: "Thinwall Food Containers TFC00001", uom: "pcs" },
  ]);
  
  const [viewDetails, setViewDetails] = useState(false);
  const [deleteButton, setDeleteButton] = useState(false);
  const [blockButton, setBlockButton] = useState(false);
  const [unBlockButton, setUnBlockButton] = useState(false);

  const [currentPage1, setCurrentPage] = useState(1);
  const [rowIndex, setRowIndex] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [result, setResult] = useState(10);
  const [searchValue, setSearchValue] = useState(0);
  const [viewFilter, setViewFilter] = useState(false);
  // const [iconvisiable, seticonvisiable] = React.useState(true);
  // const [iconitemsshow, seticonitemshow] = React.useState(false);

  const [user_Firstname, setUser_Firstname] = useState("");
  const [user_Lastname, setUser_Lastname] = useState("");
  const [rawmaterial, setRawmaterial] = useState("");

  const [user_Email, setUser_Email] = useState("");
  const [user_Password, setUser_Password] = useState("");
  const [user_Mobile, setUser_Mobile] = useState("");
  const [user_Designation, setUser_Designation] = useState("");
  const [user_Department, setUser_Department] = useState("");
  const [user_Image, setUser_Image] = useState("");
  const [user_Image_File, setUser_Image_File] = useState("");
  const [user_Role, setUser_Role] = useState("");

  const [popUpOpen, setpopUpOpen] = useState(false);
  const [viewSupplierDetails, setViewSupplierDetails] = useState(false);


  const [user_FirstNameError, setUser_FirstNameError] = useState("");
  const [user_LastNameError, setUser_LastNameError] = useState("");
  const [user_EmailError, setUser_EmailError] = useState("");
  const [user_PasswordError, setUser_PasswordError] = useState("");
  const [user_PhoneNumberError, setUser_PhoneNumberError] = useState("");
  const [user_DesignationError, setUser_DesignationError] = useState("");
  const [user_DepartmentError, setUser_DepartmentError] = useState("");
  const [user_RoleError, setUser_RoleError] = useState("");

  const [uom,setUom ] = useState("");
  const [itemDes,setItemDes ] = useState("");


  const handleSupplierChange = (value) => {
    debugger;
    const name = value.value;
    setSelectedSupplier(value);

    if (name === "Plasco Engineering Inc SULD00001") {
      setSupplierName("SULD00001");
      setAddress1("No. 90");
      setAddress2("Nanpi Rd");
      setAddress3("Nanpi Rewan");
      setCity("Taibao");
      setState("Chiayioi");
      setCountry("Chiayi");
      setZipCode(61251);
      setLogo(PLASCO);
    } else if (value === "SULD00001") {
      setSupplierName("Supplier B");
      setAddress1("Address 1 for SULD00001");
      setAddress2("Address 2 for SUPLR1001");
      setAddress3("Address 3 for SUPLR1001");
      setCity("City for SUPLR1001");
      setState("State for SUPLR1001");
      setCountry("Country for SUPLR1001");
      setZipCode("54321");
    } else {
      setSupplierName("");
      setAddress1("");
      setAddress2("");
      setAddress3("");
      setCity("");
      setState("");
      setCountry("");
      setZipCode("");
    }
  };
  const handleClose = () => {
    setpopUpOpen(false);
    setViewSupplierDetails(false);
  };
  const HandleSupplier = (rowIndex) => {
    handleSupplierOpen(true);
    setViewSupplierDetails(true);
  };
  const handleSupplierOpen = (isOpen) => {
    setViewSupplierDetails(isOpen);
};


  const modules = {
    toolbar: [
      //[{ 'font': [] }],
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      [{ align: [] }, { color: [] }, { background: [] }],
      ["clean"],
    ],
  };

  const formats = [
    //'font',
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "color",
    "background",
  ];

  const Container = styled.div`
    height: 243px;
  `;


  const showadd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setSubmitButton(true);
    setAddScreenTitle(true);
    setUpdateButton(false);
    setUpdateScreenTitle(false);
  };

  const cancel = () => {
    debugger;

    setUser_Firstname("");
    setUser_Lastname("");
    setUser_Email("");
    setUser_Password("");
    setUser_Mobile("");
    setUser_Designation("");
    setUser_Department("");
    setUser_Image("");
    setUser_Role("");

    setUser_FirstNameError("");
    setUser_LastNameError("");
    setUser_EmailError("");
    setUser_PasswordError("");
    setUser_PhoneNumberError("");
    setUser_DesignationError("");
    setUser_DepartmentError("");
    setUser_RoleError("");

    setLoading(true);
    setTimeout(() => {
      setLoading(false);

      setCurrentPage(1);
      setResult(10);

      setVisibleAdd(false);
      setVisibleTable(true);
      setSubmitButton(false);
      setAddScreenTitle(false);
      setUpdateButton(false);
      setUpdateScreenTitle(false);
    }, 2000);
  };
  const HandleAddress = (rowIndex) => { };
  const HandleSort = async (columnName) => {
    
  };
  const HandleEdit = async (items) => {
  }

  function handleDropDown(e) {
    // setResult(e.target.value);
    // setCurrentPage(1);
  }

  const HandleSubmit = async (items, flag) => {
    
  };

   const HandleFilter = (filterValuesPass, itemIndex) => {
    
  };

  const handleImageUpload = async () => {
    debugger;
  };
  const HandleUserDetails = (rowIndex, flag) => {
      
  };

  return (
    <MainLayout>
      <ToastContainer />
      {visibleTable && (
        <div className="max-md:absolute">
          <div className={Maintable.headerTitle}>
            <div className="my-2 mx-12 lg:mx-0">{`Inventory -> Return to Supplier`}</div>
          </div>

          <div className="justify-between flex items-center">
            <div className={Maintable.searchAdd}>
              <div className="mt-1 gap-2 flex justify-between items-baseline">
                <label for="pageSize" className="hidden md:block">
                  Rows Per Page:
                </label>
                <select
                  onChange={handleDropDown}
                  className={Maintable.dropDownRecords}
                  name="pageSize"
                  id="pageSize"
                >
                  <option value="2">2</option>
                  <option value="10" selected>
                    10
                  </option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div>
                <label for="table-search" className="sr-only">
                  Search
                </label>
              </div>
              <div className="relative mt-1">
                <div className={Maintable.searchIconPosition}>
                  <svg
                    className={Maintable.searchIconSvg}
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  type="text"
                  id="table-search"
                  className={Maintable.searchInputBox}
                  placeholder="Search for items"
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
              {/* <button
                onClick={() => setViewFilter(!viewFilter)}
                className="relative px-5 py-0 overflow-hidden font-medium text-gray-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner group"
              >
                <span className="absolute top-0 left-0 w-0 h-0 transition-all duration-200 border-t-2 border-gray-600 group-hover:w-full ease"></span>
                <span className="absolute bottom-0 right-0 w-0 h-0 transition-all duration-200 border-b-2 border-gray-600 group-hover:w-full ease"></span>
                <span className="absolute top-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-gray-600 group-hover:h-full ease"></span>
                <span className="absolute bottom-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-gray-600 group-hover:h-full ease"></span>
                <span className="absolute inset-0 w-full h-full duration-300 delay-300 bg-gray-900 opacity-0 group-hover:opacity-100"></span>
                <span className="relative transition-colors duration-300 delay-200 group-hover:text-white ease">
                  <svg
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    height="2em"
                    width="2em"
                    //className=" mt-1 bg-gray-800 inline-flex items-center sm:mx-3 justify-center  md:p-1 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                    //onClick={() => setViewFilter(!viewFilter)}
                  >
                    <path d="M6 10.5a.5.5 0 01.5-.5h3a.5.5 0 010 1h-3a.5.5 0 01-.5-.5zm-2-3a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5zm-2-3a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5z" />
                  </svg>
                </span>
              </button> */}
            </div>
            <div className="flex self-end">
              <div className="text-red-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner w-fit h-9 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">
                <span className="mb-12 text-red-600 text-7xl">.</span>
                <span>Block</span>
              </div>
              <button
                type="button"
                className={Maintable.addButton}
                onClick={showadd}
              >
                <svg
                  width="25"
                  height="20"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.5 37.5H27.5V27.5H37.5V22.5H27.5V12.5H22.5V22.5H12.5V27.5H22.5V37.5ZM25 50C21.5417 50 18.2917 49.3433 15.25 48.03C12.2083 46.7183 9.5625 44.9375 7.3125 42.6875C5.0625 40.4375 3.28167 37.7917 1.97 34.75C0.656667 31.7083 0 28.4583 0 25C0 21.5417 0.656667 18.2917 1.97 15.25C3.28167 12.2083 5.0625 9.5625 7.3125 7.3125C9.5625 5.0625 12.2083 3.28083 15.25 1.9675C18.2917 0.655833 21.5417 0 25 0C28.4583 0 31.7083 0.655833 34.75 1.9675C37.7917 3.28083 40.4375 5.0625 42.6875 7.3125C44.9375 9.5625 46.7183 12.2083 48.03 15.25C49.3433 18.2917 50 21.5417 50 25C50 28.4583 49.3433 31.7083 48.03 34.75C46.7183 37.7917 44.9375 40.4375 42.6875 42.6875C40.4375 44.9375 37.7917 46.7183 34.75 48.03C31.7083 49.3433 28.4583 50 25 50ZM25 45C30.5833 45 35.3125 43.0625 39.1875 39.1875C43.0625 35.3125 45 30.5833 45 25C45 19.4167 43.0625 14.6875 39.1875 10.8125C35.3125 6.9375 30.5833 5 25 5C19.4167 5 14.6875 6.9375 10.8125 10.8125C6.9375 14.6875 5 19.4167 5 25C5 30.5833 6.9375 35.3125 10.8125 39.1875C14.6875 43.0625 19.4167 45 25 45Z"
                    fill="white"
                  />
                </svg>
                ADD
              </button>
            </div>
          </div>
          <TableGrid
            className={Maintable.Table}
            headerData={          ReturntoSupplierHeader      }
            tableData={bindUserMasterDatas}
            onEditGrid={HandleEdit}
            onDeleteGrid={HandleUserDetails}
            onSortGrid={HandleSort}
            onBlocKGrid={HandleUserDetails}
            onViewGrid={HandleUserDetails}
            onAddressGrid={HandleAddress}
            onFilterGrid={HandleFilter}
            sortColumn={sortColumn}
            sortOrder={sortOrder}
            viewFilterGrid={viewFilter}
          />

          <div className={Maintable.submitCancelMainDiv}>
            <button
              disabled={currentPage1 === 1}
              onClick={() => setCurrentPage(currentPage1 - 1)}
              type="button"
              className={Maintable.submitButton}
            >
              Previous
            </button>
            <label>{currentPage1}</label>
            <button
              disabled={currentPage1 === totalPages / result}
              onClick={() => setCurrentPage(currentPage1 + 1)}
              type="button"
              className={Maintable.cancelButton}
            >
              Next
            </button>
          </div>
        </div>
      )}
      {visibleAdd && (
        <div className="flex flex-col w-full">
          <div className={Maintable.clientMasterMainAdd}>
            {addScreenTitle && (
              <div className={Maintable.addScreenTitle}>
                {"Inventory -> Return to Supplier -> Add"}
              </div>
            )}

            {updateScreenTitle && (
              <div className={Maintable.addScreenTitle}>
                {"Inventory -> Return to Supplier -> Edit"}
              </div>
            )}

            {viewScreenTitle && (
              <div className={Maintable.addScreenTitle}>
                {"Inventory -> Return to Supplier ->  View"}
              </div>
            )}

            <div className={Maintable.addShadowScreen}>
              <div className={Maintable.mainContentScreen}>
                <form className={Maintable.formForClientMaster}>


                  <div className={Maintable.grid2}>
                    <div>
                      <label for="first_name" className={Maintable.label}>
                        Return Slip No.
                        <span className="text-red-600">*</span>

                      </label>

                      <input  
                        type="text"
                        id="issueslip"
                        name="issueslip"
                        value={user_Firstname}
                        placeholder="Return Slip No."
                        onChange={(e) => {
                          setUser_Firstname(e.target.value);
                          setUser_FirstNameError(
                            user_Firstname.length > 0
                              ? /[A-Za-z ]/.exec(user_Firstname)
                                ? ""
                                : "Only Alphabet and space allowed"
                              : "Enter First Name"
                          );
                        }}
                        class={Maintable.input}
                        className={
                          user_FirstNameError
                            ? validationcolor.errorColor
                            : Maintable.input
                        }
                      />

                      {user_FirstNameError && (
                        <span className="text-red-500">
                          {user_FirstNameError}
                        </span>
                      )}
                    </div>
                    <div>
                      <label for="designation" className={Maintable.label}>
                         Date
                         <span className="text-red-600">*</span>

                      </label>
                      <input
                        type="date"
                        id="issuedate"
                        name="issuedate"
                        value={user_Designation}
                        placeholder="HR"
                        onChange={(e) => {
                          setUser_Designation(e.target.value);
                          setUser_DesignationError(
                            user_Department.length > 0
                              ? /[A-Za-z ]/.exec(user_Department)
                                ? ""
                                : "Only Alphabet and space allowed"
                              : "Enter First Name"
                          );
                        }}
                        class={Maintable.input}
                        className={
                          user_DesignationError
                            ? validationcolor.errorColor
                            : Maintable.input
                        }
                      />
                      {user_DesignationError && (
                        <span className="text-red-500">
                          {user_DesignationError}
                        </span>
                      )}
                    </div>
                    {/* <div>
                      <label for="lastname" className={Maintable.label}>
                        Category
                      </label>
                     
                      <input
                        type="text"
                        id="category"
                        name="category"
                        value={user_Lastname}
                        placeholder="Doe"
                        onChange={(e) => {
                          setUser_Lastname(e.target.value);
                          setUser_LastNameError(
                            user_Lastname.length > 0
                              ? ""
                                ? ""
                                : "Only Alphabet and space allowed"
                              : "Enter Last Name"
                          );
                        }}
                        class={Maintable.input}
                        className={
                          user_LastNameError
                            ? validationcolor.errorColor
                            : Maintable.input
                        }
                      />
                      {user_LastNameError && (
                        <span className="text-red-500">{user_LastNameError}</span>
                      )}
                    </div> */}
                    {/* <div>
                      <label for="first_name" className={Maintable.label}>
                        R.M. ID.
                      </label>
                      <select class={Maintable.input}>
                        <option>Select</option>
                        <option value="RMLD00001">RMLD00001</option>
                        <option value="RMHD00001">RMHD00001</option>
                        <option value="RMIN00001">RMIN00001</option>
                        <option value="RMSL00001">RMSL00001</option>
                        <option value="RMSD00001">RMSD00001</option>
                      </select>

                    </div> */}
                    {/* <div>
                      <label for="lastname" className={Maintable.label}>
                        Category
                      </label>
                     
                      <input
                        type="text"
                        id="category"
                        name="category"
                        value={user_Lastname}
                        placeholder="Doe"
                        onChange={(e) => {
                          setUser_Lastname(e.target.value);
                          setUser_LastNameError(
                            user_Lastname.length > 0
                              ? ""
                                ? ""
                                : "Only Alphabet and space allowed"
                              : "Enter Last Name"
                          );
                        }}
                        class={Maintable.input}
                        className={
                          user_LastNameError
                            ? validationcolor.errorColor
                            : Maintable.input
                        }
                      />
                      {user_LastNameError && (
                        <span className="text-red-500">{user_LastNameError}</span>
                      )}
                    </div> */}
                    <div>
                      <label for="first_name" className={Maintable.label}>
                        Material
                        <span className="text-red-600">*</span>

                      </label>
                      <Select
                        options={bindMaterialDD}
                        className={Maintable.input1}
                        onChange={(value) => {
                          console.log(value.value, value.value.slice(-9),value.uom);
                          setItemDes(value.value.slice(0, -9));
                          // setMaterialCode(value.value.slice(-9));
                          setUom(value.uom)
                        }}
                      />
                      {/* <input
                        type="text"
                        id="rawmaterial"
                        name="rawmaterial"
                        value={rawmaterial}
                        placeholder="Raw Material"
                        onChange={(e) => {
                          setUser_Firstname(e.target.value);
                          setUser_FirstNameError(
                            user_Firstname.length > 0
                              ? /[A-Za-z ]/.exec(user_Firstname)
                                ? ""
                                : "Only Alphabet and space allowed"
                              : "Enter First Name"
                          );
                        }}
                        class={Maintable.input}
                        className={
                          user_FirstNameError
                            ? validationcolor.errorColor
                            : Maintable.input
                        }
                      /> */}
                      {/* {user_FirstNameError && (
                        <span className="text-red-500">
                          {user_FirstNameError}
                        </span>
                      )} */}
                    </div>
                    {/* <div>
                      <label for="lastname" className={Maintable.label}>
                        Category
                      </label>
                      
                      <input
                        type="text"
                        id="category"
                        name="category"
                        value={user_Lastname}
                        placeholder="Doe"
                        onChange={(e) => {
                          setUser_Lastname(e.target.value);
                          setUser_LastNameError(
                            user_Lastname.length > 0
                              ? ""
                                ? ""
                                : "Only Alphabet and space allowed"
                              : "Enter Last Name"
                          );
                        }}
                        class={Maintable.input}
                        className={
                          user_LastNameError
                            ? validationcolor.errorColor
                            : Maintable.input
                        }
                      />
                      {user_LastNameError && (
                        <span className="text-red-500">{user_LastNameError}</span>
                      )}
                    </div> */}
                    {/* <div>
                      <label for="email" className={Maintable.label}>
                        Description
                      </label>
                      <input
                        type="text"
                        id="email"
                        name="user_Email"
                        value={user_Email}
                        placeholder="Raw_Description"
                        onChange={(e) => {
                          setUser_Email(e.target.value);
                          setUser_EmailError(
                            user_Email.length > 0
                              ? /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                                  user_Email
                                )
                                ? ""
                                : "Invalid Email"
                              : "Enter Email"
                          );
                        }}
                        class={Maintable.input}
                        className={
                          user_EmailError
                            ? validationcolor.errorColor
                            : Maintable.input
                        }
                      />
                      {user_EmailError && (
                        <span className="text-red-500">{user_EmailError}</span>
                      )}
                    </div> */}
                    
                    <div>                    
                      <div className={Maintable.grid1_2} >
                      <div>
                          <label for="phone" className={Maintable.label}>
                            Quantity
                            <span className="text-red-600">*</span>

                          </label>
                          <input
                            type="text"
                            id="mobile"
                            name="user_Mobile"
                            value={user_Mobile}
                            placeholder="Opening Quantity"
                            onChange={(e) => {
                              setUser_Mobile(e.target.value);
                              setUser_PhoneNumberError(
                                user_Mobile.length > 0
                                  ? /\d/.test(user_Mobile) &&
                                    user_Mobile.length == 10
                                    ? ""
                                    : "Invalid Mobile Number"
                                  : "Enter Mobile No"
                              );
                            }}
                            class={Maintable.input}
                            className={
                              user_PhoneNumberError
                                ? validationcolor.errorColor
                                : Maintable.input
                            }
                          />
                          {/* {user_PhoneNumberError && (
                            <span className="text-red-500">
                              {user_PhoneNumberError}
                            </span>
                          )} */}
                        </div>
                        <div >
                          <label for="passsword" className={Maintable.label}>
                            s.M.
                          </label>
                          <input
                              type="text"
                              name="uom"
                              placeholder="U.O.M."
                              class={Maintable.input}
                              value={uom}
                              readOnly
                            />
                          {/* <Select
                            options={bindUOMDD}
                            className={Maintable.input1}
                          /> */}
                          {/* <select class={Maintable.input}>
                        <option>Select</option>
                        <option>KG</option>
                        <option>MT</option>
                        <option>LTMS</option>
                        <option>Nos</option>
                        <option>L</option>
                        <option>BDLS</option>
                        <option>SACKS</option>
                        <option>cm</option>
                        <option>°C</option>
                      </select> */}
                          {/* <input
                          type="text"
                          id="uom"
                          name="uom"
                          value={user_Password}
                          placeholder="Unit of Measure"
                          onChange={(e) => {
                            setUser_Password(e.target.value);
                            setUser_PasswordError(
                              user_Password.length > 0
                                ? /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(
                                  user_Email
                                )
                                  ? ""
                                  : "Invalid Password"
                                : "Enter Password"
                            );
                          }}
                          class={Maintable.input}
                          className={
                            user_PasswordError
                              ? validationcolor.errorColor
                              : Maintable.input
                          }
                        /> */}
                          {user_PasswordError && (
                            <span className="text-red-500">
                              {user_PasswordError}
                            </span>
                          )}
                        </div>
                        {/* <div>
                      <label for="phone" className={Maintable.label}>
                        Stock Remaining
                      </label>
                      <input
                        type="number"
                        id="mobile"
                        name="user_Mobile"
                        value={user_Mobile}
                        placeholder="Opening Quantity"
                        onChange={(e) => {
                          setUser_Mobile(e.target.value);
                          setUser_PhoneNumberError(
                            user_Mobile.length > 0
                              ? /\d/.test(user_Mobile) &&
                                user_Mobile.length == 10
                                ? ""
                                : "Invalid Mobile Number"
                              : "Enter Mobile No"
                          );
                        }}
                        class={Maintable.input}
                        className={
                          user_PhoneNumberError
                            ? validationcolor.errorColor
                            : Maintable.input
                        }
                      />
                      {user_PhoneNumberError && (
                        <span className="text-red-500">
                          {user_PhoneNumberError}
                        </span>
                      )}
                    </div> */}

                      </div>
                    </div>

                    {/* <div>
                      <label for="phone" className={Maintable.label}>
                        Opening Quantity
                      </label>
                      <input
                        type="number"
                        id="mobile"
                        name="user_Mobile"
                        value={user_Mobile}
                        placeholder="Opening Quantity"
                        onChange={(e) => {
                          setUser_Mobile(e.target.value);
                          setUser_PhoneNumberError(
                            user_Mobile.length > 0
                              ? /\d/.test(user_Mobile) &&
                                user_Mobile.length == 10
                                ? ""
                                : "Invalid Mobile Number"
                              : "Enter Mobile No"
                          );
                        }}
                        class={Maintable.input}
                        className={
                          user_PhoneNumberError
                            ? validationcolor.errorColor
                            : Maintable.input
                        }
                      />
                      {user_PhoneNumberError && (
                        <span className="text-red-500">
                          {user_PhoneNumberError}
                        </span>
                      )}
                    </div> */}

                    {/* <div>
                      <label for="designation" className={Maintable.label}>
                        Opening Date
                      </label>
                      <input
                        type="date"
                        id="last_name"
                        name="user_Designation"
                        value={user_Designation}
                        placeholder="HR"
                        onChange={(e) => {
                          setUser_Designation(e.target.value);
                          setUser_DesignationError(
                            user_Department.length > 0
                              ? /[A-Za-z ]/.exec(user_Department)
                                ? ""
                                : "Only Alphabet and space allowed"
                              : "Enter First Name"
                          );
                        }}
                        class={Maintable.input}
                        className={
                          user_DesignationError
                            ? validationcolor.errorColor
                            : Maintable.input
                        }
                      />
                      {user_DesignationError && (
                        <span className="text-red-500">
                          {user_DesignationError}
                        </span>
                      )}
                    </div> */}
                    {/* <div>
                      <label for="Department" className={Maintable.label}>
                        Department
                      </label>
                      <input
                        type="text"
                        id="Department"
                        name="user_Department"
                        value={user_Department}
                        placeholder="Developer"
                        onChange={(e) => {
                          setUser_Department(e.target.value);
                          setUser_DepartmentError(
                            user_Department.length > 0
                              ? /[A-Za-z ]/.exec(user_Department)
                                ? ""
                                : "Only Alphabet and space allowed"
                              : "Enter First Name"
                          );
                        }}
                        class={Maintable.input}
                        className={
                          user_DepartmentError
                            ? validationcolor.errorColor
                            : Maintable.input
                        }
                      />
                      {user_DepartmentError && (
                        <span className="text-red-500">{user_DepartmentError}</span>
                      )}
                    </div> */}
                    {/* <div>
                      <label for="Department" className={Maintable.label}>
                        Role
                      </label>
                      <select className={Maintable.input} onChange={(e) => {setUser_Role(e.target.value)}}>
                        <option value="select">Select</option>
                        <option value="user">User</option>
                        <option value="admin">Admin</option>
                        <option value="superadmin">Super Admin</option>
                      </select>

                      {user_RoleError && (
                        <span className="text-red-500">{user_RoleError}</span>
                      )}
                    </div> */}
                    {/* <div>
                      <label className={Maintable.label} for="multiple_files">
                        Photo
                      </label>
                      <input
                        className={Maintable.fileUpload}
                        onChange={(e) => setUser_Image_File(e.target.files[0])}
                        name="user_Image"
                        id="profile"
                        type="file"
                        multiple
                      /> */}
                    {/* <BsFillCheckCircleFill className="hi" />
                              <BsExclamationCircleFill className="hello" /> */}
                    {/* </div> */}
                    {/* <div className="flex">
                      <div className="mt-7 mr-7">
                        <button
                          type="button"
                          className={Maintable.submitButton}
                          onClick={() => handleImageUpload()}
                        >
                          UPLOAD
                        </button>
                      </div>
                      <div>
                        <input
                          type="image"
                          src={user_Image}
                          alt="Image"
                          className="rounded-full w-20 h-20"
                        />
                      </div>
                    </div> */}
                    {/*<div className="flex">
                    <div className="flex-col">
                    <label className={Maintable.label} for="multiple_files">
                        Photo
                      </label>
                      <input
                        className={Maintable.fileUpload}
                        onChange={(e) => setUser_Image_File(e.target.files[0])}
                        name="user_Image"
                        id="profile"
                        type="file"
                        multiple
                      />
                    </div>
                      
                      <div className="mt-7 mx-7">
                        <button
                          type="button"
                          className={Maintable.submitButton}
                          onClick={() => handleImageUpload()}
                        >
                          UPLOAD
                        </button>
                      </div>
                      <div>
                        <input
                          type="image"
                          src={user_Image}
                          alt="Image"
                          className="rounded-full w-20 h-20"
                        />
                      </div>
                    </div>*/}
                  </div>
                  <div class={Maintable.labelBold}>
                    Supplier Details
                  </div>
                  <hr class={Maintable.horizontalLine}></hr>
                  <div className={Maintable.grid2}>
                  <div>
                        <label for="client_id" class={Maintable.label}>
                          Supplier Name
                          <span className="text-red-600">*</span>
                        </label>
                        <Select
                          //  Itcode={bindMaterialDD.value.split(-10)}
                          // Itcode2=split{()}
                          options={bindSupplierDD}

                          // options = {bindMaterialDD.value.split(-10)}
                          // onChange={(value) => 
                          // console.log(value.value, value.value.slice(-9))
                          // }
                          onChange={handleSupplierChange}
                          className={Maintable.input1}
                        />
                        {/* <input
                          type="number"
                          id="first_name"
                          class={Maintable.input}
                        /> */}
                        {/* <select
                          className={Maintable.input}
                          value={selectedSupplier}
                          onChange={handleSupplierChange}
                        >
                          <option value="">Select</option>
                          <option value="SULD00001">SULD00001</option>
                          <option value="SULD00002">SULD00002</option>
                          <option value="SULD00003">SULD00003</option>
                          <option value="SULD00004">SULD00004</option>
                          <option value="SULD00005">SULD00005</option>
                        </select> */}

                      </div>

                      <div>
                        <label
                          htmlFor="supplierNameInput"
                          className={Maintable.label}
                        >
                          Supplier ID.
                        </label>
                        {Suppliers.map((Data) => (

                          <input
                            type="text"
                            id="supplierNameInput"
                            name="supplierName"
                            className={Maintable.input}
                            value={supplierName}
                            readOnly
                          />
                        ))}
                      </div>
                      <div>
                        <label htmlFor="Address1" className={Maintable.label}>
                          Address
                          {/* <span className="text-red-600">*</span> */}
                        </label>
                        <input
                          type="text"
                          id="address1"
                          className={Maintable.input}
                          value={address1}
                          readOnly
                        />
                      </div>
                      <div>
                        <label htmlFor="Address2" className={Maintable.label}>
                          Address 2
                        </label>
                        <input
                          type="text"
                          id="address2"
                          className={Maintable.input}
                          value={address2}
                          readOnly
                        />
                      </div>
                      <div>
                        <label htmlFor="Address3" className={Maintable.label}>
                          Address 3
                        </label>
                        <input
                          type="text"
                          id="address3"
                          className={Maintable.input}
                          value={address3}
                          readOnly
                        />
                      </div>
                      <div>
                        <label htmlFor="city" className={Maintable.label}>
                          City
                          <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          id="city"
                          className={Maintable.input}
                          value={city}
                          readOnly
                        />
                      </div>
                      <div>
                        <label htmlFor="state" className={Maintable.label}>
                          State
                          {/* <span className="text-red-600">*</span> */}
                        </label>
                        <input
                          type="text"
                          id="state"
                          className={Maintable.input}
                          value={state}
                          readOnly
                        />
                      </div>
                      <div>
                        <label htmlFor="Country" className={Maintable.label}>
                          Country
                          {/* <span className="text-red-600">*</span> */}
                        </label>
                        <input
                          type="text"
                          id="country"
                          className={Maintable.input}
                          value={country}
                          readOnly
                        />
                      </div>
                      <div>
                        <label htmlFor="zipcode" className={Maintable.label}>
                          Zip/Pin Code
                        </label>
                        <input
                          type="number"
                          id="zipcode"
                          className={Maintable.input}
                          value={zipCode}
                          readOnly
                        />
                      </div>
                      <div>
                        <label class={Maintable.label} for="multiple_files">
                          Logo
                          {/* <span className="text-red-600">*</span> */}
                        </label>
                        {/* <input
                          name="cli_Logo"
                          class={Maintable.fileUpload}
                          id="multiple_files"
                          type="image"
                          alt="Image"
                        /> */}
                        <img
                          alt="PLASOIMG"
                          src={logo}
                          class="w-20 h-16 mt-4 flex"
                        />
                      </div>



                    <div className="col-span-2">
                      <label for="email" className={Maintable.label}>
                        Description
                      </label>
                      <div class={Maintable.input_richtextbox}>
                        <Container>
                          <ReactQuill
                            style={{ height: "200px" }}
                            theme="snow"
                            modules={modules}
                            formats={formats}
                            id="message"
                            name="cli_Description"
                          />
                        </Container>
                      </div>
                      {user_EmailError && (
                        <span className="text-red-500">{user_EmailError}</span>
                      )}
                    </div>
                    {/* <div>
                      <label for="passsword" className={Maintable.label}>
                        Unit of Measure
                      </label>
                      <select class={Maintable.input}>
                        <option>Select</option>
                        <option>KG</option>
                        <option>MT</option>
                        <option>LTMS</option>
                        <option>Nos</option>
                        <option>L</option>
                        <option>BDLS</option>
                        <option>SACKS</option>
                        <option>cm</option>
                        <option>°C</option>
                      </select>
                      
                      {user_PasswordError && (
                        <span className="text-red-500">
                          {user_PasswordError}
                        </span>
                      )}
                    </div> */}
                    {/* <div>
                      <label for="phone" className={Maintable.label}>
                        Opening Quantity
                      </label>
                      <input
                        type="number"
                        id="mobile"
                        name="user_Mobile"
                        value={user_Mobile}
                        placeholder="Opening Quantity"
                        onChange={(e) => {
                          setUser_Mobile(e.target.value);
                          setUser_PhoneNumberError(
                            user_Mobile.length > 0
                              ? /\d/.test(user_Mobile) &&
                                user_Mobile.length == 10
                                ? ""
                                : "Invalid Mobile Number"
                              : "Enter Mobile No"
                          );
                        }}
                        class={Maintable.input}
                        className={
                          user_PhoneNumberError
                            ? validationcolor.errorColor
                            : Maintable.input
                        }
                      />
                      {user_PhoneNumberError && (
                        <span className="text-red-500">
                          {user_PhoneNumberError}
                        </span>
                      )}
                    </div>

                    <div>
                      <label for="designation" className={Maintable.label}>
                        Opening Date
                      </label>
                      <input
                        type="date"
                        id="last_name"
                        name="user_Designation"
                        value={user_Designation}
                        placeholder="HR"
                        onChange={(e) => {
                          setUser_Designation(e.target.value);
                          setUser_DesignationError(
                            user_Department.length > 0
                              ? /[A-Za-z ]/.exec(user_Department)
                                ? ""
                                : "Only Alphabet and space allowed"
                              : "Enter First Name"
                          );
                        }}
                        class={Maintable.input}
                        className={
                          user_DesignationError
                            ? validationcolor.errorColor
                            : Maintable.input
                        }
                      />
                      {user_DesignationError && (
                        <span className="text-red-500">
                          {user_DesignationError}
                        </span>
                      )}
                    </div> */}
                    {/* <div>
                      <label for="phone" className={Maintable.label}>
                        Opening Quantity
                      </label>
                      <input
                        type="number"
                        id="mobile"
                        name="user_Mobile"
                        value={user_Mobile}
                        placeholder="Opening Quantity"
                        onChange={(e) => {
                          setUser_Mobile(e.target.value);
                          setUser_PhoneNumberError(
                            user_Mobile.length > 0
                              ? /\d/.test(user_Mobile) &&
                                user_Mobile.length == 10
                                ? ""
                                : "Invalid Mobile Number"
                              : "Enter Mobile No"
                          );
                        }}
                        class={Maintable.input}
                        className={
                          user_PhoneNumberError
                            ? validationcolor.errorColor
                            : Maintable.input
                        }
                      />
                      {user_PhoneNumberError && (
                        <span className="text-red-500">
                          {user_PhoneNumberError}
                        </span>
                      )}
                    </div> */}

                    {/* <div>
                      <label for="designation" className={Maintable.label}>
                        Opening Date
                      </label>
                      <input
                        type="date"
                        id="last_name"
                        name="user_Designation"
                        value={user_Designation}
                        placeholder="HR"
                        onChange={(e) => {
                          setUser_Designation(e.target.value);
                          setUser_DesignationError(
                            user_Department.length > 0
                              ? /[A-Za-z ]/.exec(user_Department)
                                ? ""
                                : "Only Alphabet and space allowed"
                              : "Enter First Name"
                          );
                        }}
                        class={Maintable.input}
                        className={
                          user_DesignationError
                            ? validationcolor.errorColor
                            : Maintable.input
                        }
                      />
                      {user_DesignationError && (
                        <span className="text-red-500">
                          {user_DesignationError}
                        </span>
                      )}
                    </div> */}
                    {/* <div>
                      <label for="Department" className={Maintable.label}>
                        Department
                      </label>
                      <input
                        type="text"
                        id="Department"
                        name="user_Department"
                        value={user_Department}
                        placeholder="Developer"
                        onChange={(e) => {
                          setUser_Department(e.target.value);
                          setUser_DepartmentError(
                            user_Department.length > 0
                              ? /[A-Za-z ]/.exec(user_Department)
                                ? ""
                                : "Only Alphabet and space allowed"
                              : "Enter First Name"
                          );
                        }}
                        class={Maintable.input}
                        className={
                          user_DepartmentError
                            ? validationcolor.errorColor
                            : Maintable.input
                        }
                      />
                      {user_DepartmentError && (
                        <span className="text-red-500">{user_DepartmentError}</span>
                      )}
                    </div> */}
                    {/* <div>
                      <label for="Department" className={Maintable.label}>
                        Role
                      </label>
                      <select className={Maintable.input} onChange={(e) => {setUser_Role(e.target.value)}}>
                        <option value="select">Select</option>
                        <option value="user">User</option>
                        <option value="admin">Admin</option>
                        <option value="superadmin">Super Admin</option>
                      </select>

                      {user_RoleError && (
                        <span className="text-red-500">{user_RoleError}</span>
                      )}
                    </div> */}
                    {/* <div>
                      <label className={Maintable.label} for="multiple_files">
                        Photo
                      </label>
                      <input
                        className={Maintable.fileUpload}
                        onChange={(e) => setUser_Image_File(e.target.files[0])}
                        name="user_Image"
                        id="profile"
                        type="file"
                        multiple
                      /> */}
                    {/* <BsFillCheckCircleFill className="hi" />
                              <BsExclamationCircleFill className="hello" /> */}
                    {/* </div> */}
                    {/* <div className="flex">
                      <div className="mt-7 mr-7">
                        <button
                          type="button"
                          className={Maintable.submitButton}
                          onClick={() => handleImageUpload()}
                        >
                          UPLOAD
                        </button>
                      </div>
                      <div>
                        <input
                          type="image"
                          src={user_Image}
                          alt="Image"
                          className="rounded-full w-20 h-20"
                        />
                      </div>
                    </div> */}
                    {/*<div className="flex">
                    <div className="flex-col">
                    <label className={Maintable.label} for="multiple_files">
                        Photo
                      </label>
                      <input
                        className={Maintable.fileUpload}
                        onChange={(e) => setUser_Image_File(e.target.files[0])}
                        name="user_Image"
                        id="profile"
                        type="file"
                        multiple
                      />
                    </div>
                      
                      <div className="mt-7 mx-7">
                        <button
                          type="button"
                          className={Maintable.submitButton}
                          onClick={() => handleImageUpload()}
                          UPLOAD
                          >
                      </button>
                      </div>
                      <div>
                        <input
                          type="image"
                          src={user_Image}
                          alt="Image"
                          className="rounded-full w-20 h-20"
                        />
                      </div>
                    </div>*/}
                  </div>

                </form>
                <div className={Maintable.submitCancelMainDiv}>
                  {submitButton && (
                    <button
                      data-modal-hide="bottom-right-modal"
                      type="button"
                      className={Maintable.submitButton}
                      onClick={() => HandleSubmit(0, 1)}
                    >
                      SUBMIT
                    </button>
                  )}
                  {updateButton && (
                    <button
                      data-modal-hide="bottom-right-modal"
                      type="submitButton"
                      className={Maintable.submitButton}
                      onClick={() => HandleSubmit(0, 2)}
                    >
                      UPDATE
                    </button>
                  )}
                  {/* <ToastContainer /> */}
                  <button
                    data-modal-hide="bottom-right-modal"
                    type="button"
                    className={Maintable.cancelButton}
                    onClick={cancel}
                  >
                    CANCEL
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={() => setpopUpOpen(true)}
      >
        <div>
          <button className="w-full">
            <span
              className="text-white flex justify-end"
              onClick={() => setpopUpOpen(false)}
              class="text-2xl p-0 m-o block float-right mr-3"
            >
              &times;
            </span>
          </button>
          <UserDetails data={currentData} />
        </div>
        <div className={Maintable.submitCancelMainDiv}>
          {deleteButton && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={Maintable.submitButton}
              onClick={() => HandleSubmit(rowIndex, 3)}
            >
              Delete
            </button>
          )}

          {blockButton && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={Maintable.submitButton}
              onClick={() => HandleSubmit(rowIndex, 4)}
            >
              Block
              {/* {currentData.Emp_Blockedby == 1 ? "Unblock" : "Block"}*/}
            </button>
          )}
          {unBlockButton && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={Maintable.submitButton}
              onClick={() => HandleSubmit(rowIndex, 5)}
            >
              Unblock
              {/* {(currentData.Emp_Blockedby == 1) ? "Unblock" : "Block"} */}
            </button>
          )}

          <button
            data-modal-hide="bottom-right-modal"
            type="button"
            className={Maintable.cancelButton}
            onClick={() => setpopUpOpen(false)}
          >
            CANCEL
          </button>
        </div>
      </MainPopup>
      {/*<div className="modal-design">
        <Popup
          // open={viewDetails}
          closeOnDocumentClick={false}
          onClose={() => setViewDetails(false)}
        >
          <div className="modal-reset">
            <button className="close" onClick={() => setViewDetails(false)}>
              &times;
            </button>
            <div className="modal-content">
              <UserDetails data={currentData} />
            </div>
          </div>
          <div className={Maintable.submitCancelMainDiv}>
            {deleteButton && (
              <button
                data-modal-hide="bottom-right-modal"
                type="button"
                className={Maintable.submitButton}
                onClick={() => HandleSubmit(rowIndex, 3)}
              >
                Delete
              </button>
            )}

            {blockButton && (
              <button
                data-modal-hide="bottom-right-modal"
                type="button"
                className={Maintable.submitButton}
                onClick={() => HandleSubmit(rowIndex, 4)}
              >
                Block
              </button>
            )}
            {unBlockButton && (
              <button
                data-modal-hide="bottom-right-modal"
                type="button"
                className={Maintable.submitButton}
                onClick={() => HandleSubmit(rowIndex, 5)}
              >
                Unblock
              </button>
            )}

            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={Maintable.cancelButton}
              onClick={() => setViewDetails(false)}
            >
              CANCEL
            </button>
          </div>
        </Popup>
            </div>*/}
            <MainPopup

popUpOpen={viewSupplierDetails}
setpopUpOpen={setViewSupplierDetails}
Open={HandleSupplier}
handlePopupClick={true}
>
<div>
  <button class="w-full">
    <span
      className="text-white flex justify-end"
      onClick={handleClose}
      class="text-2xl p-0 m-o block float-right mr-3"
    >
      &times;
    </span>
  </button>
  <SupplierDetails data={currentData} />
</div>
</MainPopup>
    </MainLayout>
  );
}

export default ReturntoSupplier;
