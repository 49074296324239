import React from "react";
import MainLayout from "../components/Mainlayout";
import PieRechartComponent from "../popup-containers/Piechart";
import PieRechartComponent2 from "../popup-containers/PieChart2";
const Dashboard = () => {
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString("en-GB");

  return (
    <MainLayout>
      <div>
        <div className="container mx-auto ">
          <div className="w-full bg-[#f7f7f8] px-2 md:px-0 mb-16 text-gray-800 leading-normal">
            <div class="flex justify-start ml-4 py-2">
              <button
                type="button"
                class="inline-block px-6 py-3 mr-3 font-bold text-center uppercase align-middle transition-all bg-transparent bg-white border rounded shadow cursor-pointer leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs text-black"
              >
                Y.T.D.
              </button>{" "}
              <button
                type="button"
                class="inline-block px-6 py-3 mr-3 font-bold text-center uppercase align-middle transition-all bg-transparent bg-white border rounded shadow  cursor-pointer leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs text-black"
              >
                M.T.D.
              </button>{" "}
            </div>

            <div class="flex flex-wrap">
              <div className="w-full md:w-full xl:w-1/2 2xl:w-1/2 p-4 ">
                <div className="bg-white border rounded shadow p-2 h-72  justify-center">
                  <div className="flex flex-row items-center">
                    <div className="flex-1  text-center my-12 flex flex-col mt-20">
                      <div>
                        <h5 className="font-bold uppercase text-gray-500 text-3xl mb-4 sm:text-center">
                          Sales
                        </h5>
                      </div>

                      <div class="flex justify-center">
                        <svg
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          height="3rem"
                          width="3rem"
                        >
                          <path
                            fillRule="evenodd"
                            d="M0 0h1v15h15v1H0V0zm10 3.5a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v4a.5.5 0 01-1 0V4.9l-3.613 4.417a.5.5 0 01-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 01-.808-.588l4-5.5a.5.5 0 01.758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 01-.5-.5z"
                          />
                        </svg>
                      </div>

                      <h3 className="font-bold text-xl mt-4 sm:text-center">
                        GH₵‎ 35,265,015{" "}
                        <span className="">
                          {/* <i className="fas fa-caret-up"></i> */}
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-full xl:w-1/2 2xl:w-1/2 p-4">
                <div className="bg-white border rounded shadow p-2 h-72 justify-center">
                  <div className="flex flex-row items-center">
                    <div className="flex-1  text-center my-12  flex flex-col mt-20">
                      <div>
                        <h5 className="font-bold uppercase text-gray-500 text-2xl mb-4">
                          Production
                        </h5>
                      </div>
                      <div class="flex justify-center">
                        <svg
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          height="3rem"
                          width="3rem"
                          class="flex items-center"
                        >
                          <path d="M18.41 4L16 6.41v.18L18.41 9H22v2h-4.41L16 9.41V12h-1a2 2 0 01-2-2V7.5H9.86c-.09.37-.24.72-.44 1.05L15.18 19H20a2 2 0 012 2v1H2v-1a2 2 0 012-2h6.61l-4.69-8.5a4.006 4.006 0 01-3.81-3A4 4 0 015 2.63c2.13-.55 4.31.73 4.86 2.87H13V3a2 2 0 012-2h1v2.59L17.59 2H22v2h-3.59M6 4.5a2 2 0 00-2 2 2 2 0 002 2 2 2 0 002-2 2 2 0 00-2-2z" />
                        </svg>
                      </div>

                      <h3 className="font-bold text-xl mt-4">
                        47,295,342 MT{" "}
                        <span className="text-green-500">
                          <i className="fas fa-caret-up"></i>
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <hr className="border-b-2 border-gray-400 my-8 mx-4" /> */}
            <div className="flex flex-row flex-wrap flex-grow mt-2">
              <div className="w-full md:w-full lg:w-1/2 p-3 h-1/3">
                <div className="bg-white border rounded shadow ">
                  <div className="shadow-lg rounded-lg overflow-hidden h-72 justify-center">
                    <div className="flex justify-center lg:mt-12">
                      <PieRechartComponent />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-full lg:w-1/2 p-3">
                <div className="bg-white border rounded shadow">
                  <div className="shadow-lg rounded-lg overflow-hidden h-72 justify-center">
                    <div className="flex justify-center lg:mt-12">
                      <PieRechartComponent2 />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Dashboard;
