import React from "react";
import { Maintable } from "../css/Common";
import PLASCO from "../image/PLASCO1.png";

const SupplierDetails = () => {
  return (
    <div class="flex">
      <div class=" flex flex-col w-full">
        <div class={Maintable.clientMasterMainAdd}>
          <div class={Maintable.addScreenTitle}>{"Supplier Details"}</div>

          <div class="px-6 py-2 lg:px-8">
            <form class={Maintable.formForClientMaster}>
              {/* <div class={Maintable.labelBold}>
                                    Purchase Order Details
                                </div>
                                <hr class={Maintable.horizontalLine}></hr>
                                <div class="grid gap-4 mb-6 md:grid-cols-3">
                                    <div>
                                        <label for="client_id" class={Maintable.label}>
                                            PO Number
                                            <span className="text-red-600">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="first_name"
                                            value="PO1001"
                                            class={Maintable.input}
                                        />
                                    </div>
                                    <div>
                                        <label for="client_name" class={Maintable.label}>
                                            PO Date
                                            <span className="text-red-600">*</span>
                                        </label>
                                        <input
                                            type="date"
                                            id="podate"
                                            class={Maintable.input}
                                            value="{2023-06-15}"
                                        />
                                    </div>
                                    <div>
                                        <label for="client_name" class={Maintable.label}>
                                            Expected Date
                                            <span className="text-red-600">*</span>
                                        </label>
                                        <input
                                            type="date"
                                            id="expirydate"
                                            class={Maintable.input}
                                        />
                                    </div>
                                </div> */}

              {/* <div class={Maintable.labelBold}>Supplier Details</div> */}
              <hr class={Maintable.horizontalLine}></hr>
              <div class="grid gap-4 mb-6 md:grid-cols-3">
                <div>
                  <label for="client_id" class={Maintable.label}>
                    Supplier Id
                    <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    id="supplierId"
                    name="supplierId"
                    className={Maintable.input}
                    value="SUPLR00001"
                    readOnly
                  />
                </div>

                <div>
                  <label
                    htmlFor="supplierNameInput"
                    className={Maintable.label}
                  >
                    Supplier Name:
                  </label>

                  <input
                    type="text"
                    id="supplierNameInput"
                    name="supplierName"
                    className={Maintable.input}
                    value="Plasco Engineering Inc."
                    readOnly
                  />
                </div>
                <div>
                  <label htmlFor="Address1" className={Maintable.label}>
                    Address<span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    id="address1"
                    className={Maintable.input}
                    value="No. 90 "
                    readOnly
                  />
                </div>
                <div>
                  <label htmlFor="Address2" className={Maintable.label}>
                    Address 2
                  </label>
                  <input
                    type="text"
                    id="address2"
                    className={Maintable.input}
                    value="Nanpi Rd"
                    readOnly
                  />
                </div>
                <div>
                  <label htmlFor="Address3" className={Maintable.label}>
                    Address 3
                  </label>
                  <input
                    type="text"
                    id="address3"
                    className={Maintable.input}
                    value=" Nanpi Rewan"
                    readOnly
                  />
                </div>
                <div>
                  <label htmlFor="city" className={Maintable.label}>
                    City
                    <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    id="city"
                    className={Maintable.input}
                    value=" Taibao"
                    readOnly
                  />
                </div>
                <div>
                  <label htmlFor="state" className={Maintable.label}>
                    State
                    <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    id="state"
                    className={Maintable.input}
                    value=" Taibao"
                    readOnly
                  />
                </div>
                <div>
                  <label htmlFor="Country" className={Maintable.label}>
                    Country
                    <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    id="country"
                    className={Maintable.input}
                    value=" Chiayi"
                    readOnly
                  />
                </div>
                <div>
                  <label htmlFor="zipcode" className={Maintable.label}>
                    Zip/Pin Code
                  </label>
                  <input
                    type="text"
                    id="zipcode"
                    className={Maintable.input}
                    value="61251 "
                    readOnly
                  />
                </div>
                <div>
                  <label class={Maintable.label} for="multiple_files">
                    Logo
                    <span className="text-red-600">*</span>
                  </label>
                  <input
                    name="cli_Logo"
                    class={Maintable.fileUpload}
                    id="multiple_files"
                    type="text"
                    alt="Image"
                    value="/https.plascologo.png"
                  />
                </div>
                <img alt="PLASOIMG" src={PLASCO} class="w-20 h-16 mt-4 flex" />
              </div>
              <div className="flex self-end">
                {/* <div className="text-red-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner w-fit h-9 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">
                        <span className="mb-12 text-red-600 text-7xl">.</span>
                        <span>Block</span>
                      </div> */}
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* )} */}
    </div>
  );
};
export default SupplierDetails;
