const Topmenu = {
  Topmenu: "flex justify-between border-b-2 p-4 bg-black",
  Logo1: "text-sm text-white opacity-50 font-bold",
  Logo2: "text-sm text-[#ff5349] font-bold",
  Menu: "flex items-center",
  Menulist: "list-none ml-4 text-sm text-white font-bold pr-4 break-normal",
  image: "w-1/12 h-1/12 rounded-lg",
};

const Leftmenu = {
  Leftmenu:
    "relative px-4 pt-4 py-4 w-fit h-screen border-r mt-0 leading-8 bg-black",
  Menulist:
    "list-none ml-4 text-sm text-white font-bold pr-4 py-4 whitespace-nowrap",
};

const Common = {
  Main: "w-full p-8 leading-8 flex flex-col",
  Mainheading: " text-2xl font-medium my-5",
  Label: "block mb-2 text-sm font-medium text-gray-900 dark:text-black",
  Input:
    "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-500 dark:placeholder-gray-400 dark:text-black",
  Text: "text-sm text-black h-9 mb-2",
  Backbtn:
    "text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10  dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600",
  Nxtbtn:
    "text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800",
};
const maincontent = {
  maincontent: "w-2/5 mx-auto",
};

const Maintable = {
  /*....table design css...*/
  headerTitle:
    "lg:text-xl md:text-xl text-sm font-medium  items-center  text-neutral-700 ",
  tableShadowWidth:
    "shadow-2xl shadow-gray-400 rounded-lg  w-full max-2xl:w-full ",
  searchAdd: "pb-2 mt-1 bg-white flex gap-2 justify-between",
  addButton:
    "text-white bg-[#24292F] h-9 hover:bg-[#24292F]/90 focus:ring-4 focus:outline-none focus:ring-[#24292F]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 dark:hover:bg-[#050708]/30 mr-2 mb-2 ",
  tableVisibility: `md:block hidden `,
  Table:
    "relative z-0 flex flex-col md:block hidden text-sm text-left text-gray-500 w-full ",
  Table1:
    " z-0 flex flex-row md:flex-col block md:hidden text-sm text-left text-gray-500 w-full h-96 overflow-y-auto",
  tableHeader:
    "text-xs flex md:flex-row flex-col whitespace-nowrap bg-black text-white capitalize cursor-pointer ",
  tableHeaderColumn: "px-2 py-3",
  tableRow:
    "flex md:flex-row relative flex-wrap md:flex-nowrap bg-white hover:bg-gray-50 dark:hover:bg-gray-600",
  tableData: "px-2 py-1  md:py-3 flex-1 bg-white",
  searchIconPosition:
    "absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none",
  searchIconSvg: "w-5 h-5 text-gray-500 dark:text-gray-400",
  searchInputBox:
    "block p-2 pl-10 text-sm text-gray-900 border  rounded-lg w-full bg-gray-50 focus:ring-blue-500 focus:border-blue-500 ",

  dropDownRecords:
    "block px-2 text-sm text-gray-900 border border-gray-300 rounded-lg w-20 h-9 bg-gray-50 focus:ring-blue-500 focus:border-blue-500",

  mobileTable: "shadow-2xl shadow-gray-400 rounded-lg lg:mx-2 md:mx-2 my-4 rounded-lg",

  filterSubmitBtn:
    "block p-3 border justify-center border-white text-white bg-[#24292F] hover:bg-[#24292F]/90 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm mt-2 border-gray-300 flex items-center rounded-lg w-24 h-7 focus:ring-blue-500 focus:border-blue-500",

  /*.....Add screen Design...*/

  topSpaceContent: "mt-3 py-4",
  firstMainAddScreen: "mx-auto w-2/5 border-black",
  secondBoxScreen: "relative h-full max-w-full md:h-auto mx-16",
  addShadowScreen: "relative bg-white rounded-lg shadow-2xl lg:mx-0 md:mx-0 mx-4 shadow-gray-400 ",
  mainContentScreen: "px-6 py-6 lg:px-8",
  addScreenTitle:
    "my-2 mx-4 lg:mx-0 md:px-14 lg:text-xl md:text-xl text-sm font-medium text-neutral-700 dark:text-black",
  formContentSpace: "space-y-6 mt-3 py-4",
  labelBold:
    "block mb-2 text-sm capitalize font-bold text-neutral-700 dark:text-black",
  labelAmount:
    "block my-2 text-md capitalize font-bold text-neutral-700 dark:text-black",
  label:
    "block mb-2 text-sm capitalize font-medium text-neutral-700 dark:text-black",
  label_1:
    "block text-sm capitalize font-medium text-neutral-700 dark:text-black",
  input:
    "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-400 dark:placeholder-gray-400 dark:text-black",
  input1:
    "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-0.5  dark:border-gray-500 dark:placeholder-gray-400 dark:text-black",
  input_richtextbox:
    "bg-gray-50 text-gray-900 rounded-lg text-sm w-full  dark:border-gray-500 dark:placeholder-gray-400 dark:text-black",
  radioButtonLabel:
    "inline-flex items-center justify-between h-12 w-16 p-4 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400  dark:hover:bg-gray-700",
  radioFontWeight: "w-full text-lg font-semibold",
  textArea:
    "block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500  dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500",
  submitCancelMainDiv:
    "flex justify-center p-3 space-x-2 items-baseline border-gray-200 rounded-b dark:border-gray-600",
  submitButton:
    "text-white bg-[#24292F] hover:bg-[#24292F]/90 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center  dark:focus:ring-blue-800",
  cancelButton:
    "text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10  dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600",

  /*.....Client Master...*/

  clientMasterMainAdd: "border-black",
  formForClientMaster: "overflow-y-scroll p-5 max-h-[60vh]",
  horizontalLine: "h-px mb-2 bg-gray-200 border-0 ",

  /*....Employee Master...*/

  gird3: "grid gap-4 mb-6 md:grid-cols-3 sm:col-span-1",

  /*....Leave Master...*/

  grid1: "grid gap-6 mb-6 md:grid-cols-1",

  /*.....Time Master...*/

  grid2: "grid gap-6 mb-6 md:grid-cols-2 sm:col-span-2",
  grid1_2: "inline-grid gap-6 w-full mb-6 md:grid-cols-2 sm:col-span-2",
  gird7: "grid gap-4 mb-6 md:grid-cols-7 sm:col-span-1",
  gird9: "grid gap-4 mb-6 md:grid-cols-10 sm:col-span-1 items-baseline",

  /*.....User Master...*/

  fileUpload:
    "block w-full p-2.5 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none  dark:border-gray-600 dark:placeholder-gray-400",

  /*.....Role vs Capability...*/

  roleCapLabel: "text-sm font-medium text-black dark:text-gray-300",
  underlistRoleCap:
    "flex w-auto text-sm font-medium rounded-lg dark:text-black",
  smallCheckBox:
    "w-4 h-4 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2  dark:border-gray-500",
  smailCheckboxLabel:
    "w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300",
  largeCheckbox:
    "w-4 h-4 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2  dark:border-gray-600",

  /*......Signup...*/

  signupBackground: "bg-gray-50",
  signMainContent:
    "flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0",
  signupShadow:
    "w-full bg-gray-900 rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0  dark:border-gray-700",
  signupSpaceing: "p-6 space-y-4 md:space-y-6 sm:p-8",
  signupTitle:
    "text-xl font-bold leading-tight tracking-tight text-white md:text-lg dark:text-black",
  formSpace: "space-y-4 md:space-y-6",
  signupLabel: "block mb-2 text-sm font-medium text-white dark:text-black",
  signupInput:
    "bg-slate-100 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500",

  /*.....Login....*/
};

const validationcolor = {
  errorColor:
    "bg-gray-50 border-2 border-rose-600 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-500 dark:placeholder-gray-400 dark:text-black",
  successColor:
    "bg-gray-50 border-2 border-green-600 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-500 dark:placeholder-gray-400 dark:text-black",
};

const menuActive = {
  active: "bg-gray-700 px-3 py-2 rounded-md",
};

// const Common = {
//     Topmenu:'flex bg-[#141023] border border-b-slate-300 justify-between',
//     title: 'text-2xl text-neutral-300 my-5',
//     lable: 'text-sm text-white opacity-50 mb-2',
//     input: 'border border-white text-white bg-[#141023] rounded-lg mb-2',
//     text: 'text-sm text-white opacity-50 mb-2',
//     backbtn: 'w-40 h-8 border rounded-lg text-white',
//     nxtbtn: 'w-40 h-8 rounded-lg float-right bg-[#3C5DC7] text-white',
//     tableheadw36: 'text-sm text-white opacity-50 mb-2 w36'
// }

// const Vendor = {
//     title: 'text-2xl text-black my-5 text-center',
//     col: 'flex flex-col basis-full',
//     lable: 'text-sm text-black mb-2 flex basis-1/3',
//     rgtlable: 'text-sm text-black mb-2 flex basis-1/3 justify-end',
//     bord: 'border border-black border-opacity-50 rounded-lg p-5 w-1/2 m-auto mt-12 flex flex-col basis-full',
//     input: ' border border-black border-opacity-50 text-black rounded-lg mb-2 flex basis-2/3 h-8 w-full',
//     inptextArea: ' border border-black border-opacity-50 text-black rounded-lg mb-2 flex basis-2/3 h-full w-full',
//     text: 'text-sm text-black mb-2',
//     backbtn: 'w-40 h-8 border rounded-lg text-white',
//     nxtbtn: 'w-40 h-8 rounded-lg float-right bg-[#3C5DC7] text-white mt-5',
//     tableheadw36: 'text-sm text-white opacity-50 mb-2 w36'
// }

export { menuActive };
export { Topmenu };
export { Leftmenu };
export { Common };
export { maincontent };
export { Maintable };
export { validationcolor };
