import React from "react";
import MainLayout from "../components/Mainlayout";
import { Common, Maintable } from "../css/Common";
import TableGrid from "../components/table";
import { TODHeader, TODData } from "../components/headerAttribute";
import { useState } from "react";
import Select from "react-select";

const maincontent = {
  maincontent: "w-2/5 mx-auto",
};

const TransferOtherDepartment = () => {
  const [visibleTable, setVisibleTable] = useState(true);
  const [visibleAdd, setVisibleAdd] = useState(false);

  const toggleData = () => {
    setVisibleTable(false);
    setVisibleAdd(true);
  };

  const cancel = () => {
    setVisibleTable(true);
    setVisibleAdd(false);
  };
  const [bindUOMDD, setBindUOMDD] = useState([
    { value: "MTRS", label: "MTRS" },
    { value: "km", label: "km" },
    { value: "meters", label: "meters" },
    { value: "cm", label: "cm" },
    { value: "ft", label: "ft" },
    { value: "lb", label: "lb" },
    { value: "KG", label: "KG" },
    { value: "oz", label: "oz" },
    { value: "s", label: "s" },
    { value: "min", label: "min" },
    { value: "hr", label: "hr" },
    { value: "°C", label: "°C" },
    { value: "°F", label: "°F" },
    { value: "K", label: "K" },
    { value: "L", label: "L" },
    { value: "mL", label: "mL" },
    { value: "gal", label: "gal" },
    { value: "lbf", label: "lbf" },
    { value: "N", label: "N" },
    { value: "Pa", label: "Pa" },
    { value: "bar", label: "bar" },
    { value: "MT", label: "MT" },
    { value: "MTRS", label: "MTRS" },
    { value: "N/A", label: "N/A" },
    { value: "BDLS", label: "BDLS" },
    { value: "SACKS", label: "SACKS" },
    { value: "pcs", label: "pcs" },
  ]);
  const [bindMaterialDD, setBindMaterialDD] = useState([
    { value: "Acrylic RMAD00001", label: "Acrylic RMAD00001",uom: "KG" },
    { value: "Lamination Film BLAM00001", label: "Lamination FilmBLAM00001",uom: "KG" },

    { value: "Pigments RMIN00001", label: "Pigments RMIN00001",uom: "KG" },
    {
      value: "Plain Shrink Film PSF00001",
      label: "Plain Shrink Film PSF00001",uom: "KG" 
    },
    { value: "Polymer RMLD00001", label: "Polymer RMLD00001",uom: "SACKS"  },
    { value: "Polyethylene RMHD00001", label: "Polyethylene RMHD00001",uom: "KG"  },
    { value: "Polymers RMSD00001", label: "Polymers RMSD00001",uom: "KG"  },
    {
      value: "Printed Shrink Film PSF00002",
      label: "Printed Shrink Film PSF00002",uom: "MTRS" 
    },
    {
      value: "Shrink Film for Water Pouch PSF00003",
      label: "Shrink Film for Water Pouch PSF00003",uom: "KG" 
    },
    { value: "Solvents RMSL00001", label: "Solvents RMSL00001",uom: "L"  },
    {
      value: "Thinwall Food Containers TFC00001",
      label: "Thinwall Food Containers TFC00001",uom: "KG" 
    },
  ]);
  const [fromDepartment, setFromDepartment] = useState([
    { value: "blowfilm", label: "Blow Film" },
    { value: "fgstore", label: "F.G. Store" },
    { value: "injectionmoulding", label: "Injection Moulding" },
    { value: "lamination", label: "Lamination" },
    { value: "printing", label: "Printing" },
    { value: "reproduction", label: "Reproduction" },
    { value: "rmstore", label: "R.M. Store" },
    { value: "slitting", label: "Slitting" },

  ]);
  const [toDepartment, setToDepartment] = useState([
    { value: "blowfilm", label: "Blow Film" },
    { value: "printing", label: "Printing" },
    { value: "slitting", label: "Slitting" },
    { value: "lamination", label: "Lamination" },
    { value: "reproduction", label: "Reproduction" },
    { value: "injectionmoulding", label: "Injection Moulding" },
    { value: "fgstore", label: "F.G. Store" },
    { value: "rmstore", label: "R.M. Store" },
    
  ]);
  const [materialCode, setMaterialCode] = useState("");
  const [itemDes, setItemDes] = useState("");
  const [uom,setUom ] = useState("");
  return (
    <MainLayout>
      <div class="flex">
        {visibleTable && (
          <div class="relative w-full mx-auto">
            <div class={Maintable.headerTitle}>
              <div class="my-2 ml-8"> {`Inventory -> Transfer to Other Department`}</div>
            </div>
            <div className="mx-8">
              <div className="md:justify-between md:flex  md:items-center">
                <div className={Maintable.searchAdd}>
                  <div className="mt-1 gap-2 flex justify-between items-baseline">
                    <label htmlFor="pageSize" className="hidden md:block">
                      Rows Per Page:
                    </label>
                    <select
                      className={Maintable.dropDownRecords}
                      name="pageSize"
                      id="pageSize"
                    >
                      <option value="2">2</option>
                      <option value="10" selected>
                        10
                      </option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor="table-search" className="sr-only">
                      Search
                    </label>
                  </div>
                  <div className="relative mt-1">
                    <div className={Maintable.searchIconPosition}>
                      <svg
                        className={Maintable.searchIconSvg}
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <input
                      type="text"
                      id="table-search"
                      className={Maintable.searchInputBox}
                      placeholder="Search for items"
                    />
                  </div>
                </div>
                <div className="flex self-end">
                  <div className="text-red-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner w-fit h-9 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">
                    <span className="mb-12 text-red-600 text-7xl">.</span>
                    <span>Block</span>
                  </div>
                  <button
                    type="button"
                    className={Maintable.addButton}
                    onClick={toggleData}
                  >
                    <svg
                      width="25"
                      height="20"
                      viewBox="0 0 50 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22.5 37.5H27.5V27.5H37.5V22.5H27.5V12.5H22.5V22.5H12.5V27.5H22.5V37.5ZM25 50C21.5417 50 18.2917 49.3433 15.25 48.03C12.2083 46.7183 9.5625 44.9375 7.3125 42.6875C5.0625 40.4375 3.28167 37.7917 1.97 34.75C0.656667 31.7083 0 28.4583 0 25C0 21.5417 0.656667 18.2917 1.97 15.25C3.28167 12.2083 5.0625 9.5625 7.3125 7.3125C9.5625 5.0625 12.2083 3.28083 15.25 1.9675C18.2917 0.655833 21.5417 0 25 0C28.4583 0 31.7083 0.655833 34.75 1.9675C37.7917 3.28083 40.4375 5.0625 42.6875 7.3125C44.9375 9.5625 46.7183 12.2083 48.03 15.25C49.3433 18.2917 50 21.5417 50 25C50 28.4583 49.3433 31.7083 48.03 34.75C46.7183 37.7917 44.9375 40.4375 42.6875 42.6875C40.4375 44.9375 37.7917 46.7183 34.75 48.03C31.7083 49.3433 28.4583 50 25 50ZM25 45C30.5833 45 35.3125 43.0625 39.1875 39.1875C43.0625 35.3125 45 30.5833 45 25C45 19.4167 43.0625 14.6875 39.1875 10.8125C35.3125 6.9375 30.5833 5 25 5C19.4167 5 14.6875 6.9375 10.8125 10.8125C6.9375 14.6875 5 19.4167 5 25C5 30.5833 6.9375 35.3125 10.8125 39.1875C14.6875 43.0625 19.4167 45 25 45Z"
                        fill="white"
                      />
                    </svg>
                    ADD
                  </button>
                </div>
              </div>
              <TableGrid
                className={Maintable.Table}
                headerData={TODHeader}
                tableData={TODData}
              />
              <div className={Maintable.submitCancelMainDiv}>
                <button
                  // disabled={currentPage1 === 1}
                  // onClick={() => setCurrentPage(currentPage1 - 1)}
                  type="button"
                  className={Maintable.submitButton}
                >
                  Previous
                </button>
                <label>1</label>
                <button
                  // disabled={currentPage1 === totalPages / result}
                  // onClick={() => setCurrentPage(currentPage1 + 1)}
                  type="button"
                  className={Maintable.cancelButton}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        )}
        {visibleAdd && (
          <div className="flex flex-col w-full">
            <div className={Maintable.clientMasterMainAdd}>
              <div className={Maintable.addScreenTitle}>
                {"Inventory -> Transfer to Other Department -> Add"}
              </div>
              <div className={Maintable.addShadowScreen}>
                <div className={Maintable.mainContentScreen}>
                  <form className={Maintable.formForClientMaster}>
                    <div className={Maintable.labelBold}>Department</div>
                    <hr className={Maintable.horizontalLine} />
                    <div className={Maintable.grid2}>
                      <div>
                        <label className={Maintable.label}>
                          Transfer Note No.
                          <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Transfer Note No"
                          className={Maintable.input}
                        />
                      </div>
                      <div>
                        <label className={Maintable.label}>
                          Date
                          <span className="text-red-600">*</span>
                        </label>
                        <input type="date" className={Maintable.input} />
                      </div>
                      <div>
                        <label className={Maintable.label}>
                          Item type
                          <span className="text-red-600">*</span>
                        </label>
                        <select className={Maintable.input}>
                          <option>Select</option>
                          <option>R.M.</option>
                          <option>F.G.</option>
                          <option>Others</option>
                        </select>
                      </div>
                      <div>
                        <label className={Maintable.label}>
                          Item Name
                          <span className="text-red-600">*</span>
                        </label>
                        <Select
                          //  Itcode={bindMaterialDD.value.split(-10)}
                          // Itcode2=split{()}
                          options={bindMaterialDD}
                          
                          // options = {bindMaterialDD.value.split(-10)}
                          onChange={(value) => {
                            console.log(value.value, value.value.slice(-9),value.uom);
                            setItemDes(value.value.slice(0, -9));
                            setMaterialCode(value.value.slice(-9));
                            setUom(value.uom)
                          }}
                          className={Maintable.input1}
                        />
                        {/* <input
                          type="text"
                          placeholder="Item Discription"
                          className={Maintable.input}
                        /> */}
                      </div>
                      <div>
                        <label for="first_name" className={Maintable.label}>
                          Item Code
                        </label>
                        <input
                          type="text"
                          name="materialCode"
                          value={materialCode}
                          placeholder="Item Discription"
                          className={Maintable.input}
                        />
                        {/* <Select
                          options= {bindMaterialDD}
                          className={Maintable.input1}
                        /> */}
                      </div>
                      <div>
                        <div className={Maintable.grid1_2}>
                          <div>
                            <label for="passsword" className={Maintable.label}>
                              U.O.M.
                            </label>
                            {/* <Select
                              options={bindUOMDD}
                              className={Maintable.input1}
                            /> */}
                            <input
                              type="Text"
                              name="user_Mobile"
                              placeholder="U.O.M."
                              class={Maintable.input}
                              value={uom}
                              readOnly
                            />
                          </div>
                          <div>                              

                            <label for="phone" className={Maintable.label}>
                              Quantity
                              <span className="text-red-600">*</span>

                            </label>
                            <input
                              type="number"
                              name="user_Mobile"
                              placeholder="Opening Quantity"
                              class={Maintable.input}
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <label className={Maintable.label}>
                          From Department
                          <span className="text-red-600">*</span>
                        </label>
                        <Select
                          options={fromDepartment}
                          className={Maintable.input1}
                        />
                      </div>
                      <div>
                        <label className={Maintable.label}>
                          To Department
                          <span className="text-red-600">*</span>
                        </label>
                        <Select
                          options={toDepartment}
                          className={Maintable.input1}
                        />
                      </div>
                    </div>
                    <div>
                      <label className={Maintable.label}>Remarks</label>
                      <textArea
                        id="message"
                        rows="4"
                        class={Maintable.textArea}
                        placeholder="Remarks"
                      ></textArea>
                    </div>

                    {/* End of existing input boxes */}
                   
                  </form>
                  <div className={Maintable.submitCancelMainDiv}>
                      <button
                        data-modal-hide="bottom-right-modal"
                        type="button"
                        className={Maintable.submitButton}
                      >
                        SUBMIT
                      </button>
                      <button
                        data-modal-hide="bottom-right-modal"
                        type="button"
                        className={Maintable.cancelButton}
                        onClick={cancel}
                      >
                        CANCEL
                      </button>
                    </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default TransferOtherDepartment;
